import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
  Menu,
  MenuItem,
  Divider,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import CustomizedTableCell from "../../CustomComponents/table/tableCell";
import CustomizedTableHead from "../../CustomComponents/table/tableHead";
import CustomizedTableRow from "../../CustomComponents/table/tableRow";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loaders/Loader";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  allLocations,
  selectedLocation,
  setSelectedLocation,
  getsentimentalLocation,
  requestLoading,
  handleOpenCreateLocationGroupsModel,
  handleOpenDeleteGroupModel,
  groupsPageNumber,
  setGroupsPageNumber,
  rowsPerPage,
  setRowsPerPage,
  locationGroups,
  groupsLoading,
  handleTaskChecked,
  deleteGroups,
  setDeleteGroups,

  selectAll,
  setDeleteMultiple,
  handleOpenDeleteMultipleGroupModel,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const filter = createFilterOptions();

  // const handleChangeCheckButton = (e) => {
  //   setSelectAll(true);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setGroupsPageNumber(0);
  };
  const handleChangePage = (event, newPage) => {
    setGroupsPageNumber(newPage);
  };

  const headCells = [
    /*  {
      padding: "0px",
      alignment: "left",
        label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ), 
    }, */
    { id: "ID", alignment: "left", label: t("ID") },
    { id: "ratings", alignment: "left", label: t("group_names") },
    { id: "updatedBy", alignment: "left", label: t("updated_at") },
    { id: "created_at", alignment: "left", label: t("created_at") },
    /*   { id: "satisfaction", alignment: "right", label: t("Actions") }, */
  ];

  const handleClick = (event, id, selectedData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
    setDeleteMultiple(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    setDeleteMultiple(false);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    setSelectedRow(null);
    setDeleteMultiple(false);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "700px",
              height: "480px",
            },
          }}
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 16px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "3px",
              }}
            >
              {title}
            </Typography>

            <IconButton
              autoFocus
              onClick={handleCloseNewRequestModel}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {groupsLoading ? (
              <Loader />
            ) : locationGroups !== null && locationGroups?.rows?.length > 0 ? (
              <Grid
                container
                spacing={4}
                sx={{
                  height: "auto",
                }}
              >
                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#1B2430",
                      marginTop: "14px",
                    }}
                  >
                    {t("groups_label")}
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {deleteGroups?.length > 0 &&
                      (deletePermission && viewPermission ? (
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: "#FC3652",
                            "&:hover": {
                              backgroundColor: "#ff3366",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() =>
                            handleOpenDeleteMultipleGroupModel(
                              "delete",
                              selectedRow?.id
                            )
                          }
                        >
                          {t("Delete")}
                        </Button>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Button
                              sx={{
                                backgroundColor: "#e0e0e0",
                                color: "white",
                              }}
                              disabled
                              onClick={() =>
                                handleOpenDeleteMultipleGroupModel(
                                  "delete",
                                  selectedRow?.id
                                )
                              }
                            >
                              {t("Delete")}
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      ))}

                    {/*    {addPermission && viewPermission ? (
                      <CommonButton
                        onSubmit={handleOpenCreateLocationGroupsModel}
                        customHeight={true}
                        label={t("create_groups")}
                        icon={<AddIcon sx={{ color: "#FFFF" }} />}
                      />
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <CommonButton
                            onSubmit={handleOpenCreateLocationGroupsModel}
                            customHeight={true}
                            label={t("create_groups")}
                            icon={<AddIcon sx={{ color: "#FFFF" }} />}
                            disabled={true}
                          />
                        </span>
                      </BootstrapTooltip>
                    )} */}
                  </Box>
                </Grid>

                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    marginTop: "12px",
                    overflowY: "auto",
                    maxHeight: "300px",
                    height: "280px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table
                      aria-labelledby="tableTitle"
                      size={"medium"}
                      aria-label="enhanced table"
                    >
                      <TableHead sx={{ padding: "0px" }}>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <CustomizedTableCell
                              sx={{
                                fontWeight: 700,
                                fontSize: "14px",
                                paddingRight: headCell?.padding
                                  ? headCell?.padding
                                  : "26px",
                                paddingLeft: headCell?.padding
                                  ? headCell?.padding
                                  : "16px",
                              }}
                              key={headCell.id}
                              align={headCell.alignment}
                            >
                              {headCell.label}
                            </CustomizedTableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {locationGroups?.rows?.map((row, index) => (
                          <TableRow
                            sx={{ fontWeight: "700px" }}
                            tabIndex={-1}
                            key={index}
                          >
                            {/*  <TableCell
                              hover
                              sx={{ width: "40px", padding: "0px" }}
                            >
                              <Checkbox
                                checked={deleteGroups.includes(row.id)}
                                onChange={(e) => {
                                  handleTaskChecked(e, index, row.id, row);
                                }}
                                label=""
                              />
                            </TableCell> */}

                            <TableCell align="left">{row?.id}</TableCell>

                            <TableCell align="left">{row?.name}</TableCell>
                            <TableCell align="left">
                              {moment(row?.updatedAt).format("MMM DD YYYY")}
                            </TableCell>
                            <TableCell align="left">
                              {moment(row?.createdAt).format("MMM DD YYYY")}
                            </TableCell>
                            {/*    <TableCell padding="none" align="right">
                              <>
                                <>
                                  <IconButton
                                    onClick={(e) =>
                                      handleClick(e, row?.id, row)
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>

                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                  >
                                    {editPermission && viewPermission ? (
                                      <MenuItem
                                        onClick={() => {
                                          handleOpenCreateLocationGroupsModel(
                                            "edit",
                                            selectedRow?.id
                                          );
                                          closeMenu();
                                          handleClose();
                                          // setDeleteId(selectedRow?.id);
                                        }}
                                      >
                                        <Typography>{t("Edit")}</Typography>
                                      </MenuItem>
                                    ) : (
                                      <BootstrapTooltip
                                        title={t("authorized_access")}
                                      >
                                        <span>
                                          <MenuItem
                                            disabled
                                         
                                          >
                                            <Typography>{t("Edit")}</Typography>
                                          </MenuItem>
                                        </span>
                                      </BootstrapTooltip>
                                    )}
                                    <Divider variant="middle" />
                                    {deletePermission && viewPermission ? (
                                      <MenuItem
                                        onClick={() => {
                                          closeMenu();
                                          handleClose();
                                          handleOpenDeleteGroupModel(
                                            "delete",
                                            selectedRow?.id
                                          );
                                          // handleOpenModal(row?.id);
                                        }}
                                      >
                                        <Typography>{t("Delete")}</Typography>
                                      </MenuItem>
                                    ) : (
                                      <BootstrapTooltip
                                        title={t("authorized_access")}
                                      >
                                        <span>
                                          <MenuItem
                                            disabled
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              handleOpenDeleteGroupModel(
                                                "delete",
                                                selectedRow?.id
                                              );
                                              // handleOpenModal(row?.id);
                                            }}
                                          >
                                            <Typography>
                                              {t("Delete")}
                                            </Typography>
                                          </MenuItem>
                                        </span>
                                      </BootstrapTooltip>
                                    )}
                                  </Menu>
                                </>
                              </>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={locationGroups?.count}
                    rowsPerPage={rowsPerPage}
                    page={groupsPageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  height: "320px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Grid item sm={8} md={8} lg={8}>
                  <Typography
                    sx={{
                      lineHeight: "28px",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#1b2430",
                      marginTop: "30px",
                      marginBottom: "15px",
                    }}
                  >
                    {t("No_Groups_Found")}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: "24px",
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#1B2430",

                      marginBottom: "15px",
                    }}
                  >
                    {t("no_group_found_description")}
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg-={12}
                    sm={12}
                    sx={{ paddingTop: "8px" }}
                  >
                    <Link
                      // href={`${profileLink}`}
                      target="_blank"
                      sx={{ cursor: "pointer", fontWeight: 700 }}
                      onClick={() =>
                        navigate("/user/accounts-overview", {
                          state: { tabIndex: 3, page: 0 },
                        })
                      }
                    >
                      {" "}
                      {t("manage_groups")}
                    </Link>
                  </Grid>
                  {/*  {addPermission && viewPermission ? (
                    <CommonButton
                      onSubmit={handleOpenCreateLocationGroupsModel}
                      customHeight={true}
                      label={t("create_groups")}
                      icon={<AddIcon sx={{ color: "#FFFF" }} />}
                    />
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <CommonButton
                          disabled={true}
                          onSubmit={handleOpenCreateLocationGroupsModel}
                          customHeight={true}
                          label={t("create_groups")}
                          icon={<AddIcon sx={{ color: "#FFFF" }} />}
                        />
                      </span>
                    </BootstrapTooltip>
                  )} */}
                </Grid>
              </Grid>
            )}
            {groupsLoading === false &&
              locationGroups !== null &&
              locationGroups?.rows?.length > 0 && (
                <Grid container justifyContent="flex-end">
                  <Grid
                    item
                    xs={3}
                    md={3}
                    lg-={3}
                    sm={3}
                    sx={{ paddingTop: "16px" }}
                  >
                    <Link
                      // href={`${profileLink}`}
                      target="_blank"
                      sx={{ cursor: "pointer", fontWeight: 700 }}
                      onClick={() =>
                        navigate("/user/accounts-overview", {
                          state: { tabIndex: 3 },
                        })
                      }
                    >
                      {" "}
                      {t("manage_groups")}
                    </Link>
                  </Grid>
                </Grid>
              )}
          </DialogContent>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
