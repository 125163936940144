import React, { useEffect, useState } from "react";
import data from "./data.json";
import moment from "moment";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  Tooltip,
  tooltipClasses,
  CircularProgress,
} from "@mui/material";
import BouncingDotsLoader from "../../../../../../components/Loaders/smallBounceLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";
import Tags from "./Components/Tags";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Languagetags from "./Components/languageTags";
import EditModal from "../../Modal";
import { useStyles } from "../../../Styles/style";
import { deepLanguages } from "../../../../../../assets/defaultLanguage/deepL";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function Companydetails({
  locationDetailsData,
  location,
  locationCategory,
  getLocationDetails,
  getLocationDetailsListing,
  lodgingCategories,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [language, setLanguage] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState(null);
  useEffect(() => {
    const defaultLanguage = deepLanguages.find(
      (item) =>
        item?.code ===
        locationDetailsData?.result?.google?.storefrontAddress?.languageCode
    );
    setLanguage(defaultLanguage);
  }, [locationDetailsData]);

  useEffect(() => {
    if (locationCategory !== null) {
      if (locationCategory?.result?.categories?.length > 0) {
        const selectedCategory = locationCategory?.result?.categories?.find(
          (item) =>
            item?.name ===
            locationDetailsData?.result?.google?.categories?.primaryCategory
              ?.name
        );
        setPrimaryCategory(selectedCategory);
      }
    }
  }, [locationCategory]);
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          // onClose={handleCloseTemplateMenu}
        >
          <EditModal
            modalIndex={1}
            location={location}
            locationCategory={locationCategory}
            locationDetailsData={locationDetailsData}
            onCancel={handleCloseTemplateMenu}
            getLocationDetails={getLocationDetails}
            getLocationDetailsListing={getLocationDetailsListing}
            lodgingCategories={lodgingCategories}
            centerData={centerData}
            displayMapError={displayMapError}
          />
        </Dialog>
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {isLoading ? (
          <BouncingDotsLoader />
        ) : (
          <>
            {locationDetailsData !== null ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={8} sm={8} md={10} lg={11}>
                      <Typography className={classes.titleName}>
                        {t("Company_Details")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={1}>
                      {editPermission && viewPermission ? (
                        <Button fullWidth onClick={handleOpenTemplateMenu}>
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Edit")}
                          </Typography>
                        </Button>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Button
                              disabled
                              fullWidth
                              sx={{ cursor: "pointer" }}
                              onClick={handleOpenTemplateMenu}
                            >
                              <Typography sx={{ fontWeight: 600 }}>
                                {t("Edit")}
                              </Typography>
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Location_Name")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.title
                          ? locationDetailsData?.result?.google?.title
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {" "}
                        {t("Address")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      sx={{ display: "block" }}
                    >
                      {locationDetailsData?.result?.google?.storefrontAddress
                        ?.addressLines?.length > 0 ? (
                        <Typography className={classes.headName}>
                          {locationDetailsData?.result?.google?.storefrontAddress?.addressLines?.join(
                            " "
                          )}
                        </Typography>
                      ) : (
                        <Typography className={classes.headName}>
                          {"--"}
                        </Typography>
                      )}

                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.storefrontAddress
                          ?.locality
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.locality
                          : ""}
                      </Typography>

                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.storefrontAddress
                          ?.administrativeArea
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.administrativeArea
                          : ""}
                      </Typography>

                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.storefrontAddress
                          ?.regionCode
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.regionCode
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                  </Grid>
                  <Divider />
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Opening_Date")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.openInfo
                          ?.openingDate?.day &&
                        locationDetailsData?.result?.google?.openInfo
                          ?.openingDate?.month &&
                        locationDetailsData?.result?.google?.openInfo
                          ?.openingDate?.year
                          ? locationDetailsData?.result?.google?.openInfo
                              ?.openingDate?.day +
                            "/" +
                            locationDetailsData?.result?.google?.openInfo
                              ?.openingDate?.month +
                            "/" +
                            locationDetailsData?.result?.google?.openInfo
                              ?.openingDate?.year
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider />
                  {/* <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Language")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headNameDescDetails}>
                        {language !== "" ? language?.name : "--"}
                      </Typography>
                    </Grid>
                  </Grid> */}

                  <Divider />
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Postal_Code")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headNameDescDetails}>
                        {locationDetailsData?.result?.google?.storefrontAddress
                          ?.postalCode
                          ? locationDetailsData?.result?.google
                              ?.storefrontAddress?.postalCode
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  {/*  <Grid
                    container
                      className={classes.categoryGrid}
                  >
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography className={classes.headName}>
                        {t("Primary_Category")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",

                            // minWidth: 350,
                            }}

                        >
                          {primaryCategory !== null && (
                            <Item className={classes.tagsItemBrand}>
                              <Typography className={classes.tagsTextBrands}>
                                {primaryCategory?.displayName}
                              </Typography>
                            </Item>
                          )}
                        </Box>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      sx={{ textAlign: "center" }}
                    >
                      {primaryCategory===null && (
                        <CircularProgress
                          style={{ width: "20px", height: "20px" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Divider /> */}
                  <Grid container className={classes.categoryGrid}>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography className={classes.headName}>
                        {t("Service_Areas")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <div style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            minWidth: 350,
                          }}
                        >
                          {locationDetailsData?.result?.google?.serviceArea !==
                            null &&
                          locationDetailsData?.result?.google?.serviceArea
                            ?.places?.placeInfos?.length > 0
                            ? locationDetailsData?.result?.google?.serviceArea?.places?.placeInfos?.map(
                                (item, index) => (
                                  <Item
                                    className={classes.tagsItemBrand}
                                    key={index}
                                  >
                                    <Typography
                                      className={classes.tagsTextBrands}
                                    >
                                      {item?.placeName}{" "}
                                    </Typography>
                                  </Item>
                                )
                              )
                            : "--"}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NotFound text={t("No_Data_Found")} />
            )}
          </>
        )}
      </Card>
    </>
  );
}
export default Companydetails;
