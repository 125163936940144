import React, { useState, useEffect } from "react";
import data from "../data.json";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function HourTags({ locationDetailsData, getLocationDetails, location }) {
  const [timesArr, setTimesArr] = useState([]);

  useEffect(() => {
    handleDisplayData();
  }, [location, locationDetailsData]);

  const handleDisplayData = () => {
    const finalFilteredData = [];
    const arr2 = [];
    let periodsData =
      locationDetailsData?.result?.google?.regularHours?.periods;
    periodsData?.forEach((itemOne) => {
      let openTimes = [];
      let closeTimes = [];
      let mergedTimes = [];
      periodsData?.forEach((itemtwo) => {
        if (
          itemOne?.openDay === itemtwo?.openDay &&
          itemOne?.closeDay === itemtwo?.closeDay
        ) {
          openTimes?.push(itemtwo.openTime);
          closeTimes.push(itemtwo.closeTime);
          const obj = {
            openHours: itemtwo.openTime,
            closedHours: itemtwo.closeTime,
          };
          mergedTimes.push(obj);
        }
      });
      itemOne.mergedTimes = [...mergedTimes];
      finalFilteredData.push(itemOne);

      finalFilteredData.forEach((item) => {
        let isFound = false;
        arr2.forEach((item2) => {
          if (item.openDay === item2.openDay) {
            isFound = true;
          }
        });
        if (!isFound) {
          //  timesArr.push(item);
          arr2.push(item);
        }
      });
    });
    setTimesArr(arr2);
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const [allData, setAllData] = useState([]);
  const [dataCount, setDataCount] = useState(3);

  const loadMoreData = () => {
    setDataCount(locationDetailsData?.result?.length);
  };
  const loadLessData = () => {
    setDataCount(3);
  };
  return (
    <>
      {timesArr?.length > 0 &&
        timesArr.slice(0, dataCount).map((periods) => {
          return (
            <Grid item xs={12} sm={12} md={12} lg={12} key={periods}>
              <Grid container sx={{ padding: "16px" }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography className={classes.headName}>
                    {/* {periods?.openDay} */}
                    {periods?.openDay === "MONDAY"
                      ? t("monday")
                      : periods?.openDay === "TUESDAY"
                      ? t("tuesday")
                      : periods?.openDay === "WEDNESDAY"
                      ? t("wednesday")
                      : periods?.openDay === "THURSDAY"
                      ? t("thursday")
                      : periods?.openDay === "FRIDAY"
                      ? t("friday")
                      : periods?.openDay === "SATURDAY"
                      ? t("saturday")
                      : periods?.openDay === "SUNDAY"
                      ? t("sunday")
                      : periods?.openDay}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{ textAlign: "End" }}
                >
                  {periods?.mergedTimes?.map((item, index) => (
                    <Typography key={index} className={classes.headName}>
                      {item?.openHours && item?.openHours?.hours
                        ? item?.openHours?.hours >= 10
                          ? item?.openHours?.hours
                          : "0" + item?.openHours?.hours
                        : "00"}
                      :
                      {item?.openHours && item?.openHours?.minutes
                        ? item?.openHours?.minutes >= 10
                          ? item?.openHours?.minutes
                          : "0" + item?.openHours?.minutes
                        : "00"}{" "}
                      -{" "}
                      {item?.closedHours && item?.closedHours?.hours
                        ? item?.closedHours?.hours >= 10
                          ? item?.closedHours?.hours
                          : "0" + item?.closedHours?.hours
                        : "00"}
                      :
                      {item?.closedHours && item?.closedHours?.minutes
                        ? item?.closedHours?.minutes >= 10
                          ? item?.closedHours?.minutes
                          : "0" + item?.closedHours?.minutes
                        : "00"}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          );
        })}
      {timesArr?.length > 3 ? (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ alignItems: "start", padding: "12px" }}
          >
            {dataCount >= 3 ? (
              <Button onClick={loadMoreData}>
                <Typography className={classes.viewMoreButton}>
                  {t("View_More")}
                </Typography>
              </Button>
            ) : (
              <Button onClick={loadLessData}>
                <Typography className={classes.viewMoreButton}>
                  {t("View_Less")}
                </Typography>
              </Button>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default HourTags;
