import React, { useRef, useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import {
  Grid,
  Typography,
  Box,
  Autocomplete,
  Tooltip as MuiToolTip,
  tooltipClasses,
  TextField,
  Button,
  TableContainer,
  TableBody,
  Table,
  Paper,
} from "@mui/material";
import { useStyles } from "../../Style/style";
import { useTranslation } from "react-i18next";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <MuiToolTip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const GroupedChart = ({
  startDate,
  endDate,
  handleDisplayName,
  handleDisplayGroupName,
  reviewDistributionLoading,
  reviewDistributionData,
  setDownloadFormat,
  downloadFormat,
  reviewDistributionTableData,
  setFeaturesObj,
  featuresObj,
  tooltipContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const gridItemRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [downloadValue, setDownloadValue] = useState(null);
  const [locationIds, setLocationIds] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [reviewDistributionSortedData, setReviewDistributionSortedData] =
    useState([]);
  const [tooltipData, setTooltipData] = useState(null);
  const [graphView, setGraphView] = useState(null);
  const [tableView, setTableView] = useState(null);
  const [dataCount, setDataCount] = useState(5);
  const [groupDataCount, setGroupDataCount] = useState(5);
  const [formattedDates, setFormattedDates] = useState([]);
  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);
  useEffect(() => {
    setGraphView("graph");
  }, []);

  const loadMoreData = () => {
    setDataCount(locationIds?.length);
    setGroupDataCount(groupIds?.length);
  };
  const loadLessData = () => {
    if (locationIds?.length >= 5) {
      setDataCount(5);
      setGroupDataCount(0);
    } else {
      setDataCount(locationIds?.length);
      setGroupDataCount(5 - locationIds?.length);
    }
  };
  useEffect(() => {
    if (reviewDistributionTableData?.length > 0) {
      const locationIdss = Array.from(
        new Set(
          reviewDistributionTableData
            .filter((item) => item.locationId !== undefined)
            .map((item) => item.locationId)
        )
      );

      const groupIdss = Array.from(
        new Set(
          reviewDistributionTableData
            .filter((item) => item.groupId !== undefined)
            .map((item) => item.groupId)
        )
      );
      if (locationIdss?.length > 0) {
        if (locationIdss?.length >= 5) {
          setDataCount(5);
          setGroupDataCount(0);
        } else {
          setDataCount(locationIdss?.length);
          setGroupDataCount(5 - locationIdss?.length);
        }
      }

      setLocationIds(locationIdss);
      setGroupIds(groupIdss);
    }
  }, [reviewDistributionTableData]);

  useEffect(() => {
    if (reviewDistributionData?.length > 0) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const durationInMonths = end.diff(start, "days");
      let sortedData = [];

      if (durationInMonths <= 60) {
        sortedData = reviewDistributionData?.sort((a, b) => {
          const dateA = new Date(a?.date);
          const dateB = new Date(b?.date);
          return dateA - dateB;
        });
      } else {
        sortedData = reviewDistributionData.sort((a, b) => {
          const [yearA, monthA, dayA] = a?.date.split("-").map(Number);
          const [yearB, monthB, dayB] = b?.date?.split("-").map(Number);

          if (yearA !== yearB) {
            return yearA - yearB;
          } else if (monthA !== monthB) {
            return monthA - monthB;
          } else {
            return dayA - dayB;
          }
        });
      }

      const groupedData = handleGroupedData(sortedData);
      setReviewDistributionSortedData(groupedData);
    }
  }, [reviewDistributionData]);

  useEffect(() => {
    if (gridItemRef.current) {
      const gridItemWidth = gridItemRef.current.clientWidth;

      const newChartWidth = gridItemWidth - 20;

      setChartWidth(newChartWidth);
    }
  }, [reviewDistributionSortedData]);

  useEffect(() => {
    if (reviewDistributionData?.length > 0) {
      const monthsArray = getMonthsBetweenDates(startDate, endDate);

      const labelsForDataMappingInMonths = monthsArray?.map((date) => {
        const monthNames = [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ];
        const startDateOne = dayjs(date);
        const jsDate = startDateOne.toDate();

        const month = monthNames[jsDate.getMonth()]; // Get the month abbreviation
        const year = jsDate.getFullYear();
        return `${year}-${month}`;
      });

      const uniqueLables = Array.from(
        new Set(
          labelsForDataMappingInMonths?.map((item) => {
            return item;
          })
        )
      );

      setFormattedDates(uniqueLables);
    }
  }, [reviewDistributionData]);

  const handleGroupedData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const { ratingValue, reviews } = item;
      let xAxisLabel = "";

      if (!item.date) {
        return acc; // Skip items without a valid dateOfAverage
      }
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const durationInMonths = end.diff(start, "days");

      if (durationInMonths > 60) {
        // Display month-year data
        const [year, month] = item.date.split("-");
        const monthYear = new Date(`${year}-${month}-01`).toLocaleString(
          "default",
          {
            month: "short",
            year: "numeric",
          }
        );
        xAxisLabel = monthYear;
      } else {
        // Display day-wise data
        const [year, month, day] = item.date.split("-");
        const date = new Date(`${year}-${month}-${day}`);
        if (!isNaN(date.getTime())) {
          const monthName = date.toLocaleString("default", {
            month: "short",
          });
          xAxisLabel = `${day} ${monthName} ${year.split("-")[0].slice(2)}`;
        }
      }

      if (!acc[xAxisLabel]) {
        acc[xAxisLabel] = {
          xAxisLabel,
          oneStar: 0,
          twoStar: 0,
          threeStar: 0,
          fourStar: 0,
          fiveStar: 0,
        };
      }

      switch (ratingValue) {
        case 1:
          acc[xAxisLabel].oneStar += parseInt(reviews);
          break;
        case 2:
          acc[xAxisLabel].twoStar += parseInt(reviews);
          break;
        case 3:
          acc[xAxisLabel].threeStar += parseInt(reviews);
          break;
        case 4:
          acc[xAxisLabel].fourStar += parseInt(reviews);
          break;
        case 5:
          acc[xAxisLabel].fiveStar += parseInt(reviews);
          break;
        default:
          break;
      }

      return acc;
    }, {});

    const result = Object.values(groupedData);

    return result;
  };
  const handleKeywordsDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);

    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "reviewDistribution") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];

  const handleDisplayMonth = (monthValue) => {
    let formatedValue;

    const [year, month] = monthValue.split("-");

    const monthYear = new Date(`${year}-${month}-01`).toLocaleString(
      "default",
      {
        month: "short",
        year: "numeric",
      }
    );
    formatedValue = monthYear;
    return formatedValue;
  };

  const getMonthsBetweenDates = (startDates, endDates) => {
    const months = [];
    let currentDate = new Date(startDates);
    currentDate.setDate(1);

    while (currentDate < endDates) {
      months.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    months.push(endDates);
    return months;
  };
  function handleArrows(value, lastValue) {
    let precision = "positive";
    const difference = value - lastValue;
    if (difference === 0) {
      precision = "neutral";
    } else if (difference > 0) {
      precision = "positive";
    } else {
      precision = "negative";
    }
    return precision;
  }

  const handleClickGraph = () => {
    setGraphView("graph");
    setTableView(null);
  };

  const handleClickTable = () => {
    setTableView("table");
    setGraphView(null);
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={7.7} sm={7.7} md={7.7} lg={7.7}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.subHeadRatingReviewDetail}>
                {t("Review_Distribution")}
              </Typography>
              <BootstrapTooltip
                title={
                  graphView
                    ? t("Review_Distribution_Over_Time_Tooltip")
                    : t("rating_distribution_tooltipTwo")
                }
              >
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            sx={{
              border: "1px solid #C4C4C4",
              height: "52px",
              borderRadius: "4px",
              padding: "8px",
              marginTop: "7.5px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                onClick={handleClickGraph}
                className={
                  graphView
                    ? classes.graphButtonDivSelected
                    : classes.graphButtonDiv
                }
              >
                <Typography className={classes.graphButtonTypo}>
                  {t("graph_text")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                onClick={handleClickTable}
                className={
                  tableView
                    ? classes.graphButtonDivSelected
                    : classes.graphButtonDiv
                }
              >
                <Typography className={classes.graphButtonTypo}>
                  {t("table_text")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
            <Autocomplete
              id="tags-outlined"
              options={downloadOptions}
              value={downloadValue}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              onChange={handleKeywordsDownloadChange}
              renderInput={(params) => (
                <TextField {...params} placeholder={t("actionss")} />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {reviewDistributionLoading ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "300px",
            alignItems: "center",
            borderLeft: "1px solid #9d9d9d",
            borderBottom: "1px solid #9d9d9d",
            paddingX: "8px",
          }}
        >
          <WaveSpinner />
        </Box>
      ) : reviewDistributionData?.length > 0 &&
        reviewDistributionSortedData?.length > 0 ? (
        <Grid container>
          {graphView && (
            <Grid item xs={12} sm={12} md={12} lg={12} ref={gridItemRef}>
              {chartWidth && (
                <BarChart
                  width={chartWidth}
                  height={450}
                  data={reviewDistributionSortedData}
                  layout="horizontal"
                  ref={chartRef}
                >
                  <Legend
                    payload={[
                      {
                        value: `${t("1_Star")}`,
                        type: "circle",
                        id: "oneStar",
                        color: "#ec2715",
                      },
                      {
                        value: `${t("2_Star")}`,
                        type: "circle",
                        id: "twoStar",
                        color: "#f2742e",
                      },
                      {
                        value: `${t("3_Star")}`,
                        type: "circle",
                        id: "threeStar",
                        color: "#ffc130",
                      },
                      {
                        value: `${t("4_Star")}`,
                        type: "circle",
                        id: "fourStar",
                        color: "#8fb02b",
                      },
                      {
                        value: `${t("5_Star")}`,
                        type: "circle",
                        id: "fiveStar",
                        color: "#239f25",
                      },
                    ]}
                  />
                  <YAxis type="number" />

                  <XAxis
                    dataKey="xAxisLabel"
                    type="category"
                    // interval={20}

                    width={150}
                    textAnchor="middle"
                    height={100}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          tooltipData !== null && (
                            <div className={classes.customTooltipReview}>
                              <Typography
                                className={classes.toolTipStyleReview}
                              >
                                {tooltipData !== null &&
                                  tooltipData?.xAxisLabel}
                              </Typography>
                              <Typography
                                className={classes.toolTipStyleReview}
                              >
                                {tooltipData !== null &&
                                  tooltipData?.starValue +
                                    ":" +
                                    " " +
                                    (
                                      (tooltipData.starCount /
                                        tooltipData?.total) *
                                      100
                                    )?.toFixed() +
                                    "%"}
                              </Typography>
                            </div>
                          )
                        );
                      }

                      return null;
                    }}
                  />

                  <Legend />

                  <Bar
                    isAnimationActive={false}
                    dataKey="oneStar"
                    stackId="a"
                    key={Math.random()}
                    barSize={40}
                    fill="#ec2715"
                    onMouseEnter={(event, data) => {
                      if (
                        event.payload &&
                        event.payload.xAxisLabel &&
                        event.payload.oneStar !== undefined
                      ) {
                        setTooltipData({
                          starValue: t("1_Star"),
                          starCount: event.payload.oneStar,
                          xAxisLabel: event.payload?.xAxisLabel,
                          total:
                            event.payload.oneStar +
                            event.payload.twoStar +
                            event.payload.threeStar +
                            event.payload.fourStar +
                            event.payload.fiveStar,
                        });
                      } else {
                        setTooltipData(null);
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                  ></Bar>
                  <Bar
                    isAnimationActive={false}
                    dataKey="twoStar"
                    stackId="a"
                    fill="#f2742e"
                    barSize={40}
                    onMouseEnter={(event, data) => {
                      if (
                        event.payload &&
                        event.payload.xAxisLabel &&
                        event.payload.twoStar !== undefined
                      ) {
                        setTooltipData({
                          starValue: t("2_Star"),
                          starCount: event.payload.twoStar,
                          xAxisLabel: event.payload?.xAxisLabel,
                          total:
                            event.payload.oneStar +
                            event.payload.twoStar +
                            event.payload.threeStar +
                            event.payload.fourStar +
                            event.payload.fiveStar,
                        });
                      } else {
                        setTooltipData(null);
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                  ></Bar>
                  <Bar
                    isAnimationActive={false}
                    dataKey="threeStar"
                    stackId="a"
                    fill="#ffc130"
                    barSize={40}
                    onMouseEnter={(event, data) => {
                      if (
                        event.payload &&
                        event.payload.xAxisLabel &&
                        event.payload.threeStar !== undefined
                      ) {
                        setTooltipData({
                          starValue: t("3_Star"),
                          starCount: event.payload.threeStar,
                          xAxisLabel: event.payload?.xAxisLabel,
                          total:
                            event.payload.oneStar +
                            event.payload.twoStar +
                            event.payload.threeStar +
                            event.payload.fourStar +
                            event.payload.fiveStar,
                        });
                      } else {
                        setTooltipData(null); // Hide tooltip if data is empty
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                  ></Bar>
                  <Bar
                    isAnimationActive={false}
                    dataKey="fourStar"
                    stackId="a"
                    fill="#8fb02b"
                    barSize={40}
                    onMouseEnter={(event, data) => {
                      if (
                        event.payload &&
                        event.payload.xAxisLabel &&
                        event.payload.fourStar !== undefined
                      ) {
                        setTooltipData({
                          starValue: t("4_Star"),
                          starCount: event.payload.fourStar,
                          xAxisLabel: event.payload?.xAxisLabel,
                          total:
                            event.payload.oneStar +
                            event.payload.twoStar +
                            event.payload.threeStar +
                            event.payload.fourStar +
                            event.payload.fiveStar,
                        });
                      } else {
                        setTooltipData(null);
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                  ></Bar>
                  <Bar
                    isAnimationActive={false}
                    dataKey="fiveStar"
                    stackId="a"
                    fill="#239f25"
                    barSize={40}
                    onMouseEnter={(event, data) => {
                      if (
                        event.payload &&
                        event.payload.xAxisLabel &&
                        event.payload.fiveStar !== undefined
                      ) {
                        setTooltipData({
                          starValue: t("5_Star"),
                          starCount: event.payload.fiveStar,
                          xAxisLabel: event.payload?.xAxisLabel,
                          total:
                            event.payload.oneStar +
                            event.payload.twoStar +
                            event.payload.threeStar +
                            event.payload.fourStar +
                            event.payload.fiveStar,
                        });
                      } else {
                        setTooltipData(null); // Hide tooltip if data is empty
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                  ></Bar>
                </BarChart>
              )}
            </Grid>
          )}
          {tableView && (
            <Grid
              container
              className={classes.tableContainer}
              ref={gridItemRef}
            >
              <TableContainer component={Paper}>
                <Table>
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      <CustomizedTableCell
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          paddingRight: "70px",
                        }}
                      >
                        {t("Name")}
                      </CustomizedTableCell>
                      {Array.from(
                        new Set(
                          formattedDates?.map((item) => {
                            return item;
                          })
                        )
                      )
                        .slice(-7)
                        .sort((a, b) => new Date(a) - new Date(b))
                        .map((month) => (
                          <CustomizedTableCell
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              width: "200px",
                            }}
                            key={month}
                          >
                            {handleDisplayMonth(month)}
                          </CustomizedTableCell>
                        ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>
                  <TableBody>
                    {locationIds?.length > 0 &&
                      locationIds?.slice(0, dataCount).map((locationId) => (
                        <CustomizedTableRow
                          key={locationId}
                          sx={{
                            height: "100px",
                          }}
                        >
                          <CustomizedTableCell>
                            {handleDisplayName(locationId)}
                          </CustomizedTableCell>
                          {Array.from(
                            new Set(
                              formattedDates?.map((item) => {
                                return item;
                              })
                            )
                          )
                            .slice(-7)
                            .sort()
                            .map((month) => {
                              const rating =
                                reviewDistributionTableData?.find(
                                  (item) =>
                                    item.locationId === locationId &&
                                    item.dateOfAverage === month
                                )?.thisMonthRating || "0";
                              const lastMonthRating =
                                reviewDistributionTableData?.find(
                                  (item) =>
                                    item.locationId === locationId &&
                                    item.dateOfAverage === month
                                )?.lastMonthRating || "0";
                              return (
                                <CustomizedTableCell
                                  key={month}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`${tooltipContent}`}
                                      </h1>
                                    }
                                  >
                                    <span
                                      style={{
                                        color:
                                          rating >= 4.2
                                            ? "#13CF8F"
                                            : rating >= 4.0 && rating < 4.2
                                            ? "#F8A92B"
                                            : "#FF1616",
                                      }}
                                    >
                                      {rating}

                                      {handleArrows(
                                        rating !== null &&
                                          !isNaN(rating) &&
                                          isFinite(rating)
                                          ? rating
                                          : 0,
                                        lastMonthRating !== null &&
                                          !isNaN(lastMonthRating) &&
                                          isFinite(lastMonthRating)
                                          ? lastMonthRating
                                          : 0
                                      ) === "negative" ? (
                                        <ArrowDownwardIcon
                                          className={classes.redIconClass}
                                        />
                                      ) : handleArrows(
                                          rating !== null &&
                                            !isNaN(rating) &&
                                            isFinite(rating)
                                            ? rating
                                            : 0,
                                          lastMonthRating !== null &&
                                            !isNaN(lastMonthRating) &&
                                            isFinite(lastMonthRating)
                                            ? lastMonthRating
                                            : 0
                                        ) === "positive" ? (
                                        <ArrowUpwardIcon
                                          className={classes.greenIconClass}
                                        />
                                      ) : (
                                        <ArrowForwardIcon
                                          className={classes.orangeIconClass}
                                        />
                                      )}
                                    </span>
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                              );
                            })}
                        </CustomizedTableRow>
                      ))}

                    {groupIds?.length > 0 &&
                      groupIds?.slice(0, groupDataCount)?.map((groupId) => (
                        <CustomizedTableRow
                          key={groupId}
                          sx={{
                            height: "100px",
                          }}
                        >
                          <CustomizedTableCell>
                            {handleDisplayGroupName(groupId)}
                          </CustomizedTableCell>
                          {Array.from(
                            new Set(
                              formattedDates?.map((item) => {
                                return item;
                              })
                            )
                          )
                            .slice(-7)
                            .sort()
                            .map((month) => {
                              const rating =
                                reviewDistributionTableData?.find(
                                  (item) =>
                                    item.groupId === groupId &&
                                    item.dateOfAverage === month
                                )?.thisMonthRating || "0";
                              const lastMonthRating =
                                reviewDistributionTableData?.find(
                                  (item) =>
                                    item.groupId === groupId &&
                                    item.dateOfAverage === month
                                )?.lastMonthRating || "0";
                              return (
                                <CustomizedTableCell
                                  key={month}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`${tooltipContent}`}
                                      </h1>
                                    }
                                  >
                                    <span
                                      style={{
                                        color:
                                          rating >= 4.2
                                            ? "#13CF8F"
                                            : rating >= 4.0 && rating < 4.2
                                            ? "#F8A92B"
                                            : "#FF1616",
                                      }}
                                    >
                                      {rating}

                                      {handleArrows(
                                        rating !== null &&
                                          !isNaN(rating) &&
                                          isFinite(rating)
                                          ? rating
                                          : 0,
                                        lastMonthRating !== null &&
                                          !isNaN(lastMonthRating) &&
                                          isFinite(lastMonthRating)
                                          ? lastMonthRating
                                          : 0
                                      ) === "negative" ? (
                                        <ArrowDownwardIcon
                                          className={classes.redIconClass}
                                        />
                                      ) : handleArrows(
                                          rating !== null &&
                                            !isNaN(rating) &&
                                            isFinite(rating)
                                            ? rating
                                            : 0,
                                          lastMonthRating !== null &&
                                            !isNaN(lastMonthRating) &&
                                            isFinite(lastMonthRating)
                                            ? lastMonthRating
                                            : 0
                                        ) === "positive" ? (
                                        <ArrowUpwardIcon
                                          className={classes.greenIconClass}
                                        />
                                      ) : (
                                        <ArrowForwardIcon
                                          className={classes.orangeIconClass}
                                        />
                                      )}
                                    </span>
                                  </BootstrapTooltip>
                                </CustomizedTableCell>
                              );
                            })}
                        </CustomizedTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {locationIds.length + groupIds?.length > 5 && (
                <Grid container sx={{ background: "#FFF" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.viewMoreButtonGrid}
                  >
                    {dataCount + groupDataCount === 5 ? (
                      <Button onClick={loadMoreData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    ) : (
                      <Button onClick={loadLessData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_Less")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <NoDataFound />
      )}
    </Grid>
  );
};

export default GroupedChart;
