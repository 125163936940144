import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../../contexts/JWTContext.js";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  Select,
  MenuItem,
  tooltipClasses,
  Autocomplete,
  TextField,
  createFilterOptions,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { languagesData } from "../../../../assets/defaultLanguage/index.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton.js";
import Loader from "../../../../components/Loaders/Loader.js";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../EmotionAi/Style/style.js";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dayjs from "dayjs";
import Collapsible from "../../../../components/CustomComponents/Collapsible.js";
import DatePickerComponent from "../Components/RangePickerResults.js";
import FilterModal from "../../../../components/Models/GoogleStatsFilterModel";
import moment from "moment";
import LogoImageModal from "./UploadModels/CoverImageModal";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  open,
  title,
  handleCloseNewRequestModel,
  others,
  setOthers,
  companyUser,
  setCompanyUser,
  recipientError,
  setRecipientError,
  recipientEmailError,
  setRecipientEmailError,
  recipientEmail,
  setRecipientEmail,
  recipientValue,
  setRecipientValue,
  emailReportRef,
  setSelectedCompanyUsers,
  selectedCompanyUsers,
  totalEmailsArr,
  isModalEmailsSent,
  handleAddNewUserEmailsChange,
  addNewUserEmials,
  handleResetOthers,
  handleButtonClick,
  exportReportLoading,
  setEmailSubject,
  emailSubject,
  emailBody,
  setEmailBody,
  handleScheduleReport,
  scheduleReportLoading,
  repeatValue,
  setRepeatValue,
  //  getSingleSelectedReportById={getSingleSelectedReportById}
  singleReportData,
  handleUpdateScheduledReport,
  updateReportLoading,
  emailTitle,
  setEmailTitle,
  startDateSchedule,
  setStartDateSchedule,
  endDateSchedule,
  setEndDateSchedule,
  handleChangeStartDateSchedule,
  handleChangeEndDateSchedule,
  setDateRange,
  dateRange,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,

  endDateRangeSchedule,
  handleChangeRangeStartDateSchedule,
  startDateRangeSchedule,
  setStartDateRangeSchedule,
  setEndDateRangeSchedule,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  setScheduleLanguage,
  scheduleLanguage,
  allLocations,
  allGroups,
  startDate,
  endDate,
  setNextEndDateSchedule,
  nextStartDateSchedule,
  nextEndDateSchedule,
  setNextStartDateSchedule,
  nextEndDate,
  nextStartDate,
  locations,
  selectedGroups,
  displayReportError,
  setDisplayReportError,
  setReportScheduleLocations,
  countries,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  selectAllGroups,
  setSelectAllLocations,
  selectAllLocations,
  getSingleSelectedReportById,
  dateRangeSchedule,
  setDateRangeSchedule,
  scheduleCountries,
  selectAllScheduleCountries,
  setSelectAllScheduleCountries,
  selectAllScheduleLocations,
  setSelectAllScheduleLocations,
  selectAllScheduleGroups,
  setSelectAllScheduleGroups,
  setCallApi,
  resetStart,
  resetEnd,
  setScheduleCountries,
  oldScheduleCountries,
  setOldScheduleCountries,
  getCountryWiseLocations,
  oldScheduleGroups,
  removeDuplicateLocaions,
  filterArrayById,
  setOldScheduleGroups,
  filterArrayByIdForGroups,
  fileInputRef,
  handleReopenModal,
  handleRemoveFile,
  handleFileUpload,
  selectedFile,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  setUploadedImages,
  setImages,
  uploadedImages,
  images,
  setDisabledStartDateSchedule,
  disabledStartDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const textArea = useRef();
  const location = useLocation();
  const currentPath = location.pathname;
  const [users, setUsers] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState(["configureReport"]);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [selectAll, setSelectAll] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [startDateInvalid, setStartDateInvalid] = useState(null);
  const [emailSubjectRequired, setEmailSubjectRequired] = useState(false);
  const [greaterError, setGreaterError] = useState(false);
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [openReviewsFilterModel, setOpenReviewsFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [selectedFileImage, setSelectedImageFile] = useState(null);

  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllFiltersGroups, setSelectAllFiltersGroups] =
    useState(selectAllGroups);
  const timePeriod = [
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  useEffect(() => {
    getAllUsers();
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
    /* if (setScheduleLanguage) {
      setScheduleLanguage(userLang);
    } */
    getSingleSelectedReportById();
  }, [open]);

  useEffect(() => {
    if (singleReportData !== null && singleReportData !== undefined) {
      setDisplayReportError(false);
      const userLang = languagesData?.find(
        (item) => item?.value === i18nextLng
      );

      setDateRangeSchedule({
        startDate: new Date(singleReportData?.filter?.startDate),
        endDate: new Date(singleReportData?.filter?.endDate),
        range: singleReportData?.filter?.dateRange,
      });

      if (singleReportData?.ReportScheduleLocations?.length > 0) {
        const uniqueLocations = [];
        const uniqueIds = new Set();

        for (const postLocation of singleReportData?.ReportScheduleLocations) {
          const locationId = postLocation?.Location?.id;

          if (!uniqueIds.has(locationId)) {
            uniqueIds.add(locationId);
            uniqueLocations.push(postLocation);
          }
        }
        setReportScheduleLocations(uniqueLocations);
      } else {
        setReportScheduleLocations([]);
      }
      if (singleReportData?.fileType === "both") {
        setPdfChecked(true);
        setXlsChecked(true);
      } else if (singleReportData?.fileType === "pdf") {
        setPdfChecked(true);
        setXlsChecked(false);
      } else {
        setXlsChecked(true);
        setPdfChecked(false);
      }
      if (singleReportData?.filter?.country?.length > 0) {
        let arr = [];
        singleReportData?.filter?.country?.map((item) => {
          countries?.map((itemTwo) => {
            if (item === itemTwo?.countryCode) {
              arr.push(itemTwo);
            }
          });
        });
        setScheduleCountries(arr);
      }
      setStartDateSchedule(singleReportData?.filter?.startDate);
      setEndDateSchedule(singleReportData?.filter?.endDate);
      setNextStartDateSchedule(singleReportData?.filter?.nextStartDate);
      setNextEndDateSchedule(singleReportData?.filter?.nextEndDate);
      if (singleReportData?.filter?.locationIds?.length > 0) {
        let locationArr = [];
        singleReportData?.filter?.locationIds?.map((item) => {
          allLocations?.map((itemTwo) => {
            if (item === itemTwo?.id) {
              locationArr?.push(itemTwo);
            }
          });
        });

        setScheduleLocations(locationArr);
      } else {
        setScheduleLocations([]);
      }

      if (singleReportData?.filter?.groupIds?.length > 0) {
        let groupsArr = [];
        singleReportData?.filter?.groupIds?.map((item) => {
          allGroups?.rows?.map((itemTwo) => {
            if (item === itemTwo?.id) {
              groupsArr?.push(itemTwo);
            }
          });
        });
        setScheduleGroups(groupsArr);
      } else {
        setScheduleGroups([]);
      }

      setEmailSubject(singleReportData?.emailSubject);
      setEmailBody(singleReportData?.emailBody);
      setRepeatValue(singleReportData?.repeatType);
      setEmailTitle(singleReportData?.title);
      setStartDateRangeSchedule(singleReportData?.startDate);
      setEndDateRangeSchedule(singleReportData?.endDate);

      if (
        singleReportData?.customLogo !== null &&
        singleReportData?.customLogo !== ""
      ) {
        let tempImage = {
          name: singleReportData?.customLogo,
        };
        setSelectedImageFile(tempImage);
        setUploadedImages([singleReportData?.customLogo]);
      } else {
        setSelectedImageFile(null);
        setUploadedImages([]);
      }

      const userLangReport = languagesData?.find(
        (item) => item?.language === singleReportData?.language
      );
      if (userLangReport !== undefined) {
        setLanguageSchedular(userLangReport);
      } else {
        setLanguageSchedular(userLang);
      }
      const selectedEmails = JSON.parse(singleReportData?.emails);

      if (selectedEmails?.length > 0) {
        let emailsArr = [];
        let foundMatch = false; // Flag to track if a match is found for the current item in selectedEmails

        selectedEmails?.forEach((item) => {
          let matchFound = false; // Flag to check if a match is found for the current item in users array

          users?.forEach((itemTwo) => {
            if (item === itemTwo?.User?.email) {
              selectedCompanyUsers?.push(itemTwo);
              foundMatch = true; // Update flag as a match is found for this item in selectedEmails
              matchFound = true; // Update flag as a match is found for this item in users array
            }
          });

          if (!matchFound) {
            emailsArr?.push(item);
            /*    handleAddNewUserEmailsChange(item);
            setOthers(true); */
          }
        });
        if (emailsArr?.length > 0) {
          const commaSeparatedString = emailsArr.join(",\n");
          handleAddNewUserEmailsChange(commaSeparatedString);
          setOthers(true);
        }
      }
    }
  }, [singleReportData]);

  useEffect(() => {
    if (
      startDateRangeSchedule &&
      endDateRangeSchedule &&
      dayjs(startDateRangeSchedule).isAfter(endDateRangeSchedule)
    ) {
      setGreaterError(true);
    } else {
      setGreaterError(false);
    }
  }, [startDateRangeSchedule, endDateRangeSchedule]);

  const handleChangeOthers = (e) => {
    setOthers(e.target.checked);
    if (e.target.checked === false) {
      handleResetOthers();
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?companyId=${companyData?.id}&pageNumber=1&limit=500`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailTitle(value);
    setTitleError(value.trim() === "");
    setDisplayReportError(false);
  };
  const handleChangeCompanyUser = (e) => {
    setCompanyUser(e.target.checked);
    // setOthers(false);
    if (e.target.checked === false) {
      setSelectedCompanyUsers([]);
    }
  };

  const handleChangeCountries = (value) => {
    if (value?.length > 0) {
      if (value.find((option) => option.id === 0)) {
        // Check if all options are selected
        if (countries?.length === scheduleCountries?.length) {
          setScheduleCountries([]);
          setSelectAllScheduleCountries(false);
        } else {
          setScheduleCountries(true);
          setSelectAllScheduleCountries(countries);
          const googleLinkedLocations = allLocations?.filter(
            (item) =>
              item?.status === "approved" &&
              item.socialLink.length > 0 &&
              item.socialLink.some(
                (link) =>
                  link?.type === "google" &&
                  link?.statusOfGoogleInsight === "Done"
              )
          );
          setScheduleLocations(googleLinkedLocations);
        }
      } else {
        setScheduleCountries(value);

        handleChangeLocationsBySelectingCountries(value);
        if (value?.length === countries?.length) {
          setSelectAllScheduleCountries(true);
        } else {
          setSelectAllScheduleCountries(false);
        }
      }
    } else {
      setScheduleCountries([]);
      // setLocation([]);
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...scheduleLocations];
    const results = oldScheduleCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  scheduleLocations?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );

    setScheduleLocations(googleLinkedLocations);

    setOldScheduleCountries(value);
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (scheduleCountries?.length > 0) {
      scheduleCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeGroups = (value, autoSelectValue) => {
    setScheduleGroups(value);

    let newLocations = [...scheduleLocations];
    const results = oldScheduleGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations?.push(locationItem);
            } else {
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  scheduleLocations?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );

                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);
    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    setScheduleLocations(googleLinkedLocations);
    setOldScheduleGroups(value);
  };

  function monthDiff(dateFrom, dateTo) {
    // Convert dayjs objects to native Date objects
    const fromDate = dateFrom instanceof dayjs ? dateFrom.toDate() : dateFrom;
    const toDate = dateTo instanceof dayjs ? dateTo.toDate() : dateTo;

    return (
      toDate.getMonth() -
      fromDate.getMonth() +
      12 * (toDate.getFullYear() - fromDate.getFullYear())
    );
  }

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
  };

  const handleEmailSubjectChange = (e) => {
    const value = e.target.value;
    setEmailSubject(value);
    setEmailSubjectRequired(value.trim() === "");
  };
  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllFiltersGroups(selectAllGroups);
    }
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleChangeScheduleStartDate = (e) => {
    setStartDateRangeSchedule(e);

    setEmailBody(
      `Dear customer Please find attached google insights report for the ${dayjs(
        startDateSchedule
      ).format("MMM DD, YYYY")} to ${dayjs(endDateSchedule).format(
        "MMM DD, YYYY"
      )}`
    );
  };
  const handleChangeScheduleEndDate = (e) => {
    setEndDateRangeSchedule(e);
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveFileImage = () => {
    setSelectedImageFile(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "850px",
              height: "550px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle className={classes.emailReportTitle}>
            {title}

            {/*   {currentPath==="/user/reviews" && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                }}
                style={{ color: "#495059" }}
              >
                {t("reviews_report_subTitle")}
              </Typography>
            )} */}
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid item xs={12} md={12} sm={12} lg={12}>
                {currentPath !== "/user/reviews" &&
                  locations?.length === 0 &&
                  scheduleLocations?.length === 0 &&
                  scheduleGroups?.length === 0 &&
                  selectedGroups?.length === 0 && (
                    <Alert mt={2} mb={3} severity="error">
                      {t("Please select a location or group to proceede")}
                    </Alert>
                  )}
                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("configure_report")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureReport")}
                    id={"configureReport"}
                    ComponentToRender={
                      <Grid container>
                        <Typography className={classes.emailReportLabel} mt={1}>
                          {t("configure_report")}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container sx={{ marginLeft: "-10px" }}>
                              <Grid item xs={3}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    onChange={handleChangePdfChecked}
                                    checked={pdfChecked}
                                  />
                                  <Typography>{"PDF"}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    onChange={handleChangeXlsChecked}
                                    checked={xlsChecked}
                                  />
                                  <Typography>{"XLS"}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.emailReportLabel}>
                              {t("upload_logo")}
                            </Typography>

                            <Box mt={3} ml={-3}>
                              <CommonButton
                                onSubmit={handleOpenLogoUploadModal}
                                label={t("Select_logo")}
                              />
                            </Box>
                            <Box>
                              {selectedFileImage ? (
                                <Box className={classes.fileBoxStyle}>
                                  <Typography
                                    // onClick={handleReopenModal}
                                    className={classes.selectedFileTypo}
                                  >
                                    {selectedFileImage?.name}
                                  </Typography>
                                  <IconButton
                                    className={classes.removeFileButton}
                                    onClick={() => handleRemoveFileImage()}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      className={classes.deleteIcon}
                                    />
                                  </IconButton>
                                </Box>
                              ) : (
                                <p>{t("")}</p>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_name")}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder={t("enter_title")}
                              value={emailTitle}
                              fullWidth
                              onChange={handleInputChange}
                              error={titleError || displayReportError}
                              helperText={
                                titleError
                                  ? t("Field_req")
                                  : displayReportError
                                  ? t("report_name_exists")
                                  : ""
                              }
                            />
                          </Grid>
                          {/*   <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_range")}
                            </Typography>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={dateRangeSchedule?.range}
                              onChange={(e) => {
                                handleDateRangChange(e, false);
                              }}
                              fullWidth
                              //  error={dateRangeError}
                              helperText={dateRangeError ? t("Field_req") : ""}
                            >
                              <MenuItem value="month">
                                {t("monthValue")}
                              </MenuItem>
                              <MenuItem value="thisMonth">
                                {" "}
                                {t("This_Month")}
                              </MenuItem>
                              <MenuItem value="12months">
                                {t("12months")}
                              </MenuItem>
                              <MenuItem value="custom">
                                {t("custom_text")}
                              </MenuItem>
                            </Select>
                          </Grid> */}

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={10}
                            lg={10}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_language")}
                            </Typography>
                            <Select
                              required
                              // defaultValue={"en"}
                              style={{ width: "100%" }}
                              key={languageSchedular?.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={languageSchedular?.value}
                              // label="Select"
                              onChange={(e, value) => {
                                const userLang = languagesData?.find(
                                  (item) => item?.value === e.target.value
                                );
                                handleChangeLanguageForScheduler(userLang);
                              }}
                            >
                              {languagesData.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.language}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            sx={{ paddingTop: "0px", textAlign: "end" }}
                          >
                            <Box sx={{ marginTop: "25px" }}>
                              <CommonButton
                                icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                                onSubmit={handleOpenFilterModel}
                                label={t("Filters")}
                              />
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("Select_Date_Range")}
                          </Typography>
                        </Grid>
                        <DatePickerComponent
                          handleChangeStartDate={handleChangeStartDateSchedule}
                          handleChangeEndDate={handleChangeEndDateSchedule}
                          startDate={
                            startDateSchedule !== null
                              ? startDateSchedule
                              : null
                          }
                          endDate={
                            endDateSchedule !== null ? endDateSchedule : null
                          }
                          dateRange={dateRangeSchedule}
                          setDateRange={setDateRangeSchedule}
                          fromFilters={true}
                          rangeValue={"between"}
                          removePadding={false}
                          fromGoogleInsight={true}
                          setEmailBody={setEmailBody}
                        />
                      </Grid>
                    }
                  />
                </Box>

                {/***********************2rd section */}
                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("Schedule_email")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("scheduleEmail")}
                    id={"scheduleEmail"}
                    ComponentToRender={
                      <Grid container>
                        {/*   <Typography className={classes.emailReportLabel} mt={1}>
                          {t("Schedule_email")}
                        </Typography> */}

                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("send_email")}
                              </Typography>

                              <BootstrapTooltip
                                title={t("repeate_type_tooltip")}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "5px",
                                    marginTop: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </Typography>
                              </BootstrapTooltip>
                            </Box>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={repeatValue}
                              onChange={(e) => {
                                setRepeatValue(e.target.value);
                              }}
                              fullWidth
                            >
                              <MenuItem value="no">{t("only_once")}</MenuItem>
                              <MenuItem value="daily">
                                {t("every_day")}
                              </MenuItem>
                              <MenuItem value="weekly">
                                {t("every_week")}
                              </MenuItem>
                              <MenuItem value="monthly">
                                {t("every_month")}
                              </MenuItem>
                              <MenuItem value="yearly">
                                {t("every_year")}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Start_date")}
                              </Typography>

                              <BootstrapTooltip
                                title={t("start_schedule_date_tooltip")}
                              >
                                <Typography
                                  sx={{
                                    marginLeft: "5px",
                                    marginTop: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </Typography>
                              </BootstrapTooltip>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                disablePast
                                value={
                                  startDateRangeSchedule !== null
                                    ? dayjs(startDateRangeSchedule)
                                    : null
                                }
                                className={classes.whiteBackground}
                                closeOnSelect={false}
                                onChange={(e) => {
                                  handleChangeScheduleStartDate(e);
                                  setStartDateError(e === null || e === "");
                                }}
                                onError={(error) => {
                                  setStartDateInvalid(error);
                                }}
                                format="DD/MM/YYYY hh:mm A" // Display only hours without minutes
                                minutesStep={60}
                                minDate={dayjs("2005-01-01")}
                                // maxDate={dayjs().endOf("day")}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    fullWidth
                                    {...params}
                                    sx={{ background: "#FFFF" }}
                                    error={startDateError}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <Box sx={{ color: "red" }}>
                              {startDateError
                                ? t("Field_req")
                                : startDateInvalid !== null
                                ? t("invalid_date")
                                : ""}
                            </Box>
                          </Grid>
                          {repeatValue !== "no" && (
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              sx={{ paddingTop: "0px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("End_Date")}
                                </Typography>

                                <BootstrapTooltip
                                  title={t("end_schedule_date_tooltip")}
                                >
                                  <Typography
                                    sx={{
                                      marginLeft: "5px",
                                      marginTop: "4px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <InfoOutlinedIcon />
                                  </Typography>
                                </BootstrapTooltip>
                              </Box>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disablePast
                                  value={
                                    endDateRangeSchedule !== null
                                      ? dayjs(endDateRangeSchedule)
                                      : null
                                  }
                                  format="DD/MM/YYYY"
                                  className={classes.whiteBackground}
                                  onChange={(e) => {
                                    handleChangeScheduleEndDate(e);
                                    if (
                                      startDateRangeSchedule &&
                                      e &&
                                      dayjs(startDateRangeSchedule).isAfter(e)
                                    ) {
                                      setGreaterError(true);
                                    } else {
                                      setGreaterError(false);
                                    }
                                  }}
                                  minDate={dayjs("2005-01-01")}
                                  // maxDate={dayjs().endOf("day")}
                                  renderInput={(params) => (
                                    <TextField
                                      error
                                      autoComplete="off"
                                      fullWidth
                                      {...params}
                                      sx={{ background: "#FFFF" }}
                                    />
                                  )}
                                />
                                <style>
                                  {`
       .MuiDialogActions-root {
        display: none;
      }
     
    `}
                                </style>
                              </LocalizationProvider>
                              <Box sx={{ color: "red" }}>
                                {greaterError ? t("invalid_date") : ""}
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>

                <Box sx={{ marginTop: "12px" }}>
                  {" "}
                  <Collapsible
                    title={t("configure_emaill")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureEmail")}
                    id={"configureEmail"}
                    ComponentToRender={
                      <Grid container>
                        {/*    <Typography className={classes.emailReportLabel} mt={1}>
                          {t("configure_emaill")}
                        </Typography> */}
                        <Grid item xs={12} sx={{ paddingTop: "0px" }}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("select_user_type")}
                          </Typography>
                          {/* <Typography className={classes.emailReportSubLabel}>
                    {t("select_user_type")}
                  </Typography> */}
                          <Grid container sx={{ marginLeft: "-10px" }}>
                            <Grid
                              item
                              xs={3}
                              sx={{ paddingTop: "0px", marginTop: "3px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeCompanyUser}
                                  checked={companyUser}
                                />
                                <Typography>{t("company_users")}</Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sx={{ paddingTop: "0px", marginTop: "3px" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeOthers}
                                  checked={others}
                                />
                                <Typography>{t("others")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          {companyUser && users?.length > 0 && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("recipients")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={users?.length > 0 ? users : []}
                                getOptionLabel={(option) =>
                                  option?.User?.firstName !== null &&
                                  option?.User?.lastName !== null
                                    ? option?.User?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                      option?.User?.firstName.slice(1) +
                                      " " +
                                      (option?.User?.lastName !== null &&
                                      option?.User?.lastName !== ""
                                        ? option?.User?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                          option?.User?.lastName.slice(1) +
                                          " " +
                                          "(" +
                                          option?.User?.email +
                                          ")"
                                        : "")
                                    : option?.User?.email
                                }
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    // Check if all options are selected
                                    if (
                                      users?.length ===
                                      selectedCompanyUsers?.length
                                    ) {
                                      setSelectedCompanyUsers([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      setSelectedCompanyUsers(users);
                                      //  setLocationRequired(false);
                                    }
                                  } else {
                                    setSelectedCompanyUsers(value);
                                    if (value?.length === users?.length) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                value={selectedCompanyUsers}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAll}
                                        />
                                        {option?.firstName}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          // checked={selected}
                                          checked={selected || selectAll}
                                        />
                                        {option?.User?.firstName !== null &&
                                        option?.User?.lastName !== null
                                          ? option?.User?.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            option?.User?.firstName.slice(1) +
                                            " " +
                                            (option?.User?.lastName !== null &&
                                            option?.User?.lastName !== ""
                                              ? option?.User?.lastName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                option?.User?.lastName.slice(
                                                  1
                                                ) +
                                                " " +
                                                "(" +
                                                option?.User?.email +
                                                ")"
                                              : "")
                                          : option?.User?.email}
                                        {/* {option?.internalName + ", " + option?.city} */}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (users?.length > 0) {
                                    return [
                                      { id: 0, firstName: t("Select_All") },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_user")}
                                  />
                                )}
                              />
                            </>
                          )}

                          {others && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Email_Address")}
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                multiline
                                rows={5}
                                maxRows={10}
                                ref={textArea}
                                variant="outlined"
                                placeholder={t("Enter_recipients_email_here")}
                                sx={{ marginBottom: "12px" }}
                                value={addNewUserEmials}
                                fullWidth
                                // error={emailsError}
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddNewUserEmailsChange(
                                      event.target.value + ","
                                    );
                                  }
                                }}
                                onChange={(e) =>
                                  handleAddNewUserEmailsChange(e.target.value)
                                }
                              />
                              <Box className={classes.reviewsubHeadBox}>
                                {totalEmailsArr?.length > 5000 ? (
                                  <Typography
                                    className={classes.reviewsubHeadTextError}
                                  >
                                    {t("max_upload_text")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.reviewsubHeadText}
                                  >
                                    {t("send_email_text_emotionAi") +
                                      " " +
                                      totalEmailsArr?.length +
                                      " " +
                                      t("email_addresses")}
                                  </Typography>
                                )}
                              </Box>

                              <Box>
                                <input
                                  type="file"
                                  id="file-email"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={handleFileUpload}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleButtonClick}
                                    label={t("Browse_File")}
                                  />
                                </Box>

                                <Box>
                                  {selectedFile ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFile}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          <Typography className={classes.emailReportSubLabel}>
                            {t("subjectss")}
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_subject")}
                            value={emailSubject}
                            fullWidth
                            error={emailSubjectRequired}
                            helperText={
                              emailSubjectRequired ? t("Field_req") : ""
                            }
                            onChange={handleEmailSubjectChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: "0px", marginTop: "3px" }}
                        >
                          <Typography className={classes.emailReportSubLabel}>
                            {t("body")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_body_text")}
                            value={emailBody}
                            fullWidth
                            // error={headingRequired}
                            onChange={(e) => {
                              setEmailBody(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>

                {/*******************3rd section  */}
              </Grid>
            )}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <CommonButton
                displayWhite="true"
                label={t("Cancel")}
                onSubmit={handleCloseNewRequestModel}
              />
              <CommonButton
                type="submit"
                label={singleReportData !== null ? t("Update") : t("schedule")}
                isLoading={
                  singleReportData !== null
                    ? updateReportLoading
                    : scheduleReportLoading
                }
                disabled={
                  emailTitle === ""
                    ? true
                    : dateRangeError
                    ? true
                    : // : languageSchedular===null || languageSchedular===""
                    // ? true
                    dateRange === "custom" &&
                      (startDateSchedule === null ||
                        endDateSchedule === null ||
                        startDateSchedule === "" ||
                        endDateSchedule === "")
                    ? true
                    : startDateRangeSchedule === null
                    ? true
                    : startDateInvalid !== null
                    ? true
                    : emailSubject === ""
                    ? true
                    : totalEmailsArr.length === 0 &&
                      selectedCompanyUsers.length === 0
                    ? true
                    : updateReportLoading && scheduleReportLoading
                    ? true
                    : greaterError
                    ? true
                    : false
                }
                onSubmit={
                  singleReportData !== null
                    ? handleUpdateScheduledReport
                    : handleScheduleReport
                }
              />
            </Box>
          </Box>
        </Dialog>
      </div>

      {openLogoUploadModal && (
        <LogoImageModal
          title={t("upload_logo")}
          handleClose={handleCloseLogoUploadModal}
          open={openLogoUploadModal}
          selectedImages={images}
          setSelectedImages={setImages}
          setSelectedFile={setSelectedImageFile}
          setUploadedImages={setUploadedImages}
          uploadedImages={uploadedImages}
        />
      )}

      {openModalFilter && (
        <FilterModal
          locations={scheduleLocations}
          allLocations={allLocations}
          //  handleChangeLocation={handleChangeLocation}
          startDate={startDateSchedule}
          endDate={endDateSchedule}
          handleChangeStartDate={handleChangeStartDateSchedule}
          handleChangeEndDate={handleChangeEndDateSchedule}
          selectedCountries={scheduleCountries}
          selectAllCountries={selectAllScheduleCountries}
          setSelectAllCountries={setSelectAllScheduleCountries}
          //  resetHandler={resetHandler}
          openModalFilter={openModalFilter}
          handleCloseModalFilter={handleCloseFilterModel}
          allGroups={allGroups}
          setSelectedGroups={setScheduleGroups}
          selectedGroups={scheduleGroups}
          handleChangeGroups={handleChangeGroups}
          selectAllLocations={selectAllScheduleLocations}
          setSelectAllLocations={setSelectAllScheduleLocations}
          setSelectAllGroups={setSelectAllScheduleGroups}
          selectAll={selectAllScheduleGroups}
          setCallApi={setCallApi}
          countries={countries}
          resetStart={resetStart}
          resetEnd={resetEnd}
          locationAddPermission={true}
          locationViewPermission={true}
          defaultLocation={defaultLocation}
          setLocation={setScheduleLocations}
          setSelectedCountries={setScheduleCountries}
          oldCountries={oldScheduleCountries}
          oldGroup={oldScheduleGroups}
          getCountryWiseLocations={getCountryWiseLocations}
          handleChangeCountries={handleChangeCountries}
          setDateRange={setDateRangeSchedule}
          dateRange={dateRangeSchedule}
          openFrom={"download"}
          setEmailBody={setEmailBody}
          disabledStartDate={disabledStartDate}
          setDisabledStartDateSchedule={setDisabledStartDateSchedule}
        />
      )}
    </>
  );
};

export default FetchModel;
