import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import { api } from "../../../../../../../contexts/JWTContext";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useStyles } from "../../../../Styles/style";
import { CircularProgress } from "@material-ui/core";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import { styled } from "@mui/system";
import AddressChangeModel from "./AddressChangeModel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const CompanyDetailsModal = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  locationCategory,
  getLocationDetails,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  getLocationListingDetails,
  setIndexValue,
  addressTouchedValue,
  setAddressTouchedValue,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [serviceAreas, setServiceAreas] = useState([]);
  const [selectedServiceAreas, setSelectedServiceAreas] = useState([]);
  const [newSelectedServiceAreas, setNewSelectedServiceAreas] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [serviceLoading, setServiceLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState({});
  const [touchedValues, setTouchedValues] = useState({});
  const [companyDetailsData, setCompanyDetailsData] = useState({});
  const [cancelState, setCancelState] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [serviceAreasLimitError, setServiceAreasLimitError] = useState(false);
  const [openingDateError, setOpeningDateError] = useState(null);
  const [openAddressChangeModel, setOpenAddressChangeModel] = useState(false);
  const [openEditUI, setOpenEditUI] = useState(false);
  const [addressLineRequired, setAddressLineRequired] = useState(false);
  const [administrativeAreaRequired, setAdministrativeArea] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [regionCodeRequired, setRegionCodeRequired] = useState(false);
  const [localityRequired, setLocalityRequired] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const [addressInfo, setAddressInfo] = useState({
    addressLine: "",
    administrativeArea: "",
    postalCode: "",
    regionCode: "",
    locality: "",
  });
  let tempObjOne;
  let timer = 0;

  useEffect(() => {
    getLogsDetail();
  }, []);

  useEffect(() => {
    if (openEditUI === true) {
      setAddressInfo({
        addressLine:
          locationDetailsData?.result?.google?.storefrontAddress?.addressLines
            ?.length > 0
            ? locationDetailsData?.result?.google?.storefrontAddress?.addressLines.join(
                " "
              )
            : "",
        administrativeArea: locationDetailsData?.result?.google
          ?.storefrontAddress?.administrativeArea
          ? locationDetailsData?.result?.google?.storefrontAddress
              ?.administrativeArea
          : "",
        postalCode: locationDetailsData?.result?.google?.storefrontAddress
          ?.postalCode
          ? locationDetailsData?.result?.google?.storefrontAddress?.postalCode
          : "",
        regionCode: locationDetailsData?.result?.google?.storefrontAddress
          ?.regionCode
          ? locationDetailsData?.result?.google?.storefrontAddress?.regionCode
          : "",
        locality: locationDetailsData?.result?.google?.storefrontAddress
          ?.locality
          ? locationDetailsData?.result?.google?.storefrontAddress?.locality
          : "",
      });
    }
  }, [openEditUI]);
  useEffect(() => {
    if (saveState === false) {
      if (Object.keys(touchedValues)?.length > 0) {
        setTouchedValue(true);
      } else {
        setTouchedValue(false);
      }
    }
  }, [touchedValues, companyDetailsData]);

  useEffect(() => {
    if (selectedServiceAreas?.length !== newSelectedServiceAreas?.length) {
      setTouchedValue(true);
    }
  }, [selectedServiceAreas]);

  useEffect(() => {
    setSelectedServiceAreas(
      locationDetailsData?.result?.google?.serviceArea
        ? locationDetailsData?.result?.google?.serviceArea?.places?.placeInfos
        : []
    );
    setNewSelectedServiceAreas(
      locationDetailsData?.result?.google?.serviceArea
        ? locationDetailsData?.result?.google?.serviceArea?.places?.placeInfos
        : []
    );
  }, []);
  useEffect(() => {
    setSelectedServiceAreas(
      locationDetailsData?.result?.google?.serviceArea
        ? locationDetailsData?.result?.google?.serviceArea?.places?.placeInfos
        : []
    );
    setNewSelectedServiceAreas(
      locationDetailsData?.result?.google?.serviceArea
        ? locationDetailsData?.result?.google?.serviceArea?.places?.placeInfos
        : []
    );
  }, [locationDetailsData]);

  useEffect(() => {
    if (tempObjOne?.year && tempObjOne?.month && tempObjOne?.day) {
      const date = new Date(
        `${tempObjOne?.month}/${tempObjOne?.day}/${tempObjOne?.year} 10:05:00`
      );

      setSelectedStartDate(date);
    }
  }, [tempObjOne, locationDetailsData]);
  useEffect(() => {
    if (
      locationDetailsData?.result?.google?.openInfo?.openingDate?.year &&
      locationDetailsData?.result?.google?.openInfo?.openingDate?.month &&
      locationDetailsData?.result?.google?.openInfo?.openingDate?.day
    ) {
      const date = new Date(
        `${locationDetailsData?.result?.google?.openInfo?.openingDate?.month}/${locationDetailsData?.result?.google?.openInfo?.openingDate?.day}/${locationDetailsData?.result?.google?.openInfo?.openingDate?.year} 10:05:00`
      );
      setSelectedStartDate(tempObjOne);

      let tempObjTwo = {
        year: locationDetailsData?.result?.google?.openInfo?.openingDate?.year,
        month:
          locationDetailsData?.result?.google?.openInfo?.openingDate?.month,
        day: locationDetailsData?.result?.google?.openInfo?.openingDate?.day,
      };

      setStartDate(date);
      setSelectedStartDate(tempObjTwo);
    }
  }, [tempObjOne, locationDetailsData]);

  const handleOpenAddressChangeModel = () => {
    setOpenEditUI(true);
  };
  const handleCloseAddressChangeModel = () => {
    setOpenEditUI(false);
  };

  const handleChangeDate = (e, index, data) => {
    const year = dayjs(e).format("YYYY");
    const month = dayjs(e).format("MM");
    const day = dayjs(e).format("D");
    const tempObjOne = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
    };
    setSelectedStartDate(tempObjOne);
  };

  const getAllServiceAreas = async (inputValue) => {
    setServiceLoading(true);
    if (inputValue?.length > 0) {
      try {
        const res = await api.get(
          `google/search-location?input=${inputValue}&key=AIzaSyCrTucXlJJfZ2YSZlyXxevyMa0kZuBKuGs`
        );

        if (res.status === 200) {
          setServiceLoading(false);
          if (res?.data?.data?.predictions?.length > 0) {
            let tempServiceAreas = [];
            res.data.data.predictions.forEach((item) => {
              tempServiceAreas.push({
                placeName: item.description,
                placeId: item.place_id,
              });
            });
            setServiceAreas(tempServiceAreas);
          }
        }
      } catch (error) {}
    }

    setServiceLoading(false);
  };
  const getLogsDetail = async () => {
    setIsLoading(true);

    try {
      const res = await api.get(`/locationLogs/changeAddress/${location?.id}`);

      if (res.status === 200) {
        if (res?.data?.data?.status === "pending") {
          setRequestSent(true);
        } else {
          setRequestSent(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleChangeServiceAreas = (value) => {
    setSelectedServiceAreas(value);
    if (value?.length > 20) {
      setServiceAreasLimitError(true);
    } else {
      setServiceAreasLimitError(false);
    }
    setServiceAreas([]);
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false && addressTouchedValue === false) {
      // setSelectedTab(newValue);
      getLocationDetails(location);
      onCancel();

      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false && addressTouchedValue === false) {
      setDisplayConfirmationBox(false);
      getLocationDetails(location);
      onCancel();
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleUpdateCompanyDetail = async () => {
    if (addressTouchedValue === true) {
      handleRequestAddressChange();
    }
    if (touchedValue === true) {
      if (
        companyDetailsData !== null &&
        companyDetailsData.locationName !== "" &&
        companyDetailsData?.locationName?.length < 125 &&
        selectedServiceAreas?.length <= 20 &&
        serviceAreasLimitError === false &&
        openingDateError === null
      ) {
        setTouchedValue(false);
        setSaveState(true);
        try {
          setDisplayConfirmationBox(false);

          const body = {
            title:
              companyDetailsData !== null
                ? companyDetailsData.locationName
                : "",
            storefrontAddress: {
              regionCode:
                companyDetailsData !== null
                  ? companyDetailsData.locationCode
                  : "",
              languageCode:
                companyDetailsData !== null
                  ? companyDetailsData.streetName
                  : "",
              postalCode:
                companyDetailsData !== null
                  ? companyDetailsData.locationPostalcode
                  : "",
              locality:
                companyDetailsData !== null ? companyDetailsData.cityName : "",
              addressLines:
                companyDetailsData !== null
                  ? [companyDetailsData.locationAddressline]
                  : "",
            },
            // categories: {
            //   primaryCategory: {
            //     name: selectedCategory?.name,
            //     displayName: selectedCategory?.displayName,
            //   },
            // },
            openInfo: {
              openingDate: {
                year: selectedStartDate?.year,
                month: selectedStartDate?.month,
                day: selectedStartDate?.day,
              },
            },
            serviceArea: {
              businessType: "CUSTOMER_AND_BUSINESS_LOCATION",
              places: {
                placeInfos: selectedServiceAreas,
              },
            },
          };

          const body2 = {
            title:
              companyDetailsData !== null
                ? companyDetailsData.locationName
                : "",
            storefrontAddress: {
              regionCode:
                companyDetailsData !== null
                  ? companyDetailsData.locationCode
                  : "",
              languageCode:
                companyDetailsData !== null
                  ? companyDetailsData.streetName
                  : "",
              postalCode:
                companyDetailsData !== null
                  ? companyDetailsData.locationPostalcode
                  : "",
              locality:
                companyDetailsData !== null ? companyDetailsData.cityName : "",
              addressLines:
                companyDetailsData !== null
                  ? [companyDetailsData.locationAddressline]
                  : "",
            },
            // categories: {
            //   primaryCategory: {
            //     name: selectedCategory?.name,
            //     displayName: selectedCategory?.displayName,
            //   },
            // },
            serviceAreas: null,
            openInfo: {
              openingDate: {
                year: selectedStartDate?.year,
                month: selectedStartDate?.month,
                day: selectedStartDate?.day,
              },
            },
          };

          setIsLoading(true);

          const res = await api.patch(
            `/locationListing/update-companyDetail/${location?.id}`,

            selectedServiceAreas.length > 0 ? body : body2
          );

          if (indexValue === null) {
          } else {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          }

          if (cancelState === true) {
            getLocationDetailsListing(location);
            onCancel();
            // setTouchedValue(false);
          }
          if (res.status === 200) {
            setTouchedValues({});
            setIsLoading(false);
            // setLocationDetailsData(res?.data?.data);
            getLocationDetailsListing(location);
            toast.success("Updated Successfully");

            // onCancel();
            //  getLocationListingsData(location);
          }
          //  getLocationDetails(location);
        } catch (error) {
          toast.error(error?.response?.data?.message);
          setIsLoading(false);
        }
      } else {
        setDisplayConfirmationBox(false);
        setIndexValue(null);
      }
    }
  };
  const handleRequestAddressChange = async () => {
    if (addressInfo?.addressLine === "") {
      setAddressLineRequired(true);
    }

    if (addressInfo?.regionCode === "") {
      setRegionCodeRequired(true);
    }
    if (addressInfo?.locality === "") {
      setLocalityRequired(true);
    }

    if (
      addressInfo?.addressLine !== "" &&
      addressInfo?.regionCode !== "" &&
      addressInfo?.locality !== ""
    ) {
      try {
        setDisplayConfirmationBox(false);
        setAddressTouchedValue(false);
        setAddressLoading(true);

        const res = await api.post(
          `/locationLogs/changeAddress/${location?.id}`,
          {
            companyId: companyData?.id,
            currentAddress: {
              addressLine:
                locationDetailsData?.result?.google?.storefrontAddress
                  ?.addressLines,
              administrativeArea: locationDetailsData?.result?.google
                ?.storefrontAddress?.administrativeArea
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.administrativeArea
                : "",
              postalCode: locationDetailsData?.result?.google?.storefrontAddress
                ?.postalCode
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.postalCode
                : "",
              regionCode: locationDetailsData?.result?.google?.storefrontAddress
                ?.regionCode
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.regionCode
                : "",
              locality: locationDetailsData?.result?.google?.storefrontAddress
                ?.locality
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.locality
                : "",
            },
            newAddress: {
              addressLine: [addressInfo?.addressLine],
              administrativeArea: addressInfo?.administrativeArea,
              postalCode: addressInfo?.postalCode,
              regionCode: addressInfo?.regionCode,
              locality: addressInfo?.locality,
            },
          }
        );
        if (res.status === 200) {
          toast.success(t("request_sent_for_address"));

          handleCloseAddressChangeModel();
          setAddressLoading(false);
          // setRequestSent(true);
          getLogsDetail();
          if (touchedValue === false) {
            if (indexValue === null) {
            } else {
              let selectedValue = indexValue;

              setSelectedTab(selectedValue);
            }

            if (cancelState === true) {
              onCancel();
              // setTouchedValue(false);
            }
          }
        }
      } catch (error) {
        setAddressLoading(false);
        handleCloseAddressChangeModel();
        toast.error(error?.response?.data?.message);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  return (
    <>
      {openAddressChangeModel && (
        <AddressChangeModel
          title={t("Request_Address_change")}
          open={openAddressChangeModel}
          handleCloseAddressChangeModel={handleCloseAddressChangeModel}
          isFullWidth={true}
          locationDetailsData={locationDetailsData}
        />
      )}
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCompanyDetail}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            setAddressTouchedValue={setAddressTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid item xs={10} sm={10} md={11.5} lg={11.5}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.modalHeader}>
                {t("Company_Details_Account")}
              </Typography>
              <BootstrapTooltip title={t("Company_details_subhead")}>
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              autoFocus
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        {data !== null && (
          <Formik
            initialValues={{
              locationName: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.title
                : "",

              cityName: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.locality
                : "",
              streetName: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.languageCode
                : "",

              locationPostalcode: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.postalCode
                : "",
              locationAddressline: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.addressLines
                : "",
              locationCode: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.storefrontAddress
                    ?.regionCode
                : "",

              selectedCategory: locationCategory?.result
                ? locationCategory?.result?.categories
                : "",
              selectedStartDate: locationDetailsData?.result
                ? locationDetailsData?.result?.google?.openInfo?.openingDate
                : "",

              submit: false,
            }}
            innerRef={(formikActions) => {
              formikActions
                ? setTouchedValues(formikActions?.touched)
                : setTouchedValues({});
              formikActions
                ? setCompanyDetailsData(formikActions?.values)
                : setCompanyDetailsData({});
            }}
            validationSchema={Yup.object().shape({
              locationName: Yup.string()
                .max(125, "Location name must be less than 125 characters")
                .required(`${t("loc_name_req")}`),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              handleUpdateCompanyDetail();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <>
                <Form>
                  <Grid container className={classes.actionContent}>
                    {isLoading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className={classes.loaderBox}
                        >
                          <Loader />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Grid container sx={{ padding: "18px" }}>
                            <Grid item md={12} sm={12} xs={12} lg={12}>
                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                sx={{ marginTop: "12px" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#1B2430",
                                  }}
                                >
                                  {t("Location_Name")}{" "}
                                  <span style={{ color: "#FC3652" }}>*</span>
                                </Typography>
                                <TextField
                                  InputProps={{
                                    inputProps: {
                                      style: { marginLeft: "4px" },
                                    },
                                  }}
                                  name="locationName"
                                  value={values?.locationName}
                                  error={Boolean(
                                    touched?.locationName &&
                                      errors?.locationName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched?.locationName &&
                                    errors?.locationName
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                sx={{
                                  marginTop: "12px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#1B2430",
                                  }}
                                >
                                  {t("Opening_Date")}
                                </Typography>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DatePicker", "DatePicker"]}
                                  >
                                    <DatePicker
                                      sx={{ width: "100%" }}
                                      value={
                                        startDate !== "" && startDate !== null
                                          ? dayjs(startDate)
                                          : null
                                      }
                                      format="DD/MM/YYYY"
                                      onChange={(e) => {
                                        handleChangeDate(e, data);
                                        setStartDate(e);
                                        setTouchedValue(true);
                                      }}
                                      onError={(error) => {
                                        setOpeningDateError(error);
                                      }}
                                      name="selectedStartDate"
                                      renderInput={(params) => (
                                        <TextField
                                          name="selectedStartDate"
                                          fullWidth
                                          {...params}
                                          error={false}
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>

                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                sx={{ marginTop: "12px" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#1B2430",
                                  }}
                                >
                                  {t("Service_Areas")}{" "}
                                </Typography>
                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  md={serviceLoading ? 11 : 12}
                                  sm={serviceLoading ? 11 : 12}
                                  xs={serviceLoading ? 11 : 12}
                                  lg={serviceLoading ? 11 : 12}
                                >
                                  <Autocomplete
                                    multiple
                                    disablePortal
                                    id="combo-box-demo"
                                    options={
                                      serviceAreas?.length > 0
                                        ? serviceAreas.filter(
                                            (option) =>
                                              !selectedServiceAreas.some(
                                                (selectedOption) =>
                                                  selectedOption.placeId ===
                                                  option.placeId
                                              )
                                          )
                                        : []
                                    }
                                    getOptionLabel={(option) =>
                                      option?.placeName ? option.placeName : ""
                                    }
                                    className="Autocomplete-field"
                                    value={selectedServiceAreas}
                                    onChange={(e, value) => {
                                      handleChangeServiceAreas(value);
                                      setTouchedValue(true);
                                    }}
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {`${option.placeName} `}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        placeholder={t("Service_Areas")}
                                        {...params}
                                        name="language"
                                        error={serviceAreasLimitError}
                                        helperText={
                                          serviceAreasLimitError &&
                                          t("sever_areas_limit_error")
                                        }
                                        onChange={(e, newValue) => {
                                          if (e.target.value.length === 0) {
                                            setServiceAreas([]);
                                          }
                                          clearTimeout(timer);
                                          timer = setTimeout(
                                            () =>
                                              getAllServiceAreas(
                                                e.target.value
                                              ),
                                            1000
                                          );
                                        }}
                                      />
                                    )}
                                  ></Autocomplete>
                                </Grid>
                                {serviceLoading && (
                                  <Grid
                                    item
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    lg={1}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <CircularProgress
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        color: "#06BDFF",
                                        padding: "5px",
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>

                              <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                lg={12}
                                sx={{ marginTop: "12px" }}
                              >
                                <Card
                                  variant="outlined"
                                  className={
                                    requestSent
                                      ? classes.locationAddressCardRequested
                                      : classes.locationAddressCard
                                  }
                                >
                                  <Grid container>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                      <Typography
                                        className={
                                          classes.locationAddressCardLabels
                                        }
                                      >
                                        {t("Addres_Line")}
                                      </Typography>
                                      {openEditUI ? (
                                        <TextField
                                          type="text"
                                          name="firstName"
                                          placeholder={t("Addres_Line")}
                                          value={addressInfo?.addressLine}
                                          error={addressLineRequired}
                                          helperText={
                                            addressLineRequired &&
                                            t("Addre_line_req")
                                          }
                                          variant="standard"
                                          onChange={(e) => {
                                            setAddressTouchedValue(true);
                                            setAddressInfo({
                                              ...addressInfo,
                                              addressLine: e.target.value,
                                            });
                                            if (e.target.value?.length > 0) {
                                              setAddressLineRequired(false);
                                            } else {
                                              setAddressLineRequired(true);
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.adressField}
                                        >
                                          {locationDetailsData?.result?.google
                                            ?.storefrontAddress?.addressLines
                                            ?.length > 0
                                            ? locationDetailsData?.result
                                                ?.google?.storefrontAddress
                                                ?.addressLines[0]
                                            : "--"}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                      <Typography
                                        className={
                                          classes.locationAddressCardLabels
                                        }
                                      >
                                        {t("Administrative_Area")}
                                      </Typography>
                                      {openEditUI ? (
                                        <TextField
                                          // disabled={loading}

                                          type="text"
                                          name="lastName"
                                          autoComplete="off"
                                          placeholder={t("Administrative_Area")}
                                          inputProps={{ autoComplete: "off" }}
                                          value={
                                            addressInfo?.administrativeArea
                                          }
                                          variant="standard"
                                          error={administrativeAreaRequired}
                                          helperText={
                                            administrativeAreaRequired &&
                                            t("Administrative_Area_req")
                                          }
                                          onChange={(e) => {
                                            setAddressTouchedValue(true);
                                            setAddressInfo({
                                              ...addressInfo,
                                              administrativeArea:
                                                e.target.value,
                                            });
                                            /*   if (e.target.value?.length > 0) {
                                              setAdministrativeArea(false);
                                            } else {
                                              setAdministrativeArea(true);
                                            } */
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.adressField}
                                        >
                                          {locationDetailsData?.result?.google
                                            ?.storefrontAddress
                                            ?.administrativeArea
                                            ? locationDetailsData?.result
                                                ?.google?.storefrontAddress
                                                ?.administrativeArea
                                            : "--"}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3.2}
                                      sm={3.2}
                                      md={3.2}
                                      lg={3.2}
                                    >
                                      <Typography
                                        className={
                                          classes.locationAddressCardLabels
                                        }
                                      >
                                        {t("Postal_Code")}
                                      </Typography>

                                      {openEditUI ? (
                                        <TextField
                                          type="text"
                                          placeholder={t("Postal_Code")}
                                          defaultValue=""
                                          autoComplete="none"
                                          variant="standard"
                                          value={addressInfo?.postalCode}
                                          error={postalCodeRequired}
                                          helperText={
                                            postalCodeRequired &&
                                            t("postal_req")
                                          }
                                          onChange={(e) => {
                                            setAddressTouchedValue(true);
                                            setAddressInfo({
                                              ...addressInfo,
                                              postalCode: e.target.value,
                                            });
                                            /*  if (e.target.value?.length > 0) {
                                              setPostalCodeRequired(false);
                                            } else {
                                              setPostalCodeRequired(true);
                                            } */
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.adressField}
                                        >
                                          {locationDetailsData?.result?.google
                                            ?.storefrontAddress?.postalCode
                                            ? locationDetailsData?.result
                                                ?.google?.storefrontAddress
                                                ?.postalCode
                                            : "--"}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0.8}
                                      sm={0.8}
                                      md={0.8}
                                      lg={0.8}
                                      sx={{ textAlign: "end" }}
                                    >
                                      {openEditUI ? (
                                        addressLoading ? (
                                          <CircularProgress
                                            color="primary"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        ) : (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <BootstrapTooltip
                                              title={t("save_and_send_request")}
                                            >
                                              <CheckCircleOutlineIcon
                                                className={
                                                  classes.tickAddressIcon
                                                }
                                                onClick={() =>
                                                  handleRequestAddressChange()
                                                }
                                              />
                                            </BootstrapTooltip>
                                            <CloseIcon
                                              onClick={() =>
                                                handleCloseAddressChangeModel()
                                              }
                                              sx={{ cursor: "pointer" }}
                                            />
                                          </Box>
                                        )
                                      ) : requestSent ? (
                                        <BootstrapTooltip
                                          title={t("request_sent")}
                                        >
                                          <CheckCircleOutlineIcon
                                            className={
                                              classes.tickAddressIconRequested
                                            }
                                          />
                                        </BootstrapTooltip>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("edit_address")}
                                        >
                                          <EditIcon
                                            className={classes.editIconAddress}
                                            onClick={() =>
                                              handleOpenAddressChangeModel()
                                            }
                                          />
                                        </BootstrapTooltip>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid container sx={{ marginTop: "8px" }}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                      <Typography
                                        className={
                                          classes.locationAddressCardLabels
                                        }
                                      >
                                        {t("Region_Code")}
                                      </Typography>

                                      {openEditUI ? (
                                        <TextField
                                          // disabled={loading}

                                          type="text"
                                          placeholder={t("Region_Code")}
                                          defaultValue=""
                                          autoComplete="off"
                                          // inputProps={{
                                          //   autoComplete: "none",
                                          // }}
                                          value={addressInfo?.regionCode}
                                          error={regionCodeRequired}
                                          variant="standard"
                                          helperText={
                                            regionCodeRequired &&
                                            t("Region_Code_Req")
                                          }
                                          onChange={(e) => {
                                            setAddressTouchedValue(true);
                                            setAddressInfo({
                                              ...addressInfo,
                                              regionCode: e.target.value,
                                            });
                                            if (e.target.value?.length > 0) {
                                              setRegionCodeRequired(false);
                                            } else {
                                              setRegionCodeRequired(true);
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.adressField}
                                        >
                                          {locationDetailsData?.result?.google
                                            ?.storefrontAddress?.regionCode
                                            ? locationDetailsData?.result
                                                ?.google?.storefrontAddress
                                                ?.regionCode
                                            : "--"}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                      <Typography
                                        className={
                                          classes.locationAddressCardLabels
                                        }
                                      >
                                        {t("Locality")}
                                      </Typography>
                                      {openEditUI ? (
                                        <TextField
                                          // disabled={loading}

                                          type="text"
                                          placeholder={t("Locality")}
                                          value={addressInfo?.locality}
                                          variant="standard"
                                          error={localityRequired}
                                          helperText={
                                            localityRequired &&
                                            t("Locality_Req")
                                          }
                                          onChange={(e) => {
                                            setAddressTouchedValue(true);
                                            setAddressInfo({
                                              ...addressInfo,
                                              locality: e.target.value,
                                            });
                                            if (e.target.value?.length > 0) {
                                              setLocalityRequired(false);
                                            } else {
                                              setLocalityRequired(true);
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          className={classes.adressField}
                                        >
                                          {locationDetailsData?.result?.google
                                            ?.storefrontAddress?.locality
                                            ? locationDetailsData?.result
                                                ?.google?.storefrontAddress
                                                ?.locality
                                            : "--"}
                                        </Typography>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                    ></Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridButtons}
                  >
                    <CommonButton
                      displayWhite="true"
                      label={t("Close")}
                      onSubmit={handleCancelButton}
                    />
                    <CommonButton
                      disabled={
                        serviceAreasLimitError || openingDateError !== null
                      }
                      type="submit"
                      label={t("Save")}
                    />
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Grid>
    </>
  );
};

export default CompanyDetailsModal;
