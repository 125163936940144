import React, { useEffect, useState, useRef } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  TextField,
  Tabs,
  Tab,
  Alert as MuiAlert,
  CircularProgress,
  DialogContent,
} from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { TextareaAutosize } from "@material-ui/core";
import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { languagesData } from "../../../../assets/defaultLanguage";
import { api } from "../../../../contexts/JWTContext";
import ReplayIcon from "@mui/icons-material/Replay";
import ReviewTextPresent from "./ReviewTextPresent";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    //  paddingTop: "10px",
    //  paddingLeft: "20px",
    // paddingRight: "20px",
    //  paddingBottom: "20px",
    width: "100%",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  postSubmitButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "98px",
    height: "48px",
    borderRadius: "8px",
  },
  PostAiButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "136px",
    height: "48px",
    borderRadius: "8px",
  },
}));

const Alert = styled(MuiAlert)(spacing);

const PostAIModal = ({
  title,
  onCancel,
  openDefaultModel,
  textPresentPrompt,
  setTextPresentPrompt,
  tags,
  setTags,
  textNotPresentPrompt,
  setTextNotPresentPrompt,
  tagsNotPresent,
  setTagsNotPresent,
  selectedReviewOption,
  formalChecked,
  setFormalChecked,
  reviewToneChecked,
  setReviewToneChecked,
  friendlyChecked,
  setFriendlyChecked,
  includeChecked,
  setIncludeChecked,
  excludeChecked,
  setExcludeChecked,
  yesChecked,
  setYesChecked,
  noChecked,
  setNoChecked,
  moreLanguageChecked,
  setMoreLanguageChecked,
  userLanguageChecked,
  setUserLanguageChecked,
  userLanguage,
  setUserLanguage,
  reviewLanguageChecked,
  setReviewLanguageChecked,
  shortChecked,
  setShortChecked,
  detailedCheked,
  setDetailedChecked,
  extraData,
  setExtraData,
  formalCheckedTwo,
  setFormalCheckedTwo,
  reviewToneCheckedTwo,
  setReviewToneCheckedTwo,
  friendlyCheckedTwo,
  setFriendlyCheckedTwo,
  includeCheckedTwo,
  setIncludeCheckedTwo,
  excludeCheckedTwo,
  setExcludeCheckedTwo,
  yesCheckedTwo,
  setYesCheckedTwo,
  noCheckedTwo,
  setNoCheckedTwo,
  moreLanguageCheckedTwo,
  setMoreLanguageCheckedTwo,
  userLanguageCheckedTwo,
  setUserLanguageCheckedTwo,
  userLanguageTwo,
  setUserLanguageTwo,
  shortCheckedTwo,
  setShortCheckedTwo,
  detailedChekedTwo,
  setDetailedCheckedTwo,
  extraDataTwo,
  setExtraDataTwo,
  handleSavePrompt,
  Ratings,
  selectedLocation,
  setDisplayToast,
  displayToast,
  selectedRating,
  modelPresentPrompt,
  setModelPresentPrompt,
  modelNotPresentPrompt,
  setModelNotPresentPrompt,
  userTranslationLanguage,
  setUserTranslationLanguage,
  userTranslationLanguageTwo,
  setUserTranslationLanguageTwo,
  selectAllPresent,
  setSelectAllPresent,
  selectAllNotPresent,
  setSelectAllNotPresent,
  handleDisplayNames,
  defaultLocation,
  getLocation,
  singleChecked,
  setSingleChecked,
  singleCheckedTwo,
  setSingleCheckedTwo,
  multiChecked,
  setMultiChecked,
  setMultiCheckedTwo,
  multiCheckedTwo,
  tagsRequired,
  setTagsRequired,
  setTagsRequiredTwo,
  tagsRequiredTwo,
  tagsGridRef,
  tagsGridRefTwo,
  selectedTab,
  setSelectedTab,
  langGridRef,
  langGridRefTwo,
  setLanguageRequired,
  languageRequired,
  languageRequiredTwo,
  setLanguageRequiredTwo,
  scrollStateLang,
  setScrollStateLang,
  scrollStateLangTwo,
  setScrollStateLangTwo,
  scrollStateTags,
  setScrollStateTags,
  scrollStateTagsTwo,
  setScrollStateTagsTwo,
}) => {
  const classes = useStyles();
  const textArea = useRef();
  const { t } = useTranslation();

  const [openTestModel, setOpenTestModel] = useState(false);
  const [textPresentGeneratedContent, setTextPresentGeneratedContent] =
    useState("");
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [textNotPresentGeneratedContent, setTextNotPresentGeneratedContent] =
    useState("");
  const [reviewsData, setReviewsData] = useState([]);
  const [dummyReviewsData, setDummyReviewsData] = useState([]);

  useEffect(() => {
    if (openDefaultModel) {
      getReviewsData();
      getAllThirdPartySource();
      if (
        selectedReviewOption?.some((item) => item?.label === "Present") &&
        selectedReviewOption?.some((item) => item?.label === "Not Present")
      ) {
        setSelectedTab(0);
      } else if (
        selectedReviewOption?.some((item) => item?.label === "Present") ==
          false &&
        selectedReviewOption?.some((item) => item?.label === "Not Present") ===
          true
      ) {
        setSelectedTab(1);
      } else {
        setSelectedTab(0);
      }
    }
  }, [openDefaultModel]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setDisplayToast(false);
    setTagsRequired(false);
    setTagsRequiredTwo(false);
    setLanguageRequired(false);
    setLanguageRequiredTwo(false);
  };

  const handleOpenTestModel = () => {
    let tagsError = false;
    let tagsErrorTwo = false;
    let langError = false;
    let langErrorTwo = false;
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      (excludeChecked || includeChecked) &&
      tags?.length === 0
    ) {
      setTagsRequired(true);
      setScrollStateTags(true);
      tagsError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      (excludeCheckedTwo || includeCheckedTwo) &&
      tagsNotPresent?.length === 0
    ) {
      setTagsRequiredTwo(true);
      setScrollStateTagsTwo(true);
      tagsErrorTwo = true;
    } else {
      setTagsRequired(false);
      tagsError = false;
      setTagsRequiredTwo(false);
      setScrollStateTags(false);
      setTagsRequiredTwo(false);
      tagsErrorTwo = false;
    }
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      moreLanguageChecked &&
      userLanguage?.length === 0
    ) {
      setLanguageRequired(true);
      setScrollStateLang(true);
      langError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      moreLanguageCheckedTwo &&
      userLanguageTwo?.length === 0
    ) {
      setLanguageRequiredTwo(true);
      setScrollStateLangTwo(true);

      langErrorTwo = true;
    } else {
      setLanguageRequiredTwo(false);
      setLanguageRequiredTwo(false);
      setScrollStateLangTwo(false);
      langError = false;
      setLanguageRequired(false);
      langErrorTwo = false;
    }
    if (
      tagsError === false &&
      tagsErrorTwo === false &&
      langError === false &&
      langErrorTwo === false
    ) {
      setOpenTestModel(true);
    }
  };
  const handleCloseTestModel = () => {
    setOpenTestModel(false);
  };

  const getReviewsData = async () => {
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    try {
      const res = await api.get(
        `/review?limit=20&page=1&filters[locationId][$equals]=${locationIds?.toString()}&filters[ratingValue][$equals]=${
          Ratings === "one"
            ? "1"
            : Ratings === "two"
            ? "2"
            : Ratings === "three"
            ? "3"
            : Ratings === "four"
            ? "4"
            : "5"
        }${
          selectedReviewOption?.some((item) => item?.label === "Present")
            ? "&filters[ratingText][$ne]=" + null
            : ""
        }`
      );

      if (res.status < 400 && res.data.data?.results?.length > 0) {
        setReviewsData(res.data.data?.results);
      } else {
        setReviewsData([]);
      }
    } catch (error) {
      setReviewsData([]);
    }
  };
  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);
        let tempSelected;
      }
    } catch (error) {}
  };

  const getDummyReviewsData = async () => {
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    try {
      const res = await api.get(
        `review/dummy?text=${selectedTab === 0 ? true : false}&ratingValue=${
          Ratings === "one"
            ? "1"
            : Ratings === "two"
            ? "2"
            : Ratings === "three"
            ? "3"
            : Ratings === "four"
            ? "4"
            : "5"
        }`
      );

      if (res.status === 200) {
        let tempArray = res.data.data;
        tempArray?.forEach((item) => {
          item["locationId"] = defaultLocation?.id;
        });

        setDummyReviewsData(tempArray);
      } else {
        setDummyReviewsData([]);
      }
    } catch (error) {
      setDummyReviewsData([]);
    }
  };

  useEffect(() => {
    if (reviewsData?.length === 0) {
      getDummyReviewsData();
    }
  }, [reviewsData, selectedTab]);

  return (
    <>
      <Dialog
        maxWidth
        maxHeight
        open={openDefaultModel}
        onClose={onCancel}
        sx={{ "& .MuiDialog-paper": { width: "850px", height: "550px" } }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>
                {t("Customised_prompt")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid className={classes.headingTextGrid}>
            <Typography className={classes.headingText}>
              {t("prompt_model_subHeading")}
            </Typography>
          </Grid>
        </DialogTitle>

        <Divider variant="middle" />
        <DialogContent>
          {selectedReviewOption?.length > 0 &&
            selectedReviewOption?.length === 2 && (
              <Grid container>
                <Grid xs={12} md={12} sm={12} lg={12}>
                  <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs
                      sx={{ width: "60%" }}
                      className={classes.Tabs}
                      value={selectedTab}
                      onChange={handleChange}
                      variant="scrollable"
                      centered
                    >
                      <Tab
                        sx={{ width: "50%", paddingTop: "0px" }}
                        label={t("Review_text_present")}
                        disabled={
                          selectedReviewOption?.some(
                            (item) => item?.label === "Present"
                          ) === false
                        }
                      />

                      <Tab
                        sx={{ width: "50%" }}
                        label={t("Review_text_not_present")}
                        disabled={
                          selectedReviewOption?.some(
                            (item) => item?.label === "Not Present"
                          ) === false
                        }
                      />
                    </Tabs>
                  </Box>
                </Grid>

                {selectedTab === 0 && (
                  <ReviewTextPresent
                    present={true}
                    textPresentPrompt={textPresentPrompt}
                    formalChecked={formalChecked}
                    setFormalChecked={setFormalChecked}
                    reviewToneChecked={reviewToneChecked}
                    setReviewToneChecked={setReviewToneChecked}
                    friendlyChecked={friendlyChecked}
                    setFriendlyChecked={setFriendlyChecked}
                    includeChecked={includeChecked}
                    setIncludeChecked={setIncludeChecked}
                    excludeChecked={excludeChecked}
                    setExcludeChecked={setExcludeChecked}
                    yesChecked={yesChecked}
                    setYesChecked={setYesChecked}
                    noChecked={noChecked}
                    setNoChecked={setNoChecked}
                    moreLanguageChecked={moreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageChecked}
                    userLanguageChecked={userLanguageChecked}
                    setUserLanguageChecked={setUserLanguageChecked}
                    userLanguage={userLanguage}
                    setUserLanguage={setUserLanguage}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tags}
                    setTags={setTags}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    shortChecked={shortChecked}
                    setShortChecked={setShortChecked}
                    detailedCheked={detailedCheked}
                    setDetailedChecked={setDetailedChecked}
                    extraData={extraData}
                    setExtraData={setExtraData}
                    setTextPresentPrompt={setTextPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelPresentPrompt}
                    setModelPresentPrompt={setModelPresentPrompt}
                    userTranslationLanguage={userTranslationLanguage}
                    setUserTranslationLanguage={setUserTranslationLanguage}
                    selectAllPresent={selectAllPresent}
                    setSelectAllPresent={setSelectAllPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleChecked}
                    setSingleChecked={setSingleChecked}
                    multiChecked={multiChecked}
                    setMultiChecked={setMultiChecked}
                    tagsRequired={tagsRequired}
                    setTagsRequired={setTagsRequired}
                    tagsGridRef={tagsGridRef}
                    langGridRef={langGridRef}
                    setLanguageRequired={setLanguageRequired}
                    languageRequired={languageRequired}
                    scrollStateLang={scrollStateLang}
                    setScrollStateLang={setScrollStateLang}
                    scrollStateTags={scrollStateTags}
                    setScrollStateTags={setScrollStateTags}
                  />
                )}
                {selectedTab === 1 && (
                  <ReviewTextPresent
                    present={false}
                    textPresentPrompt={textNotPresentPrompt}
                    formalChecked={formalCheckedTwo}
                    setFormalChecked={setFormalCheckedTwo}
                    reviewToneChecked={reviewToneCheckedTwo}
                    setReviewToneChecked={setReviewToneCheckedTwo}
                    friendlyChecked={friendlyCheckedTwo}
                    setFriendlyChecked={setFriendlyCheckedTwo}
                    includeChecked={includeCheckedTwo}
                    setIncludeChecked={setIncludeCheckedTwo}
                    excludeChecked={excludeCheckedTwo}
                    setExcludeChecked={setExcludeCheckedTwo}
                    yesChecked={yesCheckedTwo}
                    setYesChecked={setYesCheckedTwo}
                    noChecked={noCheckedTwo}
                    setNoChecked={setNoCheckedTwo}
                    moreLanguageChecked={moreLanguageCheckedTwo}
                    setMoreLanguageChecked={setMoreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageCheckedTwo}
                    setUserLanguageChecked={setUserLanguageCheckedTwo}
                    userLanguage={userLanguageTwo}
                    setUserLanguage={setUserLanguageTwo}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tagsNotPresent}
                    setTags={setTagsNotPresent}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextNotPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textNotPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    shortChecked={shortCheckedTwo}
                    setShortChecked={setShortCheckedTwo}
                    detailedCheked={detailedChekedTwo}
                    setDetailedChecked={setDetailedCheckedTwo}
                    extraData={extraDataTwo}
                    setExtraData={setExtraDataTwo}
                    setTextPresentPrompt={setTextNotPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelNotPresentPrompt}
                    setModelPresentPrompt={setModelNotPresentPrompt}
                    userTranslationLanguage={userTranslationLanguageTwo}
                    setUserTranslationLanguage={setUserTranslationLanguageTwo}
                    selectAllPresent={selectAllNotPresent}
                    setSelectAllPresent={setSelectAllNotPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleCheckedTwo}
                    setSingleChecked={setSingleCheckedTwo}
                    multiChecked={multiCheckedTwo}
                    setMultiChecked={setMultiCheckedTwo}
                    setTagsRequired={setTagsRequiredTwo}
                    tagsRequired={tagsRequiredTwo}
                    tagsGridRef={tagsGridRefTwo}
                    langGridRef={langGridRefTwo}
                    languageRequired={languageRequiredTwo}
                    setLanguageRequired={setLanguageRequiredTwo}
                    scrollStateLang={scrollStateLangTwo}
                    setScrollStateLang={setScrollStateLangTwo}
                    scrollStateTags={scrollStateTagsTwo}
                    setScrollStateTags={setScrollStateTagsTwo}
                  />
                )}
              </Grid>
            )}
          {selectedReviewOption?.length > 0 &&
            selectedReviewOption?.length === 1 && (
              <Grid container>
                {selectedReviewOption?.some(
                  (item) => item?.label === "Present"
                ) && (
                  <ReviewTextPresent
                    present={true}
                    textPresentPrompt={textPresentPrompt}
                    formalChecked={formalChecked}
                    setFormalChecked={setFormalChecked}
                    reviewToneChecked={reviewToneChecked}
                    setReviewToneChecked={setReviewToneChecked}
                    friendlyChecked={friendlyChecked}
                    setFriendlyChecked={setFriendlyChecked}
                    includeChecked={includeChecked}
                    setIncludeChecked={setIncludeChecked}
                    excludeChecked={excludeChecked}
                    setExcludeChecked={setExcludeChecked}
                    yesChecked={yesChecked}
                    setYesChecked={setYesChecked}
                    noChecked={noChecked}
                    setNoChecked={setNoChecked}
                    moreLanguageChecked={moreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageChecked}
                    userLanguageChecked={userLanguageChecked}
                    setUserLanguageChecked={setUserLanguageChecked}
                    userLanguage={userLanguage}
                    setUserLanguage={setUserLanguage}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tags}
                    setTags={setTags}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    shortChecked={shortChecked}
                    setShortChecked={setShortChecked}
                    detailedCheked={detailedCheked}
                    setDetailedChecked={setDetailedChecked}
                    extraData={extraData}
                    setExtraData={setExtraData}
                    setTextPresentPrompt={setTextPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelPresentPrompt}
                    setModelPresentPrompt={setModelPresentPrompt}
                    userTranslationLanguage={userTranslationLanguage}
                    setUserTranslationLanguage={setUserTranslationLanguage}
                    selectAllPresent={selectAllPresent}
                    setSelectAllPresent={setSelectAllPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleChecked}
                    setSingleChecked={setSingleChecked}
                    multiChecked={multiChecked}
                    setMultiChecked={setMultiChecked}
                    tagsRequired={tagsRequired}
                    setTagsRequired={setTagsRequired}
                    tagsGridRef={tagsGridRef}
                    langGridRef={langGridRef}
                    languageRequired={languageRequired}
                    setLanguageRequired={setLanguageRequired}
                    scrollStateLang={scrollStateLang}
                    setScrollStateLang={setScrollStateLang}
                    scrollStateTags={scrollStateTags}
                    setScrollStateTags={setScrollStateTags}
                  />
                )}

                {selectedReviewOption?.some(
                  (item) => item?.label === "Not Present"
                ) && (
                  <ReviewTextPresent
                    present={false}
                    textPresentPrompt={textNotPresentPrompt}
                    formalChecked={formalCheckedTwo}
                    setFormalChecked={setFormalCheckedTwo}
                    reviewToneChecked={reviewToneCheckedTwo}
                    setReviewToneChecked={setReviewToneCheckedTwo}
                    friendlyChecked={friendlyCheckedTwo}
                    setFriendlyChecked={setFriendlyCheckedTwo}
                    includeChecked={includeCheckedTwo}
                    setIncludeChecked={setIncludeCheckedTwo}
                    excludeChecked={excludeCheckedTwo}
                    setExcludeChecked={setExcludeCheckedTwo}
                    yesChecked={yesCheckedTwo}
                    setYesChecked={setYesCheckedTwo}
                    noChecked={noCheckedTwo}
                    setNoChecked={setNoCheckedTwo}
                    moreLanguageChecked={moreLanguageCheckedTwo}
                    setMoreLanguageChecked={setMoreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageCheckedTwo}
                    setUserLanguageChecked={setUserLanguageCheckedTwo}
                    userLanguage={userLanguageTwo}
                    setUserLanguage={setUserLanguageTwo}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tagsNotPresent}
                    setTags={setTagsNotPresent}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextNotPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textNotPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    shortChecked={shortCheckedTwo}
                    setShortChecked={setShortCheckedTwo}
                    detailedCheked={detailedChekedTwo}
                    setDetailedChecked={setDetailedCheckedTwo}
                    extraData={extraDataTwo}
                    setExtraData={setExtraDataTwo}
                    setTextPresentPrompt={setTextNotPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelNotPresentPrompt}
                    setModelPresentPrompt={setModelNotPresentPrompt}
                    userTranslationLanguage={userTranslationLanguageTwo}
                    setUserTranslationLanguage={setUserTranslationLanguageTwo}
                    selectAllPresent={selectAllNotPresent}
                    setSelectAllPresent={setSelectAllNotPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleCheckedTwo}
                    setSingleChecked={setSingleCheckedTwo}
                    multiChecked={multiCheckedTwo}
                    setMultiChecked={setMultiCheckedTwo}
                    setTagsRequired={setTagsRequiredTwo}
                    tagsRequired={tagsRequiredTwo}
                    tagsGridRef={tagsGridRefTwo}
                    langGridRefTwo={langGridRefTwo}
                    languageRequired={languageRequiredTwo}
                    setLanguageRequired={setLanguageRequiredTwo}
                    scrollStateLang={scrollStateLangTwo}
                    setScrollStateLang={setScrollStateLangTwo}
                    scrollStateTags={scrollStateTagsTwo}
                    setScrollStateTags={setScrollStateTagsTwo}
                  />
                )}
              </Grid>
            )}
        </DialogContent>

        <DialogActions
          sx={{ padding: "20px", boxShadow: "0px -1px 0px #E0E0E0" }}
        >
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                //   label={<RefreshIcon />}
                alignment={true}
                label={t("Cancel")}
              />

              <CommonButton
                onSubmit={handleOpenTestModel}
                label={t("test_prompt")}
                alignment={true}
                className={classes.PostAiButton}
                /*   disabled={
                  (selectedTab === 0 ||
                    (selectedReviewOption?.length===1 &&
                      selectedReviewOption[0]?.label === "Present")) &&
                  ((formalChecked === false &&
                    friendlyChecked === false &&
                    reviewToneChecked === false) ||
                    ((excludeChecked===true || includeChecked === true) &&
                      tags?.length === 0) ||
                    (shortChecked === false && detailedCheked === false) ||
                    (yesChecked===false && noChecked === false) ||
                    (userLanguageChecked === false &&
                      reviewLanguageChecked === false &&
                      moreLanguageChecked === false) ||
                    (moreLanguageChecked === true &&
                      userLanguage?.length === 0))
                    ? true
                    : (selectedTab === 1 ||
                        (selectedReviewOption?.length===1 &&
                          selectedReviewOption[0]?.label === "Not Present")) &&
                      ((formalCheckedTwo === false &&
                        friendlyCheckedTwo === false &&
                        reviewToneCheckedTwo === false) ||
                        ((excludeCheckedTwo===true ||
                          includeCheckedTwo === true) &&
                          tagsNotPresent?.length === 0) ||
                        (shortCheckedTwo === false &&
                          detailedChekedTwo === false) ||
                        (yesCheckedTwo===false && noCheckedTwo === false) ||
                        (userLanguageCheckedTwo === false &&
                          moreLanguageCheckedTwo === false) ||
                        (moreLanguageCheckedTwo===true &&
                          userLanguageTwo?.length === 0))
                    ? true
                    : false
                } */
              />
              <CommonButton
                onSubmit={handleSavePrompt}
                /*  disabled={
                  selectedTab === 0 &&
                  ((formalChecked === false &&
                    friendlyChecked === false &&
                    reviewToneChecked === false) ||
                    ((excludeChecked===true || includeChecked === true) &&
                      tags?.length === 0) ||
                    (shortChecked === false && detailedCheked === false) ||
                    (yesChecked===false && noChecked === false) ||
                    (userLanguageChecked === false &&
                      reviewLanguageChecked === false &&
                      moreLanguageChecked === false) ||
                    (moreLanguageChecked === true &&
                      userLanguage?.length === 0))
                    ? true
                    : selectedTab === 1 &&
                      ((formalCheckedTwo === false &&
                        friendlyCheckedTwo === false &&
                        reviewToneCheckedTwo === false) ||
                        ((excludeCheckedTwo===true ||
                          includeCheckedTwo === true) &&
                          tagsNotPresent?.length === 0) ||
                        (shortCheckedTwo === false &&
                          detailedChekedTwo === false) ||
                        (yesCheckedTwo===false && noCheckedTwo === false) ||
                        (userLanguageCheckedTwo === false &&
                          moreLanguageCheckedTwo === false) ||
                        (moreLanguageCheckedTwo===true &&
                          userLanguageTwo?.length === 0))
                    ? true
                    : false
                } */
                label={t("Save")}
                alignment={true}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PostAIModal;
