import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { errorHandler } from "../../../redux/slices/internalServerError";
import TitleHeader from "../../../components/CustomComponents/title";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import useAuth from "../../../hooks/useAuth";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as XLSX from "xlsx";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  MenuItem,
  Tooltip,
  tooltipClasses,
  TextField,
  Autocomplete,
  IconButton,
  Dialog,
  Button,
  Menu,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as AddGroup } from "../../../assets/images/AddGroup.svg";

import { ReactComponent as More } from "../../../assets/images/MoreIcon.svg";
import { ReactComponent as Google } from "../../../assets/images/Button.svg";
import { ReactComponent as Tiktok } from "../../../assets/images/tiktok.svg";
import { ReactComponent as Twitter } from "../../../assets/images/Twitterbutton.svg";
import { ReactComponent as Vector } from "../../../assets/images/Facebook.svg";
import { ReactComponent as Apple } from "../../../assets/images/AppleIcon.svg";
import { ReactComponent as Bing } from "../../../assets/images/Bing.svg";
import InternalServerErrorImg from "../../../assets/images/serverErrorImage.svg";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";
import BouncingDotsLoader from "../../../components/Loaders/bounsingLoader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { collapseToast, toast } from "react-toastify";
import Chart from "./Components/index";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Googleicon from "../../../assets/Icons/Group.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import GoogleError from "../../../components/CustomComponents/Errors/NotConnected";
import LocationRequestedState from "../../../components/CustomComponents/Errors/LocationRequested";

import LocationNotFound from "../../../components/CustomComponents/Errors/NoLocationFound";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import Delete from "../../../components/Models/DeleteModal/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./styles/styles";
import Switch from "@mui/material/Switch";
import SyncModel from "../../../components/Models/DeleteModal/syncModel";
import GoogleConnectModal from "../../../components/Models/DeleteModal/GoogleConnectModal";
import { LoadingButton } from "@mui/lab";
import ReconnectCard from "../../../components/CustomComponents/Cards/ReconnectCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DeleteModel from "../../../components/Models/DeleteModal/Delete";

import axios from "axios";
import { countriesData } from "../../../assets/countriesList";
import AddIcon from "@mui/icons-material/Add";
import QuestionMark from "../../../assets/Icons/Question.svg";
import Iframe from "../../../components/Models/IframeModal/Iframe";
// import DatePickerComponent from "../EmotionAi/Components/RangePicker";
import RangePickerResults from "./Components/RangePickerResults";
import { languageOptions } from "../../../assets/defaultLanguage";
import FilterHeader from "./Components/FilterHeader";
import EmailReportModal from "./ExportModels/EmailReportModal";
import ExportFileDownloadModal from "./ExportModels/ExportFileDownloadModal";
import SecheduledReports from "../../../components/Models/DeleteModal/SecheluedReports";
import SecheduleReport from "./ExportModels/ScheduleReport";
import { WarningAmberOutlined } from "@mui/icons-material";
import LocationFixModel from "./Components/LocationFixModel";
const Paper = styled(MuiPaper)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    spacing: 4,
    textAlign: "justfied",
    backgroundColor: "#E0F7FF",
    color: "#1B2430",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "400",
    minWidth: 390,
  },
});

function Listings() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const { signOut, permissionsAcess } = useAuth();
  const [uberAllLoading, setUberAllLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobLoading, setjobLoading] = useState(false);
  const [asyncLoading, setAsyncLoading] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [googleInsightData, setGoogleInsightData] = useState(null);
  const [googleReviewData, setGoogleReviewData] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState([]);
  const [displayGoogleError, setDisplayGoogleError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [displayBox, setDisplayBox] = useState(false);
  const languageData = localStorage.getItem("i18nextLng");
  const [stateGoggleInsight, setStateGoogleInsight] = useState([]);
  let userLang = "";
  let selectedLanguage = "";

  selectedLanguage = languageOptions[userLang];
  const { user } = useAuth();

  const [ratingLoading, setRatingLoading] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const stateData = JSON.parse(localStorage.getItem("user"));

  const [retryLoading, setRetryLoading] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [callApi, setCallApi] = useState(false);
  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const [singleLocation, setSingleLocation] = useState(null);

  const [singleLocationLoading, setSingleLocationLoading] = useState(false);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  const [searchesBreakdownLoading, setSearchesBreakdownLoading] =
    useState(false);
  const [searchesBreakdownData, setSearchesBreakdownData] = useState(null);
  const [graphSearchKeywordsLoading, setGraphSearchKeywordsLoading] =
    useState(false);
  const [graphSearchesKeywordData, setGraphSearchesKeywordData] =
    useState(null);
  const [openGoogleModel, setOpenGoogleModel] = useState(false);
  const [listingPersmissions, setListingPermission] = useState(null);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [locationEditPermission, setLocationEditPermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectAllCountries, setSelectAllCountries] = useState(false);
  const filter = createFilterOptions();
  const [repeatValue, setRepeatValue] = useState("no");
  const [scheduledReportsLoading, setScheduledReportsLoading] = useState(false);
  const [locationDeletePermission, setLocationDeletePermission] =
    useState(false);
  const [locationViewPermission, setLocationViewPermission] = useState(false);

  const [locationRequestError, setLocationRequestError] = useState(false);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [scheduledRportsPageNumber, setScheduledRportsPageNumber] = useState(0);
  const [rowsPerPageScheduled, setRowsPerPageScheduled] = useState(10);
  const todayOne = new Date();
  const today = new Date(todayOne);
  today.setDate(today.getDate() - 7);

  const previousYear = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  const newStartDate = new Date(
    previousYear.getFullYear() - 1,
    previousYear.getMonth(),
    previousYear.getDate()
  );
  const [disabledStartDate, setDisabledStartDate] = useState(previousYear);
  console.log(disabledStartDate, "disabledStartDate");
  const [disabledStartDateSchedule, setDisabledStartDateSchedule] =
    useState(previousYear);
  const [startDate, setStartDate] = useState(previousYear);
  const [endDate, setEndDate] = useState(today);
  const [nextStartDate, setNextStartDate] = useState(newStartDate);
  const [nextEndDate, setNextEndDate] = useState(previousYear);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [oneYearAgoDate, setOneYearAgoDate] = useState(null);
  const [callInsightsApis, setCallInsightsApis] = useState(false);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [expiredLocations, setExpiredLocation] = useState(null);

  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    range: "custom",
  });
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [openFilterModel, setOpenFilterModel] = useState(false);
  const [oldCountries, setOldCountries] = useState([]);
  const [oldGroup, setOldGroup] = useState([]);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [openSchduleModal, setOpenSchduleModal] = useState(false);
  const [openDownloadPdfModal, setOpenDownloadPdfModal] = useState(false);
  const [openReportEmailModal, setOpenReportEmailModal] = useState(false);
  const [scheduleLocations, setScheduleLocations] = useState(location);
  const [selectAllScheduleLocations, setSelectAllScheduleLocations] =
    useState(selectAllLocations);
  const [scheduleGroups, setScheduleGroups] = useState(selectedGroup);
  const [selectAllScheduleGroups, setSelectAllScheduleGroups] =
    useState(selectAllGroups);
  const [scheduleCountries, setScheduleCountries] = useState(selectedCountries);
  const [oldScheduleCountries, setOldScheduleCountries] =
    useState(oldCountries);
  const [oldScheduleGroups, setOldScheduleGroups] = useState(oldGroup);
  const [selectAllScheduleCountries, setSelectAllScheduleCountries] =
    useState(selectAllCountries);
  const [emailTitle, setEmailTitle] = useState("");
  const [startDateSchedule, setStartDateSchedule] = useState(null);
  const [endDateSchedule, setEndDateSchedule] = useState(null);
  const [nextStartDateSchedule, setNextStartDateSchedule] = useState(null);
  const [nextEndDateSchedule, setNextEndDateSchedule] = useState(null);
  const [dateRangeSchedule, setDateRangeSchedule] = useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    range: "custom",
  });
  const [startDateRangeSchedule, setStartDateRangeSchedule] = useState(null);
  const [endDateRangeSchedule, setEndDateRangeSchedule] = useState(null);
  const [emailBody, setEmailBody] = useState(
    `Dear customer Please find attached google insights report for the ${dayjs(
      startDate
    ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
  );
  const [exportReportLoading, setExportReportLoading] = useState(false);
  const [languageSchedular, setLanguageSchedular] = useState("");
  const [recipientError, setRecipientError] = useState(true);
  const [recipientEmailError, setRecipientEmailError] = useState(true);
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientValue, setRecipientValue] = useState([]);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [addNewUserEmials, setAddNewUserEmails] = useState("");
  const [validEmailsArray, setValidEmailsArray] = useState([]);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [totalEmailsArr, setTotalEmailsArr] = useState([]);
  const [totalModalEmailsArr, setTotalModalEmailsArr] = useState([]);
  const [companyUser, setCompanyUser] = useState(true);
  const [others, setOthers] = useState(false);
  const [emailSubject, setEmailSubject] = useState(
    `Google insights report of ${companyData?.name}`
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllCheckedModal, setSelectAllCheckedModal] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const [isModalEmailsSent, setIsModalEmailsSent] = useState(true);
  const [scheduledReports, setScheduledReports] = useState(null);
  const [openDeleteScheduledReportModel, setOpenDeleteScheduledReportModel] =
    useState();
  const [deletedIds, setDeletedId] = useState(null);
  const [deleteScheduleReportLoading, setDeleteScheduleReportLoading] =
    useState(false);
  const [singleReportData, setSingleReportData] = useState(null);
  const [selectedEditId, setSelectedEditId] = useState(null);
  const [updateReportLoading, setUpdateReportLoading] = useState();
  const [openCreateSchedule, setOpenCreateSchedule] = useState(false);
  const [openUpdateStatusModel, setOpenUpdateStatusModel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pauseValue, setPauseValue] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const emailReportRef = useRef(null);
  const [scheduleReportLoading, setScheduleReportLoading] = useState(false);
  const [displayReportError, setDisplayReportError] = useState(false);
  const [reportScheduleLocations, setReportScheduleLocations] = useState([]);
  const [displayReconnectError, setDisplayReconnectError] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const [openGoogleRetryModel, setOpenGoogleRetryModel] = useState(false);
  const [displayEmailWarning, setDisplayEmailWarning] = useState(false);
  const [openFixModel, setOpenFixModel] = useState(false);
  const [pdfChecked, setPdfChecked] = useState(true);
  const [xlsChecked, setXlsChecked] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const subscriptionDateOne = new Date(
      singleLocation?.subscriptionDate !== null
        ? singleLocation?.subscriptionDate
        : singleLocation?.createdAt
    );

    const oneYearAgo = new Date(subscriptionDateOne);
    oneYearAgo.setFullYear(subscriptionDateOne.getFullYear() - 1);
    setOneYearAgoDate(oneYearAgo);
  }, [singleLocation]);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/locationListing"
      );
      setListingPermission(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });

      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (allLocations?.length > 0) {
      let arr = [];
      const expiredLocation = allLocations?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (
              itemTwo?.type === "google" &&
              (itemTwo?.validToken === false ||
                (itemTwo?.statusOfGoogleInsight !== "Done" &&
                  itemTwo?.statusOfGoogleInsight !== "inProcess"))
            ) {
              arr?.push(item);
            }
          });
        } else {
          arr?.push(item);
        }
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
        setExpiredLocation([]);
      } else if (arr?.length > 0) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr);
      }
    }
  }, [allLocations]);

  useEffect(() => {
    getAllLocations();
    getAllGroups();

    getSelectedCustomer();
    if (languageData !== "en" || stateData.language === null) {
      LanguageChange();
    } else {
      setCallApi(true);
    }
  }, []);

  useEffect(() => {
    if (location?.length > 0 && callInsightsApis) {
      getMetricData();
      getSearchesBreakdownData();
      getLocationInsightData();
      getGraphSearchesKeyword();
    }
  }, [callInsightsApis]);

  useEffect(() => {
    if (location?.length > 0 && callApi) {
      getMetricData();
      getSearchesBreakdownData();
      getLocationInsightData();
      getGraphSearchesKeyword();
    }
  }, [callApi, location]);

  useEffect(() => {
    setScheduleLocations(location);
    setSelectAllScheduleLocations(selectAllLocations);
    setScheduleGroups(selectedGroup);
    setSelectAllScheduleGroups(selectAllGroups);
    setScheduleCountries(selectedCountries);
    setSelectAllScheduleCountries(selectAllCountries);
    setOldScheduleCountries(oldCountries);
    setOldScheduleGroups(oldGroup);
    setDateRangeSchedule({
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      range: dateRange?.range,
    });
    setStartDateSchedule(startDate);
    setEndDateSchedule(endDate);
    setNextEndDateSchedule(nextEndDate);
    setNextStartDateSchedule(nextStartDate);
    setEmailBody(
      `Dear customer Please find attached google insights report for the ${dayjs(
        startDate
      ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
    );
    setDisabledStartDateSchedule(disabledStartDate);
  }, [
    location,
    selectedGroup,
    selectedCountries,
    dateRange,
    openDownloadPdfModal,
    openEmailModal,
  ]);
  useEffect(() => {
    if (openSchduleModal === true) {
      getAllScheduledReports();
    }
  }, [scheduledRportsPageNumber, rowsPerPageScheduled, openSchduleModal]);

  useEffect(() => {
    // Combine the arrays
    const combinedArray = [...dataArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );
    setSelectedItems(distinctEmails);
    setTotalModalEmailsArr(distinctEmails);
  }, [dataArray]);

  const addCancelTokenSource = () => {
    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSources((prevSources) => [
      ...prevSources,
      newCancelTokenSource,
    ]);
    return newCancelTokenSource;
  };

  const removeCancelTokenSource = (source) => {
    setCancelTokenSources((prevSources) =>
      prevSources.filter((cancelTokenSource) => cancelTokenSource !== source)
    );
  };

  const cancelAllRequests = () => {
    cancelTokenSources.forEach((cancelTokenSource) => {
      cancelTokenSource.cancel("New request is made");
    });
  };

  const getLocationById = async (locationId) => {
    const newCancelTokenSource = addCancelTokenSource();
    try {
      setSingleLocationLoading(true);
      const res = await api.get(`/location/${locationId}`, {
        cancelToken: newCancelTokenSource.token,
      });
      if (res.status === 200) {
        setSingleLocation(res.data.data);
        let tempAllLocations = [...allLocations];
        let index = tempAllLocations.findIndex(
          (item) => item?.id === res.data.data?.id
        );
        tempAllLocations[index] = res.data.data;
        setAllLocations(tempAllLocations);

        let filteredLocations = expiredLocations?.filter(
          (item) => item?.id !== res.data.data?.id
        );
        setExpiredLocation(filteredLocations);

        //   setSingleLocationLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSingleLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
      if (axios.isCancel(error)) {
      } else {
        setSingleLocationLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const handleRetryWithGoogle = async () => {
    setRetryLoading(true);

    try {
      const response = await api.get(
        `googleInsight/script-on-One-Location?id=${
          locationId !== null ? locationId : ""
        } `
      );

      if (response.status === 200) {
        setRetryLoading(false);
        toast.success(response?.data?.message);
        await getLocationById(locationId);
        handleCloseGoogleRetryModel();
      }
    } catch (error) {
      setRetryLoading(false);
      handleCloseGoogleRetryModel();
      toast.error(error?.response?.data?.message);
      // getLocationById(location?.id);
      // setChangeLoc(false);
    }
  };
  const handleSubmitFilters = () => {
    setCallApi(true);
    setCallInsightsApis(false);
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  function removeDuplicateLocaions(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const getAllGroups = async () => {
    try {
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
      }
    } catch (error) {
      setAllGroups(null);
    }
  };

  const getAllLocations = async () => {
    setLocationLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
          isListingPage: true,
        },
      });
      if (res.status === 200) {
        setLocationLoading(false);
        //  setAllLocations(res?.data?.data);
        const defaultLocation = res?.data?.data?.results.find(
          (item) => item?.defaultLocation !== null
        );

        let countriesArr = [];
        let count = 0;
        if (res?.data?.data?.results?.length > 0) {
          setAllLocations(res?.data?.data?.results);
          if (
            res?.data?.data?.results?.length === 1 &&
            res?.data?.data?.results[0]?.status === "requested"
          ) {
            setLocationRequestError(true);
            setLocation([]);
            setCallApi(false);
          } else {
            res?.data?.data?.results?.forEach((item, index) => {
              countriesData?.forEach((itemTwo) => {
                if (
                  item?.countryCode !== null &&
                  item?.countryCode !== "" &&
                  item?.countryCode !== undefined &&
                  item?.countryCode === itemTwo?.code
                ) {
                  countriesArr?.push(itemTwo);
                }
              });
            });
            if (countriesArr?.length > 0) {
              const countryWiseLocations = getCountryWiseLocations(
                countriesData,
                res?.data?.data?.results
              );

              setCountries(countryWiseLocations);
            } else {
              setCountries([]);
            }

            if (defaultLocation !== undefined) {
              const subscriptionDate = new Date(
                defaultLocation?.subscriptionDate !== null
                  ? defaultLocation?.subscriptionDate
                  : defaultLocation?.createdAt
              );
              const subscriptionDateValue = new Date(
                defaultLocation?.subscriptionDate !== null
                  ? defaultLocation?.subscriptionDate
                  : defaultLocation?.createdAt
              );
              subscriptionDate.setFullYear(subscriptionDate.getFullYear() - 1);
              let disabledDateValue = new Date(
                subscriptionDateValue.setFullYear(
                  subscriptionDateValue.getFullYear() - 1
                )
              );
              disabledDateValue.setDate(disabledDateValue.getDate() - 1);

              setDisabledStartDate(disabledDateValue);

              if (previousYear < subscriptionDate) {
                setStartDate(subscriptionDate);
              } else {
                setStartDate(previousYear);
              }

              setEndDate(today);
              setLocation([defaultLocation]);
              setCallApi(true);
              setDefaultvalueLocation(defaultLocation);
            } else {
              const googleLinkedLocations = res?.data?.data?.results?.filter(
                (item) =>
                  item?.status === "approved" &&
                  item.socialLink.length > 0 &&
                  item.socialLink.some(
                    (link) =>
                      link?.type === "google" &&
                      link?.statusOfGoogleInsight === "Done"
                  )
              );
              setLocation([googleLinkedLocations[0]]);
              setDefaultvalueLocation(googleLinkedLocations[0]);
              const subscriptionDate = new Date(
                googleLinkedLocations[0]?.subscriptionDate !== null
                  ? googleLinkedLocations[0]?.subscriptionDate
                  : googleLinkedLocations[0]?.createdAt
              );
              const subscriptionDateValue = new Date(
                googleLinkedLocations[0]?.subscriptionDate !== null
                  ? googleLinkedLocations[0]?.subscriptionDate
                  : googleLinkedLocations[0]?.createdAt
              );
              subscriptionDate.setFullYear(subscriptionDate.getFullYear() - 1);
              let disabledDateValue = new Date(
                subscriptionDateValue.setFullYear(
                  subscriptionDateValue.getFullYear() - 1
                )
              );
              disabledDateValue.setDate(disabledDateValue.getDate() - 1);

              setDisabledStartDate(disabledDateValue);

              if (previousYear < subscriptionDate) {
                setStartDate(subscriptionDate);
              } else {
                setStartDate(previousYear);
              }

              setEndDate(today);
              setCallApi(true);
            }
          }
        } else {
          setAllLocations(null);
          setCallApi(false);
        }

        setLocationLoading(false);
      }
    } catch (error) {
      setLocationLoading(false);
      setLocation(null);
      setAllLocations(null);

      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  console.log(disabledStartDate, "disabledStartDate");
  const getCountryWiseLocations = (countriesData, locationsArray) => {
    return countriesData
      .map((country) => {
        const locations = locationsArray
          .filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          .map((location) => location);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
        };
      })
      .filter((country) => country.locationsArray.length > 0);
  };

  const LanguageChange = async () => {
    let userLng = "";
    const defaultLanguage = Object.keys(languageOptions).find((item) => {
      userLng = languageOptions[`${languageData}`]?.name;
    });

    try {
      const res = await api.patch(`/user/${user.id}`, {
        firstName: stateData.firstName,
        lastName: stateData.lastName,
        email: stateData.email,
        language: userLng,
        role: stateData.role,
      });

      if (res.status === 200) {
        setCallApi(true);

        localStorage.setItem("user", JSON.stringify(res.data.data));

        const UpdatedLanguage = Object.keys(languageOptions).find(
          (item) =>
            languageOptions[`${item}`]?.name === res?.data?.data?.language
        );
        //     toast.success(t("Language_updated_successfully"));

        localStorage.setItem("i18nextLng", UpdatedLanguage);
      }
    } catch (error) {}
  };

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (selectedCountries?.length > 0) {
      selectedCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (selectedGroup?.length > 0) {
      selectedGroup?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };
  const handleChangeGroups = (value, autoSelectValue) => {
    setCallApi(false);
    setCallInsightsApis(false);
    setSelectedGroup(value);

    let newLocations = [...location];
    const results = oldGroup.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations?.push(locationItem);
            } else {
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  location?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );

                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          location?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);
    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    setLocation(googleLinkedLocations);
    handleDisableDates(googleLinkedLocations);
    setOldGroup(value);
  };
  const handleChangeLocation = async (loc, ind) => {
    setCallApi(false);
    setCallInsightsApis(false);

    if (loc.length > 0) {
      setLocation(loc);
      handleDisableDates(loc);

      setSelectedChartTab(0);
    } else {
      setLocation([]);
      //  setDisplayReconnectError(false);
    }
  };
  const handleDisableDates = (locationList) => {
    if (locationList?.length === 1) {
      const subscriptionDateLoc = new Date(locationList[0]?.subscriptionDate);

      let subscriptionDate = new Date(locationList[0]?.subscriptionDate);

      const oneYearBeforSubsciptionDate = new Date(
        subscriptionDateLoc.setFullYear(subscriptionDate.getFullYear() - 1)
      );
      oneYearBeforSubsciptionDate.setDate(
        oneYearBeforSubsciptionDate.getDate() - 1
      );

      /*   if (oneYearBeforSubsciptionDate > previousYear) {
       // setStartDate(oneYearBeforSubsciptionDate);
        setDateRange({
          startDate: oneYearBeforSubsciptionDate,
          endDate: today,
        });
      } else {
        setStartDate(previousYear);
        setDateRange({
          startDate: previousYear,
          endDate: today,
        });
      } */

      setDisabledStartDate(oneYearBeforSubsciptionDate);

      //  setEndDate(today);
    } else if (locationList?.length > 1) {
      let earliestObject = locationList[0];

      locationList.forEach((item) => {
        if (
          new Date(item?.subscriptionDate) <
          new Date(earliestObject?.subscriptionDate)
        ) {
          earliestObject = item;
        }
      });

      const subscriptionDateLoc = new Date(earliestObject?.subscriptionDate);
      const subscriptionDate = new Date(earliestObject?.subscriptionDate);
      const oneYearBeforSubsciptionDate = new Date(
        subscriptionDateLoc.setFullYear(subscriptionDate.getFullYear() - 1)
      );

      setDisabledStartDate(oneYearBeforSubsciptionDate);
      /*  if (oneYearBeforSubsciptionDate > previousYear) {
        setStartDate(oneYearBeforSubsciptionDate);
        let previousStartDate = dayjs(oneYearBeforSubsciptionDate).subtract(
          1,
          "year"
        );
        setDateRange({
          startDate: oneYearBeforSubsciptionDate,
          endDate: today,
        });
        setNextStartDate(previousStartDate);
      } else {
        setDateRange({
          startDate: previousYear,
          endDate: today,
        });
        setStartDate(previousYear);

        let previousStartDate = dayjs(previousYear).subtract(1, "year");
        setNextStartDate(previousStartDate);
      } */
      //  setEndDate(today);

      let previousEndDate = dayjs(today).subtract(1, "year");
      //  setNextEndDate(previousEndDate);
    }
  };
  const handleViewLocation = (selectedLocation) => {
    setLocation(selectedLocation);
  };
  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {
      handleSessionOut(
        error?.response?.status,
        error?.response?.data?.message,
        handleSignOut
      );
    }
  };

  const getMetricData = async () => {
    let startDateNew = dayjs(startDate).startOf("day");
    let formatedStartDateNew = startDateNew.toISOString();

    let endDateNew = dayjs(endDate).endOf("day");
    let formatedEndDate = endDateNew.toISOString();

    let newStartDate = dayjs(nextStartDate).startOf("day");
    let formatedStartDate = newStartDate.toISOString();

    let newEndDate = dayjs(nextEndDate).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    let locationIds = [];
    if (location?.length > 0) {
      location?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let groupId = [];
    if (selectedGroup?.length > 0) {
      selectedGroup?.map((item) => {
        groupId?.push(item?.id);
      });
    }
    let countryCode = [];
    if (selectedCountries?.length > 0) {
      selectedCountries?.map((item) => {
        countryCode?.push(item?.countryCode);
      });
    }
    const newCancelTokenSource = addCancelTokenSource();
    try {
      setMetricsLoading(true);
      const res = await api.get(
        `googleInsight/google-insight-metric?locationId=${locationIds.toString()}${
          groupId?.length > 0 ? "&groupId=" + groupId?.toString() : ""
        }${
          countryCode?.length > 0 ? "&country=" + countryCode?.toString() : ""
        }&startDate=${formatedStartDateNew}&endDate=${formatedEndDate}&nextStartDate=${formatedStartDate}&nextEndDate=${formatedNextEndDate}`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setMetricsLoading(false);
        if (res?.data?.data?.length > 0) {
          setGraphData(res?.data?.data);
        } else {
          setGraphData(null);
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };
  const getLocationInsightData = async () => {
    let startDateNew = dayjs(startDate).startOf("day");
    let formatedStartDateNew = startDateNew.toISOString();
    let endDateNew = dayjs(endDate).endOf("day");
    let formatedEndDate = endDateNew.toISOString();

    let newStartDate = dayjs(nextStartDate).startOf("day");
    let formatedStartDate = newStartDate.toISOString();
    let newEndDate = dayjs(nextEndDate).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    let locationIds = [];
    if (location?.length > 0) {
      location?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let groupId = [];
    if (selectedGroup?.length > 0) {
      selectedGroup?.map((item) => {
        groupId?.push(item?.id);
      });
    }
    let countryCode = [];
    if (selectedCountries?.length > 0) {
      selectedCountries?.map((item) => {
        countryCode?.push(item?.countryCode);
      });
    }
    const newCancelTokenSource = addCancelTokenSource();
    try {
      setRatingLoading(true);
      const response = await api.get(
        `googleInsight/google-insight?locationId=${locationIds?.toString()}${
          groupId?.length > 0 ? "&groupId=" + groupId?.toString() : ""
        }${
          countryCode?.length > 0 ? "&country=" + countryCode?.toString() : ""
        }&startDate=${formatedStartDateNew}&endDate=${formatedEndDate}&nextStartDate=${formatedStartDate}&nextEndDate=${formatedNextEndDate}`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (response.status === 200) {
        const businessData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_CONVERSATIONS"
        );
        const bookingsData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_BOOKINGS"
        );
        const bookingFoodData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_FOOD_ORDERS"
        );
        const businessNewData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_CONVERSATIONS"
        );
        const bookingsNewData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_BOOKINGS"
        );
        const bookingNewFoodData = googleInsightData?.find(
          (item) => item?.metricType === "BUSINESS_FOOD_ORDERS"
        );
        if (
          businessData?.metricValue === 0 &&
          bookingsData?.metricValue === 0 &&
          bookingFoodData?.metricValue === 0 &&
          (businessNewData?.metricValue > 0 ||
            bookingsNewData?.metricValue > 0 ||
            bookingNewFoodData?.metricValue > 0) &&
          selectedChartTab === 3
        ) {
          setSelectedChartTab(selectedChartTab);
        } else if (
          (businessData?.metricValue > 0 ||
            bookingsData?.metricValue > 0 ||
            bookingFoodData?.metricValue > 0) &&
          businessNewData?.metricValue === 0 &&
          bookingsNewData?.metricValue === 0 &&
          bookingNewFoodData?.metricValue === 0 &&
          selectedChartTab === 3
        ) {
          setSelectedChartTab(selectedChartTab - 1);
        } else if (
          businessData?.metricValue === 0 &&
          bookingsData?.metricValue === 0 &&
          bookingFoodData?.metricValue === 0 &&
          (businessNewData?.metricValue > 0 ||
            bookingsNewData?.metricValue > 0 ||
            bookingNewFoodData?.metricValue > 0) &&
          selectedChartTab === 2
        ) {
          setSelectedChartTab(selectedChartTab + 1);
        }

        setGoogleInsightData(response?.data?.data);

        setRatingLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setGraphData(null);

        setRatingLoading(false);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getSearchesBreakdownData = async () => {
    let startDateNew = dayjs(startDate).startOf("day");
    let formatedStartDateNew = startDateNew.toISOString();
    let endDateNew = dayjs(endDate).endOf("day");
    let formatedEndDate = endDateNew.toISOString();
    let newStartDate = dayjs(nextStartDate).startOf("day");
    let formatedStartDate = newStartDate.toISOString();
    let newEndDate = dayjs(nextEndDate).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    const newCancelTokenSource = addCancelTokenSource();

    let locationIds = [];
    if (location?.length > 0) {
      location?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let groupId = [];
    if (selectedGroup?.length > 0) {
      selectedGroup?.map((item) => {
        groupId?.push(item?.id);
      });
    }
    let countryCode = [];
    if (selectedCountries?.length > 0) {
      selectedCountries?.map((item) => {
        countryCode?.push(item?.countryCode);
      });
    }
    try {
      setSearchesBreakdownLoading(true);
      const res = await api.get(
        `searchkeywords/get-keywords-with-filters?startDate=${formatedStartDateNew}&endDate=${formatedEndDate}&nextStartDate=${formatedStartDate}&nextEndDate=${formatedNextEndDate}&locationId=${locationIds?.toString()}${
          groupId?.length > 0 ? "&groupId=" + groupId?.toString() : ""
        }${
          countryCode?.length > 0 ? "&country=" + countryCode?.toString() : ""
        }`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setSearchesBreakdownLoading(false);
        setSearchesBreakdownData(res?.data?.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setSearchesBreakdownLoading(false);
        setSearchesBreakdownData(null);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  const getGraphSearchesKeyword = async () => {
    let startDateNew = dayjs(startDate).startOf("day");
    let formatedStartDateNew = startDateNew.toISOString();
    let endDateNew = dayjs(endDate).endOf("day");
    let formatedEndDate = endDateNew.toISOString();
    let newStartDate = dayjs(nextStartDate).startOf("day");
    let formatedStartDate = newStartDate.toISOString();
    let newEndDate = dayjs(nextEndDate).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    const newCancelTokenSource = addCancelTokenSource();
    let locationIds = [];
    if (location?.length > 0) {
      location?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let groupId = [];
    if (selectedGroup?.length > 0) {
      selectedGroup?.map((item) => {
        groupId?.push(item?.id);
      });
    }
    let countryCode = [];
    if (selectedCountries?.length > 0) {
      selectedCountries?.map((item) => {
        countryCode?.push(item?.countryCode);
      });
    }
    try {
      setGraphSearchKeywordsLoading(true);
      const res = await api.get(
        `searchkeywords/get-graph-with-filters?startDate=${formatedStartDateNew}&endDate=${formatedEndDate}&nextStartDate=${formatedStartDate}&nextEndDate=${formatedNextEndDate}&locationId=${locationIds?.toString()}${
          groupId?.length > 0 ? "&groupId=" + groupId?.toString() : ""
        }${
          countryCode?.length > 0 ? "&country=" + countryCode?.toString() : ""
        }`,
        {
          cancelToken: newCancelTokenSource.token,
        }
      );
      if (res.status === 200) {
        setGraphSearchKeywordsLoading(false);
        setGraphSearchesKeywordData(res?.data?.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setGraphSearchKeywordsLoading(false);
        setGraphSearchesKeywordData(null);
      }
    } finally {
      removeCancelTokenSource(newCancelTokenSource);
    }
  };

  function kFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (Math.abs(Number(num)) / 1.0e6).toFixed(2) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return Math.sign(num) * Math.abs(num); // if value < 1000, nothing to do
    }
  }

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}
  }

  const handleConnectWithGoogle = () => {
    fetchURL();
  };
  const handleOpenGoogleModel = () => {
    setOpenGoogleModel(true);
  };
  const handleCloseGoogleModel = () => {
    setOpenGoogleModel(false);
  };

  const handleChangeCountries = (value) => {
    setCallApi(false);
    setCallInsightsApis(false);

    if (value?.length > 0) {
      if (value.find((option) => option.id === 0)) {
        // Check if all options are selected
        if (countries?.length === selectedCountries?.length) {
          setSelectedCountries([]);
          setSelectAllCountries(false);
        } else {
          setSelectAllCountries(true);
          setSelectedCountries(countries);
          const googleLinkedLocations = allLocations?.filter(
            (item) =>
              item?.status === "approved" &&
              item.socialLink.length > 0 &&
              item.socialLink.some(
                (link) =>
                  link?.type === "google" &&
                  link?.statusOfGoogleInsight === "Done"
              )
          );
          setLocation(googleLinkedLocations);
        }
        handleChangeLocationsBySelectingCountries(countries);
      } else {
        setSelectedCountries(value);

        handleChangeLocationsBySelectingCountries(value);
        if (value?.length === countries?.length) {
          setSelectAllCountries(true);
        } else {
          setSelectAllCountries(false);
        }
      }
    } else {
      handleChangeLocationsBySelectingCountries(value);
      setSelectedCountries([]);
      setSelectAllCountries(false);
      // setLocation([]);
    }
  };
  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.locationsArray?.some((loc) => loc.id === targetId)
    );
  }
  function filterArrayByIdForGroups(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.groups?.some((loc) => loc.id === targetId)
    );
  }

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...location];
    const results = oldCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  location?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          location?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );

    setLocation(googleLinkedLocations);
    handleDisableDates(googleLinkedLocations);

    setOldCountries(value);
  };
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }
  function checkCountriesExistence(locationArray, arr) {
    const googleLinkedLocations = locationArray?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    return googleLinkedLocations?.every((loc) =>
      arr.some((elem) => elem.id === loc.id)
    );
  }
  const handleResetModelStates = () => {
    setScheduleLocations(location);
    setSelectAllScheduleLocations(selectAllLocations);
    setScheduleGroups(selectedGroup);
    setSelectAllScheduleGroups(selectAllGroups);
    setScheduleCountries(selectedCountries);
    setSelectAllScheduleCountries(selectAllCountries);
    setOldScheduleCountries(oldCountries);
    setOldScheduleGroups(oldGroup);
    setDateRangeSchedule({
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      range: dateRange?.range,
    });
    setStartDateSchedule(startDate);
    setEndDateSchedule(endDate);
    setNextEndDateSchedule(nextEndDate);
    setNextStartDateSchedule(nextStartDate);
    setStartDateRangeSchedule(null);
    setEndDateRangeSchedule(null);
    setDisabledStartDateSchedule(disabledStartDate);
    setImages([]);
    setUploadedImages([]);
    setSelectedFile(null);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };
  const handleAddLocation = () => {
    navigate("/user/add-location");
  };
  const handleChangeStartDate = (e) => {
    setCallApi(false);
    setCallInsightsApis(false);
    setStartDate(e);
    let previousStartDate = dayjs(e).subtract(1, "year");
    setNextStartDate(previousStartDate);
  };
  const handleChangeEndDate = (e) => {
    setCallApi(false);
    setCallInsightsApis(false);
    let previousEndDate = dayjs(e).subtract(1, "year");
    setNextEndDate(previousEndDate);
    setEndDate(e);
  };
  function isValidDateFormat(dateString, format) {
    return dayjs(dateString, { format, strict: true }).isValid();
  }

  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = () => {
    setOpenFilterModel(false);
  };

  const resetHandler = () => {
    setStartDate(previousYear);

    setEndDate(today);
    setNextStartDate(newStartDate);
    setNextEndDate(previousYear);
    setLocation([defaultValueLocation]);
    setSelectedCountries([]);
    setSelectedGroup([]);
    setSelectAllCountries(false);
    setSelectAllGroups(false);
    setSelectAllLocations();
    setCallApi(false);
    setDateRange({
      startDate: new Date(previousYear),
      endDate: new Date(today),
      range: "custom",
    });
    setCallInsightsApis(true);
  };

  const handleClose = () => {
    setAnchorBulk(null);
  };
  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };
  const handleClickMenuItem = (value) => {
    if (value === "pdf") {
      handleOpenExportFileDownloadModal(true);
    } else if (value === "email") {
      handleOpenReportEmailModal(true);
    } else {
      handleOpenScheduleModal(true);
    }
  };
  const handleOpenExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(true);
  };

  const handleOpenScheduleModal = () => {
    setOpenSchduleModal(true);
  };
  const handleCloseScheduleModal = () => {
    setOpenSchduleModal(false);
    handleResetModelStates();
  };
  const handleOpenReportEmailModal = () => {
    setOpenReportEmailModal(true);
  };
  const handleCloseEmailReportModal = () => {
    setOpenReportEmailModal(false);
    handleResetOthers();
    handleResetModel();
  };
  const handleDownloadGoogleStatsReport = async () => {
    let startDateNew = dayjs(startDateSchedule).startOf("day");
    let startDatee = startDateNew.toISOString();

    let endDateNew = dayjs(endDateSchedule).endOf("day");
    let endDatee = endDateNew.toISOString();

    let newStartDate = dayjs(nextStartDateSchedule).startOf("day");
    let formatedStartDate = newStartDate.toISOString();

    let newEndDate = dayjs(nextEndDateSchedule).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();

    try {
      setExportReportLoading(true);

      let locationIds = [];
      if (scheduleLocations?.length > 0) {
        scheduleLocations?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      let groupIds = [];
      if (scheduleGroups?.length > 0) {
        scheduleGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
      let selectedCountry = [];
      if (scheduleCountries?.length > 0) {
        scheduleCountries?.map((item) => {
          selectedCountry.push(item?.countryCode);
        });
      }

      const res = await api.post("/googleInsightsExports", {
        groupId: groupIds,
        locationIds: locationIds,
        endDate: endDatee,
        startDate: startDatee,
        nextStartDate: formatedStartDate,
        nextEndDate: formatedNextEndDate,
        country: selectedCountry,
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
      });

      if (res.status === 201) {
        if (res?.data?.data?.length > 0) {
          res?.data?.data?.forEach((item) => {
            window.open(item?.report);
          });
        }

        toast.success(res?.data?.message);
        setOpenDownloadPdfModal(false);
        handleResetModelStates();
        setExportReportLoading(false);
        handleCloseExportFileDownloadModal();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setExportReportLoading(false);
    }
  };
  const handleCloseExportFileDownloadModal = () => {
    setOpenDownloadPdfModal(false);
    handleResetModel();
  };
  const handleChangeStartDateSchedule = (e) => {
    setStartDateSchedule(e);
    let previousStartDate = dayjs(e).subtract(1, "year");
    setNextStartDateSchedule(previousStartDate);
  };

  const handleChangeEndDateSchedule = (e) => {
    setEndDateSchedule(e);
    let previousEndDate = dayjs(e).subtract(1, "year");
    setNextEndDateSchedule(previousEndDate);
  };
  const handleChangeLanguageForScheduler = (value) => {
    setLanguageSchedular(value);
  };
  const handleReopenModal = () => {
    setOpenEmailModal(true);
  };
  const handleRemoveFile = () => {
    setDataArray([]);
    setSelectedFile(null);
    setSelectedItems([]);
  };

  const handleCancelEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
      setSelectedItems([]);
    }
    setSelectAllCheckedModal(false);
  };
  const handleEmailGoogleStatsReport = async () => {
    try {
      setExportReportLoading(true);
      let startDateNew = dayjs(startDateSchedule).startOf("day");
      let startDatee = startDateNew.toISOString();

      let endDateNew = dayjs(endDateSchedule).endOf("day");
      let endDatee = endDateNew.toISOString();

      let newStartDate = dayjs(nextStartDateSchedule).startOf("day");
      let formatedStartDate = newStartDate.toISOString();

      let newEndDate = dayjs(nextEndDateSchedule).endOf("day");
      let formatedNextEndDate = newEndDate.toISOString();

      let locationIds = [];
      if (scheduleLocations?.length > 0) {
        scheduleLocations?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      let groupIds = [];
      if (scheduleGroups?.length > 0) {
        scheduleGroups?.map((item) => {
          groupIds.push(item?.id);
        });
      }
      let selectedCountry = [];
      if (scheduleCountries?.length > 0) {
        scheduleCountries?.map((item) => {
          selectedCountry.push(item?.countryCode);
        });
      }

      let emails = [...totalEmailsArr];

      if (selectedCompanyUsers?.length > 0) {
        selectedCompanyUsers?.map((item) => {
          emails.push(item?.User?.email);
        });
      }

      const res = await api.post("/googleInsightsExports", {
        groupId: groupIds,
        locationIds: locationIds,
        endDate: endDatee,
        startDate: startDatee,
        nextEndDate: formatedNextEndDate,
        nextStartDate: formatedStartDate,
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        country: selectedCountry,
        emails: emails?.toString(),
        subject: emailSubject,
        body: emailBody,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
      });

      if (res.status === 201) {
        setExportReportLoading(false);
        handleCloseEmailReportModal();
        handleResetOthers();
        toast.success("User will receive email shortly", {
          autoClose: 7000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setExportReportLoading(false);
    }
  };
  const handleAddNewUserEmailsChange = (value) => {
    const formattedValue = value.replace(/,\s*/g, ",\n");
    setAddNewUserEmails(formattedValue);

    const emailsArray = formattedValue.split(",");
    const validEmailsArray = [];

    // Validate each email address and push the valid ones into the array
    for (let i = 0; i < emailsArray.length; i++) {
      const email = emailsArray[i].trim();
      if (isValidEmail(email)) {
        validEmailsArray.push(email);
        setValidEmailsArray(validEmailsArray);
      }
    }

    const combinedArray = [...selectedItems, ...validEmailsArray];

    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    setTotalEmailsArr(distinctEmails);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitOk = () => {
    const combinedArray = [...selectedItems, ...validEmailsArray];
    // Remove duplicates and normalize emails
    const uniqueEmails = [...new Set(combinedArray)];
    const normalizedEmails = uniqueEmails.map((email) =>
      email.toString().toLowerCase()
    );

    // Filter valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const distinctEmails = normalizedEmails.filter((email) =>
      emailRegex.test(email)
    );

    if (distinctEmails?.length > 0) {
      setTotalEmailsArr(distinctEmails);
    }
    handleCloseEmailsModal();
    if (selectedItems.length <= 0) {
      setDataArray([]);
      setSelectedFile(null);
    }
  };
  const handleCloseEmailsModal = () => {
    document.getElementById("file-email").value = "";
    setOpenEmailModal(false);
    setDataArray([]);
    setSelectedFile(null);
    setSelectedItems([]);
    setTotalEmailsArr([]);

    setSelectAllCheckedModal(false);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        let data;
        if (file.type === "text/csv") {
          const lines = fileContents.split("\n");
          data = lines.map((line) => line.replace("\r", "").split(","));
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const workbook = XLSX.read(fileContents, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          data = [];
          sheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            data.push(...jsonData);
          });
        } else {
          return;
        }
        const flatData = data.flat();

        setTimeout(() => {
          setFileLoading(false);
        }, 1000);
        setDataArray(flatData);
      };
      setFileLoading(true);

      if (file.type === "text/csv") {
        reader.readAsText(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(file);
      }
      setOpenEmailModal(true);
      setSelectedFile(file.name);
    } else {
      setTimeout(() => {
        setFileLoading(false);
      }, 1000);
    }
  };
  const handleResetOthers = () => {
    setTotalEmailsArr([]);
    setSelectedItems([]);
    setValidEmailsArray([]);
    setAddNewUserEmails([]);
    setSelectedFile(null);
    setDataArray([]);
  };

  const getAllScheduledReports = async () => {
    setScheduledReportsLoading(true);
    try {
      const res = await api.get(
        `reportSchedules?page=${
          scheduledRportsPageNumber + 1
        }&limit=${rowsPerPageScheduled}&locationId=&reportType=Google Insights`
      );
      if (res.status === 200) {
        setScheduledReports(res?.data?.data);
        setScheduledReportsLoading(false);
      }
    } catch (error) {
      setScheduledReportsLoading(false);
      setScheduledReports(null);
    }
  };
  const getSingleSelectedReportById = async () => {
    if (selectedEditId !== null) {
      try {
        const res = await api.get(`reportSchedules/${selectedEditId}`);
        if (res.status === 200) {
          setSingleReportData(res?.data?.data);
        }
      } catch (error) {
        setSingleReportData(null);
      }
    }
  };

  const handleOpenCreateScheduleModal = (id) => {
    setOpenCreateSchedule(true);
    setSelectedEditId(id);
  };
  const handleCloseCreateScheduleModal = () => {
    setOpenCreateSchedule(false);
    handleResetModel();
    handleResetModelStates();
  };
  const handleOpenDeleteScheduledReportModel = (deletedIds) => {
    setDeletedId(deletedIds);
    setOpenDeleteScheduledReportModel(true);
  };
  const handleCloseDeleteScheduledReportModel = () => {
    setOpenDeleteScheduledReportModel(false);
    setDeletedId(null);
  };
  const handleOpenUpdateStatusModel = (rowData, statusValue) => {
    setOpenUpdateStatusModel(true);
    setSelectedRowData(rowData);
    setPauseValue(statusValue);
  };
  const handleCloseUpdateStatusModel = () => {
    setOpenUpdateStatusModel(false);
    setSelectedRowData(null);
    setPauseValue(false);
  };

  const handleScheduleReport = async () => {
    setScheduleReportLoading(true);
    let startDateNew = dayjs(startDateSchedule).startOf("day");
    let startDatee = startDateNew.toISOString();

    let endDateNew = dayjs(endDateSchedule).endOf("day");
    let endDatee = endDateNew.toISOString();

    let newStartDate = dayjs(nextStartDateSchedule).startOf("day");
    let formatedStartDate = newStartDate.toISOString();

    let newEndDate = dayjs(nextEndDateSchedule).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    let locationIds = [];
    if (scheduleLocations?.length > 0) {
      scheduleLocations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let selectedCountry = [];
    if (scheduleCountries?.length > 0) {
      scheduleCountries?.map((item) => {
        selectedCountry.push(item?.countryCode);
      });
    }
    let filterObj = {
      locationIds: locationIds,
      groupIds: groupIds,
      endDate: endDatee,
      startDate: startDatee,
      nextStartDate: formatedStartDate,
      nextEndDate: formatedNextEndDate,
      country: selectedCountry,
      dateRange: "custom",
    };

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }

    try {
      const res = await api.post(
        `/reportSchedules`,

        {
          filter: filterObj,
          locationIds: locationIds,
          customLogo:
            uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
          language:
            languageSchedular !== null && languageSchedular !== ""
              ? languageSchedular?.language
              : "",
          startDate: startDateRangeSchedule,
          endDate: endDateRangeSchedule,
          fileType:
            pdfChecked && xlsChecked
              ? "both"
              : pdfChecked && xlsChecked === false
              ? "pdf"
              : "xlsx",
          emailSubject,
          emailBody,
          emails: emails,
          repeatType: repeatValue,
          reportType: "Google Insights",
          status: "pending",
          title: emailTitle,
          /*   includeReviews: reviewsChecked,
           includedSections: featuresObj,
           customLogo:
             uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
           noReviewsNotification:
             yesChecked && noChecked===false
               ? true
               : yesChecked===false && noChecked===true
               ? false
               : true, */
        }
      );
      if (res.status === 201) {
        handleCloseCreateScheduleModal();
        getAllScheduledReports();
        setScheduleReportLoading(false);
        handleResetModelStates();
        handleResetOthers();

        toast.success(`${t("Schedule_created")}`);

        setOpenCreateSchedule(false);
      }
    } catch (error) {
      setScheduleReportLoading(false);
      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };
  const handleUpdateStatus = async () => {
    try {
      setStatusLoading(true);
      const res = await api.patch(`reportSchedules/${selectedRowData?.id}`, {
        filter: selectedRowData?.filter,
        locationIds: selectedRowData?.filter?.locationIds,
        startDate: selectedRowData?.startDate,
        endDate: selectedRowData?.endDate,
        fileType: selectedRowData?.fileType,
        emailSubject: selectedRowData?.emailSubject,
        language: selectedRowData?.language,
        emailBody: selectedRowData?.emailBody,
        emails: JSON.parse(selectedRowData?.emails),
        repeatType: selectedRowData?.repeatType,
        reportType: selectedRowData?.reportType,
        status: selectedRowData?.status,
        isPaused: pauseValue === true ? true : false,
        title: selectedRowData?.title,
        reportScheduleLocations: selectedRowData?.ReportScheduleLocations,
        //  includeReviews: reviewsChecked,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setStatusLoading(false);
        handleCloseUpdateStatusModel();
        getAllScheduledReports();
      }
    } catch (error) {
      setStatusLoading(false);
      handleCloseUpdateStatusModel();
      toast.error(error?.response?.data?.data);
    }
  };
  const handleUpdateScheduledReport = async () => {
    let startDateNew = dayjs(startDateSchedule).startOf("day");
    let startDatee = startDateNew.toISOString();

    let endDateNew = dayjs(endDateSchedule).endOf("day");
    let endDatee = endDateNew.toISOString();

    let newStartDate = dayjs(nextStartDateSchedule).startOf("day");
    let formatedStartDate = newStartDate.toISOString();

    let newEndDate = dayjs(nextEndDateSchedule).endOf("day");
    let formatedNextEndDate = newEndDate.toISOString();
    let locationIds = [];
    if (scheduleLocations?.length > 0) {
      scheduleLocations?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    let groupIds = [];
    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        groupIds.push(item?.id);
      });
    }
    let selectedCountry = [];
    if (scheduleCountries?.length > 0) {
      scheduleCountries?.map((item) => {
        selectedCountry.push(item?.countryCode);
      });
    }
    let filterObj = {
      locationIds: locationIds,
      groupIds: groupIds,
      endDate: endDatee,
      startDate: startDatee,
      nextStartDate: formatedStartDate,
      nextEndDate: formatedNextEndDate,
      country: selectedCountry,
      dateRange: "custom",
    };

    let emails = [...totalEmailsArr];

    if (selectedCompanyUsers?.length > 0) {
      selectedCompanyUsers?.map((item) => {
        emails.push(item?.User?.email);
      });
    }

    const reportLoc = [];
    if (reportScheduleLocations.length > 0) {
      locationIds.forEach((itemOne) => {
        reportScheduleLocations.forEach((itemTwo) => {
          if (itemOne === itemTwo?.location?.id) {
            reportLoc.push(itemTwo);
          }
        });
      });
    }

    try {
      setUpdateReportLoading(true);
      const res = await api.patch(`reportSchedules/${selectedEditId}`, {
        filter: filterObj,
        locationIds: locationIds,
        startDate: startDateRangeSchedule,
        endDate: endDateRangeSchedule,
        language:
          languageSchedular !== null && languageSchedular !== ""
            ? languageSchedular?.language
            : "",
        fileType:
          pdfChecked && xlsChecked
            ? "both"
            : pdfChecked && xlsChecked === false
            ? "pdf"
            : "xlsx",
        emailSubject,
        emailBody,
        emails: emails,
        repeatType: repeatValue,
        reportType: "Google Insights",
        status: singleReportData?.status,
        title: emailTitle,
        reportScheduleLocations: reportLoc,
        customLogo:
          uploadedImages?.length > 0 ? uploadedImages[0]?.imagePath : "",
        /*   includeReviews: reviewsChecked,
          includedSections: featuresObj,
          noReviewsNotification:
            yesChecked && noChecked===false
              ? true
              : yesChecked===false && noChecked===true
              ? false
              : true, */
      });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setUpdateReportLoading(false);
        handleCloseCreateScheduleModal();
        handleResetModelStates();
        handleResetOthers();
        setSelectedEditId(null);
        getAllScheduledReports();
      }
    } catch (error) {
      setUpdateReportLoading(false);
      if (
        error?.response?.data?.data ===
        "Report Schedule Already Exists With this Name"
      ) {
        setDisplayReportError(true);
      } else {
        toast.error(error?.response?.data?.data);
      }
    }
  };
  const handleDeleteScheduleReport = async () => {
    try {
      setDeleteScheduleReportLoading(true);
      const res = await api.delete(`/reportSchedules/${deletedIds}`);
      if (res.status === 200) {
        toast.success(t("report_deleted"));
        setDeleteScheduleReportLoading(false);
        if (scheduledRportsPageNumber === 0) {
          getAllScheduledReports();
        }
        setScheduledRportsPageNumber(0);
        handleCloseDeleteScheduledReportModel();
      }
    } catch (error) {
      setDeletedId(null);

      toast.error(error?.response?.data?.message);
      handleCloseDeleteScheduledReportModel();
      setDeleteScheduleReportLoading(false);
    }
  };
  const handleChangeRangeEndDateSchedule = (e) => {
    setEndDateRangeSchedule(e);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        startDateRangeSchedule
      ).format("MMM DD, YYYY")} to ${dayjs(e).format("MMM DD, YYYY")}`
    );
  };

  const handleChangeRangeStartDateSchedule = (e) => {
    setStartDateRangeSchedule(e);
    setEmailBody(
      `Dear customer Please find attached emotion AI report for the ${dayjs(
        e
      ).format("MMM DD, YYYY")} to ${dayjs(endDateSchedule).format(
        "MMM DD, YYYY"
      )}`
    );
  };
  const handleResetModel = () => {
    setDeletedId(null);
    setStartDateSchedule(null);
    setEndDateSchedule(null);
    setNextEndDateSchedule(null);
    setNextStartDateSchedule(null);
    setEmailTitle("");
    setSingleReportData(null);
    setSelectedEditId(null);
    setImages([]);
    setUploadedImages([]);
    setSelectedFile(null);
    setCompanyUser(true);
    setOthers(false);
    setXlsChecked(false);
    setPdfChecked(true);
    setRepeatValue("no");
    setTotalEmailsArr([]);
    setSelectedItems([]);
    setValidEmailsArray([]);
    setAddNewUserEmails([]);

    setDataArray([]);
    setSelectedCompanyUsers([]);
    setOthers(false);

    setEmailSubject(`Google insights report of ${companyData?.name}`);
    setEmailBody(
      `Dear customer Please find attached Google insights report for the ${dayjs(
        startDate
      ).format("MMM DD, YYYY")} to ${dayjs(endDate).format("MMM DD, YYYY")}`
    );
    setStartDateRangeSchedule(null);
    setEndDateRangeSchedule(null);

    setStartDateSchedule(startDate);
    setEndDateSchedule(endDate);
    setNextStartDateSchedule(nextStartDate);
    setNextEndDateSchedule(nextEndDate);

    setScheduleLocations(location);
    setScheduleGroups(selectedGroup);
    setScheduleCountries(selectedCountries);
    setLanguageSchedular("");
    setStartDateRangeSchedule(null);
    setEndDateRangeSchedule(null);
    setDisabledStartDateSchedule(disabledStartDate);
  };

  const handleCloseGoogleRetryModel = () => {
    setOpenGoogleRetryModel(false);
    setLocationId(null);
  };
  const handleOpenGoogleRetryModel = (locId) => {
    setLocationId(locId);
    setOpenGoogleRetryModel(true);
  };

  const handleCloseEmailWaring = () => {
    setDisplayReconnectError(false);
  };
  const handleOpenLocationFixModel = () => {
    setOpenFixModel(true);
  };
  const handleCloseLocationFixModel = () => {
    setOpenFixModel(false);
  };
  const handleNavigation = (locationValue) => {
    navigate(`/user/token-expire/location/${locationValue?.id}`);
  };

  return (
    <>
      {openFixModel && (
        <LocationFixModel
          open={openFixModel}
          handleCloseAddEmailModel={handleCloseLocationFixModel}
          allLocations={expiredLocations}
          handleOpenGoogleRetryModel={handleOpenGoogleRetryModel}
          handleOpenGoogleModel={handleOpenGoogleModel}
          handleNavigation={handleNavigation}
          setLocationId={setLocationId}
        />
      )}
      {openGoogleModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openGoogleModel}
          onClose={handleCloseGoogleModel}
          aria-labelledby="responsive-dialog-title"
        >
          <GoogleConnectModal
            title={t("google_connect_title")}
            description={t("google_connect_description")}
            onConfirm={handleConnectWithGoogle}
            onCancel={handleCloseGoogleModel}
            loading={isLoading}
          />
        </Dialog>
      )}
      {openGoogleRetryModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openGoogleRetryModel}
          onClose={handleCloseGoogleModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Unable_google_data")}
            description={t("Unable_google_data_subhead")}
            onConfirm={handleRetryWithGoogle}
            onCancel={handleCloseGoogleRetryModel}
            loading={retryLoading}
          />
        </Dialog>
      )}
      {openDeleteScheduledReportModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDeleteScheduledReportModel}
          onClose={handleCloseDeleteScheduledReportModel}
        >
          <Delete
            title={t("scheduled_report_delete")}
            description={t("confirm_delete_scheduled_report")}
            onConfirm={handleDeleteScheduleReport}
            onCancel={handleCloseDeleteScheduledReportModel}
            loading={deleteScheduleReportLoading}
          />
        </Dialog>
      )}
      {openUpdateStatusModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openUpdateStatusModel}
          onClose={handleCloseUpdateStatusModel}
        >
          <Delete
            title={pauseValue ? t("pause_report") : t("resume_report")}
            description={
              pauseValue
                ? t("pause_confirmation_text")
                : t("resume_confirmation_text")
            }
            onConfirm={handleUpdateStatus}
            onCancel={handleCloseUpdateStatusModel}
            loading={statusLoading}
            schedule={true}
          />
        </Dialog>
      )}
      <TitleHeader
        title={t("Google_Stats")}
        emotionAiEcportButton={true}
        name={t("export")}
        addPermission={addPermission}
        viewPermission={viewPermission}
        anchorBulk={anchorBulk}
        handleClose={handleClose}
        handleClickMenu={handleClickMenu}
        handleClickMenuItem={handleClickMenuItem}
      />
      <Grid container spacing={2}>
        <>
          {locationLoading ? (
            <>
              <Grid
                container
                sx={{
                  height: "350px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </Grid>
            </>
          ) : allLocations?.length > 0 ? (
            locationRequestError ? (
              <Box className={classes.locationbox}>
                <LocationRequestedState location={location} />

                {locationAddPermission && locationViewPermission ? (
                  <CommonButton
                    onSubmit={handleAddLocation}
                    label={t("Add_other_location")}
                    icon={<AddIcon sx={{ color: "#FFF" }} />}
                  />
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <CommonButton
                        disabled={true}
                        onSubmit={handleAddLocation}
                        label={t("Add_other_location")}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </Box>
            ) : (
              <>
                {displayReconnectError && (
                  <Grid
                    container
                    sx={{ marginBottom: "5px", marginLeft: "8px" }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {expiredLocations?.length > 0 && (
                        <Box className={classes.toast}>
                          <Box className={classes.toastBox}>
                            <Box className={classes.toastBoxBorder}></Box>
                            <Box className={classes.toastBoxContent}>
                              <Box className={classes.toastBoxText}>
                                <Box sx={{ display: "flex" }}>
                                  <WarningAmberOutlined
                                    sx={{
                                      color: "#FAB431",
                                      marginRight: "12px",
                                    }}
                                  />
                                  <Box>
                                    <Typography>
                                      {expiredLocations?.length > 2 ? (
                                        <>
                                          {expiredLocations
                                            .slice(0, 2)
                                            .map((item) => item?.internalName)
                                            .join(", ")}{" "}
                                          <span style={{ fontWeight: 700 }}>
                                            +
                                            {`${expiredLocations?.length - 2} ${
                                              expiredLocations?.length - 2 === 1
                                                ? t("otherText")
                                                : t("othersText")
                                            } `}
                                          </span>
                                          <span>
                                            {t("google_stats_warning")}
                                          </span>
                                        </>
                                      ) : (
                                        expiredLocations
                                          .map((item) => item?.internalName)
                                          .join(", ") +
                                        t("google_stats_warning")
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box sx={{ display: "flex" }}>
                                  <CommonButton
                                    label={t("Ignore")}
                                    customHeight={true}
                                    displayWhite="true"
                                    onSubmit={handleCloseEmailWaring}
                                  />
                                  <CommonButton
                                    label={t("fix_text")}
                                    onSubmit={handleOpenLocationFixModel}
                                    customHeight={true}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FilterHeader
                    countries={countries}
                    handleChangeCountries={handleChangeCountries}
                    selectAllCountries={selectAllCountries}
                    setSelectAllCountries={setSelectAllCountries}
                    locationViewPermission={locationViewPermission}
                    locationAddPermission={locationAddPermission}
                    handleNavigationToGroups={handleNavigationToGroups}
                    allGroups={allGroups}
                    handleChangeGroups={handleChangeGroups}
                    allLocations={allLocations}
                    handleChangeLocation={handleChangeLocation}
                    location={location}
                    locationLoading={locationLoading}
                    kFormatter={kFormatter}
                    handleViewLocation={handleViewLocation}
                    selectedCountries={selectedCountries}
                    selectedGroups={selectedGroup}
                    setSelectedGroups={setSelectedGroup}
                    handleChangeStartDate={handleChangeStartDate}
                    startDate={startDate}
                    previousYear={previousYear}
                    endDate={endDate}
                    dateRange={dateRange}
                    oneYearAgoDate={oneYearAgoDate}
                    setCallInsightsApis={setCallInsightsApis}
                    handleChangeEndDate={handleChangeEndDate}
                    setDateRange={setDateRange}
                    handleOpenFilterModel={handleOpenFilterModel}
                    handleCloseFilterModel={handleCloseFilterModel}
                    openFilterModel={openFilterModel}
                    resetStart={previousYear}
                    resetEnd={today}
                    setLocation={setLocation}
                    setCallApi={setCallApi}
                    defaultLocation={defaultValueLocation}
                    checkGroupsExistence={checkGroupsExistence}
                    setSelectedCountries={setSelectedCountries}
                    checkCountriesExistence={checkCountriesExistence}
                    resetHandler={resetHandler}
                    oldCountries={oldCountries}
                    oldGroup={oldGroup}
                    setSelectAllGroups={setSelectAllGroups}
                    selectAllGroups={selectAllGroups}
                    selectAllLocations={selectAllLocations}
                    setSelectAllLocations={setSelectAllLocations}
                    getCountryWiseLocations={getCountryWiseLocations}
                    handleSubmitFilters={handleSubmitFilters}
                    handleOpenGoogleModel={handleOpenGoogleModel}
                    handleOpenGoogleRetryModel={handleOpenGoogleRetryModel}
                    setEmailBody={setEmailBody}
                    disabledStartDate={disabledStartDate}
                    setDisabledStartDateSchedule={setDisabledStartDate}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ marginTop: "8px" }}
                >
                  <Card
                    variant="outlined"
                    className={classes.cardStatsContainer}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.ratingContainer}
                    >
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Typography className={classes.subheadGoogleName}>
                          {t("Google_Stats")}
                        </Typography>{" "}
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <CustomTooltip
                            title={t("discoveredby_subhead")}
                            placement="bottom-start"
                          >
                            <IconButton>
                              <ErrorOutlineIcon
                                fontSize="small"
                                sx={{ color: "#495059" }}
                              />
                            </IconButton>
                          </CustomTooltip>
                          <Typography className={classes.subheadGoogletext}>
                            {t("Discovery_text")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Chart
                          googleInsightData={googleInsightData}
                          location={location}
                          displayBox={displayBox}
                          graphData={graphData}
                          setLoading={setLoading}
                          ratingLoading={ratingLoading}
                          metricsLoading={metricsLoading}
                          searchesBreakdownData={searchesBreakdownData}
                          setSearchesBreakdownData={setSearchesBreakdownData}
                          searchesBreakdownLoading={searchesBreakdownLoading}
                          graphSearchesKeywordData={graphSearchesKeywordData}
                          graphSearchKeywordsLoading={
                            graphSearchKeywordsLoading
                          }
                          selectedChartTab={selectedChartTab}
                          setSelectedChartTab={setSelectedChartTab}
                          setGraphSearchesKeywordData={
                            setGraphSearchesKeywordData
                          }
                          setGoogleInsightData={setGoogleInsightData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            )
          ) : (
            allLocations === null && (
              <Grid
                container
                sx={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Box className={classes.locationbox}>
                  <LocationNotFound
                    text={t("No_Location_Addedd")}
                    subtext={t("No_location_listing_subhead")}
                  />
                  {/*  <CommonButton
                        onSubmit={() => navigate("/user/add-location")}
                        label={t("Add_Location")}
                      /> */}
                </Box>
              </Grid>
            )
          )}
        </>
      </Grid>
      {openDownloadPdfModal && (
        <ExportFileDownloadModal
          open={openDownloadPdfModal}
          title={t("download_report_title")}
          handleCloseNewRequestModel={handleCloseExportFileDownloadModal}
          handleDownloadGoogleStatsReport={handleDownloadGoogleStatsReport}
          exportReportLoading={exportReportLoading}
          setExportReportLoading={setExportReportLoading}
          setDateRange={setDateRange}
          dateRange={dateRange}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          // handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          setEmailBody={setEmailBody}
          setEmailTitle={setEmailTitle}
          emailTitle={emailTitle}
          countries={countries}
          defaultLocation={defaultValueLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          selectAll={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          dateRangeSchedule={dateRangeSchedule}
          setDateRangeSchedule={setDateRangeSchedule}
          startDateSchedule={startDateSchedule}
          handleChangeStartDateSchedule={handleChangeStartDateSchedule}
          endDateSchedule={endDateSchedule}
          handleChangeEndDateSchedule={handleChangeEndDateSchedule}
          scheduleCountries={scheduleCountries}
          selectAllScheduleCountries={selectAllScheduleCountries}
          setSelectAllScheduleCountries={setSelectAllScheduleCountries}
          selectAllScheduleLocations={selectAllScheduleLocations}
          setSelectAllScheduleLocations={setSelectAllScheduleLocations}
          selectAllScheduleGroups={selectAllScheduleGroups}
          setSelectAllScheduleGroups={setSelectAllScheduleGroups}
          setCallApi={setCallApi}
          resetStart={previousYear}
          resetEnd={today}
          setScheduleCountries={setScheduleCountries}
          oldScheduleCountries={oldScheduleCountries}
          setOldScheduleCountries={oldScheduleCountries}
          getCountryWiseLocations={getCountryWiseLocations}
          oldScheduleGroups={oldScheduleGroups}
          removeDuplicateLocaions={removeDuplicateLocaions}
          filterArrayById={filterArrayById}
          setOldScheduleGroups={setOldScheduleGroups}
          filterArrayByIdForGroups={filterArrayByIdForGroups}
          checkCountriesExistence={checkCountriesExistence}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          disabledStartDate={disabledStartDateSchedule}
          setDisabledStartDateSchedule={setDisabledStartDateSchedule}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
        />
      )}
      {openReportEmailModal && (
        <EmailReportModal
          open={openReportEmailModal}
          title={t("email_this_report")}
          handleCloseNewRequestModel={handleCloseEmailReportModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          recipientError={recipientError}
          setRecipientError={setRecipientError}
          recipientEmailError={recipientEmailError}
          setRecipientEmailError={setRecipientEmailError}
          recipientEmail={recipientEmail}
          setRecipientEmail={setRecipientEmail}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          totalEmailsArr={totalEmailsArr}
          isModalEmailsSent={isModalEmailsSent}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleResetOthers={handleResetOthers}
          handleButtonClick={handleButtonClick}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          fileInputRef={fileInputRef}
          handleReopenModal={handleReopenModal}
          handleRemoveFile={handleRemoveFile}
          exportReportLoading={exportReportLoading}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          handleEmailGoogleStatsReport={handleEmailGoogleStatsReport}
          setExportReportLoading={setExportReportLoading}
          setDateRange={setDateRange}
          dateRange={dateRange}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          countries={countries}
          defaultLocation={defaultValueLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          selectAll={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          dateRangeSchedule={dateRangeSchedule}
          setDateRangeSchedule={setDateRangeSchedule}
          startDateSchedule={startDateSchedule}
          handleChangeStartDateSchedule={handleChangeStartDateSchedule}
          endDateSchedule={endDateSchedule}
          handleChangeEndDateSchedule={handleChangeEndDateSchedule}
          scheduleCountries={scheduleCountries}
          selectAllScheduleCountries={selectAllScheduleCountries}
          setSelectAllScheduleCountries={setSelectAllScheduleCountries}
          selectAllScheduleLocations={selectAllScheduleLocations}
          setSelectAllScheduleLocations={setSelectAllScheduleLocations}
          selectAllScheduleGroups={selectAllScheduleGroups}
          setSelectAllScheduleGroups={setSelectAllScheduleGroups}
          setCallApi={setCallApi}
          resetStart={previousYear}
          resetEnd={today}
          setScheduleCountries={setScheduleCountries}
          oldScheduleCountries={oldScheduleCountries}
          setOldScheduleCountries={oldScheduleCountries}
          getCountryWiseLocations={getCountryWiseLocations}
          oldScheduleGroups={oldScheduleGroups}
          removeDuplicateLocaions={removeDuplicateLocaions}
          filterArrayById={filterArrayById}
          setOldScheduleGroups={setOldScheduleGroups}
          filterArrayByIdForGroups={filterArrayByIdForGroups}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          disabledStartDate={disabledStartDateSchedule}
          setDisabledStartDateSchedule={setDisabledStartDateSchedule}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
        />
      )}

      {openSchduleModal && (
        <SecheduledReports
          title={t("Secheduled_Reports")}
          open={openSchduleModal}
          handleCloseNewRequestModel={handleCloseScheduleModal}
          handleOpenCreateScheduleModal={handleOpenCreateScheduleModal}
          allLocations={allLocations}
          scheduledReportsLoading={scheduledReportsLoading}
          scheduledRportsPageNumber={scheduledRportsPageNumber}
          setScheduledRportsPageNumber={setScheduledRportsPageNumber}
          rowsPerPageScheduled={rowsPerPageScheduled}
          setRowsPerPageScheduled={setRowsPerPageScheduled}
          scheduledReports={scheduledReports}
          handleOpenDeleteScheduledReportModel={
            handleOpenDeleteScheduledReportModel
          }
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
          handleOpenUpdateStatusModel={handleOpenUpdateStatusModel}
          handleDeleteScheduleReport={handleDeleteScheduleReport}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
        />
      )}
      {openCreateSchedule && (
        <SecheduleReport
          open={openCreateSchedule}
          title={t("Sechedule_Report")}
          handleCloseNewRequestModel={handleCloseCreateScheduleModal}
          others={others}
          setOthers={setOthers}
          companyUser={companyUser}
          setCompanyUser={setCompanyUser}
          recipientError={recipientError}
          setRecipientError={setRecipientError}
          recipientEmailError={recipientEmailError}
          setRecipientEmailError={setRecipientEmailError}
          recipientEmail={recipientEmail}
          setRecipientEmail={setRecipientEmail}
          recipientValue={recipientValue}
          setRecipientValue={setRecipientValue}
          emailReportRef={emailReportRef}
          setSelectedCompanyUsers={setSelectedCompanyUsers}
          selectedCompanyUsers={selectedCompanyUsers}
          totalEmailsArr={totalEmailsArr}
          isModalEmailsSent={isModalEmailsSent}
          handleAddNewUserEmailsChange={handleAddNewUserEmailsChange}
          addNewUserEmials={addNewUserEmials}
          handleResetOthers={handleResetOthers}
          handleButtonClick={handleButtonClick}
          exportReportLoading={exportReportLoading}
          setEmailSubject={setEmailSubject}
          emailSubject={emailSubject}
          emailBody={emailBody}
          setEmailBody={setEmailBody}
          handleScheduleReport={handleScheduleReport}
          scheduleReportLoading={scheduleReportLoading}
          repeatValue={repeatValue}
          setRepeatValue={setRepeatValue}
          //  getSingleSelectedReportById={getSingleSelectedReportById}
          singleReportData={singleReportData}
          handleUpdateScheduledReport={handleUpdateScheduledReport}
          updateReportLoading={updateReportLoading}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          startDateSchedule={startDateSchedule}
          setStartDateSchedule={setStartDateSchedule}
          endDateSchedule={endDateSchedule}
          setEndDateSchedule={setEndDateSchedule}
          handleChangeStartDateSchedule={handleChangeStartDateSchedule}
          handleChangeEndDateSchedule={handleChangeEndDateSchedule}
          setDateRange={setDateRange}
          dateRange={dateRange}
          handleChangeLanguageForScheduler={handleChangeLanguageForScheduler}
          languageSchedular={languageSchedular}
          setLanguageSchedular={setLanguageSchedular}
          handleChangeRangeEndDateSchedule={handleChangeRangeEndDateSchedule}
          endDateRangeSchedule={endDateRangeSchedule}
          handleChangeRangeStartDateSchedule={
            handleChangeRangeStartDateSchedule
          }
          startDateRangeSchedule={startDateRangeSchedule}
          setStartDateRangeSchedule={setStartDateRangeSchedule}
          setEndDateRangeSchedule={setEndDateRangeSchedule}
          scheduleLocations={scheduleLocations}
          setScheduleLocations={setScheduleLocations}
          scheduleGroups={scheduleGroups}
          setScheduleGroups={setScheduleGroups}
          setScheduleLanguage={setLanguageSchedular}
          scheduleLanguage={languageSchedular}
          allLocations={allLocations}
          allGroups={allGroups}
          startDate={startDate}
          endDate={endDate}
          setNextEndDateSchedule={setNextEndDateSchedule}
          nextStartDateSchedule={nextStartDateSchedule}
          nextEndDateSchedule={nextEndDateSchedule}
          setNextStartDateSchedule={setNextStartDateSchedule}
          nextEndDate={nextEndDate}
          nextStartDate={nextStartDate}
          locations={scheduleLocations}
          selectedGroups={scheduleGroups}
          displayReportError={displayReportError}
          setDisplayReportError={setDisplayReportError}
          setReportScheduleLocations={setReportScheduleLocations}
          countries={countries}
          defaultLocation={defaultValueLocation}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          selectAllGroups={selectAllGroups}
          setSelectAllLocations={setSelectAllLocations}
          selectAllLocations={selectAllLocations}
          getSingleSelectedReportById={getSingleSelectedReportById}
          dateRangeSchedule={dateRangeSchedule}
          setDateRangeSchedule={setDateRangeSchedule}
          scheduleCountries={scheduleCountries}
          selectAllScheduleCountries={selectAllScheduleCountries}
          setSelectAllScheduleCountries={setSelectAllScheduleCountries}
          selectAllScheduleLocations={selectAllScheduleLocations}
          setSelectAllScheduleLocations={setSelectAllScheduleLocations}
          selectAllScheduleGroups={selectAllScheduleGroups}
          setSelectAllScheduleGroups={setSelectAllScheduleGroups}
          setCallApi={setCallApi}
          resetStart={previousYear}
          resetEnd={today}
          setScheduleCountries={setScheduleCountries}
          oldScheduleCountries={oldScheduleCountries}
          setOldScheduleCountries={setOldScheduleCountries}
          getCountryWiseLocations={getCountryWiseLocations}
          oldScheduleGroups={oldScheduleGroups}
          removeDuplicateLocaions={removeDuplicateLocaions}
          filterArrayById={filterArrayById}
          setOldScheduleGroups={setOldScheduleGroups}
          filterArrayByIdForGroups={filterArrayByIdForGroups}
          fileInputRef={fileInputRef}
          handleReopenModal={handleReopenModal}
          handleRemoveFile={handleRemoveFile}
          handleFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          pdfChecked={pdfChecked}
          setPdfChecked={setPdfChecked}
          xlsChecked={xlsChecked}
          setXlsChecked={setXlsChecked}
          disabledStartDate={disabledStartDateSchedule}
          setDisabledStartDateSchedule={setDisabledStartDateSchedule}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          images={images}
          setImages={setImages}
        />
      )}
    </>
  );
}

export default Listings;
