import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
  useMediaQuery,
} from "@mui/material";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Delete from "../../../components/Models/DeleteModal/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  locationsArray,
  locationValue,
  setLocationValue,
  handleSubmit,
  loading,
  submitButtonText,
  handleOpenDeleteModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);

  const filter = createFilterOptions();

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "700px",
              height: "300px",
              borderRadius: "4px",
            },
          }}
          open={open}
          fullScreen
          PaperProps={{
            style: {
              minWidth: "610px",
              maxWidth: "610px",
            },
          }}
          //  onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                    }}
                  >
                    {t("Select_Location")}
                  </Typography>
                  {submitButtonText === "Start" ? (
                    <Box sx={{ marginLeft: "3px", marginTop: "3px" }}>
                      <BootstrapTooltip title={t("bulk_reply_tooltip")}>
                        <ErrorOutlineIcon />
                      </BootstrapTooltip>
                    </Box>
                  ) : null}
                </Box>
                {submitButtonText === "Start" ? (
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    options={locationsArray?.length > 0 ? locationsArray : []}
                    sx={{ width: "100%" }}
                    getOptionDisabled={(option) => {
                      if (option?.ruleLocation?.length > 0) {
                        if (option?.oldReviewsReplyStatus === "pending") {
                          return false;
                        }
                        if (option?.oldReviewsReplyStatus === "pause") {
                          return false;
                        }
                        if (option?.oldReviewsReplyStatus === "done") {
                          return true;
                        }
                        if (option?.oldReviewsReplyStatus === "inprogress") {
                          return true;
                        }
                        return false;
                      } else if (option.id === 0) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    // value={locationId}
                    value={locationValue?.length ? locationValue : []}
                    //   onChange={(e, value) => {
                    //   setSelectedLocation(value);
                    //   //  setLocationRequired(false);
                    // }}
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked
                      const filterLocations = locationsArray?.filter(
                        (item) =>
                          item?.oldReviewsReplyStatus !== "done" &&
                          item?.oldReviewsReplyStatus !== "inprogress" &&
                          item?.ruleLocation.length > 0
                      );
                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected

                        if (filterLocations?.length === locationValue?.length) {
                          setLocationValue([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setLocationValue(filterLocations);
                        }
                      } else {
                        setLocationValue(value);
                        if (value?.length === filterLocations?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          disabled={
                            option?.oldReviewsReplyStatus === "done" ||
                            option?.oldReviewsReplyStatus === "inprogress" ||
                            option?.ruleLocation?.length === 0
                          }
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            (option?.id !== 0 &&
                              option?.oldReviewsReplyStatus !== "done" &&
                              option?.oldReviewsReplyStatus !== "inprogress" &&
                              option?.ruleLocation?.length > 0 &&
                              (selected || selectAll)) ||
                            (option?.id === 0 && selectAll)
                          }
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={8} sm={8} md={8} lg={8}>
                            <span style={{ textAlign: "start" }}>
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>

                          <Grid
                            item={4}
                            sm={4}
                            md={4}
                            lg={4}
                            sx={{ textAlign: "start" }}
                          >
                            {option.ruleLocation?.length > 0 ? (
                              <span
                                style={{
                                  textAlign: "start",
                                  color:
                                    option?.oldReviewsReplyStatus === "done"
                                      ? "#13CF8F"
                                      : option?.oldReviewsReplyStatus ===
                                        "pause"
                                      ? "#F86F35"
                                      : option?.oldReviewsReplyStatus ===
                                        "inprogress"
                                      ? "#FFA500"
                                      : "",
                                }}
                              >
                                {option?.oldReviewsReplyStatus === "done"
                                  ? "• " + `${t("Done")}`
                                  : ""}

                                {/*   {option?.oldReviewsReplyStatus==="pending"
                                ? "• " + `${t("Pending")}`
                                : ""} */}
                                {option?.oldReviewsReplyStatus === "inprogress"
                                  ? "• " + `${t("in_progress")}`
                                  : ""}
                              </span>
                            ) : option.id === 0 ? null : (
                              <span
                                style={{
                                  textAlign: "center",
                                  color: "#FF1616",
                                }}
                              >
                                {"• " + `${t("no_response_found")}`}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        //  error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                ) : (
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    options={locationsArray?.length > 0 ? locationsArray : []}
                    //  sx={{ height: "30vh" }}

                    getOptionDisabled={(option) => {
                      if (option?.oldReviewsReplyStatus === "pending") {
                        return true;
                      }
                      if (option?.oldReviewsReplyStatus === "paused") {
                        return true;
                      }
                      if (option?.oldReviewsReplyStatus === "done") {
                        return true;
                      }
                      if (option?.oldReviewsReplyStatus === "inprogress") {
                        return false;
                      }
                      return false;
                    }}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    className="Autocomplete-field"
                    // value={locationId}
                    value={locationValue?.length ? locationValue : []}
                    //   onChange={(e, value) => {
                    //   setSelectedLocation(value);
                    //   //  setLocationRequired(false);
                    // }}
                    onChange={(event, value) => {
                      // Check if 'All' option is clicked
                      const filterLocations = locationsArray?.filter(
                        (item) =>
                          item?.oldReviewsReplyStatus !== "done" &&
                          item?.oldReviewsReplyStatus !== "paused" &&
                          item?.oldReviewsReplyStatus !== "pending"
                      );
                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected

                        if (filterLocations?.length === locationValue?.length) {
                          setLocationValue([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setLocationValue(filterLocations);
                        }
                      } else {
                        setLocationValue(value);
                        if (value?.length === filterLocations?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          disabled={
                            option?.oldReviewsReplyStatus === "done" ||
                            option?.oldReviewsReplyStatus === "paused" ||
                            option?.oldReviewsReplyStatus === "pending"
                          }
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          //  checked={selected }
                          checked={
                            option?.oldReviewsReplyStatus !== "done" &&
                            option?.oldReviewsReplyStatus !== "paused" &&
                            option?.oldReviewsReplyStatus !== "pending" &&
                            (selectAll || selected)
                          }
                        />
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid item xs={9} sm={9} md={9} lg={9}>
                            <span style={{ textAlign: "start" }}>
                              {`${option?.internalName} ${
                                option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                  ? "(" + option?.addressLine1 + "," + " "
                                  : ""
                              } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          {option?.oldReviewsReplyStatus && (
                            <Grid item={3} sm={3} md={3} lg={3}>
                              <span
                                style={{
                                  textAlign: "center",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  color:
                                    option?.oldReviewsReplyStatus === "done"
                                      ? "#13CF8F"
                                      : option?.oldReviewsReplyStatus ===
                                        "pause"
                                      ? "#F86F35"
                                      : option?.oldReviewsReplyStatus ===
                                        "inprogress"
                                      ? "#FFA500"
                                      : "",
                                }}
                              >
                                {option?.oldReviewsReplyStatus === "done"
                                  ? "• " + `${t("Done")}`
                                  : ""}

                                {/*   {option?.oldReviewsReplyStatus==="pending"
                                ? "• " + `${t("Pending")}`
                                : ""} */}
                                {option?.oldReviewsReplyStatus === "inprogress"
                                  ? "• " + `${t("in_progress")}`
                                  : ""}
                              </span>
                            </Grid>
                          )}{" "}
                        </Grid>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        //  error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleCloseNewRequestModel();
                setSelectAll(false);
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>

            <Button
              autoFocus
              disabled={locationValue?.length === 0 ? true : false}
              // onClick={() => handleSubmit()}
              onClick={() => {
                handleOpenDeleteModel();
              }}
              className="delete_button"
            >
              {/*  {loading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "25px", height: "25px", color: "#06BDFF" }}
                />
              ) : ( */}
              {submitButtonText}
              {/* )} */}
            </Button>
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
