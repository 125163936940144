import React, { useState, useEffect } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import copyIcon from "../../../../../assets/Icons/copyIcon.svg";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";

const QRCodeModal = ({
  open,
  setOpenQRModal,
  campaignQRId,
  callApi,
  getAllCampaigns,
  stepperData,
}) => {
  const { t } = useTranslation();

  const landingURL = process.env.REACT_APP_LANDING_BASE_URL;
  const navigate = useNavigate();
  const [displayText, setDisplayText] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setDisplayText(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible]);

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    if (callApi === true) {
      handleClose();
      getAllCampaigns();
    }
  };

  const copyQRCodeLink = () => {
    const devQueryParam =
      process.env.REACT_APP_NODE_ENV === "development" ||
      process.env.REACT_APP_NODE_ENV === "beta"
        ? "?dev=true"
        : "";

    navigator.clipboard.writeText(
      `${landingURL}/${stepperData?.language}/campaign-qr/${campaignQRId}${devQueryParam}`
    );
    setDisplayText(true);
    setIsVisible(true);
  };

  const handleClose = () => {
    setOpenQRModal(false);

    navigate("/user/review_request");

    if (callApi === true) {
      getAllCampaigns();
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "8px",
            },
          },
        }}
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle
            sx={{
              color: "#1B2430",
              fontSize: "20px",
              fontWeight: "700",
            }}
            id="alert-dialog-slide-title"
          >
            {t("qr_code")}
          </DialogTitle>
          <IconButton
            onClick={() => handleClose()}
            aria-label="save"
            hover="none"
            sx={{
              hover: "none",

              marginRight: "22px",
            }}
          >
            X
          </IconButton>
        </Stack>
        <Divider />
        <Box mt={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QRCode
              id="qrCodeEl"
              size={250}
              value={`${landingURL}/${
                stepperData?.language
              }/campaign-qr/${campaignQRId}${
                process.env.REACT_APP_NODE_ENV === "development" ||
                process.env.REACT_APP_NODE_ENV === "beta"
                  ? "?dev=true"
                  : ""
              }`}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <Typography>{t("qr_desc1")}</Typography>
            <Typography>{t("qr_desc2")}</Typography>
            <Typography>{t("qr_desc3")}</Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            <CommonButton label={t("download_now")} onSubmit={downloadQRCode} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "8px",
                cursor: "pointer",
                justifyContent: "center",
                marginTop: "15px",
              }}
              onClick={copyQRCodeLink}
            >
              <Box sx={{ marginTop: "3px" }}>
                <img alt="" src={copyIcon} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#06BDFF",
                    fontWeight: 700,
                    fontSize: "14px",
                    marginLeft: "7px",
                  }}
                >
                  {"Copy QR link"}
                </Typography>
              </Box>
            </Box>
            {displayText && (
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "gray",
                    lineHeight: "15px",
                    marginLeft: "25px",
                    marginTop: "10px",
                    fontWeight: 500,

                    display: isVisible ? "block" : "none",
                  }}
                >
                  {t("Copied")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default QRCodeModal;
