import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TitleHeader from "../../../components/CustomComponents/title";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import UserFaqsMain from "./Components/UserFaqsMain";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import styled from "styled-components/macro";
import { Alert as MuiAlert, Tooltip, tooltipClasses } from "@mui/material";
import AskQuestionsModel from "./Components/Models/AskQuestionsModel";

import axios from "axios";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="bottom"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const UserFaqs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { signOut, permissionsAcess, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [locationRequired, setLocationRequired] = useState(false);
  const [locationId, setLocationId] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [callApi, setCallApi] = useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [locationViewPermission, setLocationViewPermission] = useState(false);

  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);
  const [locationDeletePermission, setLocationDeletePermission] =
    useState(false);
  const [locationEditPermission, setLocationEditPermission] = useState(false);
  const [faqsLoading, setFaqsLoading] = useState(true);
  const [faqsData, setFaqsData] = useState(null);
  const [openAskQuestionModel, setOpenAskQuestionModel] = useState(false);
  const [askQuestionValue, setAskQuestionValue] = useState("");
  const [locationIdAskQ, setLocationIdAskQ] = useState([]);
  const [createQuestionLoading, setCreateQuestionLoading] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectAllAskQLocations, setSelectAllAskQLocations] = useState(false);
  const [modelQuestionText, setModelQuestionText] = useState("");
  const [selectedGroupsAskQ, setSelectedGroupsAskQ] = useState([]);
  const [filtersCounterValue, setFilterCounterValue] = useState("");
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [noQuestions, setNoQuestions] = useState(false);
  const [isTotalQFilterClicked, setIsTotalQFilterClicked] = useState(true);
  const [isNoOwnerAnswerClicked, setIsNoOwnerAnswerClicked] = useState(false);
  const [withoutAnswerClicked, setWithoutAnswerClicked] = useState(false);
  const [isPerEntityClicked, setIsPerEntityClicked] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    handleGetFaqsTableData();
  }, []);

  useEffect(() => {
    handleGetFaqsTableData();
  }, [rowsPerPage, page, locationId, selectedGroup, filtersCounterValue]);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(async () => {
    getAllLocations();
  }, []);
  useEffect(() => {
    getAllGroups();
  }, []);
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllLocations = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
        // setReviewsData(res.data.data);
        setIsLoading(false);
        let defaultlocation = null;
        if (res.data.data.results.length > 0) {
          defaultlocation = res.data.data.results?.find(
            (item, index) => item.defaultLocation !== null
          );
          setDefaultvalueLocation(defaultlocation);
        } else {
          setDefaultvalueLocation(null);
        }
      } else {
        toast.error(t("No_location_found"));
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllGroups = async () => {
    try {
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);
      }
    } catch (error) {
      setAllGroups(null);
    }
  };
  const handleGetFaqsTableData = async () => {
    setFaqsLoading(true);

    let locationIds = [];
    if (locationId?.length > 0) {
      locationId?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    try {
      const locationIdsString = locationIds.join(",");

      const res = await api.get(
        `questions?locationIds=${
          locationIdsString ? locationIdsString : ""
        }&groupIds=${
          selectedGroup ? selectedGroup?.id : ""
        }&limit=${rowsPerPage}&pageNumber=${
          page + 1
        }&filterOptions=${filtersCounterValue}&companyId=${companyData.id}`
      );

      // &companyId=${companyData.id}
      if (res.status === 200) {
        setFaqsData(res?.data?.data);
        setFaqsLoading(false);
        setNoQuestions(false);
      }

      if (res.status === 204) {
        setFaqsLoading(false);
        setNoQuestions(true);
        setFaqsData({
          rows: [],
        });
      }
    } catch (error) {
      setFaqsData(null);
      setFaqsLoading(false);
    }
  };

  const handleCreateQuestions = async () => {
    setCreateQuestionLoading(true);

    let locationIds = [];
    if (locationIdAskQ?.length > 0) {
      locationIdAskQ?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    try {
      const res = await api.post(`/questions`, {
        googleQuestionId: "",
        authorName: `${userData?.firstName} ${userData?.lastName}`,
        questionText: askQuestionValue,
        locationIds,
        companyId: companyData.id,
        userId: userData.id,
      });

      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        setCreateQuestionLoading(false);
        handleResetAskQuestionModel();
        handleGetFaqsTableData();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setCreateQuestionLoading(false);
      handleResetAskQuestionModel();
    }
  };

  const handleLocationChange = (value) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request is made");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);

    let ids = Array.prototype.map.call(value, (s) => s.id).toString();
    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[locationId][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setLocationId(value);
    setPageNumber(0);
  };
  const handleChangeGroups = (value) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request is made");
    }

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    setPageNumber(0);
    setSelectedGroup(value);
  };

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3 },
    });
  };
  function handleDisplayLocationName(locationId) {
    let location = {};
    let locationName = "";
    if (allLocation?.results?.length > 0) {
      location = allLocation?.results?.find((item) => item?.id === locationId);
    }

    locationName =
      location?.internalName +
      " " +
      (location?.city !== null && location?.city !== ""
        ? "(" + location?.city + ")"
        : "");
    return locationName;
  }

  const resetHandler = () => {
    setFilterObj({});
    setLocationId([]);
    setCallApi(true);
    setPageNumber(0);

    setSelectedGroup("");

    setFilterCounterValue("");
    setPage(0);
    setIsTotalQFilterClicked(true);
    setIsNoOwnerAnswerClicked(false);
    setWithoutAnswerClicked(false);
    setIsPerEntityClicked(false);
  };

  const handleOpenAskQuestionModel = () => {
    setOpenAskQuestionModel(true);
  };
  const handleCloseAskQuestionModel = () => {
    setOpenAskQuestionModel(false);
    setLocationIdAskQ([]);
    setSelectedGroupsAskQ([]);
    setAskQuestionValue("");
    setSelectAllAskQLocations(false);
    setSelectAllGroups(false);
  };

  const handleResetAskQuestionModel = () => {
    handleCloseAskQuestionModel();
    setLocationIdAskQ([]);
    setSelectedGroupsAskQ([]);
    setAskQuestionValue("");
  };

  function handleDisplayNames(locationId) {
    let location = {};
    let locationName = "";
    if (allLocation?.results?.length > 0) {
      location = allLocation?.results?.find((item) => item?.id === locationId);
    }

    locationName = (
      <div style={{ width: "100%" }}>
        <div style={{ lineHeight: "12px" }}>{location?.name}</div>
        {/* <br /> */}
        <div>
          <i
            style={{
              fontSize: "12px",
              fontWeight: 400,
              // lineHeight: "20px",
              color: "#757575",
            }}
          >
            {((location?.addressLine1 !== "" &&
              location?.addressLine1 !== null) ||
              (location?.city !== null && location?.city !== "")) &&
              "(" +
                (location?.addressLine1 !== "" &&
                location?.addressLine1 !== null
                  ? location?.addressLine1
                  : "") +
                (location?.addressLine1 !== "" &&
                location?.addressLine1 !== null &&
                location?.city !== null &&
                location?.city !== ""
                  ? ","
                  : "") +
                (location?.city !== null && location?.city !== ""
                  ? ` ${location?.city} `
                  : "") +
                ")"}
          </i>
        </div>
      </div>
    );

    return locationName;
  }
  return (
    <>
      <TitleHeader
        title={t("Question_Answers")}
        subHeading={t("faqs_subheading")}
        createButton={true}
        name={t("Ask_Question")}
        viewPermission={viewPermission}
        addPermission={addPermission}
        editPermission={editPermission}
        deletePermission={deletePermission}
        onClick={handleOpenAskQuestionModel}
      />

      {openAskQuestionModel && (
        <AskQuestionsModel
          title={t("askAquestion")}
          open={openAskQuestionModel}
          handleCloseNewRequestModel={handleCloseAskQuestionModel}
          allLocation={allLocation}
          isLoading={isLoading}
          setLocationRequired={setLocationRequired}
          locationRequired={locationRequired}
          handleLocationChange={handleLocationChange}
          callApi={callApi}
          setCallApi={setCallApi}
          allGroups={allGroups}
          handleChangeGroups={handleChangeGroups}
          selectedGroup={selectedGroup}
          locationViewPermission={locationViewPermission}
          setLocationViewPermission={setLocationViewPermission}
          viewPermission={viewPermission}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          locationAddPermission={locationAddPermission}
          locationEditPermission={locationEditPermission}
          locationDeletePermission={locationDeletePermission}
          BootstrapTooltip={BootstrapTooltip}
          handleNavigationToGroups={handleNavigationToGroups}
          resetHandler={resetHandler}
          handleCreateQuestions={handleCreateQuestions}
          askQuestionValue={askQuestionValue}
          setAskQuestionValue={setAskQuestionValue}
          locationId={locationIdAskQ}
          setLocationId={setLocationIdAskQ}
          createQuestionLoading={createQuestionLoading}
          selectAllLocations={selectAllAskQLocations}
          setSelectAllLocations={setSelectAllAskQLocations}
          selectedGroups={selectedGroupsAskQ}
          setSelectedGroups={setSelectedGroupsAskQ}
          selectAllGroups={selectAllGroups}
          setSelectAllGroups={setSelectAllGroups}
        />
      )}
      <UserFaqsMain
        allLocation={allLocation}
        isLoading={isLoading}
        faqsLoading={faqsLoading}
        setLocationRequired={setLocationRequired}
        locationRequired={locationRequired}
        handleLocationChange={handleLocationChange}
        locationId={locationId}
        callApi={callApi}
        setCallApi={setCallApi}
        allGroups={allGroups}
        handleChangeGroups={handleChangeGroups}
        selectedGroup={selectedGroup}
        locationViewPermission={locationViewPermission}
        setLocationViewPermission={setLocationViewPermission}
        viewPermission={viewPermission}
        addPermission={addPermission}
        editPermission={editPermission}
        deletePermission={deletePermission}
        locationAddPermission={locationAddPermission}
        locationEditPermission={locationEditPermission}
        locationDeletePermission={locationDeletePermission}
        BootstrapTooltip={BootstrapTooltip}
        handleNavigationToGroups={handleNavigationToGroups}
        resetHandler={resetHandler}
        setLocationId={setLocationId}
        faqsData={faqsData}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        handleGetFaqsTableData={handleGetFaqsTableData}
        handleDisplayLocationName={handleDisplayLocationName}
        modelQuestionText={modelQuestionText}
        setModelQuestionText={setModelQuestionText}
        handleDisplayNames={handleDisplayNames}
        companyData={companyData}
        userData={userData}
        filtersCounterValue={filtersCounterValue}
        setFilterCounterValue={setFilterCounterValue}
        noQuestions={noQuestions}
        setIsTotalQFilterClicked={setIsTotalQFilterClicked}
        setIsNoOwnerAnswerClicked={setIsNoOwnerAnswerClicked}
        setWithoutAnswerClicked={setWithoutAnswerClicked}
        setIsPerEntityClicked={setIsPerEntityClicked}
        isTotalQFilterClicked={isTotalQFilterClicked}
        isNoOwnerAnswerClicked={isNoOwnerAnswerClicked}
        withoutAnswerClicked={withoutAnswerClicked}
        isPerEntityClicked={isPerEntityClicked}
      />
    </>
  );
};

export default UserFaqs;
