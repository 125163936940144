import React, { useEffect, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  Divider,
} from "@mui/material";
import { api } from "../../../contexts/JWTContext";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { CheckBox, SelectAll } from "@mui/icons-material";
import Loader from "../../Loaders/Loader";
import { toast } from "react-toastify";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  productHeading: {
    color: "#1B2430",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  featureHeading: {
    color: "#1B2430",
    fontWeight: 400,
    fontSize: "14px",
    paddingBottom: "12px",
    lineHeight: "20px",
  },
  selectAllBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
  titleHeading: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  infoIcon: {
    marginTop: "3px",
    marginLeft: "5px",
  },
  tabRoot: {
    display: "flex",
    alignItems: "center",
  },
  selectAllText: {
    //marginTop: "8px",
  },
  toggleChecked: {
    "& .MuiSwitch-thumb": {
      color: "#EBEBEC",
      //  transform:'translateX(25px) !important'
    },
  },
  toggleUnChecked: {
    "& .MuiSwitch-thumb": {
      color: "#06BDFF",
      //  transform:'translateX(25px) !important'
    },
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FeatureRequestModel = ({
  title,
  open,
  handleClose,
  selectedCompany,
  getAllRequestedCompanies,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [subscribedFeatures, setSubscribedFeatures] = useState([]);
  const [unSubscribedFeatures, setUnSubscribedFeatures] = useState([]);
  const [selectAllSubscriptionsRequests, setSelectAllSubscriptionsRequests] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRequestData, setselectedRequestData] = useState([]);

  useEffect(() => {
    getAllSubscriptionRequests();
  }, [open]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const getAllSubscriptionRequests = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyFeatures/requests?subscribedRequested=true&unSubscribedRequested=true&companyId=${selectedCompany?.companyId}`
      );
      if (res.status === 200) {
        console.log(res, "res");
        const filteredSubscriptionRequests = res?.data?.data?.rows?.filter(
          (item) => item?.subscribedRequested === true
        );
        setSubscribedFeatures(filteredSubscriptionRequests);
        let arr = [...filteredSubscriptionRequests];
        if (arr?.length > 0) {
          arr?.map((item) => {
            item["display"] = true;
          });
        }
        setSelectAllSubscriptionsRequests(true);
        setselectedRequestData(arr);

        const filteredUnSubscriptionRequests = res?.data?.data?.rows?.filter(
          (item) => item?.unSubscribedRequested === true
        );
        setUnSubscribedFeatures(filteredUnSubscriptionRequests);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // setAllCompanies("noData");
    }
  };
  const handleChangeSelectAllSubscriptions = (e) => {
    setSelectAllSubscriptionsRequests(e.target.checked);
    if (e.target.checked) {
      let arr = [...subscribedFeatures];
      if (arr?.length > 0) {
        arr?.map((item) => {
          item["display"] = true;
        });
      }
      setselectedRequestData(arr);
    } else {
      let arr = [...subscribedFeatures];
      if (arr?.length > 0) {
        arr?.map((item) => {
          item["display"] = false;
        });
      }
      setselectedRequestData(arr);
    }
  };
  const handlePermissionChecked = (e, requestData, requestedId, indexValue) => {
    let tempArr = [...selectedRequestData];

    if (e.target.checked) {
      tempArr[indexValue]["display"] = true;
    } else {
      tempArr[indexValue]["display"] = false;
      /*   const locationIndex = tempArr.findIndex(
        (item) => item?.appId === requestedId
      );
      console.log(locationIndex, tempArr, "locationIndex");

      tempArr.splice(locationIndex, 1); */
    }
    if (tempArr?.every((item) => item?.display === true)) {
      setSelectAllSubscriptionsRequests(true);
    } else {
      setSelectAllSubscriptionsRequests(false);
    }

    setselectedRequestData([...tempArr]);
  };
  console.log(selectedRequestData, "selectedRequestData");

  const handleAcceptApprovalRequestBulk = async () => {
    setIsLoading(true);

    let data = [];
    let appIdSet = new Set();

    if (selectedRequestData?.length > 0) {
      subscribedFeatures?.forEach((itemOne) => {
        let found = false;

        selectedRequestData?.forEach((item) => {
          if (itemOne?.appId === item?.appId && !appIdSet.has(item?.appId)) {
            let obj = {
              appId: item?.appId,
              subscribed: item?.display ? true : false,
              companyId: selectedCompany?.companyId,
            };
            data.push(obj);
            appIdSet.add(item.appId);
            found = true;
          }
        });

        if (!found) {
          let obj = {
            appId: itemOne?.appId,
            subscribed: false,
            companyId: selectedCompany?.companyId,
          };
          data.push(obj);
        }
      });
    }

    try {
      const res = await api.patch(`/companyFeatures/request`, {
        data: data,
      });
      if (res.status === 200) {
        setselectedRequestData([]);
        setIsLoading(false);
        handleClose();
        getAllRequestedCompanies();
        toast.success(t("toast_subscriptions"));
      }
    } catch (error) {
      handleClose();
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "850px",
              height: "450px",
              borderRadius: "4px",
            },
          }}
          open={open}
          fullScreen
          PaperProps={{
            style: {
              minWidth: "610px",
              maxWidth: "610px",
            },
          }}
          //  onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex" }}>
                <Typography className={classes.titleHeading}>
                  {title}
                </Typography>
                <BootstrapTooltip title={t("Subscription_tooltip")}>
                  <ErrorOutlineIcon className={classes.infoIcon} />
                </BootstrapTooltip>
              </Box>

              <CloseIcon onClick={handleClose} />
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                {/*  <Grid item sm={12} md={12} lg={12}>
                  <Tabs
                    className={classes.Tabs}
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                  >
                    <Tab
                      label={
                        <div className={classes.tabRoot}>
                          {t("Subscription_Requests")}
                          <BootstrapTooltip title={t("Subscription_tooltip")}>
                            <ErrorOutlineIcon className={classes.infoIcon} />
                          </BootstrapTooltip>
                        </div>
                      }
                    />

                    <Tab
                      label={
                        <div className={classes.tabRoot}>
                          {t("Unsubscription_Requests")}
                          <BootstrapTooltip title={t("Subscription_tooltip")}>
                            <ErrorOutlineIcon className={classes.infoIcon} />
                          </BootstrapTooltip>
                        </div>
                      }
                    />
                  </Tabs>
                </Grid> */}

                <Grid item sm={12} md={12} lg={12}>
                  <Grid container>
                    <Grid
                      container
                      sx={{
                        //   borderTop: "1px solid #f7f7f7",
                        borderBottom: "1px solid #f7f7f7",

                        paddingBottom: "12px",
                      }}
                    >
                      <Grid item sm={6} md={6} lg={6}>
                        <Typography className={classes.productHeading}>
                          {t("product_name")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} sx={{ textAlign: "end" }}>
                        <Box className={classes.selectAllBox}>
                          <Checkbox
                            name="selectAll"
                            color="primary"
                            checked={selectAllSubscriptionsRequests}
                            // disabled={defaultRole}
                            onChange={(e) =>
                              handleChangeSelectAllSubscriptions(e)
                            }
                            sx={{ paddingTop: "0px", paddingRight: "3px" }}
                          />
                          <Typography className={classes.selectAllText}>
                            {t("Select_All")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {subscribedFeatures?.length > 0 &&
                      subscribedFeatures?.map((item, indexOfItem) => {
                        const selectedItem = selectedRequestData?.find(
                          (itemTwo) =>
                            itemTwo?.appId === item?.appId &&
                            itemTwo?.id === item?.id
                        );
                        return (
                          <Grid
                            container
                            sx={{
                              //   borderTop: "1px solid #f7f7f7",
                              borderBottom: "1px solid #f7f7f7",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                            }}
                          >
                            <Grid item sm={6} md={6} lg={6}>
                              {item?.featureName}
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              md={6}
                              lg={6}
                              sx={{ textAlign: "end" }}
                            >
                              <BootstrapTooltip
                                title={
                                  selectedItem?.hasOwnProperty("display") &&
                                  selectedItem?.display === true
                                    ? t("Reject")
                                    : t("Accept")
                                }
                              >
                                <Checkbox
                                  checked={
                                    selectedItem?.hasOwnProperty("display") &&
                                    selectedItem?.display === true
                                  }
                                  //  disabled={locationRequestError}
                                  onClick={(e) =>
                                    handlePermissionChecked(
                                      e,
                                      item,
                                      item?.appId,
                                      indexOfItem
                                    )
                                  }
                                />
                                {/* <Switch
                                  className={
                                    selectedItem?.hasOwnProperty("display") &&
                                    selectedItem?.display === true
                                      ? classes.toggleUnChecked
                                      : classes.toggleChecked
                                  }
                                  checked={
                                    selectedItem?.hasOwnProperty("display") &&
                                    selectedItem?.display === true
                                  }
                                  //  disabled={locationRequestError}
                                  onClick={(e) =>
                                    handlePermissionChecked(
                                      e,
                                      item,
                                      item?.appId,
                                      indexOfItem
                                    )
                                  }
                                /> */}
                              </BootstrapTooltip>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>

                  {/*  {selectedTab === 1 && (
                    <Grid container sx={{ marginTop: "12px" }}>
                      <Grid
                        container
                        sx={{
                          //   borderTop: "1px solid #f7f7f7",
                          borderBottom: "1px solid #f7f7f7",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        <Grid item sm={6} md={6} lg={6}>
                          {" "}
                          <Typography className={classes.productHeading}>
                            {t("product_name")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "end" }}
                        >
                          <Box className={classes.selectAllBox}>
                            <CheckBox
                              name="selectAll"
                              color="primary"
                              checked={selectAllSubscriptionsRequests}
                              // disabled={defaultRole}
                              onChange={(e) =>
                                handleChangeSelectAllSubscriptions(e)
                              }
                              sx={{ marginRight: "4px" }}
                            />
                            <Typography className={classes.selectAllText}>
                              {t("Select_All")}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {unSubscribedFeatures?.length > 0 &&
                        unSubscribedFeatures?.map((item) => (
                          <Grid
                            container
                            sx={{
                              //   borderTop: "1px solid #f7f7f7",
                              borderBottom: "1px solid #f7f7f7",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                            }}
                          >
                            <Grid item sm={6} md={6} lg={6}>
                              {" "}
                              {item?.featureName}
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              md={6}
                              lg={6}
                              sx={{ textAlign: "end" }}
                            >
                              <CheckBox
                                name="selectAll"
                                color="primary"
                                // disabled={defaultRole}

                                sx={{ marginRight: "4px" }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <CommonButton
              onSubmit={handleClose}
              label={t("Cancel")}
              displayWhite={"true"}
            />

            <CommonButton
              onSubmit={handleAcceptApprovalRequestBulk}
              label={t("Update")}
              disabled={isLoading}
            />
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FeatureRequestModel;
