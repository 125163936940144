import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Loader from "../../../../../components/Loaders/Loader";

import { Dialog } from "@mui/material";

import { api, handleSessionOut } from "../../../../../contexts/JWTContext";
import Typography from "@mui/material/Typography";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/step2";
import Step3 from "./Steps/step3";
import Step4 from "./Steps/Step4";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import useAuth from "../../../../../hooks/useAuth";
import QRCodeModal from "../Modal/QrCodeModal";

const steps = ["", "", "", ""];

const Index = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { signOut } = useAuth();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [allLocations, setAllLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [locationRequired, setLocationRequired] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [negativeUrl, setNegativeUrl] = useState("");
  const [email, setEmail] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [surveyData, setSurveyData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formitableDisabled, setFormitableDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [privateSurveyChecked, setPrivateSurveyChecked] = useState(false);
  const [reviewPlatFormChecked, setReviewPlatFormChecked] = useState(false);
  const [surveyEmails, setSurveyEmails] = useState([]);
  const [oldLocation, setOldLocation] = useState(null);
  const [stepperData, setStepperData] = useState({
    emailTemplateData: {
      image_url: "",
      heading: "",
      description: "Your opinion matters to us, thanks!",
    },
    custom_positive_url: "",
    validEmailCounter: 0,
    locationIds: "",
    campaign_type: "import-email",
    campaignDataUserEmails: [],
    status: "",
    language: "en",
    negative_feedback_type: "",
    platform: "",
    campaignSurveyData: "",
    custom_negative_url: "",
  });
  const [surveyImageDisplay, setSurveyImageDisplay] = useState("");
  const [surveyImageDisplayStepThree, setSurveyImageDisplayStepThree] =
    useState("");
  const [customUrlChecked, setCustomUrlChecked] = useState(false);
  const [tripAdvisorChecked, setTripAdvisorChecked] = useState(false);
  const [facebookChecked, setFacebookChecked] = useState(false);
  const [googleChecked, setGoogleChecked] = useState(true);
  const [integrationChecked, setIntegrationChecked] = useState(false);
  const [importEmailChecked, setImportEmailChecked] = useState(true);
  const [formitableChecked, setFormitableChecked] = useState(false);
  const [uploadFileChecked, setUploadFileChecked] = useState(false);
  const [directInputChecked, setDirectInputChecked] = useState(false);
  const [qrCodeChecked, setQRCodeChecked] = useState(false);
  const [negativeEmail, setNegativeEmail] = useState("");
  const [NegUrlIsValid, setNegUrlIsValid] = useState();
  const [negativeEmailError, setNegativeEmailError] = useState(false);
  const [languageData, setLanguageData] = useState("en");
  const [open, setOpen] = React.useState(false);
  const [openLater, setOpenLater] = React.useState(false);
  const [imageUpload, setImageUpload] = useState(0);
  const [startLoading, setStartLoading] = useState(false);
  const [laterLoading, setLaterLoading] = useState(false);

  const [dataArray, setDataArray] = useState([]);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [campaignEmails, setCampaignEmails] = useState("");
  const [loading, setLoading] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [campaignQRId, setCampaignQRId] = useState();
  const [singleLocation, setSingleLocation] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [checkClicked, setCheckClicked] = useState(false);
  const stateData = JSON.parse(localStorage.getItem("stateData"));
  let validEmails = [];
  const { id } = useParams();
  const currentUrl = window.location.href;

  console.log(currentUrl, "currentUrl");

  //to validate that custom url is valid or not //
  const urlRegex =
    /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/;
  function validateUrl(url) {
    return urlRegex.test(url);
  }

  /*  useEffect(() => {
    if (customUrl.length > 0) {
      const isValidUrl = validateUrl(customUrl);

      if (isValidUrl) {
        setCustomUrlIsValid(true);
      } else {
        setCustomUrlIsValid(false);
      }
    }
  }, [customUrl]); */
  const getLocationById = async (locationId, displayToast) => {
    try {
      setLocationLoading(true);
      const res = await api.get(`/location/${locationId?.id}`);
      if (res.status === 200) {
        setSingleLocation(res.data.data);
        setLocationLoading(false);
        if (displayToast === true) {
          if (res.data.data?.socialLink?.length > 0) {
            const linkFound = res.data.data?.socialLink?.some?.(
              (item) => item?.type === "formitable"
            );

            if (linkFound === false) {
              // toast.error(t("Not_connected"));
              setCheckClicked(true);
            } else {
              setCheckClicked(false);
            }
          }
        }
      }
    } catch (error) {
      setLocationLoading(false);
    }
  };

  useEffect(() => {
    if (locationId !== null && locationId?.isFormitableEnabled === true) {
      getLocationById(locationId, false);
    }
  }, [locationId]);

  useEffect(() => {
    if (negativeUrl?.length > 0) {
      const isValidUrl = validateUrl(negativeUrl);

      if (isValidUrl) {
        setNegUrlIsValid(true);
      } else {
        setNegUrlIsValid(false);
      }
    }
  }, [negativeUrl]);

  useEffect(() => {
    if (id !== undefined) {
      getCampaignById();
    }
  }, []);

  const navigate = useNavigate();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 0) {
      navigate("/user/review_request");
    }
  };

  const validateLocationId = () => {
    setLocationRequired(locationId?.length === 0);
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setIsLoading(true);
        setAllLocations(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getCampaignById = async () => {
    setLoading(true);
    try {
      const res = await api.get(`campaign/${id}`);
      if (res.status === 200) {
        let splittedStrEmails = [];
        if (res?.data?.data?.survey_email !== "") {
          splittedStrEmails = res?.data?.data?.survey_email.split(",");
          if (currentUrl?.includes("duplicate")) {
            splittedStrEmails?.push(stateData?.user?.email);
          }
          splittedStrEmails = [...new Set(splittedStrEmails)];
          const finalStrEmails = splittedStrEmails.join(",\n");
          setSurveyEmails(finalStrEmails);
        }

        setStepperData({
          ...stepperData,
          survey_email: splittedStrEmails,
          validSurveyEmails: splittedStrEmails?.length,
          custom_positive_url: res?.data?.data?.custom_positive_url,
          platform: res?.data?.data?.platform,
          negative_feedback_type: res?.data?.data?.negative_feedback_type,
          language: res?.data?.data?.language,
          campaign_type: res?.data?.data?.campaign_type,
          status: res?.data?.data?.status,
          custom_negative_url: res?.data?.data?.custom_negative_url,
          emailTemplateData: {
            image_url: res?.data?.data?.campaignEmail?.image_url,
            heading: res?.data?.data?.campaignEmail?.heading,
            description: res?.data?.data?.campaignEmail?.description,
          },
          campaignSurveyData: {
            image_url: res?.data?.data?.campaignSurvey?.image_url,
            heading: res?.data?.data?.campaignSurvey?.heading,
            description: res?.data?.data?.campaignSurvey?.description,
          },
          locationIds:
            currentUrl.includes("duplicate") === false
              ? res?.data?.data?.campaignLocations?.length > 0
                ? [res?.data?.data?.campaignLocations[0]?.location]
                : []
              : [],
        });

        setSurveyImageDisplay(res?.data?.data?.campaignSurvey?.image_url);
        setSurveyImageDisplayStepThree(
          res?.data?.data?.campaignEmail?.image_url
        );
        setLanguageData(res?.data?.data?.language);
        setNegativeUrl(res?.data?.data?.custom_negative_url);
        setCustomUrl(res?.data?.data?.custom_positive_url);
        setNegativeEmail(res?.data?.data?.survey_email);

        // setCampaignEmails(res?.data?.)
        if (res?.data?.data?.negative_feedback_type === "private-survey") {
          setPrivateSurveyChecked(true);
          setNegativeEmailError(false);
        } else if (
          res?.data?.data?.negative_feedback_type === "review-platform"
        ) {
          setReviewPlatFormChecked(true);
        } else if (res?.data?.data?.negative_feedback_type === "custom-url") {
          setCustomUrlChecked(true);
        }
        if (res?.data?.data?.campaignLocations?.length) {
          res?.data?.data?.campaignLocations?.map((item) => {
            setOldLocation(item?.location);
          });
        }
        if (
          res?.data?.data?.campaignLocations?.length > 0 &&
          currentUrl.includes("duplicate") === false
        ) {
          res?.data?.data?.campaignLocations?.map((item) => {
            setLocationId(item?.location);
          });
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  console.log(stepperData, "stepperData");

  const handleUpdateCampaign = async () => {
    setUpdateLoading(true);
    try {
      const res = await api.patch(`campaign/updateCampaign/${id}`, {
        data: {
          locationIds: [stepperData?.locationIds[0]?.id],
          platform: stepperData?.platform,
          campaign_type: stepperData?.campaign_type,
          custom_positive_url: stepperData?.custom_positive_url,
          negative_feedback_type: stepperData?.negative_feedback_type,
          custom_negative_url: stepperData?.custom_negative_url,
          survey_email:
            stepperData?.survey_email?.length > 0
              ? stepperData?.survey_email?.toString()
              : "",
          language: stepperData?.language.includes("_")
            ? stepperData?.language.replace("_", "-")
            : stepperData?.language,
          status: stepperData?.status,
          campaignDataUserEmails: [],
          campaignSurveyData: stepperData?.campaignSurveyData,
          emailTemplateData: stepperData?.emailTemplateData,
        },
      });
      if (res.status === 201) {
        toast.success(res?.data?.message);
        setUpdateLoading(false);
        setOpenUpdateModel(false);
        if (stepperData?.campaign_type === "qr-code") {
          setOpenQRModal(true);
        } else {
          navigate("/user/review_request");
        }
        //
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const EditStep1 = () => {
    setActiveStep(0);
  };
  const EditStep2 = () => {
    setActiveStep(1);
  };
  const EditStep3 = () => {
    setActiveStep(2);
  };

  const startLaterCampaignHandler = async () => {
    setLaterLoading(true);
    try {
      const res = await api.post("/campaign", {
        data: {
          locationIds: [stepperData?.locationIds[0]?.id],
          platform: stepperData?.platform,
          campaign_type: stepperData?.campaign_type,
          custom_positive_url: stepperData?.custom_positive_url,
          negative_feedback_type: stepperData?.negative_feedback_type,
          custom_negative_url: stepperData?.custom_negative_url,
          survey_email:
            stepperData?.survey_email?.length > 0
              ? stepperData?.survey_email?.toString()
              : "",
          language: stepperData?.language.includes("_")
            ? stepperData?.language.replace("_", "-")
            : stepperData?.language,

          campaignDataUserEmails:
            totalEmailsArr?.length > 0
              ? totalEmailsArr
              : stepperData?.campaignDataUserEmails,
          reach:
            totalEmailsArr?.length > 0
              ? totalEmailsArr.length
              : stepperData?.campaignDataUserEmails.length,
          campaignSurveyData: stepperData?.campaignSurveyData,
          emailTemplateData: stepperData?.emailTemplateData,
          status: "pending",
        },
      });

      if (res?.status === 201) {
        setLaterLoading(false);
        toast.success(res?.data?.message);
        handleCloseLater();
        navigate("/user/review_request");
      }
    } catch (error) {
      setLaterLoading(false);
      toast.error(error?.response?.data?.message);
      handleCloseLater();
    }
  };

  const startCampaignHandler = async () => {
    setStartLoading(true);
    try {
      const res = await api.post("/campaign", {
        data: {
          locationIds: [stepperData?.locationIds[0]?.id],
          platform: stepperData?.platform,
          campaign_type: stepperData?.campaign_type,
          custom_positive_url: stepperData?.custom_positive_url,
          negative_feedback_type: stepperData?.negative_feedback_type,
          custom_negative_url: stepperData?.custom_negative_url,
          survey_email:
            stepperData?.survey_email?.length > 0
              ? stepperData?.survey_email?.toString()
              : "",
          language: stepperData?.language.includes("_")
            ? stepperData?.language.replace("_", "-")
            : stepperData?.language,

          campaignDataUserEmails:
            totalEmailsArr?.length > 0
              ? totalEmailsArr
              : stepperData?.campaignDataUserEmails,
          campaignSurveyData: stepperData?.campaignSurveyData,
          reach:
            totalEmailsArr?.length > 0
              ? totalEmailsArr.length
              : stepperData?.campaignDataUserEmails.length,
          emailTemplateData: stepperData?.emailTemplateData,
          status: "active",
        },
      });

      setCampaignQRId(res?.data?.data?.id);
      if (res?.status === 201) {
        setStartLoading(false);
        handleCloseStartCampaign();

        toast.success(res?.data?.message);
        if (stepperData?.campaign_type === "qr-code") {
          setOpenQRModal(true);
        } else {
          navigate("/user/review_request");
        }
      }
    } catch (error) {
      setStartLoading(false);
      handleCloseStartCampaign();
      toast.error(error?.response?.data?.message);
    }
  };

  const onConfirmHandler = startCampaignHandler;
  const onConfirmLaterHandler = startLaterCampaignHandler;

  const handleCloseLater = () => {
    setOpenLater(false);
  };
  const handleOpenLater = () => {
    setOpenLater(true);
  };

  const handleCloseStartCampaign = () => {
    setOpen(false);
  };
  const handleOpenStartCampaign = () => {
    setOpen(true);
  };

  const handleOpenUpdateCampaign = () => {
    setOpenUpdateModel(true);
  };
  const handleCloseUpdateCampaign = () => {
    setOpenUpdateModel(false);
  };

  let totalEmails = 0;
  let totalEmailsArr = [];
  if (id === undefined || currentUrl?.includes("duplicate")) {
    const combinedArray = [
      ...dataArray,
      ...stepperData?.campaignDataUserEmails,
    ];

    const uniqueEmails = [...new Set(combinedArray)];

    const normalizedEmails = uniqueEmails?.map((email) =>
      email?.toString()?.toLowerCase()
    );

    const distinctEmails = Array.from(new Set(normalizedEmails));

    validEmails = distinctEmails?.filter((email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    });

    totalEmails = validEmails?.length;
    totalEmailsArr = validEmails;
    // setTotalValidEmails(validEmails);
  }

  return (
    <>
      <Typography fontWeight={700} fontSize="24px" lineHeight="32px" mb={5}>
        {t("Campaign_setup")}
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Stepper
            sx={{
              "& .MuiStepConnector-root .Mui-completed": {
                color: "secondary.dark", // circle color (COMPLETED)
              },
              "& .MuiStepConnector-line.Mui-completed.MuiStepConnector-lineHorizontal":
                {
                  color: "grey.500", // Just text label (COMPLETED)
                },
              "& .MuiStepConnector-root .Mui-active": {
                color: "secondary.main", // circle color (ACTIVE)
              },
              "& .MuiStepConnector-root .Mui-disabled": {
                borderColor: "blue", // circle's number (ACTIVE)
              },
              "& .MuiStepConnector-root .active": {
                borderColor: "blue", // circle's number (ACTIVE)
              },
              "& .MuiStepConnector-line.Mui-disabled.MuiStepConnector-lineHorizontal ":
                {
                  borderColor: "blue", // circle's number (ACTIVE)
                },
              "& .MuiStepConnector-line.MuiStepConnector-line.MuiStepConnector-lineHorizontal .Mui-disabled":
                {
                  borderColor: "blue", // circle's number (ACTIVE)
                },
              "& .MuiStepConnector-line.MuiStepConnector-line.MuiStepConnector-lineHorizontal":
                {
                  borderColor: "#06BDFF", // circle's number (ACTIVE)
                },
            }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{ paddingLeft: "1px", paddingRight: "1px" }}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            {activeStep === 0 && (
              <Box sx={{ marginY: "18px" }}>
                <Step1
                  id={id}
                  setLocationId={setLocationId}
                  getAllLocations={getAllLocations}
                  setCustomUrl={setCustomUrl}
                  setNegativeUrl={setNegativeUrl}
                  negativeUrl={negativeUrl}
                  email={email}
                  customUrl={customUrl}
                  setEmail={setEmail}
                  locationRequired={locationRequired}
                  allLocations={allLocations}
                  locationId={locationId}
                  isLoading={isLoading}
                  validateLocationId={validateLocationId}
                  stepperData={stepperData}
                  setStepperData={setStepperData}
                  surveyData={surveyData}
                  setSurveyData={setSurveyData}
                  setTripAdvisorChecked={setTripAdvisorChecked}
                  setFacebookChecked={setFacebookChecked}
                  setGoogleChecked={setGoogleChecked}
                  tripAdvisorChecked={tripAdvisorChecked}
                  facebookChecked={facebookChecked}
                  googleChecked={googleChecked}
                  NegUrlIsValid={NegUrlIsValid}
                  privateSurveyChecked={privateSurveyChecked}
                  setPrivateSurveyChecked={setPrivateSurveyChecked}
                  reviewPlatFormChecked={reviewPlatFormChecked}
                  setReviewPlatFormChecked={setReviewPlatFormChecked}
                  customUrlChecked={customUrlChecked}
                  setCustomUrlChecked={setCustomUrlChecked}
                  negativeEmail={negativeEmail}
                  setNegativeEmail={setNegativeEmail}
                  negativeEmailError={negativeEmailError}
                  setNegativeEmailError={setNegativeEmailError}
                  setImageUpload={setImageUpload}
                  setNegUrlIsValid={setNegUrlIsValid}
                  surveyImageDisplay={surveyImageDisplay}
                  setSurveyImageDisplay={setSurveyImageDisplay}
                  setFormitableDisabled={setFormitableDisabled}
                  surveyEmails={surveyEmails}
                  setSurveyEmails={setSurveyEmails}
                  oldLocation={oldLocation}
                  setOldLocation={setOldLocation}
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box sx={{ marginY: "18px" }}>
                <Step2
                  id={id}
                  stepperData={stepperData}
                  setStepperData={setStepperData}
                  imageUpload={imageUpload}
                  setImageUpload={setImageUpload}
                  surveyImageDisplayStepThree={surveyImageDisplayStepThree}
                  setSurveyImageDisplayStepThree={
                    setSurveyImageDisplayStepThree
                  }
                />
              </Box>
            )}
            {activeStep === 2 && (
              <Box sx={{ marginY: "18px" }}>
                <Step3
                  id={id}
                  locationId={locationId}
                  stepperData={stepperData}
                  locationLoading={locationLoading}
                  getLocationById={getLocationById}
                  singleLocation={singleLocation}
                  setStepperData={setStepperData}
                  integrationChecked={integrationChecked}
                  setIntegrationChecked={setIntegrationChecked}
                  importEmailChecked={importEmailChecked}
                  setImportEmailChecked={setImportEmailChecked}
                  setFormitableChecked={setFormitableChecked}
                  formitableChecked={formitableChecked}
                  uploadFileChecked={uploadFileChecked}
                  setUploadFileChecked={setUploadFileChecked}
                  directInputChecked={directInputChecked}
                  setDirectInputChecked={setDirectInputChecked}
                  languageData={languageData}
                  setLanguageData={setLanguageData}
                  dataArray={dataArray}
                  setDataArray={setDataArray}
                  totalEmails={totalEmails}
                  campaignEmails={campaignEmails}
                  setCampaignEmails={setCampaignEmails}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  qrCodeChecked={qrCodeChecked}
                  setQRCodeChecked={setQRCodeChecked}
                  formitableDisabled={formitableDisabled}
                  checkClicked={checkClicked}
                />
              </Box>
            )}
            {activeStep === 3 && (
              <Box sx={{ marginY: "18px" }}>
                <Step4
                  id={id}
                  stepperData={stepperData}
                  EditStep1={EditStep1}
                  EditStep2={EditStep2}
                  EditStep3={EditStep3}
                  surveyImageDisplayStepThree={surveyImageDisplayStepThree}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                pt: 2,
                gap: "0.2rem",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep !== 3 && (
                <>
                  <CommonButton
                    color="inherit"
                    onSubmit={handleBack}
                    label={t("Back")}
                    displayBlack={"true"}
                    sx={{ mr: 1 }}
                  />

                  <CommonButton
                    disabled={
                      (activeStep === 0 &&
                        (locationId === null
                          ? true
                          : customUrl?.length === 0
                          ? true
                          : tripAdvisorChecked === false &&
                            facebookChecked === false &&
                            googleChecked === false
                          ? true
                          : privateSurveyChecked === false &&
                            reviewPlatFormChecked === false &&
                            customUrlChecked === false
                          ? true
                          : privateSurveyChecked &&
                            (!stepperData?.campaignSurveyData.hasOwnProperty(
                              "image_url"
                            ) ||
                              stepperData?.campaignSurveyData?.image_url ===
                                "" ||
                              stepperData?.survey_email?.length === 0)
                          ? true
                          : privateSurveyChecked && negativeEmail?.length === 0
                          ? true
                          : privateSurveyChecked && negativeEmailError
                          ? true
                          : (privateSurveyChecked &&
                              stepperData?.validSurveyEmails === 0) ||
                            !surveyEmails
                          ? true
                          : customUrlChecked && negativeUrl?.length === 0
                          ? true
                          : // : customUrlChecked && NegUrlIsValid === false
                            // ? true
                            false)) ||
                      (activeStep === 1 && imageUpload === 0 ? true : false) ||
                      (activeStep === 2 &&
                      (id === undefined || currentUrl.includes("duplicate"))
                        ? qrCodeChecked === false &&
                          importEmailChecked === false &&
                          formitableChecked === false
                          ? true
                          : uploadFileChecked === false &&
                            directInputChecked === false
                          ? true
                          : // : formitableChecked===true &&
                          //   singleLocation !== null &&
                          //   singleLocation?.socialLink.some(
                          //     (item) => item?.type === "formitable"
                          //   )===false
                          // ? true
                          importEmailChecked &&
                            (totalEmails <= 0 || totalEmails > 5000) &&
                            stepperData?.validEmailCounter <= 0
                          ? true
                          : languageData?.length === 0
                          ? true
                          : false
                        : false)
                    }
                    onSubmit={handleNext}
                    label={t("Next")}
                  />
                </>
              )}
              {activeStep === 3 &&
                stepperData?.campaign_type === "formitable" &&
                (id === undefined || currentUrl?.includes("duplicate") ? (
                  <>
                    <CommonButton
                      label={t("Start_Campaign")}
                      onSubmit={handleOpenStartCampaign}
                    />
                  </>
                ) : (
                  <CommonButton
                    label={t("update_campaign")}
                    onSubmit={handleOpenUpdateCampaign}
                  />
                ))}
              {activeStep === 3 &&
                stepperData?.campaign_type === "import-email" &&
                (id === undefined || currentUrl?.includes("duplicate") ? (
                  <>
                    <CommonButton
                      color="inherit"
                      label={t("Start_later")}
                      displayWhite={"true"}
                      sx={{ mr: 1 }}
                      onSubmit={handleOpenLater}
                    />
                    <CommonButton
                      label={t("Start_Campaign")}
                      onSubmit={handleOpenStartCampaign}
                    />
                  </>
                ) : (
                  <CommonButton
                    label={t("update_campaign")}
                    onSubmit={handleOpenUpdateCampaign}
                  />
                ))}
              {activeStep === 3 &&
                stepperData?.campaign_type === "qr-code" &&
                (id === undefined || currentUrl?.includes("duplicate") ? (
                  <>
                    <CommonButton
                      label={t("get_qr")}
                      onSubmit={handleOpenStartCampaign}
                    />
                  </>
                ) : (
                  <CommonButton
                    label={t("upadte_get_qr")}
                    onSubmit={handleOpenUpdateCampaign}
                  />
                ))}
            </Box>
          </React.Fragment>
        </Box>
      )}

      {/*  <StartCampaignConfirm open={open} setOpen={setOpen} />
      <StartCampaignLater open={openLater} setOpen={setOpenLater} /> */}
      {openLater && (
        <Dialog
          fullScreen={fullScreen}
          open={openLater}
          onClose={handleCloseLater}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("review_request")}
            description={t("start_campaign_later_message")}
            onConfirm={onConfirmLaterHandler}
            onCancel={handleCloseLater}
            loading={laterLoading}
          />
        </Dialog>
      )}
      {open && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleCloseStartCampaign}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("review_request")}
            description={
              stepperData?.campaign_type === "qr-code"
                ? t("start_campaign_message_qr")
                : t("start_campaign_message")
            }
            onConfirm={onConfirmHandler}
            loading={startLoading}
            onCancel={handleCloseStartCampaign}
          />
        </Dialog>
      )}

      {openUpdateModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openUpdateModel}
          onClose={handleCloseUpdateCampaign}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("review_request")}
            description={t("update_campaign_subhead")}
            onConfirm={handleUpdateCampaign}
            loading={updateLoading}
            onCancel={handleCloseUpdateCampaign}
          />
        </Dialog>
      )}
      <QRCodeModal
        open={openQRModal}
        setOpenQRModal={setOpenQRModal}
        campaignQRId={
          campaignQRId !== undefined && campaignQRId !== null
            ? campaignQRId
            : id
        }
        stepperData={stepperData}
      />
    </>
  );
};

export default Index;
