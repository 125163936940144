import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  TextField as MuiTextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import ReviewsCard from "../../../../components/ReviewSource/ReviewsCard";
import { api } from "../../../../contexts/JWTContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../styles/style";
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const EditTask = ({ onCancel, selectedTask, alignment }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [titleRequired, setTitleRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));

  useEffect(() => {
    if (selectedTask) {
      let tempUsersArray = [];
      selectedTask?.taskUsers?.length > 0 &&
        selectedTask?.taskUsers?.map((item) => {
          tempUsersArray.push(item?.user);
        });
      setSelectedUser(tempUsersArray);
      setTitle(selectedTask?.title);
      setDescription(selectedTask?.description);
      setCompanyId(selectedTask.company);
      setLocationId(selectedTask?.location);
      setStatus(selectedTask?.status);
      setSelectedReview(selectedTask?.review);
    }
  }, [selectedTask]);
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(async () => {
    getAllCompanies();

    //  getTasks()
  }, [companyId]);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  const handleUserChange = (option) => {
    setSelectedUser(option);
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      // setLoading(true);
      const res = await api.get(`user/search`);
      if (res.status === 200) {
        // setAllCompanies(res.data.data);
        setUsers(res.data.data);
        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company/` + companyData?.id);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const createTask = async () => {
    const newStr = description.replace(/(<([^>]+)>)/gi, "");

    if (title === "") {
      setTitleRequired(true);
    }

    if (newStr === "") {
      setDescriptionRequired(true);
    }
    if (locationId === null) {
      setLocationRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });

    if (
      newStr !== "" &&
      title !== "" &&
      locationId !== null &&
      selectedUser?.length > 0
    ) {
      try {
        setIsLoading(true);

        if (selectedTask) {
          const res = await api.patch("/tasks/" + selectedTask?.id, {
            title: title,
            description: description,
            companyId: companyId?.id,
            locationId: locationId?.id,
            status: status,
            reviewId: selectedReview?.id,
            userIds: userIds?.length > 0 ? userIds.toString() : "",
          });
          if (res.status === 200) {
            toast.success("Task Updated Successfully", {
              onClose: () => {
                setIsLoading(false);

                onCancel(true);
              },
            });
            // setAllCompanies(res.data.data);
          }
        }
      } catch (error) {
        setIsLoading(false);

        toast.error("error");
      }
    }

    //
  };

  return (
    <>
      <Box className={classes.addTaskField}>
        <Box className={classes.editTaskMainBox}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <Box sx={{ marginBottom: "8px" }}>
                {selectedReview && (
                  <>
                    <Typography className={classes.addTaskTypo}>
                      {t("Linked_Review")}
                    </Typography>
                    <ReviewsCard
                      item={selectedReview}
                      location={selectedReview?.location}
                      fromEditTask="true"
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} lg={12}>
            <Typography className={classes.addTaskTitle}>
              {t("Task_Tile")}
            </Typography>
            <TextField
              sx={{ width: "100%" }}
              error={titleRequired}
              style={{ borderRadius: "8px" }}
              value={title}
              onChange={(e) => {
                setTitleRequired(false);
                setTitle(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginBottom: "8px",
                    width: "100%",
                  },
                },
              }}
              margin="normal"
            />
            {titleRequired && (
              <Typography sx={{ color: "#d32f2f" }}>
                {t("Field_req_Title")}
              </Typography>
            )}
          </Grid>

          <Box sx={{ minWidth: alignment === true ? 120 : "auto" }}>
            <Typography className={classes.addTaskTypo}>
              {t("Status")}
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                onChange={handleChangeStatus}
              >
                <MenuItem value={"open"}>{t("Open")}</MenuItem>
                <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Typography className={classes.addTaskTypo}>
            {t("Select_Locations")}
          </Typography>

          {companyId && (
            <Autocomplete
              error={locationRequired}
              disablePortal
              id="combo-box-demo"
              options={
                allCompanies?.locations?.length > 0
                  ? allCompanies?.locations
                  : []
              }
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    " " +
                    "(" +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1 + "," + " "
                      : "") +
                    (option?.city ? option?.city : "") +
                    ")"
                  : ""
              }
              sx={{ width: "100%" }}
              value={locationId}
              onChange={(e, value) => {
                setLocationRequired(false);
                handleLocationChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  name="companies"
                  error={locationRequired}
                  // label={t("TaskLocation")}
                />
              )}
            ></Autocomplete>
          )}
          {locationRequired && (
            <Typography sx={{ color: "#d32f2f" }}>
              {t("Field_req_loc")}
            </Typography>
          )}
          <Typography className={classes.addTaskTypo}>
            {t("Select_User")}
          </Typography>

          <Autocomplete
            multiple
            error={userRequired}
            disablePortal
            id="combo-box-demo"
            options={users?.results ? users?.results : []}
            getOptionLabel={(option) =>
              option?.firstName ? option.firstName : ""
            }
            sx={{ width: "100%" }}
            defaultValue={selectedUser && selectedUser}
            value={selectedUser}
            onChange={(e, value) => {
              handleUserChange(value);
              setUserRequired(false);
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.firstName + " " + option?.lastName}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_User")}
                {...params}
                required
                // label={t("TaskLocation")}
                error={userRequired}
                name="language"
              />
            )}
          ></Autocomplete>
          {userRequired && (
            <Typography sx={{ color: "#d32f2f" }}>
              {t("Field_req_user")}
            </Typography>
          )}
          <Grid container>
            <Typography className={classes.descText}>
              {t("Description")}
            </Typography>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Editors
                value={description}
                onChange={(e) => {
                  setDescriptionRequired(false);
                  setDescription(e);
                }}
              />
              {descriptionRequired && (
                <Typography sx={{ color: "#d32f2f" }}>
                  {t("Field_req_dec")}
                </Typography>
              )}
            </Grid>

            <Divider my={2} />
          </Grid>
        </Box>
        <Box sx={{ marginTop: "8px" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Box className={classes.editTaskBox}>
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                label={t("Cancel")}
              />
              <CommonButton
                onSubmit={() => {
                  createTask();
                }}
                variant="contained"
                loading={isLoading}
                label={t("Update_Task")}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditTask;
