import React, { useState, useEffect } from "react";
import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Typography,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";

import { useStyles } from "../styles/style";
function AddReview({
  onCancel,
  modelDataReviewSource,
  allReviewSource,
  locationId,
  reviewId,
  reviewSourceData,
  name,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [urlRequired, seturlRequired] = useState(false);
  // const [sourceIdRequired, setsourceIdRequired] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;
  // const [isLoading, setIsLoading] = useState(false);
  const [sourceId, setsourceId] = useState("");
  const [url, seturl] = useState(null);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    if (modelDataReviewSource) {
      seturl(
        modelDataReviewSource?.url?.length > 0
          ? modelDataReviewSource?.url
          : modelDataReviewSource?.placeId
      );
      if (modelDataReviewSource?.isActive) {
        setStatus("Active");
      } else {
        setStatus("InActive");
      }

      setsourceId(modelDataReviewSource?.thirdPartyReviewSource);
    }
  }, [modelDataReviewSource]);

  const createReviewSource = async () => {
    const data = {
      url: url,
      sourceId: sourceId,
      isActive: status === "Active" ? true : false,
    };

    if (url === null) {
      seturlRequired(true);
    }

    if (!sourceId === undefined) {
      //  setsourceIdRequired(true);
    }

    if (url !== null && sourceId !== undefined) {
      try {
        //  setIsLoading(true);
        let res = null;
        if (modelDataReviewSource) {
          res = await api.patch(`/reviewSource/${modelDataReviewSource}`, {
            url: url,
            locationId: locationId,
            sourceId: reviewSourceData?.id,
            isActive: status === "Active" ? true : false,
          });
        } else {
          res = await api.post(`/reviewSource`, {
            url: url,
            locationId: locationId,
            sourceId: reviewSourceData?.id,
            isActive: status === "Active" ? true : false,
          });
        }

        if (res.status === 201 || res.status === 200) {
          toast.success(res?.data?.message, {
            onClose: () => {
              //  setIsLoading(false);

              onCancel(true);
            },
          });
        }
      } catch (error) {
        //  setIsLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div>
        <Box className={classes.boxDelete}>
          <Grid container className={classes.mainContainerReviews}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <img
                className={classes.sourceImage}
                src={`${baseURL}/${reviewSourceData?.image}`}
                alt={"Source Image"}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Typography className={classes.sourceNameReview}>
                {name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            <Typography className={classes.taskTile}>
              {t("Url_PlaceId")}
            </Typography>
            <TextField
              className={classes.titleField}
              error={urlRequired}
              value={url}
              placeholder={t("url_is_required")}
              onChange={(e) => {
                seturlRequired(false);
                seturl(e.target.value);
              }}
              margin="normal"
            />
          </Grid>

          <DialogActions className={classes.actionButtons}>
            <Button
              autoFocus
              onClick={onCancel}
              className={classes.cancelButton}
            >
              {t("Cancel")}
            </Button>

            <Button
              onClick={createReviewSource}
              className={classes.UnsubscribeButton}
            >
              {t("Subscribe")}
            </Button>
          </DialogActions>
        </Box>
      </div>
    </>
  );
}

export default AddReview;
