import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import TableComponent from "./Components/Table";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import { useLocation } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { spacing } from "@mui/system";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";

const Paper = styled(MuiPaper)(spacing);
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  buttonAccept: {
    color: "white",
    backgroundColor: "#06BDFF",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    "&:hover": { backgroundColor: "#0638C1", color: "white" },
  },
  buttonReject: {
    color: "white",
    backgroundColor: "#FC3652",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    marginLeft: "8px",
    "&:hover": { backgroundColor: "#e37685", color: "white" },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    getAllLocation,
    t,
    selectedLocationData,
    handleChangeCheckButton,
    selectAll,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    getAllLocation();
  };

  const headCells = [
    {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e)}
        />
      ),
    },
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "customer", alignment: "left", label: t("Company_Name") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "address", alignment: "left", label: t("address") },
    { id: "status", alignment: "left", label: t("Status") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedLocationData, setselectedLocationData] = useState([]);
  const [selectedSingleLocationData, setselectedSingleLocationData] = useState(
    []
  );
  const [selectAll, setSelectAll] = useState(false);
  const [bulkButton, setBulkButton] = useState(false);
  const [openAcceptModel, setOpenAcceptModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [openMultiAcceptModel, setOpenMultiAcceptModel] = useState(false);
  const [openMultiRejectModel, setOpenMultiRejectModel] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [statusValue, setStatusValue] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [allLocation, setAllLocation] = useState([]);
  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [openConfirmPlan, setOpenConfirmPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productPlanId, setProductPlanId] = useState("");
  const [locationId, setLocationId] = useState([]);
  const [selectedPrductPlanId, setSelectedProductPlanId] = useState("");
  const [locationRegionCode, setLocationRegionCode] = useState();

  useEffect(async () => {
    await getAllLocation();
  }, [rowsPerPage, page, statusValue, companyId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllLocation();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const getAllLocation = async () => {
    // let uberAllArr = [];
    // if (statusValue?.length > 0) {
    //   statusValue?.map((item) => {
    //     uberAllArr?.push(item?.value);
    //   });
    // }
    setIsLoading(true);
    try {
      // console.log("======== uberAllArr ========= ", uberAllArr);
      const res = await api.get(
        `/location/search?sortBy=updatedAt&limit=${rowsPerPage}&page=${
          page + 1
        }&enableUberAll=requested&companyId=${
          companyId !== null ? companyId?.id : ""
        }&status=approved&state=active`,
        {
          params: {
            searchTerm: filterWithName,
          },
        }
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setselectedSingleLocationData([]);
        setselectedLocationData([]);
        setSelectAll(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompaniesData(res.data.data);
      }
    } catch (error) {}
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleListingPlanChange = async () => {
    // if (selectedReport !== null) {
    try {
      setLoading(true);
      const res = await api.patch(`thirdParty/changeProductPlan`, {
        locationId,
        planId: productPlanId,
      });

      if (res.status === 200) {
        setLoading(false);
        setOpenConfirmPlan(false);
        setOpenPlanModal(false);
        getAllLocation();
        toast.success(res?.data?.message);
        // setSelectLanguageFilter(selectLanguage);
        // setOpenRequest(false);
        // getAllProductPlans();
      }
    } catch (error) {
      setLoading(false);
      setOpenConfirmPlan(false);
      setOpenPlanModal(false);
      // getAllLocation();
      toast.error(error?.message);
    }
    // }
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    setBulkButton(true);
    let dummyArr = [];
    const filteredArr = allCompanies?.results.filter(
      (item) =>
        item?.enableUberAll !== "error" && item?.enableUberAll !== "approved"
      // item?.enableUberAll !== "rejected" &&
    );
    if (e.target.checked) {
      for (var i = 0; i < allCompanies?.results?.length; i++) {
        if (
          allCompanies?.results[i]?.enableUberAll !== "error" &&
          allCompanies?.results[i]?.enableUberAll !== "approved"
          // allCompanies?.results[i]?.enableUberAll !== "rejected" &&
          // allCompanies?.results[i]?.enableUberAll !== "approved"
        ) {
          selectedLocationData?.push(allCompanies?.results[i]?.id);
          dummyArr?.push(allCompanies?.results[i]?.id);
        }
      }

      const uniqueLocationIds = new Set(selectedLocationData);
      {
      }
      setselectedLocationData([...uniqueLocationIds]);
    } else {
      setselectedLocationData([]);
    }
  };

  const handleTaskChecked = (e, index, locationId) => {
    setBulkButton(true);
    if (e.target.checked) {
      selectedLocationData.push(locationId);
      {
      }
    } else {
      var locationIndex = selectedLocationData.indexOf(locationId);
      selectedLocationData.splice(locationIndex, 1);
    }
    if (allCompanies?.results?.length > 0) {
      const filtered = allCompanies?.results?.filter(
        (item) =>
          item?.enableUberAll !== "error" && item?.enableUberAll !== "approved"
      );

      if (filtered?.length === selectedLocationData?.length) {
        setselectedLocationData([]);
        setSelectAll(true);
      } else {
        setSelectAll(false);
        setselectedLocationData(filtered);
      }
    }
    setselectedLocationData([...selectedLocationData]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleOpenAcceptModel = (id) => {
    selectedSingleLocationData.push(id);
    setOpenAcceptModel(true);
    setAnchorEl(null);
  };
  const handleCloseAcceptModel = () => {
    // setselectedLocationData([]);
    setselectedSingleLocationData([]);
    setOpenAcceptModel(false);
    setAnchorEl(null);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMultiAcceptModel = () => {
    setOpenMultiAcceptModel(true);
    setAnchorEl(null);
  };
  const handleCloseMultiAcceptModel = () => {
    setselectedLocationData([]);
    setOpenMultiAcceptModel(false);
    setAnchorEl(null);
  };

  const handleOpenRejectModel = (id) => {
    selectedSingleLocationData.push(id);
    setOpenRejectModel(true);
    setAnchorEl(null);
  };
  const handleCloseRejectModel = () => {
    // setselectedLocationData([]);
    setselectedSingleLocationData([]);
    setOpenRejectModel(false);
    setAnchorEl(null);
  };

  const handleOpenMultiRejectModel = () => {
    setOpenMultiRejectModel(true);
    setAnchorEl(null);
  };
  const handleCloseMultiRejectModel = () => {
    setselectedLocationData([]);
    setOpenMultiRejectModel(false);
    setAnchorEl(null);
  };
  const handleClick = (event, selectedData) => {
    setSelectedRow(selectedData);

    setAnchorEl(event.currentTarget);
  };

  const handleCompanyChange = (option) => {
    setPage(0);
    setCompanyId(option);
  };

  const handleAcceptRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await api.patch(`/location/enable-uberAll`, {
        locations: selectedLocationData,
        enableUberAll: "approved",
      });
      if (res.status === 200) {
        setRequestLoading(false);
        setOpenAcceptModel(false);
        setOpenMultiAcceptModel(false);
        setselectedSingleLocationData([]);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getAllLocation();
        setSelectAll(false);
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleAcceptSingleRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await api.patch(`/location/enable-uberAll`, {
        locations: selectedSingleLocationData,
        enableUberAll: "approved",
      });
      if (res.status === 200) {
        setRequestLoading(false);
        setOpenAcceptModel(false);
        setOpenMultiAcceptModel(false);
        setselectedSingleLocationData([]);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getAllLocation();
        setSelectAll(false);
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleRejectRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await api.patch(`/location/enable-uberAll`, {
        locations: selectedLocationData,
        enableUberAll: "rejected",
      });
      if (res.status === 200) {
        setOpenRejectModel(false);
        setOpenMultiRejectModel(false);
        setselectedSingleLocationData([]);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getAllLocation();
        setRequestLoading(false);
        setSelectAll(false);
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleRejectSingleRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await api.patch(`/location/enable-uberAll`, {
        locations: selectedSingleLocationData,
        enableUberAll: "rejected",
      });
      if (res.status === 200) {
        setOpenRejectModel(false);
        setOpenMultiRejectModel(false);
        setselectedSingleLocationData([]);
        setselectedLocationData([]);
        toast.success(res?.data?.message);
        getAllLocation();
        setRequestLoading(false);
        setSelectAll(false);
      }
    } catch (error) {
      setRequestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies.length - page * rowsPerPage);

  const statusArr = [
    {
      value: "requested",
      label: "Requested",
    },
    {
      value: "error",
      label: "Error",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "rejected",
      label: "Rejected",
    },
    {
      value: "pending",
      label: "Pending",
    },
  ];
  const filterByName = (e) => {
    setPage(0);
    setFilterWithName(e.target.value);
  };
  // const handleChangeStatus = (value) => {
  //   console.log("======== value ========= ", value);
  //   setPage(0);
  //   setStatusValue(value);
  // };

  const handleOpenPlanModal = (item) => {
    setOpenPlanModal(true);
    setLocationId(item?.id);
    setSelectedProductPlanId(item?.productPlan?.planId);
    setLocationRegionCode(item?.regionCode);
  };
  const handleClosePlanModal = () => {
    setOpenPlanModal(false);
  };

  const handleOpenSelectPlan = () => {
    setOpenConfirmPlan(true);
  };
  const handleCloseSelectPlan = () => {
    setOpenConfirmPlan(false);
  };
  return (
    <>
      {openConfirmPlan && (
        <Dialog
          fullScreen={fullScreen}
          open={openConfirmPlan}
          onClose={handleCloseSelectPlan}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("listing_plan")}
            description={t("listing_plans_desc")}
            onConfirm={handleListingPlanChange}
            loading={loading}
            onCancel={handleCloseSelectPlan}
          />
        </Dialog>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={openAcceptModel}
        onClose={handleCloseAcceptModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("UberAll_Requests")}
          description={t("location_reqAccept_desc")}
          onConfirm={handleAcceptSingleRequest}
          loading={requestLoading}
          onCancel={handleCloseAcceptModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openRejectModel}
        onClose={handleCloseRejectModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("UberAll_Requests")}
          description={t("location_reqReject_desc")}
          onConfirm={handleRejectSingleRequest}
          loading={requestLoading}
          onCancel={handleCloseRejectModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openMultiAcceptModel}
        onClose={handleCloseMultiAcceptModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("UberAll_Requests")}
          description={t("location_reqMultiAccept_desc")}
          onConfirm={() => {
            handleAcceptRequest();
          }}
          loading={requestLoading}
          onCancel={handleCloseMultiAcceptModel}
        />
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openMultiRejectModel}
        onClose={handleCloseMultiRejectModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("UberAll_Requests")}
          description={t("location_reqMultiReject_desc")}
          onConfirm={() => {
            handleRejectRequest();
          }}
          loading={requestLoading}
          onCancel={handleCloseMultiRejectModel}
        />
      </Dialog>
      <Helmet title="Locations" />
      <TitleHeader title={t("listing_approval_Requests")} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              allCompaniesData?.results?.length > 0
                ? allCompaniesData?.results
                : []
            }
            getOptionLabel={(option) => option?.name}
            className="Autocomplete-field"
            value={companyId}
            onChange={(e, value) => {
              handleCompanyChange(value);
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Company")}
                {...params}
                required
                name="language"
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <SearchField
            filterWithName={filterWithName}
            filterByName={filterByName}
            onSubmit={getAllLocation}
            placeholder={t("FilterLocations")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <CommonButton
            alignment={true}
            onSubmit={getAllLocation}
            label={t("Search")}
            leftMargin={true}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={3}>
        {bulkButton === true && (
          <>
            {selectedLocationData?.length > 0 && (
              <>
                <Grid item xs={12} sm={9} md={9} lg={9}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    className={classes.buttonAccept}
                    onClick={handleOpenMultiAcceptModel}
                  >
                    {t("accept")}
                  </Button>
                  <Button
                    className={classes.buttonReject}
                    onClick={handleOpenMultiRejectModel}
                  >
                    {t("Reject")}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      {isLoading ? (
        <Loader />
      ) : allCompanies && allCompanies?.count > 0 ? (
        <Grid container marginTop="3px" spacing={6}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  t={t}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={allCompanies?.count}
                  getAllLocation={getAllLocation}
                  handleChangeCheckButton={handleChangeCheckButton}
                  selectAll={selectAll}
                  selectedLocationData={selectedLocationData}
                />
                <TableBody>
                  {allCompanies?.results?.length > 0 ? (
                    allCompanies?.results?.map((row, index) => {
                      return (
                        <>
                          <TableComponent
                            row={row}
                            index={index}
                            handleClose={handleClose}
                            setOpen={setOpen}
                            setDeleteId={setDeleteId}
                            closeMenu={closeMenu}
                            selectedRow={selectedRow}
                            handleOpenAcceptModel={handleOpenAcceptModel}
                            handleOpenRejectModel={handleOpenRejectModel}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            handleTaskChecked={handleTaskChecked}
                            selectedLocationData={selectedLocationData}
                            handleOpenPlanModal={handleOpenPlanModal}
                            setLocationId={setLocationId}
                          />
                        </>
                      );
                    })
                  ) : allCompanies?.results?.length > 0 ? (
                    allCompanies?.results.map((row, index) => {
                      return (
                        <>
                          <TableComponent
                            row={row}
                            index={index}
                            handleClose={handleClose}
                            setOpen={setOpen}
                            setDeleteId={setDeleteId}
                            closeMenu={closeMenu}
                            selectedRow={selectedRow}
                            handleOpenAcceptModel={handleOpenAcceptModel}
                            handleOpenRejectModel={handleOpenRejectModel}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            handleTaskChecked={handleTaskChecked}
                            selectedLocationData={selectedLocationData}
                            handleOpenPlanModal={handleOpenPlanModal}
                            setLocationId={setLocationId}
                          />
                        </>
                      );
                    })
                  ) : (
                    <Typography variant="h3">no data found</Typography>
                  )}
                  {emptyRows > 0 && (
                    <CustomizedTableRow style={{ height: 53 * emptyRows }}>
                      <CustomizedTableCell colSpan={8} />
                    </CustomizedTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allCompanies?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : (
        <NotFound text={t("no_listings_requests_found")} />
      )}
    </>
  );
}
export default MyLocations;
