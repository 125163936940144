import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Modal,
  TextField,
  Divider,
  Tooltip,
  tooltipClasses,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  useMediaQuery,
  Checkbox,
  Button,
  Chip,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { CircularProgress } from "@mui/material";
import PrimaryUserModal from "./Modals/PrimaryUserModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomizedTableRow from "../../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../../../components/CustomComponents/table/tableCell";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../../../../components/CustomComponents/title";
import PersonIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/Password";
import AddUser from "../../../Extras/AddUser";
import EditUser from "../../../Extras/EditUser";
import Delete from "../../../../../../components/Models/DeleteModal/Delete";
import Loader from "../../../../../../components/Loaders/Loader";
import { api, handleSessionOut } from "../../../../../../contexts/JWTContext";
import SearchField from "../../../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import ApproveUserModel from "../../ApproveUserModel";
import { ReactComponent as Resend } from "../../../../../../assets/Icons/Resend.svg";
import { ReactComponent as CopyIcon } from "../../../../../../assets/Icons/Copy.svg";
import useAuth from "../../../../../../hooks/useAuth";
import { useStyles } from "../../../styles/style";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#13CF8F",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Languages = [
  "English",
  "Dutch",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Portugese",
];

const Roles = ["account-manager", "location-manager"];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "100vh",
  width: "420px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  justifyContent: "space-around",
  // overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "100vh",
  width: "420px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Users = (props) => {
  const theme = useTheme();
  const {
    addPermission,
    editPermission,
    deletePermission,
    viewPermission,
    page,
    setPage,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const isAdminAndPrimary =
    /*  user.role === "company-admin" && */ user.isPrimary;

  const company = JSON.parse(localStorage.getItem("company"));
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [allCompanies] = useState([]);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [filterWithName, setFilterWithName] = useState("");

  const [users, setUsers] = useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalEditUser, setOpenModalEditUser] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openMultiDeleteModel, setOpenMultiDeleteModel] = useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [openPrimaryModel, setOpenPrimaryModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [openUpdateRole, setOpenUpdateRole] = React.useState(false);
  const [roleRequired, setRoleRequired] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [openApproveModel, setOpenApproveModel] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [selectAll, setSelectAll] = useState(null);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [approveUserEmail, setApproveUserEmail] = useState(null);
  const [displayText, setDisplayText] = useState("");
  const [userInviteToken, setUserInviteToken] = useState(null);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [copyValidInviteLinkLoading, setCopyValidInviteLinkLoading] =
    useState(false);
  const [resendEmailModel, setResendEmailModel] = useState(false);
  const [openLinkModel, setOpenLinkModel] = useState(false);
  const [unlinkId, setUnlinkId] = useState(null);
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [userCompanies, setUserComapies] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openLinkedConfirmationModel, setOpenLinkedConfirmationModel] =
    useState(false);

  useEffect(() => {
    getAllUsers();
    getAllLocations();
  }, []);
  useEffect(() => {
    if (props.selectedTab === 1 && openApproveModel === false) {
      const delayDebounceFn = setTimeout(() => {
        getAllUsers();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName, page, rowsPerPage, props.selectedTab]);

  // useEffect(() => {
  //   getAllUsers();
  // }, [rowsPerPage, page]);

  const handleOpenRoleModel = () => {
    setOpenUpdateRole(true);
  };
  const handleCloseRoleModal = () => {
    setSelectedRow(null);
    setOpenUpdateRole(false);
    getAllUsers();
  };
  const resetPassword = async (row) => {
    try {
      setLoading(true);

      const res = await api.post(`auth/forgot-password`, {
        email: row?.User?.email,
      });

      if (res.status === 200) {
        toast.success(res?.data?.message, {});
        navigate("/auth/sign-in");
        // setAllCompanies(res.data.data);
      } else {
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };

  const headCells = [
    {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    { id: null, alignment: "left", label: t("AccountTableID") },
    { id: "firstName", alignment: "left", label: t("AccountTableName") },
    { id: null, alignment: "left", label: t("UserTableEmail") },
    { id: null, alignment: "left", label: t("UserTableRole") },
    { id: null, alignment: "left", label: t("Status") },
    { id: null, alignment: "center", label: t("Action") },
  ];

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    getAllUsers();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id, selectedData) => {
    setSelectedRow(selectedData);
    setUpdateId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?pageNumber=${
          page !== undefined ? page + 1 : 1
        }&limit=${rowsPerPage}&companyId=${companyData?.id}${
          filterWithName !== "" ? `&search=${filterWithName}` : ""
        }`
      );
      if (res.status === 200) {
        setUsers(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };
  const handleOpenResendModel = () => {
    setResendEmailModel(true);
  };

  const handleCloseResendModel = () => {
    setSelectedUser("");
    setResendEmailModel(false);
  };
  const handleResendEmail = async () => {
    try {
      setResendEmailLoading(true);
      const res = await api.put(
        `/userInvites/${
          selectedUser?.User?.UserInvites?.length > 0
            ? selectedUser?.User?.UserInvites[0]?.id
            : ""
        }?type=resend`
      );

      if (res.status === 200) {
        toast.success(res?.data?.message);
        handleCloseResendModel();
        setResendEmailLoading(false);
        getAllUsers();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      handleCloseResendModel();
      setResendEmailLoading(false);
    }
  };

  const handleCopyValidInviteLink = async (selectedUserRow) => {
    try {
      // setCopyValidInviteLinkLoading(true);
      setCopyValidInviteLinkLoading((prevLoadingRows) => ({
        ...prevLoadingRows,
        [selectedUserRow.id]: true,
      }));
      const res = await api.put(
        `/userInvites/${
          selectedUserRow?.User?.UserInvites?.length > 0
            ? selectedUserRow?.User?.UserInvites[0]?.id
            : ""
        }?type=validate`
      );

      if (res.status === 200) {
        setCopyValidInviteLinkLoading((prevLoadingRows) => ({
          ...prevLoadingRows,
          [selectedUserRow.id]: false,
        }));
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_LOCAL_URL}/auth/register/${
            res?.data?.data?.inviteCode !== null &&
            res?.data?.data?.inviteCode !== undefined
              ? res?.data?.data?.inviteCode
              : ""
          }`
        );

        setDisplayText(true);
        setTimeout(() => {
          setDisplayText(false);
        }, 2000);
      }
    } catch (error) {
      setCopyValidInviteLinkLoading((prevLoadingRows) => ({
        ...prevLoadingRows,
        [selectedUserRow.id]: false,
      }));
    }
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location`);
      if (res.status === 200) {
        setIsLoading(true);
        setAllLocations(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const updateUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/user/ownerTransfership`, {
        companyId: companyData?.id,
        userId: selectedRow?.User?.id,
      });
      if (res.status === 200) {
        setIsLoading(false);
        handleClosePrimaryModel();
        toast.error(t("Primary_user_toast"));
        handleSignOut();
        // toast.success(t("Updated_Successfully"));
        // getAllUsers();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const deleteUserHandler = async () => {
    if (deleteId !== null) {
      setIsLoading(true);
      try {
        const res = await api.delete(`/companyUserRoles/${deleteId?.id}`);
        setOpenDeleteModel(false);
        if (res.status === 200) {
          setIsLoading(false);
          toast.success(t("Successfully_Deleted"));
          setOpenDeleteModel(false);
          setOpenLinkedConfirmationModel(false);
          getAllUsers();
          setDeleteUsers([]);
          setSelectAll(false);

          setFilterWithName("");
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  const deleteMultiUserHandler = async () => {
    if (deleteUsers?.length > 0) {
      setIsLoading(true);
      try {
        const res = await api.delete(
          `/companyUserRoles/${deleteUsers?.toString()}`
        );
        setOpenDeleteModel(false);
        if (res.status === 200) {
          setIsLoading(false);
          handleCloseMultiDeleteModel();
          toast.success(t("Successfully_Deleted"));
          getAllUsers();
          setDeleteUsers([]);
          setSelectAll(false);
          setFilterWithName("");
        }
      } catch (error) {
        handleCloseMultiDeleteModel();
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  };

  const filterByName = (e) => {
    if (openApproveModel === false) {
      setFilterWithName(e.target.value);
    } else {
      setFilterWithName("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies.length - page * rowsPerPage);

  // const handleOpen = () => setOpenModal(true);

  const handleNavigateAddRole = () => {
    navigate(`/user/accounts-overview/add-new-user`, {
      state: { page: page },
    });
  };
  const handleNavigateEditUser = (id, rowData) => {
    navigate(`/user/accounts-overview/edit-user/${id}`, {
      state: {
        page: page,
        isPrimary:
          rowData?.linkedUser === false && rowData?.isPrimary === true
            ? true
            : false,
      },
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    getAllUsers();
  };
  const handleOpenEditUser = () => {
    setOpenModalEditUser(true);
  };

  const handleCloseModalEditUser = () => {
    setOpenModalEditUser(false);
    getAllUsers();
  };
  const handleOpenDeleteModel = async (bool, userId) => {
    try {
      const res = await api.get(`user/userInCompanies/${userId}`);
      setDeleteLoading(true);
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          if (res?.data?.data?.length > 1) {
            setOpenLinkedConfirmationModel(true);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(false);
          } else {
            setOpenLinkedConfirmationModel(false);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(true);
          }

          setDeleteLoading(false);
        }
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      setOpenLinkedConfirmationModel(false);
      setUserComapies([]);
      setOpenDeleteModel(true);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleCloseLinedConfirmationModel = () => {
    setOpenLinkedConfirmationModel(false);
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };

  const handleOpenMultiDeleteModel = () => {
    setOpenMultiDeleteModel(true);
  };
  const handleCloseMultiDeleteModel = () => {
    setOpenMultiDeleteModel(false);
  };

  const handleCloseResetModel = () => {
    setOpenResetModel(false);
  };
  const handleClosePrimaryModel = () => {
    setOpenPrimaryModel(false);
  };
  const handleSubmit = async () => {
    if (fieldValue === "") {
      setRoleRequired(true);
    } else {
      setRoleRequired(false);
      setIsLoading(true);
      try {
        const res = await api.patch(`/companyUser/changeRole`, {
          companyId:
            selectedUser?.CompanyUsers?.length > 0 &&
            selectedUser?.CompanyUsers[0]?.companyId,
          role: fieldValue,
          userId: selectedUser?.id,
        });
        if (res.status === 200) {
          setIsLoading(false);
          handleCloseRoleModal();
          toast.success(res?.data?.message);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
        handleCloseRoleModal();
      }
    }
  };

  const handleCloseApproveModal = () => {
    setOpenApproveModel(false);
  };
  const handleOpenApproveModal = (row) => {
    setOpenApproveModel(true);
    setUserInviteToken(row?.UserInvites[0]?.inviteCode);
    setApproveUserEmail(row?.email);
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    let dummyArr = [];
    if (e.target.checked) {
      const filteredData = users?.rows.filter(
        (item) =>
          item?.isPrimary === false &&
          item?.User?.id !== user?.id &&
          item?.linkedUser === false
      );
      for (var i = 0; i < filteredData?.length; i++) {
        deleteUsers.push(filteredData[i]?.id);
        dummyArr?.push(filteredData[i]?.id);
      }

      const uniqueUsers = new Set(deleteUsers);

      setDeleteUsers([...uniqueUsers]);
    } else {
      setDeleteUsers([]);

      if (deleteUsers?.length === dummyArr?.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  };

  const handleRolesChecked = (e, index, userId) => {
    if (e.target.checked) {
      deleteUsers.push(userId);
    } else {
      var taskIndex = deleteUsers.indexOf(userId);
      deleteUsers.splice(taskIndex, 1);
    }
    setDeleteUsers([...deleteUsers]);
    if (users?.rows?.length === deleteUsers?.length) {
      // setDeleteUsers([]);
      setSelectAll(true);
    } else {
      setSelectAll(false);
      //  setDeleteRoles(all);
    }
  };
  const handleOpenLinkModel = (data) => {
    setUnlinkId(data?.User?.id);
    setOpenLinkModel(true);
  };

  const handleCloseLinkModel = () => {
    setOpenLinkModel(false);
  };

  const handleUnlinkUser = async () => {
    try {
      setUnlinkLoading(true);
      const res = await api.patch(`companyUserRoles/unLink`, {
        companyId: parseInt(company?.id),
        userId: unlinkId,
      });
      if (res.status === 200) {
        setUnlinkLoading(false);
        handleCloseLinkModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllUsers();
      }
    } catch (error) {
      setUnlinkLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseLinkModel();
    }
  };
  return (
    <>
      {openLinkModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openLinkModel}
          onClose={handleCloseLinkModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Unlink_User")}
            description={t("Unlink_User_des")}
            onConfirm={() => {
              setOpenLinkModel(false);
              handleUnlinkUser();
            }}
            onCancel={handleCloseLinkModel}
            loading={unlinkLoading}
          />
        </Dialog>
      )}
      {openApproveModel && (
        <ApproveUserModel
          title={t("approve_user")}
          subTitle={t("fetch_emails_subheading")}
          open={openApproveModel}
          handleCloseNewRequestModel={handleCloseApproveModal}
          isFullWidth={true}
          isDisabledEmail={true}
          token={userInviteToken}
          getAllUsers={getAllUsers}
          approveUserEmail={approveUserEmail}
        />
      )}
      <Modal
        open={openModalEditUser}
        // onClose={handleCloseModalEditUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Grid item>
            <Box sx={styleEditUser}>
              <Box
                sx={{
                  marginY: "10px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                  {t("EditUser")}
                </Typography>
              </Box>
              <Divider my={6} />
            </Box>
          </Grid>
        </Grid>
      </Modal>
      <Box sx={{ marginTop: "1rem" }}>
        <TitleHeader title={t("User")} subHeading={t("AllUser")} />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={9.5} xs={7} sm={7}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllUsers}
            placeholder={t("FilterUser")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={5}
          md={2.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CommonButton
            // isLoading={isLoading}
            onSubmit={getAllUsers}
            label={t("Search")}
            alignment={true}
          />

          {addPermission && viewPermission ? (
            <CommonButton
              onSubmit={handleNavigateAddRole}
              label={t("Add")}
              alignment={true}
            />
          ) : (
            <BootstrapTooltip title={t("authorized_access")}>
              <span>
                <CommonButton
                  disabled={true}
                  onSubmit={handleNavigateAddRole}
                  label={t("Add")}
                  alignment={true}
                />
              </span>
            </BootstrapTooltip>
          )}
        </Grid>
      </Grid>
      {/* </Paper> */}
      <Grid container>
        <Modal
          open={openModal}
          // onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item sm={12} xs={12} md={12}>
              <Box sx={style}>
                <Box
                  sx={{
                    marginY: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                    {/* {t("AddTask")} */}

                    {t("Add_New_User")}
                  </Typography>
                </Box>
                <Divider my={6} />
                {/* <Typography>Add New User</Typography> */}
                <AddUser
                  locations={allLocations}
                  Language={Languages}
                  Role={Roles}
                  id={props.params}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Userr")}
            description={`${t("delete_user")}?`}
            onConfirm={() => {
              setOpenDeleteModel(false);
              deleteUserHandler();
            }}
            onCancel={handleCloseDeleteModel}
          />
        </Dialog>
      )}

      {openLinkedConfirmationModel && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "30%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={openLinkedConfirmationModel}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "50px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {` ${t("company_linked")} ${userCompanies?.length - 1} ${
                    userCompanies?.length - 1 === 1
                      ? t("company_linked_Text1")
                      : t("companies_linked_Text")
                  } `}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${
                    deleteId?.User?.firstName !== null &&
                    deleteId?.User?.lastName !== null
                      ? deleteId?.User?.firstName +
                        " " +
                        deleteId?.User?.lastName
                      : t("this_user_text2")
                  } ${t("company_linked_desText1")} ${
                    userCompanies?.length - 1
                  } ${
                    userCompanies?.length - 1 > 1
                      ? t("companies_linked_Text")
                      : t("company_linked_Text1")
                  } ${t("company_linked_desText2")}`}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {userCompanies?.length - 1 > 1
                    ? t("company_linked_desText3")
                    : t("company_linked_desText")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("company_linked_desText4")} ${
                    deleteId?.User?.firstName !== null &&
                    deleteId?.User?.lastName !== null
                      ? deleteId?.User?.firstName +
                        " " +
                        deleteId?.User?.lastName
                      : t("this_user_text")
                  }?`}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseLinedConfirmationModel}
                label={t("No_text")}
              />
              <CommonButton
                label={t("Yes")}
                isLoading={isLoading}
                disabled={isLoading}
                onSubmit={deleteUserHandler}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {resendEmailModel && (
        <Dialog
          fullScreen={fullScreen}
          open={resendEmailModel}
          onClose={handleCloseResendModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("resend_email")}
            description={`${t("resend_email_subheading")}?`}
            onConfirm={handleResendEmail}
            onCancel={handleCloseResendModel}
            loading={resendEmailLoading}
          />
        </Dialog>
      )}
      {openMultiDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openMultiDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Userr")}
            description={`${t("delete_multi_users")} ?`}
            onConfirm={deleteMultiUserHandler}
            loading={isLoading}
            onCancel={handleCloseMultiDeleteModel}
          />
        </Dialog>
      )}
      {openResetModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openResetModel}
          onClose={handleCloseResetModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Reset_password")}
            description={`${t("reset_password")} ${
              selectedRow?.User?.firstName !== undefined &&
              selectedRow?.User?.firstName !== null &&
              selectedRow?.User?.firstName !== ""
                ? selectedRow?.User?.firstName
                : ""
            } ${
              selectedRow?.User?.lastName !== undefined &&
              selectedRow?.User?.lastName !== null &&
              selectedRow?.User?.lastName !== ""
                ? selectedRow?.User?.lastName
                : ""
            } ?`}
            onConfirm={() => {
              setOpenResetModel(false);
              resetPassword(selectedRow);
            }}
            onCancel={handleCloseResetModel}
          />
        </Dialog>
      )}
      {openPrimaryModel && (
        <PrimaryUserModal
          openPrimaryModel={openPrimaryModel}
          selectedRow={selectedRow}
          isLoading={isLoading}
          handleClosePrimaryModel={handleClosePrimaryModel}
          updateUserHandler={updateUserHandler}
        />
      )}
      {/*  {openPrimaryModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openPrimaryModel}
          onClose={handleClosePrimaryModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Make_Primary_User")}
            description={`Are you sure you want to make ${selectedRow?.firstName} ${selectedRow?.lastName} primary user?`}
            onConfirm={() => {
              setOpenPrimaryModel(false);
              updateUserHandler(selectedRow?.id);
            }}
            onCancel={handleClosePrimaryModel}
          />
        </Dialog>
      )} */}
      {deleteUsers?.length > 0 && (
        <Grid xs={12} sm={12} md={12} lg={12} mt={2} sx={{ textAlign: "end" }}>
          {deletePermission && viewPermission ? (
            <Button
              sx={{
                color: "white",
                backgroundColor: "#FC3652",
                "&:hover": {
                  backgroundColor: "#ff3366",
                  cursor: "pointer",
                },
              }}
              onClick={() => handleOpenMultiDeleteModel()}
            >
              {t("Delete")}
            </Button>
          ) : (
            <BootstrapTooltip title={t("authorized_access")}>
              <span>
                <Button
                  disabled
                  sx={{
                    backgroundColor: "#e0e0e0",
                    color: "white",
                  }}
                  onClick={() => handleOpenMultiDeleteModel()}
                >
                  {t("Delete")}
                </Button>
              </span>
            </BootstrapTooltip>
          )}
        </Grid>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            {users?.rows?.length > 0 ? (
              <>
                {" "}
                <TableContainer sx={{ marginTop: "2rem" }} component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <CustomizedTableHead>
                      <CustomizedTableRow>
                        {headCells.map((headCell) => (
                          <CustomizedTableCell
                            key={headCell.id}
                            align={headCell.alignment}
                            padding={
                              headCell.disablePadding ? "none" : "normal"
                            }
                          >
                            {/*  <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={createSortHandler(headCell.id)}
                            > */}
                            {headCell.label}
                            {/*   </TableSortLabel> */}
                          </CustomizedTableCell>
                        ))}
                      </CustomizedTableRow>
                    </CustomizedTableHead>

                    <TableBody>
                      {users?.rows?.length > 0 &&
                        users?.rows.map((row, index) => {
                          // const isNormalUser =
                          //   row?.name !== "company-admin" &&
                          //   row?.isPrimaryUser===false;
                          // const isCompanyAdmin =
                          //   row?.name==="company-admin" &&
                          //   row?.isPrimaryUser===false;
                          // const isCompanyAdminAndPrimary =
                          //   row?.name==="company-admin" && row?.isPrimaryUser;

                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                            >
                              <CustomizedTableCell hover>
                                <Checkbox
                                  checked={deleteUsers?.includes(row.id)}
                                  onChange={(e) => {
                                    handleRolesChecked(e, index, row.id);
                                  }}
                                  disabled={
                                    row?.isPrimary ||
                                    user?.id === row?.User?.id ||
                                    row?.linkedUser
                                  }
                                  label=""
                                />
                              </CustomizedTableCell>
                              <CustomizedTableCell sx={{ cursor: "pointer" }}>
                                {row?.User?.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                sx={{ cursor: "pointer" }}
                                align="left"
                              >
                                {row?.User?.firstName !== null ||
                                row?.User?.lastName !== null
                                  ? row?.User?.firstName +
                                    " " +
                                    row?.User?.lastName
                                  : "--"}
                              </CustomizedTableCell>

                              <CustomizedTableCell align="left">
                                {row?.User.email}
                              </CustomizedTableCell>

                              <CustomizedTableCell align="left">
                                {row?.isPrimary === true ? (
                                  <Item className={classes.primaryChip}>
                                    <Typography
                                      className={classes.primaryChipTypo}
                                    >
                                      {t("Primary_text")}
                                    </Typography>
                                  </Item>
                                ) : row?.name !== undefined &&
                                  row?.name !== null ? (
                                  (
                                    row.name.charAt(0).toUpperCase() +
                                    row.name.slice(1)
                                  ).replaceAll("-", " ")
                                ) : (
                                  "--"
                                )}
                              </CustomizedTableCell>

                              <CustomizedTableCell
                                align="left"
                                sx={{
                                  width: "150px",
                                  color:
                                    row?.User?.inActive === true
                                      ? "#F8A92B"
                                      : row?.User?.status === "pending"
                                      ? "#E3963E"
                                      : "" || row?.User?.status === "active"
                                      ? "#13CF8F"
                                      : "" || row?.User?.status === "disable"
                                      ? "#FC3652"
                                      : "",
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    fontSize: 10,
                                    color:
                                      row?.User?.inActive === true
                                        ? "#F8A92B"
                                        : row?.User?.status === "pending"
                                        ? "##E3963E"
                                        : "" || row?.User?.status === "active"
                                        ? "#13CF8F"
                                        : "" || row?.User?.status === "disable"
                                        ? "#FC3652"
                                        : "",
                                    paddingRight: "3px",
                                  }}
                                />
                                {row?.User?.inActive === true
                                  ? t("Inactive")
                                  : row?.User?.status === "active"
                                  ? t("Active")
                                  : row?.User?.status === "pending"
                                  ? t("Pendingg")
                                  : row?.User?.status}
                              </CustomizedTableCell>

                              <CustomizedTableCell align="right">
                                {row?.User?.status === "pending" && (
                                  <>
                                    {editPermission && viewPermission ? (
                                      <BootstrapTooltip
                                        title={
                                          <h1 style={{ fontSize: "12px" }}>
                                            {t("resend_emails")}
                                          </h1>
                                        }
                                      >
                                        <IconButton
                                          aria-label="edit"
                                          size="large"
                                          onClick={() => {
                                            handleOpenResendModel();
                                            // handleOpenEditUser();
                                            setSelectedUser(row);
                                          }}
                                        >
                                          <Resend />
                                        </IconButton>
                                      </BootstrapTooltip>
                                    ) : (
                                      <BootstrapTooltip
                                        title={t("authorized_access")}
                                      >
                                        <span>
                                          <IconButton
                                            disabled
                                            aria-label="edit"
                                            size="large"
                                            onClick={() => {
                                              handleOpenResendModel();
                                              // handleOpenEditUser();
                                              setSelectedUser(row);
                                            }}
                                          >
                                            <Resend disabled />
                                          </IconButton>
                                        </span>
                                      </BootstrapTooltip>
                                    )}

                                    {row?.User?.UserInvites.length > 0 ? (
                                      editPermission && viewPermission ? (
                                        displayText ? (
                                          <BootstrapTooltip
                                            title={
                                              <h1 style={{ fontSize: "12px" }}>
                                                {t("Copied")}
                                              </h1>
                                            }
                                          >
                                            <IconButton
                                              aria-label="edit"
                                              size="large"
                                              sx={{ marginLeft: "5px" }}
                                            >
                                              <CopyIcon />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        ) : (
                                          <BootstrapTooltip
                                            title={
                                              <h1 style={{ fontSize: "12px" }}>
                                                {t("copy_invite_link")}
                                              </h1>
                                            }
                                          >
                                            <IconButton
                                              aria-label="edit"
                                              size="large"
                                              onClick={() => {
                                                handleCopyValidInviteLink(row);
                                              }}
                                              sx={{ marginLeft: "5px" }}
                                            >
                                              {copyValidInviteLinkLoading[
                                                row?.id
                                              ] ? (
                                                <CircularProgress
                                                  color="secondary"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                              ) : (
                                                <CopyIcon />
                                              )}
                                            </IconButton>
                                          </BootstrapTooltip>
                                        )
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <IconButton
                                              aria-label="edit"
                                              size="large"
                                              disabled={true}
                                              sx={{ marginLeft: "5px" }}
                                            >
                                              <CopyIcon disabled />
                                            </IconButton>
                                          </span>
                                        </BootstrapTooltip>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                                {isAdminAndPrimary &&
                                row?.linkedUser === false ? (
                                  editPermission && viewPermission ? (
                                    <BootstrapTooltip title={t("EditUser")}>
                                      <IconButton
                                        aria-label="edit"
                                        size="large"
                                        onClick={() => {
                                          handleNavigateEditUser(row?.id, row);
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ) : (
                                    <BootstrapTooltip
                                      title={t("authorized_access")}
                                    >
                                      <span>
                                        <IconButton
                                          aria-label="edit"
                                          size="large"
                                          disabled={true}
                                          onClick={() => {
                                            handleNavigateEditUser(row?.id);
                                            // handleOpenEditUser();
                                            // setSelectedUser(row);
                                          }}
                                        >
                                          <Edit disabled />
                                        </IconButton>
                                      </span>
                                    </BootstrapTooltip>
                                  )
                                ) : (
                                  isAdminAndPrimary === false &&
                                  row?.isPrimary === false &&
                                  row?.linkedUser === false &&
                                  (editPermission && viewPermission ? (
                                    <BootstrapTooltip title={t("EditUser")}>
                                      <IconButton
                                        aria-label="edit"
                                        size="large"
                                        onClick={() => {
                                          handleNavigateEditUser(row?.id);
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ) : (
                                    <BootstrapTooltip
                                      title={t("authorized_access")}
                                    >
                                      <span>
                                        <IconButton
                                          aria-label="edit"
                                          size="large"
                                          disabled={true}
                                          onClick={() => {
                                            handleNavigateEditUser(row?.id);
                                            // handleOpenEditUser();
                                            // setSelectedUser(row);
                                          }}
                                        >
                                          <Edit disabled />
                                        </IconButton>
                                      </span>
                                    </BootstrapTooltip>
                                  ))
                                )}

                                {/*   {user?.companyId !== row.companyId && (
                                  <CustomWidthTooltip title={t("Update_Role")}>
                                    <IconButton
                                      aria-label="edit"
                                      size="large"
                                      onClick={() => {
                                        handleOpenRoleModel();
                                        setSelectedUser(row);
                                      }}
                                    >
                                      <PersonIcon />
                                    </IconButton>
                                  </CustomWidthTooltip>
                                )} */}

                                <>
                                  {row?.linkedUser ? (
                                    <BootstrapTooltip title={t("Unlink_User")}>
                                      <IconButton
                                        onClick={() => {
                                          handleOpenLinkModel(row);
                                        }}
                                      >
                                        <InsertLinkIcon />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ) : (
                                    <IconButton
                                      disabled={
                                        isAdminAndPrimary === false &&
                                        row?.isPrimary === true
                                      }
                                      onClick={(e) =>
                                        handleClick(e, row.id, row)
                                      }
                                    >
                                      <MoreVertIcon
                                        disabled={
                                          isAdminAndPrimary === false &&
                                          row?.isPrimary === true
                                        }
                                      />
                                    </IconButton>
                                  )}

                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                  >
                                    {user?.id ===
                                    selectedRow?.User
                                      ?.id ? null : isAdminAndPrimary ? (
                                      deletePermission && viewPermission ? (
                                        <MenuItem
                                          onClick={() => {
                                            setDeleteId(selectedRow);
                                            handleOpenDeleteModel(
                                              true,
                                              selectedRow?.User?.id
                                            );
                                            closeMenu();
                                            handleClose();
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{
                                              color: "#545353",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          <Typography>{t("Delete")}</Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            {" "}
                                            <MenuItem
                                              disabled
                                              onClick={() => {
                                                setDeleteId(selectedRow);
                                                handleOpenDeleteModel(
                                                  true,
                                                  selectedRow?.User?.id
                                                );
                                                closeMenu();
                                                handleClose();
                                              }}
                                            >
                                              <DeleteIcon
                                                sx={{
                                                  color: "#545353",
                                                  paddingRight: "5px",
                                                }}
                                                disabled
                                              />
                                              <Typography>
                                                {t("Delete")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      )
                                    ) : (
                                      // isAdminAndPrimary===false &&
                                      selectedRow?.isPrimary === false &&
                                      (deletePermission && viewPermission ? (
                                        <MenuItem
                                          onClick={() => {
                                            setDeleteId(selectedRow);
                                            handleOpenDeleteModel(
                                              true,
                                              selectedRow?.User?.id
                                            );
                                            closeMenu();
                                            handleClose();
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{
                                              color: "#545353",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          <Typography>{t("Delete")}</Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            {" "}
                                            <MenuItem
                                              disabled
                                              onClick={() => {
                                                setDeleteId(selectedRow);
                                                handleOpenDeleteModel(
                                                  true,
                                                  selectedRow?.User?.id
                                                );
                                                closeMenu();
                                                handleClose();
                                              }}
                                            >
                                              <DeleteIcon
                                                sx={{
                                                  color: "#545353",
                                                  paddingRight: "5px",
                                                }}
                                                disabled
                                              />
                                              <Typography>
                                                {t("Delete")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      ))
                                    )}

                                    {isAdminAndPrimary &&
                                      selectedRow?.User?.inActive === false &&
                                      selectedRow?.User?.status === "active" &&
                                      selectedRow?.isPrimary === false && (
                                        <>
                                          <MenuItem
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              setOpenPrimaryModel(true);

                                              // handleOpenModal(row?.id);
                                            }}
                                          >
                                            <PersonIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography>
                                              {t("Make_Primary_User")}
                                            </Typography>
                                          </MenuItem>
                                        </>
                                      )}

                                    {selectedRow?.User?.status === "active" &&
                                      user?.id === selectedRow?.User?.id &&
                                      (editPermission && viewPermission ? (
                                        <MenuItem
                                          onClick={() => {
                                            closeMenu();

                                            handleClose();
                                          }}
                                        >
                                          <PasswordIcon
                                            sx={{
                                              color: "#545353",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          <Typography
                                            loading={loading}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            onClick={() =>
                                              setOpenResetModel(true)
                                            }
                                          >
                                            {t("Reset_password")}
                                          </Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <MenuItem
                                              disabled
                                              onClick={() => {
                                                closeMenu();

                                                handleClose();
                                              }}
                                            >
                                              <PasswordIcon
                                                disabled
                                                sx={{
                                                  color: "#545353",
                                                  paddingRight: "5px",
                                                }}
                                              />
                                              <Typography
                                                loading={loading}
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                onClick={() =>
                                                  setOpenResetModel(true)
                                                }
                                              >
                                                {t("Reset_password")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      ))}
                                    {selectedRow?.User?.status ===
                                      "pending" && (
                                      <>
                                        {editPermission && viewPermission ? (
                                          <MenuItem
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              handleOpenApproveModal(
                                                selectedRow?.User
                                              );
                                            }}
                                          >
                                            <CheckCircleIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography>
                                              {t("approve_user")}
                                            </Typography>
                                          </MenuItem>
                                        ) : (
                                          <BootstrapTooltip
                                            title={t("authorized_access")}
                                          >
                                            <span>
                                              {" "}
                                              <MenuItem
                                                disabled
                                                onClick={() => {
                                                  closeMenu();
                                                  handleClose();
                                                  handleOpenApproveModal(
                                                    selectedRow?.User
                                                  );
                                                }}
                                              >
                                                <CheckCircleIcon
                                                  disabled
                                                  sx={{
                                                    color: "#545353",
                                                    paddingRight: "5px",
                                                  }}
                                                />
                                                <Typography>
                                                  {t("approve_user")}
                                                </Typography>
                                              </MenuItem>
                                            </span>
                                          </BootstrapTooltip>
                                        )}
                                      </>
                                    )}
                                  </Menu>
                                </>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                      )}
                      <Modal
                        open={openModalEditUser}
                        // onClose={handleCloseModalEditUser}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Grid container>
                          <Grid item>
                            <Box sx={styleEditUser}>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                {t("EditUser")}
                              </Typography>

                              <EditUser
                                id={props.params}
                                locations={allLocations}
                                user={selectedUser}
                                Role={Roles}
                                Language={Languages}
                                onCancel={handleCloseModalEditUser}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Modal>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={users?.count}
                  rowsPerPage={rowsPerPage}
                  page={page !== undefined ? page : 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <>
                <NotFound text={t("User_Not_Found")} />
              </>
            )}
          </Box>
        </>
      )}
      <Modal
        open={openUpdateRole}
        onClose={handleCloseRoleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Grid item>
            <Box sx={style}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                {t("Update_Role")}
              </Typography>
              <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginTop: "8px",
                  }}
                  gutterBottom
                  component="div"
                >
                  {t("Select_Role")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={Roles}
                  sx={{ width: "100%" }}
                  onChange={(e, value) => {
                    setFieldValue(value);
                    setRoleRequired(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      name="role"
                      error={Boolean(roleRequired)}
                      helperText={roleRequired && "Role is Required"}
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Grid
                container
                spacing={3}
                justifyContent="flex-end"
                alignItems="flex-start"
                my={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  sx={{ display: "flex" }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CommonButton
                    displayWhite="true"
                    onSubmit={handleCloseRoleModal}
                    label={t("Cancel")}
                  />
                  <CommonButton
                    disabled={isLoading}
                    label={t("Save")}
                    onSubmit={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default Users;
