import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { Legend, CartesianGrid, XAxis, YAxis, LineChart, Line  } from 'recharts';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox, Typography, Box, Divider } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../Styles/style";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import BouncingDotsLoader from "../../../../../components/Loaders/smallBounceLoader";
import Loader from "../../../../../components/Loaders/Loader";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import { DominoSpinner } from "../../../../../components/Loaders/lineLoader";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const UserViews = ({
  googleInsightData,
  data,
  ratingLoading,
  metricsLoading,
  checkNumber,
  type,
  mobileMap,
  desktopMap,
  mobileSearches,
  desktopSearches,
  requestDirections,
  visit,
  call,
  kFormatter,
  totalValue,
  allLables,
  tooltipContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const [mapPercentage, setMapPercentage] = useState(0);
  const [desktopMapPercentage, setDesktopMapPercentage] = useState(0);
  const [desktopPercentage, setDesktopPercentage] = useState(0);

  const [mobileMapData, setMobileMapData] = useState(null);
  const [mobileSearchData, setMobileSearchData] = useState(null);
  const [desktopMapData, setDesktopMapData] = useState(null);
  const [desktopSearchData, setDesktopSearchData] = useState(null);
  // const [tooltipContent, setTooltipContent] = useState("");

  /*  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    const month1 = data?.labels[0];
    const month2 = data?.labels[data?.labels.length - 1];

    const isSameMonth = month1 === month2;

    const content = isSameMonth
      ? `vs ${month1}, ${previousYear}`
      : `vs ${month1}, ${previousYear} - ${month2}, ${previousYear}`;

    setTooltipContent(content);
  }, [data]); */

  useEffect(() => {
    if (googleInsightData !== null && googleInsightData?.length > 0) {
      const mobilePerData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_IMPRESSIONS_MOBILE_SEARCH"
      );
      setMobileSearchData(mobilePerData);
      if (mobilePerData !== undefined) {
        const mobileSearchPercentage = (
          (mobilePerData?.metricValue / totalValue) *
          100
        ).toFixed();

        setPercentage(mobileSearchPercentage);
      }

      const mobileMapPerData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_IMPRESSIONS_MOBILE_MAPS"
      );
      setMobileMapData(mobileMapPerData);
      if (mobileMapPerData !== undefined) {
        const mobilePercentageSearch = (
          (mobileMapPerData?.metricValue / totalValue) *
          100
        ).toFixed();
        setMapPercentage(mobilePercentageSearch);
      }

      const desktopPerData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH"
      );
      setDesktopSearchData(desktopPerData);
      if (desktopPerData !== undefined) {
        const desktopPercentageSearch = (
          (desktopPerData?.metricValue / totalValue) *
          100
        ).toFixed();
        setDesktopPercentage(desktopPercentageSearch);
      }

      const desktopMapPerData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_IMPRESSIONS_DESKTOP_MAPS"
      );
      setDesktopMapData(desktopMapPerData);
      if (desktopMapPerData !== undefined) {
        const desktopPercentageSearch = (
          (desktopMapPerData?.metricValue / totalValue) *
          100
        ).toFixed();
        setDesktopMapPercentage(desktopPercentageSearch);
      }
    } else {
      setMobileSearchData(null);
      setPercentage(0);
      setMobileMapData(null);
      setMapPercentage(0);
      setDesktopSearchData(null);
      setDesktopPercentage(0);
      setDesktopMapData(null);
      setDesktopMapPercentage(0);
    }
  }, [googleInsightData, totalValue]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <Grid container className={classes.resultsMainGrid}>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12}>
            {" "}
            {/* <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
              {t("User_Views")}
            </Typography> */}
            {ratingLoading ? (
              <BouncingDotsLoader />
            ) : (
              <Typography className={classes.totalValueText}>
                {kFormatter(totalValue)}
              </Typography>
            )}
            <Typography className={classes.totalValuesubText}>
              <VisibilityIcon
                fontSize="small"
                className={classes.visibilityIcon}
              />{" "}
              {t("Profile_subtext")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          {" "}
          <Typography className={classes.headingChartBox}>
            {/*  <CustomGoogleMapCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Mobile_map")}{" "}
            <Typography className={classes.headingMobileMap}>
              {" "}
              {isNaN(mapPercentage) || mapPercentage === "Infinity"
                ? "0"
                : mapPercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("Text_tooltip1")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>
          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {mobileMapData !== null
                  ? kFormatter(mobileMapData?.metricValue)
                  : "0"}
              </Typography>
              {mobileMapData?.BUSINESS_IMPRESSIONS_MOBILE_MAPS_PERCENTAGE !==
                "NaN" &&
                mobileMapData?.BUSINESS_IMPRESSIONS_MOBILE_MAPS_PERCENTAGE !==
                  "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {mobileMap === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          mobileMap === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {mobileMapData !== null
                          ? checkNumber(
                              mobileMapData?.BUSINESS_IMPRESSIONS_MOBILE_MAPS_PERCENTAGE !==
                                "NaN"
                                ? mobileMapData?.BUSINESS_IMPRESSIONS_MOBILE_MAPS_PERCENTAGE
                                : "0",
                              "mobileMap"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Typography
            className={classes.headingChartBox}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {/*  <CustomGoogleSearchCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Mobile_Searches")}{" "}
            <Typography className={classes.headingMobile}>
              {" "}
              {isNaN(percentage) || percentage === "Infinity"
                ? "0"
                : percentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltipText5")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {" "}
                {/*  {graphData?.VIEWS_SEARCH} */}
                {mobileSearchData !== null
                  ? kFormatter(mobileSearchData?.metricValue)
                  : "0"}
              </Typography>
              {mobileSearchData?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH_PERCENTAGE !==
                "NaN" &&
                mobileSearchData?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH_PERCENTAGE !==
                  "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {mobileSearches === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          mobileSearches === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {mobileSearchData !== null
                          ? checkNumber(
                              mobileSearchData?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH_PERCENTAGE !==
                                "NaN"
                                ? mobileSearchData?.BUSINESS_IMPRESSIONS_MOBILE_SEARCH_PERCENTAGE
                                : "0",
                              "mobileSearches"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3}>
          {" "}
          <Typography className={classes.headingChartBox}>
            {/*  <CustomGoogleMapCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Desktop_Maps")}{" "}
            <Typography className={classes.headingDesktopMap}>
              {" "}
              {isNaN(desktopMapPercentage) ||
              desktopMapPercentage === "Infinity"
                ? "0"
                : desktopMapPercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("Text_tooltip7")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>
          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {desktopMapData !== null
                  ? kFormatter(desktopMapData?.metricValue)
                  : "0"}
              </Typography>
              {desktopMapData?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS_PERCENTAGE !==
                "NaN" &&
                desktopMapData?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS_PERCENTAGE !==
                  "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {desktopMap === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          desktopMap === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {desktopMapData !== null
                          ? checkNumber(
                              desktopMapData?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS_PERCENTAGE !==
                                "NaN"
                                ? desktopMapData?.BUSINESS_IMPRESSIONS_DESKTOP_MAPS_PERCENTAGE
                                : "0",
                              "desktopMap"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Typography className={classes.headingChartBox}>
            {/*  <CustomGoogleSearchCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Desktop_Searches")}{" "}
            <Typography className={classes.headingDesktop}>
              {" "}
              {isNaN(desktopPercentage) || desktopPercentage === "Infinity"
                ? "0"
                : desktopPercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltipText8")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {" "}
                {/*  {graphData?.VIEWS_SEARCH} */}
                {desktopSearchData !== null
                  ? kFormatter(desktopSearchData?.metricValue)
                  : "0"}
              </Typography>
              {desktopSearchData?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH_PERCENTAGE !==
                "NaN" &&
                desktopSearchData?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH_PERCENTAGE !==
                  "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {desktopSearches === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          desktopSearches === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {desktopSearchData !== null
                          ? checkNumber(
                              desktopSearchData?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH_PERCENTAGE !==
                                "NaN"
                                ? desktopSearchData?.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH_PERCENTAGE
                                : "0",
                              "desktopSearches"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12} className={classes.graphGrid}>
        {metricsLoading ? (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
              alignItems: "center",
              borderLeft: "1px solid #9d9d9d",
              borderBottom: "1px solid #9d9d9d",
              paddingX: "8px",
            }}
          >
            <WaveSpinner />

            {/*   <DominoSpinner /> */}
          </Box>
        ) : (
          <Line data={data} options={options} height={100} />
        )}
      </Grid>
    </>
  );
};

export default UserViews;
