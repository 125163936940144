import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  TextField,
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  createFilterOptions,
} from "@mui/material";
import Loader from "../../../../../components/Loaders/Loader";

import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { languagesData } from "../../../../../assets/defaultLanguage";
import { toast } from "react-toastify";
import { api } from "../../../../../contexts/JWTContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  // maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  // maxHeight: "87vh",
  width: "550px",
  boxShadow: 24,
  p: 4,
  // overflowY: "scroll",
};

const BulkUpdateModel = ({
  handleCloseBlukUpdateModel,
  openEditBulkModel,
  getAllLocation,
  locations,
  selectedLocation,
  setSelectedLocation,
  email,
  setEmail,
  selectLanguage,
  setSelectLanguage,
  setLocationRequired,
  locationRequired,
  setDisconnectLocations,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const [selectAll, setSelectAll] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  useEffect(() => {
    if (openEditBulkModel === true) {
      getAllLocations();
    }
  }, [openEditBulkModel]);

  const submitBulkUpdate = async () => {
    if (selectedLocation?.length === 0) {
      setLocationRequired(true);
    } else {
      setLocationRequired(false);
    }
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds.push(item?.id);
      });
    }
    if (selectedLocation?.length > 0) {
      setBulkLoading(true);
      try {
        const res = await api.patch(
          `/location/bulkedit-locations${selectAll ? "?type=all" : ""}`,
          {
            locations: locationIds,
            defaultLanguage: selectLanguage,
            businessEmail: email,
          }
        );
        if (res.status === 200) {
          handleCloseBlukUpdateModel();
          setBulkLoading(false);
          setEmail(null);
          setSelectLanguage("en");
          setSelectedLocation([]);
          toast.success(res?.data?.message);
          setDisconnectLocations([]);
          getAllLocation();
        }
      } catch (error) {
        setBulkLoading(false);
        handleCloseBlukUpdateModel();
        setEmail(null);
        setSelectLanguage("en");
        setSelectedLocation([]);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: true,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          locations?.map((item) => {
            res?.data?.data?.results?.forEach((element) => {
              if (element?.id === item) {
                selectedLocation.push(element);
              }
            });
          });
          setSelectedLocation([...selectedLocation]);
        }
        setAllCompanies(res.data.data?.results);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={openEditBulkModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "28px",
                  marginBottom: "8px",
                  marginTop: "8px",
                }}
              >
                {t("bulk_edit_location")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {isLoading ? (
            <Grid
              container
              sx={{
                height: "350px",
                paddingTop: "15px",
                paddingBottom: "15px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Loader />
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                paddingTop: "10px",
                paddingBottom: "15px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      lineHeight: "20px",
                      marginTop: "15px",
                    }}
                  >
                    {t("Select_Locations")}
                  </Typography>

                  <Autocomplete
                    multiple
                    error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    disableCloseOnSelect
                    options={allCompanies?.length > 0 ? allCompanies : []}
                    value={selectedLocation ? selectedLocation : []}
                    onChange={(e, value) => {
                      const selectAllObj = value?.find(
                        (item) => item?.internalName === "Select All"
                      );
                      if (selectAllObj !== null && selectAllObj !== undefined) {
                        setSelectedLocation(allCompanies);
                      } else {
                        setSelectedLocation(value);
                      }

                      if (value?.length > 0) {
                        setLocationRequired(false);
                      } else {
                        setLocationRequired(true);
                      }
                      // handleLocationChange(value);

                      if (value.find((option) => option.id === 0)) {
                        // Check if all options are selected
                        if (allCompanies?.length === selectedLocation?.length) {
                          setSelectedLocation([]);
                          setSelectAll(false);
                        } else {
                          setSelectAll(true);
                          setSelectedLocation(allCompanies);
                          //  setLocationRequired(false);
                        }
                      } else {
                        setSelectedLocation(value);
                        if (value?.length === allCompanies?.length) {
                          setSelectAll(true);
                        } else {
                          setSelectAll(false);
                        }
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (allCompanies?.length > 0) {
                        return [
                          { id: 0, internalName: "Select All" },
                          ...filtered,
                        ];
                      } else {
                        return [];
                      }
                    }}
                    // defaultValue={selectedLocation}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                          " " +
                          "(" +
                          (option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                        : ""
                    }
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    // onChange={(e, value) => {
                    //   setLocationRequired(false);

                    //   setSelectedLocation(value);
                    // }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          id="select-all-checkbox"
                          checked={selectAll || selected}
                          // checked={selected}
                        />
                        {`${option?.internalName} ${
                          option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null &&
                          option?.addressLine1 !== undefined
                            ? "(" + option?.addressLine1 + "," + " "
                            : ""
                        } ${option?.city ? option?.city + ")" : ""}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        error={locationRequired === true}
                        helperText={locationRequired && t("Field_req_loc")}
                        // label={t("Select Location")}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item sm={12} md={12}>
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      lineHeight: "20px",
                      marginTop: "15px",
                    }}
                  >
                    {t("Email_Address_Buisness")}
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      //  disabled={isLoading}
                      type="email"
                      name="businessEmail"
                      placeholder="e.g.  john@obenan.com"
                      value={email}
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          if (
                            /^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/.test(
                              e.target.value
                            )
                          ) {
                            setValidEmail(false);
                          } else {
                            setValidEmail(true);
                          }
                        } else {
                          setValidEmail(false);
                        }

                        setEmail(e.target.value);
                      }}
                      error={validEmail}
                      helperText={validEmail ? t("valid_email_t") : ""}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item sm={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginTop: "15px",
                  }}
                >
                  {t("Default_Review")}
                </Typography>
                <Grid
                  container
                  sx={{
                    marginBottom: "1rem",
                    width: "100%",
                  }}
                >
                  <Grid item sm={12} md={12}>
                    <Box sx={{ width: "100%" }}>
                      <Select
                        required
                        defaultValue={"en"}
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={languagesData?.language}
                        // label="Select"
                        onChange={(e, value) => {
                          setSelectLanguage(e.target.value);
                        }}
                      >
                        {languagesData.map((item) => (
                          <MenuItem value={item.value}>
                            {item.language}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Divider />
          <Grid container spacing={1} sx={{ paddingTop: "10px" }}>
            <Grid item spacing={2} xs={12} md={12}>
              <Box sx={{ float: "right" }}>
                <CommonButton
                  label={t("Cancel_Button")}
                  displayWhite="true"
                  onSubmit={handleCloseBlukUpdateModel}
                />
                &nbsp;&nbsp;
                <CommonButton
                  isLoading={bulkLoading}
                  label={t("Submit")}
                  disabled={validEmail}
                  onSubmit={submitBulkUpdate}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default BulkUpdateModel;
