import { makeStyles } from "@mui/styles";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed: "#FF0000",
};

export const useStyles = makeStyles((theme) => ({
  box: {
    height: "100vh",
    width: "500px",
    bgcolor: "background.paper",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  cardbox: {
    maxHeight: "100vh",
    height: "auto",
    overflowY: "scroll",
    maxWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },

  cardcontent: {
    marginBottom: "8px",
    // bgcolor: "white",
    display: "flex",
    padding: "8px",
    cursor: "pointer",

    alignItems: "center",
    justifyContent: "space-between",
  },
  titleName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: color?.darkGrey,
  },
  subheadName: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
    textAlign: "center",
  },
  locationAddress: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color.grey,
    // textAlign: "center",
  },
  subheadNavigateCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color.darkGrey,
  },
  subheadGoogleName: {
    fontWeight: 700,
    fontSize: "20px",
    color: color.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    // marginBottom: "16px",
  },
  headLocationName: {
    fontWeight: 700,
    fontSize: "18px",
    color: color.darkGrey,
    textTransform: "capitalize",
  },
  headRatingName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color.darkGrey,
  },
  subHeadRatingName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "10px",
    color: color.darkGrey,
  },
  subHeadLocationName: {
    fontWeight: 400,
    fontSize: "14px",
    color: color.grey,
  },
  locationListCard: {
    backgroundColor: theme.palette.mode === "dark" ? "#20232a" : "white",
    borderRadius: "8px",
    height: "100%",
  },

  fieldhead: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.mode === "dark" ? "#FFF" : color?.darkGrey,
    padding: "8px 8px 1px 8px",
  },
  nameContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //   color: "#0300FF",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconStyle: {
    color: color?.grey,
    marginRight: "5px",
  },
  editButton: {
    padding: "14px 20px",
    width: "140px",
    height: "48px",
    background: color?.blue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ratingContainer: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  commonButton: {
    marginRight: "5px",
  },

  cardcontent_map: {
    marginBottom: "8px",
    bgcolor: color?.white,
    display: "flex",
    padding: "14px",

    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    cursor: "pointer",
  },
  titleName_map: {
    fontWeight: 600,
    fontSize: "16px",
    color: color?.white,
    lineHeight: "24px",
  },
  subheadName_map: {
    fontWeight: 400,
    color: color?.white,
    fontSize: "14px",
    lineHeight: "24px",
  },

  locationContainer_map: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "relative",
    bottom: 100,
    borderRadius: "8px",
    padding: "20px",
  },

  heading: {
    fontWeight: 400,
    fontSize: "11px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "10px",
    color: color.grey,
  },

  chartSubHeading: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",
    color: color?.darkGrey,
  },
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: color?.lightGrey,
  },

  chartSubHeadingPercentNegative: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositive: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },

  locationDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  percentageBox: {
    display: "flex",
    paddingTop: "10px",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardNavigationcontent: {
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    height: "120px",
  },
  subheadNavigationCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color?.darkGrey,
    lineHeight: "24px",
  },
  LinkStyle: {
    textDecoration: "none",
  },
  linkText: {
    textTransform: "capitalize",
    textDecoration: "none",
    display: "flex",
    color: color?.darkBlue,
  },
  headLocationNameRating: {
    fontWeight: 700,
    fontSize: "18px",
    color: color?.darkGrey,
    textTransform: "capitalize",
  },

  editButtonMap: {
    padding: "14px 20px",
    width: "100%",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  containergraphconfig: {
    opacity: "0.4",
  },
  headingPieChart: {
    fontWeight: 400,
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "2px",

    color: color.grey,
  },

  pieChartSubHeading: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",

    color: color?.darkGrey,
  },
  chartSubHeadingconfig: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",
    color: color?.lightGray,
  },

  locationPieChartDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "20px",
  },
  subheadGoogleNamePie: {
    fontWeight: 700,
    fontSize: "20px",
    color: color?.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    // marginBottom: "16px",
  },
  pieChartDiv: {
    width: "80%",
    margin: "auto",
  },
  namePieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.skyBlue,
    marginRight: "5px",
    borderRadius: "50%",

    display: "inline-block",
  },
  categoryPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.lightBlue,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  brandPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.mustard,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  percentageBoxPie: {
    display: "flex",
    paddingTop: "10px",
    marginLeft: "10px",
  },
  chartSubHeadingPercentNegativePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositivePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },
  mainGridPieChart: {
    display: "flex",
  },
  cardReviews: {
    backgroundColor: color?.gray,
    borderRadius: "none",

    height: "auto",
  },

  headReviewsName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color?.darkGrey,
  },
  subHeadReviewsName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "14px",
    color: color.grey,
  },
  subHeadReviewsNameBox: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: color?.darkGrey,
    marginTop: "10px",
    marginBottom: "4px",
  },
  headingAccount: {
    fontSize: "18px",
    fontWeight: 600,
  },
  cardContentAccount: {
    height: "auto",
    width: "100%",
    padding: "30px",
  },
  accountsContainer: {
    paddingTop: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  accountsDiv: {
    padding: "10px",
    display: "flex",
  },
  accountName: {
    fontSize: "13px",
    fontWeight: 600,
    color: color?.graphite,
    textTransform: "capitalize",
  },
  buttonContainerAccount: {
    padding: "10px",
  },
  locationBoxAccount: {
    marginTop: "100px",
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },

  errorHeadingAccount: {
    fontSize: "16px",
    fontWeight: 700,
  },
  errorHeadingTextAccount: {
    fontSize: "14px",
    fontWeight: 600,
    color: color?.graphite,
  },

  MainBoxLocation: {
    padding: "24px",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
  connectBoxLocation: {
    borderRadius: "8px",
    backgroundColor: color?.gray,
    padding: "24px",
    marginBottom: "8px",
  },

  UnsubscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.darkPink,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.magenta,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  subscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "94px",
    height: "40px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  reconnectButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: "10px",
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  MainboxProduct: {
    padding: "24px",
  },

  subscriptionProductContainer: {
    marginTop: "20px",
  },
  mainContainerPost: {
    background: color?.lightPink,
    padding: "2px 16px",
    width: "80%",
    borderRadius: "7px",
    marginBottom: "10px",
    alignItems: "center",
    height: "auto",
    "&:hover ": {
      background: color?.babypink,
    },
  },
  errorTextPost: {
    fontWeight: 400,
    // fontSize: "0.8125rem",
    lineHeight: "20px",
    color: color?.maroon,
    fontSize: "14px",
    padding: "8px 5px",
  },
  errorIconPost: {
    color: color?.red,
    marginTop: "5px",
  },
  arrowIconPost: {
    color: color?.maroon,
    paddingTop: "5px",
    cursor: "pointer",
  },
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: color?.darkGray,
    backgroundColor: color?.darkGraphite,
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  reviewsTabs: {
    textTransform: "none",
    marginTop: "30px",
  },
  mainContainerReviews: {
    padding: "20px",
  },

  sourceImage: {
    width: "80px",
    height: "80px",
  },
  sourceNameReview: {
    //   fontFamily: "Work Sans",
    padding: "30px 0px 20px 10px",
    fontWeight: 800,
    height: "100%",
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  UnsubscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "117px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.darkBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  cancelButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "100px",
    height: "48px",
    background: color?.white,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkGrey,
    border: "1px solid #E0E0E0",
    "&:hover ": {
      background: "#c2cbcf",
      borderRadius: "8px",
      color: "white",
    },
  },
  errorText: {
    fontWeight: 400,
    // fontSize: "0.8125rem",
    lineHeight: "20px",
    color: "#330404",
    fontSize: "1px",
    padding: "8px 5px",
  },

  actionButtons: {
    padding: "20px 16px",
    gap: "16px",
    width: "100%",
    height: "100%",
    background: color?.white,
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  errorGrid: {
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 5px 0px 5px",
    cursor: "pointer",
  },
  subscribedText: {
    textAlign: "center",
    background: color?.lightBlue,
    color: color?.white,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    padding: "3px 6px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  mainContainer: {
    padding: "20px",
  },
  mainErrorContainer: {
    padding: "20px",
    width: "95%",
    margin: "10px 5px 5px 10px",
    background: color?.lightPink,
    borderRadius: "8px",
  },

  sourceName: {
    //   fontFamily: "Work Sans",
    padding: "30px 0px 20px 10px",
    fontWeight: 800,
    height: "100%",
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "capitalize",
  },
  UnsubscribeButtonReview: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "134px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  retryText: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkBlue,
  },
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: color?.white,
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainerPost: {
    padding: "20px 0px 10px 0px",
  },
  iconStylePost: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  postDialog: {
    padding: "20px",
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  postLoadingButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "auto",
    height: "48px",
    color: color?.darkGrey,
    border: "1px solid #c4c4c4",
    borderRadius: "8px",
    marginRight: "10px",
  },
  PostAiButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "136px",
    height: "48px",
    borderRadius: "8px",
  },
  postSubmitButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "98px",
    height: "48px",
    borderRadius: "8px",
  },
  editPostBox: {
    marginY: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  },

  lazyLoadBox: {
    display: "row",
    justifyContent: "center",
    gap: "8px",
    paddingY: "12px",
  },
  locationTypo: {
    fontSize: "14px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  dateTimeTypo: {
    fontSize: "14px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginBottom: "8px",
    marginTop: "8px",
  },

  lengthErrorTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkRed,
    lineHeight: "15px",
    marginBottom: "8px",
  },
  postEditBox: {
    bgcolor: color?.gray,
    width: "100%",
    borderRadius: "8px",
    marginY: "8px",
    ml: 2,
  },
  googleCTATypo: {
    fontSize: "18px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "24px",
    marginBottom: "8px",
    marginTop: "8px",

    width: "100%",
  },
  postButtonBox: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    mt: 2,
  },
  postCardDesc: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginBottom: "8px",
    ml: 2,
  },
  textLimitTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkRed,
    lineHeight: "15px",
    marginBottom: "8px",
  },
  IconButtonDeletePost: {
    color: "red",
    width: "20px",
    height: "18px",
    mt: 4,
  },
  postAIBox: {
    bottom: "10px",
    width: "100%",
    position: "absolute",
  },
  googleBox: {
    bgcolor: color?.gray,
    width: "100%",
    borderRadius: "8px",
    marginY: "8px",
  },
  bulkEdit: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: "8px",
    color: color?.darkGrey,
  },
  bulkEditSubHead: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
  },
  postLogCard: {
    border: "1px solid #E0E0E0",
    bgcolor: color?.white,
    justifyContent: "space-around",
    mt: 4,
    mb: 4,
    height: "auto",
  },
  postLogsContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  postLogsTypo: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: color?.grey,
  },
  postLogTimePost: {
    fontWeight: 700,
    fontSize: "24px",
    color: color?.darkGrey,
    lineHeight: "32px",
  },
  postLogsPostContainer: {
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  postBackground: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    paddingX: "24px",
  },
  postCard: {
    maxWidth: "550px",
    textAlign: "center",
    bgcolor: "#f7f9fc",
    borderRadius: "8px",
  },
  postTypoMain: {
    fontSize: 14,
    textAlign: "center",
    mt: 4,
  },
  postMainDescriptionTypo: {
    fontWeight: "700",
    fontSize: "14px",
    color: color?.white,
    width: "1,532px",
    textAlign: "center",
    backgroundColor: color?.darkBlue,
    paddingY: "14px",
    // paddingX: "20px",
    borderRadius: "8px",
    marginTop: "24px",
    // marginX: "24px",
    cursor: "pointer",
  },
  editPostCard: {
    border: "3px solid blue",
    paddingX: "14px",
    borderRadius: "8px",
  },
  postStack: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
    marginBottom: "24px",
    marginRight: "24px",
  },
  postcancelButton: {
    fontWeight: "700",
    size: "large",
    color: color?.darkGrey,
    borderRadius: "8px",
    padding: " 14px 20px",
    border: "1px solid #E0E0E0",
    hover: "none",
  },
  jobLoadingButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "auto",
    height: "48px",
    color: "white",
    borderRadius: "8px",
    marginRight: "10px",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
}));
