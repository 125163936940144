import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import EditModal from "../../Modal";
import data from "./data.json";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Keywordtags from "./Components/keywordsTag";
import BouncingDotsLoader from "../../../../../../components/Loaders/smallBounceLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";
import Tags from "./Components/keywordsTag";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Companydescription({
  locationDetailsData,
  location,
  getLocationDetails,
  locationCategory,
  setLocationDetailsData,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const hotelCategoryArr = [
    "categories/gcid:hotel",
    "categories/gcid:extended_stay_hotel",
    "categories/gcid:resort_hotel",
    "categories/gcid:capsule_hotel",
    "categories/gcid:love_hotel",
    "categories/gcid:hostel",
    "categories/gcid:motel",
    "categories/gcid:resort_hotel",
    "categories/gcid:lodge",
    "categories/gcid:camping_cabin",
    "categories/gcid:inn",
    "categories/gcid:guest_house",
    "categories/gcid:farm",
    "categories/gcid:religious_lodging",
    "categories/gcid:apartment_building",
    "categories/gcid:apartment_complex",
    "categories/gcid:apartment_rental_agency",
    "categories/gcid:chalet",
    "categories/gcid:cottage",
    "categories/gcid:villa",
    "categories/gcid:campground",
  ];

  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          //  onClose={handleCloseTemplateMenu}
        >
          <EditModal
            modalIndex={7}
            location={location}
            getLocationDetails={getLocationDetails}
            getLocationDetailsListing={getLocationDetailsListing}
            locationDetailsData={locationDetailsData}
            onCancel={handleCloseTemplateMenu}
            setLocationDetailsData={setLocationDetailsData}
            locationCategory={locationCategory}
            lodgingCategories={lodgingCategories}
            categoryLoading={categoryLoading}
            centerData={centerData}
            displayMapError={displayMapError}
          />
        </Dialog>
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {isLoading ? (
          <BouncingDotsLoader />
        ) : (
          <>
            {locationDetailsData !== null ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container sx={{ padding: "16px" }}>
                    <Grid item xs={8} sm={8} md={10} lg={11}>
                      <Typography className={classes.titleName}>
                        {t("Company_Description")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={1}>
                      {editPermission && viewPermission ? (
                        <Button fullWidth onClick={handleOpenTemplateMenu}>
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Edit")}
                          </Typography>
                        </Button>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Button
                              disabled
                              fullWidth
                              sx={{ cursor: "pointer" }}
                              onClick={handleOpenTemplateMenu}
                            >
                              <Typography sx={{ fontWeight: 600 }}>
                                {t("Edit")}
                              </Typography>
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: "16px" }}>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Keywords")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      md={7}
                      lg={7}
                      sx={{ width: "40px" }}
                    >
                      <Tags locationDetailsData={locationDetailsData} />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container sx={{ padding: "16px" }}>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Short_Description")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7} lg={7}>
                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.shortDescription
                          ? locationDetailsData?.result?.shortDescription
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container sx={{ padding: "16px" }}>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Long_Description")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7} lg={7}>
                      <Typography className={classes.headName}>
                        {locationDetailsData?.result?.google?.profile
                          ?.description
                          ? locationDetailsData?.result?.google?.profile
                              ?.description
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NotFound text={t("No_Data_Found")} />
            )}
          </>
        )}
      </Card>
    </>
  );
}
export default Companydescription;
