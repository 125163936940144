import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Table = ({
  row,
  index,
  handleSubClickOpen,
  handleConnectWithGoogle,
  //   handleNavigation,
  setOpen,
  setDeleteId,
  closeMenu,
  handleOpenAcceptModel,
  handleOpenRejectModel,
  handleClick,
  handleClose,
  selectedRow,
  anchorEl,
  handleTaskChecked,
  selectedLocationData,
  deleteTable,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [googleConnect, setGoogleConnect] = useState(null);
  useEffect(() => {
    row?.socialLink?.length > 0 &&
      row?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
      });
  }, []);
  let address = "";
  if (row?.addressLine1 && !row?.city) address = row?.addressLine1;
  else if (!row?.addressLine1 && row?.city) address = row?.city;
  else if (!row?.addressLine1 && !row?.city) address = "--";
  else address = `${row?.addressLine1}, ${row?.city}`;

  return (
    <>
      <CustomizedTableRow
        // hover
        tabIndex={-1}
        key={`${row.id}-${index}`}
      >
        {deleteTable === false && (
          <CustomizedTableCell hover>
            <Checkbox
              checked={selectedLocationData.includes(row.id)}
              onChange={(e) => {
                handleTaskChecked(e, index, row.id, row, deleteTable);
              }}
              //   inputProps={{ "aria-label": "controlled" }}
              label=""
            />
          </CustomizedTableCell>
        )}
        <CustomizedTableCell>{row.id}</CustomizedTableCell>
        <CustomizedTableCell align="left">
          {row.internalName}
        </CustomizedTableCell>
        <CustomizedTableCell align="left">
          {row?.company !== null ? row?.company?.name : "--"}
        </CustomizedTableCell>

        <CustomizedTableCell align="left" sx={{ width: "300px" }}>
          {address}
        </CustomizedTableCell>

        {/* <TableCell align="left">{getSubscription}</TableCell> */}

        {/*  <CustomizedTableCell align="left">
                          {row.status}
                        </CustomizedTableCell> */}
        <CustomizedTableCell padding="none" align="center">
          <Box mr={2}>
            <IconButton onClick={(e) => handleClick(e, row)} variant="outlined">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  closeMenu();
                  //  handleClose();
                  handleOpenAcceptModel(selectedRow?.id, deleteTable);
                }}
              >
                {t("accept")}
              </MenuItem>

              <Divider variant="middle" />
              <MenuItem
                onClick={() => {
                  closeMenu();
                  //  handleClose();
                  handleOpenRejectModel(selectedRow?.id, deleteTable);
                }}
              >
                {t("Reject")}
              </MenuItem>
            </Menu>
          </Box>
        </CustomizedTableCell>
      </CustomizedTableRow>
    </>
  );
};

export default Table;
