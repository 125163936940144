import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import Brandtags from "./Components/brandTags";
import { useStyles } from "../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function Locationbrands({
  locationDetailsData,
  location,
  locationCategory,
  getLocationDetails,
  setLocationDetailsData,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          //  onClose={handleCloseTemplateMenu}
        >
          <EditModal
            modalIndex={11}
            onCancel={handleCloseTemplateMenu}
            location={location}
            locationCategory={locationCategory}
            locationDetailsData={locationDetailsData}
            getLocationDetails={getLocationDetails}
            setLocationDetailsData={setLocationDetailsData}
            getLocationDetailsListing={getLocationDetailsListing}
            lodgingCategories={lodgingCategories}
            categoryLoading={categoryLoading}
            centerData={centerData}
            displayMapError={displayMapError}
          />
        </Dialog>
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {locationDetailsData !== null ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container sx={{ padding: "16px" }}>
                <Grid item xs={8} sm={8} md={9} lg={9}>
                  <Typography className={classes.titleNameCategories}>
                    {t("Additional_Categories")}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={3}>
                  {editPermission && viewPermission ? (
                    <Button fullWidth>
                      <Typography
                        onClick={handleOpenTemplateMenu}
                        sx={{ fontWeight: 600 }}
                      >
                        {t("Edit")}
                      </Typography>
                    </Button>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <Button disabled fullWidth>
                          <Typography
                            onClick={handleOpenTemplateMenu}
                            sx={{ fontWeight: 600 }}
                          >
                            {t("Edit")}
                          </Typography>
                        </Button>
                      </span>
                    </BootstrapTooltip>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "16px" }}
              >
                <Brandtags locationDetailsData={locationDetailsData} />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <NotFound text={t("No_Data_Found")} />
        )}
      </Card>
    </>
  );
}
export default Locationbrands;
