import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Typography,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import { useStyles } from "../styles/styles";
import { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useAuth();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (location?.state !== null) {
      setShowMessage(true);
      setEmail(location?.state?.email);
    }
    if (location?.state === null && window.location.href.includes("email")) {
      const url = new URL(window.location.href);

      // Get email and password from URL parameters
      const emailParam = url.searchParams.get("email");

      const passwordParam = url.searchParams.get("password");
      let emailParamFormatted = emailParam?.includes(" ")
        ? emailParam?.replace(" ", "+")
        : emailParam;
      handleLogin(emailParamFormatted, passwordParam);
      // setEmail(emailParam);
      //  setPassword(passwordParam);
    }

    setTimeout(() => {
      setShowMessage(false);
      location.state = null;
    }, 3000);
  }, []);

  const handleLogin = async (userEmail, userPassword) => {
    try {
      setLoading(true);
      setShowMessage(false);
      location.state = null;
      const response = await signIn(userEmail, userPassword);

      if (response.status !== 200) {
        throw new Error(response?.data?.message);
      }

      setLoading(false);
    } catch (error) {
      if (error?.message) {
        const message = error.message || "Something went wrong";
        //  setStatus({ success: false });
        //  setErrors({ submit: message });
        //setSubmitting(false);

        toast.error(error.message);

        setLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: location?.state !== null ? location?.state?.email : email,
        password: password !== "" ? password : "",
        submit: true,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("email_validation_text"))
          .max(255)
          .required(t("Email_required_text"))
          .matches(/\S+@\S+\.\S+/, `Must be a valid email`),
        password: Yup.string().max(255).required(t("Password_required_text")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLoading(true);
          setShowMessage(false);
          location.state = null;
          const response = await signIn(values.email, values.password);

          if (response.status !== 200) {
            throw new Error(response?.data?.message);
          }

          setLoading(false);
        } catch (error) {
          if (error?.message) {
            const message = error.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
            toast.error(error.message);
            setLoading(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* <Alert mt={3} mb={3} severity="info">
            Use <strong>demo@bootlab.io</strong> and{" "}
            <strong>unsafepassword</strong> to sign in
          </Alert> */}
          {/* {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )} */}
          {showMessage && (
            <Grid container className={classes.mainContainer}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.errorText}>
                    {t("company_exist_message")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <TextField
            disabled={loading}
            type="email"
            name="email"
            placeholder={t("signup_placeholder_text")}
            label={t("Email")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autocomplete="off"
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={4}
          />
          <TextField
            disabled={loading}
            type="password"
            name="password"
            placeholder="********"
            label={t("Password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={4}
          />
          <Grid
            container
            sx={{
              display: "flex",
              margingTop: "8px",
              marginBottom: "16px",
            }}
          >
            <Grid
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={loading}
                    value="remember"
                    color="primary"
                  />
                }
                label={t("Remember_me")}
              />
            </Grid>

            <Grid
              item
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                disabled={loading}
                component={Link}
                to="/auth/reset-password"
                color="primary"
              >
                {t("Forgot_password")}
              </Button>
            </Grid>
          </Grid>

          <LoadingButton
            type="submit"
            loading={loading}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            className={classes.signInButton}
          >
            {t("Sign_in")}
          </LoadingButton>

          <Typography
            className={classes.signInLabel}
            disabled={loading}
            component={Link}
            to="/auth/sign-up"
            variant="contained"
            fullWidth
            color="primary"
          >
            {t("Signup_account_subtext")}
          </Typography>
          {/* </Grid> */}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
