import React from "react";
import { Box, Grid, Typography, Dialog, DialogContent } from "@mui/material";
import { useStyles } from "../../styles/styles";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const PrimaryUserModal = ({
  openPrimaryModel,
  selectedRow,
  isLoading,
  handleClosePrimaryModel,
  updateUserHandler,
  primaryUser,
  modelType,
}) => {
  console.log(primaryUser, modelType, "modelType");
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "35%",
            height: "auto",
            // padding: "20px",
            borderRadius: "8px",
          },
        }}
        open={openPrimaryModel}
        // onClose={handleCloseNewRequestModel}
      >
        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "center" }}
            >
              <WarningAmberIcon className={classes.iconClass} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ paddingTop: "10px" }}
            >
              <Typography className={classes.makePrimaryUserTextHead}>
                {t("Make_Primary_User")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ paddingTop: "10px" }}
            >
              {modelType === "ownership" ? (
                <>
                  <Typography className={classes.makePrimaryUserTex}>
                    {`${t("primary_User_Text1")} ${
                      selectedRow?.firstName !== undefined &&
                      selectedRow?.firstName !== null &&
                      selectedRow?.firstName !== ""
                        ? selectedRow?.firstName
                        : ""
                    } ${
                      selectedRow?.lastName !== undefined &&
                      selectedRow?.lastName !== null &&
                      selectedRow?.lastName !== ""
                        ? selectedRow?.lastName
                        : ""
                    } ${t("primary_User_Text12")} `}
                  </Typography>{" "}
                  <Typography className={classes.makePrimaryUserTex}>
                    {` ${t("primary_User_TextNew")} ${
                      primaryUser?.firstName !== undefined &&
                      primaryUser?.firstName !== null &&
                      primaryUser?.firstName !== ""
                        ? primaryUser?.firstName
                        : ""
                    } ${
                      primaryUser?.lastName !== undefined &&
                      primaryUser?.lastName !== null &&
                      primaryUser?.lastName !== ""
                        ? primaryUser?.lastName
                        : ""
                    }`}
                  </Typography>
                  <Typography className={classes.makePrimaryUserTex}>
                    {` ${t("primary_User_TextNewTwo")}.
             `}
                  </Typography>
                </>
              ) : (
                <Typography className={classes.makePrimaryUserTex}>
                  {`${t("primary_User_Text1")} ${
                    selectedRow?.firstName !== undefined &&
                    selectedRow?.firstName !== null &&
                    selectedRow?.firstName !== ""
                      ? selectedRow?.firstName
                      : ""
                  } ${
                    selectedRow?.lastName !== undefined &&
                    selectedRow?.lastName !== null &&
                    selectedRow?.lastName !== ""
                      ? selectedRow?.lastName
                      : ""
                  } ${t("primary_User_TextMake")} `}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box className={classes.makePrimaryActions}>
            <CommonButton
              displayWhite="true"
              onSubmit={handleClosePrimaryModel}
              label={t("No_text")}
            />
            <CommonButton
              label={t("Yes")}
              isLoading={isLoading}
              disabled={isLoading}
              onSubmit={updateUserHandler}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrimaryUserModal;
