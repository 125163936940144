import React, { useContext } from "react";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import NotFound from "../../../../components/NotFound/NotFound";
import { Grid, Divider as MuiDivider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Navbar from "../navbar/Navbar";
import DoughnutChart from "./DoughnutChart";
import KeywordHistoryTrendChart from "./KeywordHistoryTrendChart";
import LanguagesTable from "./LanguagesTable";
import { KeywordSearchContext } from "../navbar/context/KeywordSearchContext";

import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

function KeywordSearch() {
  const { t } = useTranslation();
  const { language, country, keywords, keywordData, loading, setLoading } =
    useContext(KeywordSearchContext);

  return (
    <React.Fragment>
      <>
        <Helmet title="KeywordSearch Dashboard" />
        <Grid item>
          <Navbar setLoading={setLoading} />
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          <>
            {keywordData !== null ? (
              <>
                {keywordData && keywordData[0].is_data_found ? (
                  <Grid container>
                    <Grid container spacing={6}>
                      <Grid item xs={12} lg={4}>
                        <DoughnutChart
                          difficulty={
                            keywordData && keywordData[0]?.difficulty
                              ? keywordData[0].difficulty
                              : 0
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <KeywordHistoryTrendChart
                          history_trend={
                            keywordData && keywordData[0]?.history_trend
                              ? keywordData[0].history_trend
                              : 0
                          }
                          volume={
                            keywordData && keywordData[0]?.volume
                              ? keywordData[0].volume
                              : 0
                          }
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <LanguagesTable
                          history_trend={
                            keywordData && keywordData[0]?.history_trend
                              ? keywordData[0].history_trend
                              : 0
                          }
                          competition={
                            keywordData && keywordData[0]?.competition
                              ? keywordData[0].competition
                              : 0
                          }
                          cpc={
                            keywordData && keywordData[0]?.cpc
                              ? keywordData[0].cpc
                              : 0
                          }
                        />
                      </Grid>
                    </Grid>
                    <Divider my={6} />
                  </Grid>
                ) : (
                  <Grid>
                    {keywordData && !keywordData[0].is_data_found && (
                      <>
                        <NotFound text={`${t("No_result_found")} `} />
                      </>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <NotFound text={t("Search_and_analyze_Keywords")} />
            )}
          </>
        )}
      </>
    </React.Fragment>
  );
}

export default KeywordSearch;
