import React, { useState, useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
  Dialog,
  Divider,
  Grid,
  TextField,
  IconButton,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import moment from "moment";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "styled-components/macro";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const RangePickerResults = ({
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
  dateRange,
  setDateRange,
  fromFilters,
  fromGoogleInsight,
  oneYearAgoDate,
  previousYear,
  setCallInsightsApis,
  setEmailBody,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [endDateModel, setEndDateModel] = useState(new Date());
  const [modelRange, setModelRange] = useState({
    startDate: new Date(dateRange?.startDate),
    endDate: new Date(dateRange?.endDate),
    range: dateRange?.range,
  });

  const currentDate = new Date();
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);

  // Subtract 7 days
  currentDate.setDate(currentDate.getDate() - 7);

  const handleOpen = () => setOpen(true);

  const handleDateChange = (range) => {
    console.log(range, "range");
    setModelRange({
      startDate: range?.startDate,
      endDate: range?.endDate,
      range:
        range?.label === "Last month"
          ? "month"
          : range?.label === "Last 30 days"
          ? "30days"
          : range?.label === "This month"
          ? "thisMonth"
          : range?.label === "Last year"
          ? "12months"
          : "custom",
    });
    // setModelRange(range);
    if (setCallInsightsApis !== undefined) {
      setCallInsightsApis(false);
    }
    const newEndDate = new Date();
    newEndDate.setDate(newEndDate.getDate() - 7); // Set end date to 7 days before today
    setEndDateModel(newEndDate);

    // handleClose();
  };

  const today = new Date();
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
  const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
  // console.log(modelRange, "range");
  useEffect(() => {
    const yearSelect = document.querySelector(
      ".MuiPicker-yearSelection select"
    );
    if (yearSelect) {
      const yearOptions = yearSelect.options;
      for (let i = 0; i < yearOptions.length; i++) {
        const year = parseInt(yearOptions[i].value);
        if (year > 2024) {
          yearOptions[i].disabled = true;
        }
      }
    }
  }, [open]);
  useEffect(() => {
    setModelRange(dateRange);
  }, [dateRange]);

  const handleSaveDateRange = () => {
    handleChangeStartDate(new Date(modelRange?.startDate.setHours(0, 0, 0, 0)));
    handleChangeEndDate(modelRange?.endDate);
    setOpen(false);
    setDateRange(modelRange);
    if (setEmailBody !== undefined) {
      setEmailBody(
        `Dear customer Please find attached google insights report for the ${moment(
          modelRange?.startDate
        ).format("MMM DD, YYYY")} to ${moment(modelRange?.endDate).format(
          "MMM DD, YYYY"
        )}`
      );
    }
  };

  const handleClose = () => {
    setModelRange({
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      range: dateRange?.range,
    });

    setOpen(false);
  };

  /*  const ranges = [
      {
      label: "Last 7 Days",
      startDate: startDate, // Start date is 7 days before today
      endDate: endDate, // End date is today
    }, 
    {
      label: t("30days"),
      startDate: new Date(new Date().setDate(new Date().getDate() - 29)), // Start date is 30 days ago
      endDate: endDateModel, // End date is today
    },
    {
      label: t("This_Month"),
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Start date is first day of current month
      endDate: endDateModel, // End date is today
    },
    {
      label: t("monthValue"),
      startDate: lastMonthStart, // Start date is first day of last month
      endDate: lastMonthEnd,
      // endDate: endDate, // End date initially set to today
    },
    {
      label: t("Last_Year"),
      startDate: lastYearStart, // Start date is first day of last year
      endDate: new Date(new Date().getFullYear() - 1, 11, 31), // End date is last day of last year
    },
  ]; */

  return (
    <>
      {fromGoogleInsight ? (
        <>
          {" "}
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ padding: "8px" }}>
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "14px",
                color: "#1b2430",
              }}
            >
              {t("Fromm")}
            </Typography>
            <TextField
              value={moment(modelRange?.startDate).format("DD/MM/YYYY")}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Fromm")}
              //  label={t("Fromm")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ padding: "8px" }}>
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "14px",
                color: "#1b2430",
              }}
            >
              {t("Too")}
            </Typography>
            <TextField
              value={moment(modelRange?.endDate).format("DD/MM/YYYY")}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Too")}
              // label={t("Too")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
        </>
      ) : (
        <>
          {" "}
          <Grid
            item
            xs={fromGoogleInsight ? 6 : 12}
            sm={fromGoogleInsight ? 6 : 12}
            md={fromGoogleInsight ? 6 : 12}
            lg={fromGoogleInsight ? 6 : 12}
            sx={{ padding: "8px" }}
            mt={fromGoogleInsight ? 3 : 0}
          >
            <TextField
              value={moment(modelRange?.startDate).format("DD/MM/YYYY")}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Fromm")}
              label={t("Fromm")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
          <Grid
            item
            xs={fromGoogleInsight ? 6 : 12}
            sm={fromGoogleInsight ? 6 : 12}
            md={fromGoogleInsight ? 6 : 12}
            lg={fromGoogleInsight ? 6 : 12}
            sx={{ padding: "8px" }}
            mt={fromGoogleInsight ? 3 : 0}
          >
            <TextField
              value={moment(modelRange?.endDate).format("DD/MM/YYYY")}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Too")}
              label={t("Too")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
        </>
      )}

      {open && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "620px",
              height: "540px",
              borderRadius: "4px",
              overflow: "visible",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          fullScreen
          open={open}
          onClose={handleClose}
        >
          <Grid>
            <Grid container sx={{ padding: "12px" }}>
              <Grid
                xs={12}
                sm={11.5}
                md={11.5}
                lg={11.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                  {t("Select_Date_Range")}
                </Typography>
                <BootstrapTooltip
                  title={
                    <h1 style={{ fontSize: "12px" }}>
                      {t("date_range_tooltip")}
                    </h1>
                  }
                >
                  <IconButton
                    sx={{
                      zIndex: "10",
                    }}
                  >
                    <ErrorOutlineIcon
                      sx={{
                        color: "#1B2430",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              </Grid>
              <Grid xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <IconButton
                  autoFocus
                  onClick={handleClose}
                  // className="delete_button"
                  sx={{
                    paddingTop: "0px",
                    cursor: "pointer",
                    alignItems: "start",
                  }}
                >
                  <CloseIcon
                    sx={{
                      zIndex: "10",
                      paddingTop: "0px",
                      cursor: "pointer",
                      alignItems: "start",
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              sx={{
                //  padding: "12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DateRangePicker
                open={open}
                toggle={handleClose}
                onChange={handleDateChange}
                value={modelRange}
                initialDateRange={modelRange}
                definedRanges={[]}
                minDate={new Date(oneYearAgoDate)}
                maxDate={currentDate}
              />
            </Grid>
          </Grid>
          <Grid>
            <Divider />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ justifyContent: "end", padding: "8px", textAlign: "end" }}
              >
                <CommonButton
                  label={t("Save")}
                  onSubmit={handleSaveDateRange}
                  //  loading={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      )}
      <style>
        {`
          .MuiDialogActions-root {
            display: block !important;
            text-align: right;
          }
        
            .MuiDivider-fullWidth + .MuiDivider-vertical{
            display: none !important;
          }
        .MuiDivider-vertical{
            display: none !important;
          }
             
        `}
      </style>
    </>
  );
};

export default RangePickerResults;
