// import React from "react";
// import styled from "styled-components/macro";
// import { Helmet } from "react-helmet-async";

// import { Paper, Typography } from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
// import ResetPasswordComponent from "../../components/auth/ResetPassword";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

// const Wrapper = styled(Paper)`
//   padding: ${(props) => props.theme.spacing(6)};

//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: ${(props) => props.theme.spacing(10)};
//   }
// `;

// function ResetPassword() {
//   return (
//     <React.Fragment>
//       <Brand />
//       <Wrapper>
//         <Helmet title="Reset Password" />

//         <Typography component="h1" variant="h4" align="center" gutterBottom>
//           Reset Password
//         </Typography>
//         <Typography component="h2" variant="body1" align="center">
//           Enter your email to reset your password
//         </Typography>

//         <ResetPasswordComponent />
//       </Wrapper>
//     </React.Fragment>
//   );
// }

// export default ResetPassword;
import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import logo from "../../../assets/Logo/logoblack.svg";
import { Paper, Typography } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
 Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { useStyles } from "../styles/styles";
import NavbarLanguagesDropdown from "../../../components/navbar/NavbarLanguages";
import { useTranslation } from "react-i18next";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordComponent from "./resetForm";
// import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 256.67px;
//   height: 80.25px;
//   margin-bottom: 18px;
//   margin-top: 28.87px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

function ResetPassword({ onDrawerToggle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* <Brand />
       */}
      <img
        src={logo}
        alt="logo"
        style={{
          width: "256.67px",
          height: "80.81px",
          marginBottom: "18px",
          top: "83.32px",
          left: "27.68px",
        }}
      />
      <Wrapper>
        <Helmet title="Reset Password" />
<Grid container alignItems="center" sx={{ padding: "3px" }}>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <NavbarLanguagesDropdown />
            </Grid>
          </Grid>
        <Typography
          component="h1"
          variant="h4"
          
          gutterBottom
         
          className={classes.resetPasswordLabel}
        >
          {t("Reset_Password")}
        </Typography>
        <Typography
          component="h2"
          variant="body1"
         
          className={classes.resetPasswordsubtext}
        >
          {t("Reset_subtext")}
          
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
