import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { useLocation } from "react-router-dom";
import { Grid, Card } from "@mui/material";
import { DialogContentText } from "@mui/material";

const baseURL = process.env.REACT_APP_BASE_URL;

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   //   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

const WhiteTextTypography = withStyles({
  root: {
    color: "#495059",
    fontSize: "14px",
    fontWeight: "400",
  },
})(Typography);

const HeadTextTypography = withStyles({
  root: {
    color: "#1B2430",
    fontWeight: "700",
    fontSize: "20px",
  },
})(Typography);

const Head2TextTypography = withStyles({
  root: {
    color: "#1B2430",
    fontSize: "14px",
  },
})(Typography);

const ButtonTextTypography = withStyles({
  root: {
    color: "#06BDFF",
    fontSize: "14px",
    marginRight: "10px",
    marginBottom: "6px",
  },
})(Typography);

const SubscriptionCard = ({
  item,
  result,
  handleSubscriptionClose,
  confirmSubscription,
  subscriptionArr,
  setSubscriptionArr,
  updateSubscriptionHandler,
}) => {
  const [checked, setChecked] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const location = useLocation();
  const { t } = useTranslation();
  var CurrentDate = moment();

  useEffect(() => {
    if (location?.state !==null) {
      if (
        location?.state?.showProduct===true &&
        location?.state?.displayOn===true &&
        result===undefined
      ) {
        setChecked(true);
      } else {
        if (result !==undefined && result?.status === "active") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    } else {
      if (result===undefined && location?.search.length > 0) {
        setChecked(true);
      } else if (result !==undefined && result?.status === "active") {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, []);
  useEffect(() => {
    if (location?.state !==null) {
      if (
        location?.state?.showProduct===true &&
        location?.state?.displayOn===true &&
        result===undefined
      ) {
        setChecked(true);
      } else {
        if (result !==undefined && result?.status === "active") {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    } else {
      if (result===undefined && location?.search.length > 0) {
        setChecked(true);
      } else if (result !==undefined && result?.status === "active") {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [item, result]);

  const handleSubscription = (e, id) => {
    setChecked(e.target.checked);
    let arr = [];
    if (e.target.checked) {
      subscriptionArr.push(id);
    } else {
      var ind = subscriptionArr.indexOf(id);
      subscriptionArr.splice(ind, 1);
    }
    setSubscriptionArr([...subscriptionArr]);
  };

  return (
    <>
      <Box className="box_delete">
        <Dialog
          // fullScreen={fullScreen}
          open={confirmSubscription}
          onClose={handleSubscriptionClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div>
            <Box className="box_delete">
              <DialogContentText
                id="responsive-dialog-title"
                sx={{}}
                className="delete_dialog_content1"
              >
                {"Update Subscription"}
              </DialogContentText>

              <DialogContentText
                id="responsive-dialog-title"
                className="delete_dialog_content2"
              >
                {` ${t("Are_you_sure_you_want_to")}  update subscription ?`}

                {/* {result !==undefined ? "Unsubscribe?" : "Subscribe?"} */}
              </DialogContentText>

              <DialogActions>
                <Button
                  className="delete_button"
                  autoFocus
                  onClick={handleSubscriptionClose}
                >
                  <ButtonTextTypography>{t("Cancel")}</ButtonTextTypography>
                </Button>
                <Button
                  className="delete_button"
                  variant="text"
                  onClick={updateSubscriptionHandler}
                  autoFocus
                >
                  <ButtonTextTypography>{t("Confirm")}</ButtonTextTypography>
                </Button>
              </DialogActions>
            </Box>
          </div>
        </Dialog>
      </Box>

      <Card
        /*   onClick={() => {
          setConfirmSubscription(true);
        }} */
        variant="outlined"
        sx={{
          minHeight: "68px",
          width: "auto",
          marginBottom: "8px",
          padding: "10px",
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Item>
            <img src={`${baseURL}/${item?.logoUrl}`} alt="icon" />
          </Item>
          <Stack direction="column" flex={1}>
            <Item>
              <Head2TextTypography>{item?.displayName}</Head2TextTypography>
            </Item>
            <Item>
              <WhiteTextTypography sx={{ fontSize: "14px" }}>
                {item?.discription}
              </WhiteTextTypography>
            </Item>
          </Stack>
          <Item>
             <WhiteTextTypography variant="body2">
              {<SubscriptionStatus status={result} />}
            </WhiteTextTypography>
            <Switch
              checked={checked}
              onChange={(e) => handleSubscription(e, item?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Item>
        </Stack> */}
        <Grid
          container
          direction="row"
          // justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <img src={`${baseURL}/${item?.logoUrl}`} alt="icon" />
          </Grid>
          <Grid item xs={9.5} sm={9.5} md={9.5} lg={9.5}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "4px" }}
              >
                <Typography sx={{ color: "#1B2430", fontSize: "14px" }}>
                  {item?.displayName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "4px" }}
              >
                <Typography
                  sx={{ color: "#495059", fontSize: "14px", fontWeight: "400" }}
                >
                  {item?.discription}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Switch
              checked={checked}
              onChange={(e) => handleSubscription(e, item?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default SubscriptionCard;
