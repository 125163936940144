import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Autocomplete,
  Tooltip as MuiToolTip,
  tooltipClasses,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import GridSkeletonLoader from "./GridSkeletonLoader";
import NoDataRatingVariance from "./NoDataFound/NoDataRatingVariance";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import { styled } from "@mui/system";
import { useStyles } from "../../Style/style";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  LabelList,
  Cell,
} from "recharts";
import { WaveSpinner } from "../../../../../components/Loaders/wavaloader";
import { padding } from "polished";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <MuiToolTip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const ReviewsDetail = ({
  getRandomColor,
  handleDisplayLocationName,
  allLocations,
  handleDisplayName,
  handleDisplayGroupName,
  handleDisplayNames,
  ratingVarianceData,
  handleDisplayVariance,
  ratingVarianceLoading,
  kFormatter,
  handleOpenReviewModel,
  locations,
  filteredLocations,
  setVarianceType,
  setSelectedLocationId,
  setSelectedGroupId,
  getAllReviewsData,
  startDate,
  endDate,
  downloadFormat,
  setDownloadFormat,
  handleOpenDownloadPdfModal,
  handleDisplayExportLocationName,
  handleDisplayGroupNames,
  handleDisplayGroupNameSimple,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  tooltipContent,
  prevTooltipContent,
  setFeaturesObj,
  featuresObj,
  viewPermission,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const gridItemRef = useRef(null);
  const [dataCount, setDataCount] = useState(5);
  const [downloadValue, setDownloadValue] = useState(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [graphView, setGraphView] = useState(null);
  const [tableView, setTableView] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    setGraphView("graph");
  }, []);

  useEffect(() => {
    if (gridItemRef.current) {
      const gridItemWidth = gridItemRef.current.clientWidth;
      const newChartWidth = gridItemWidth - 20;
      setChartWidth(newChartWidth);
    }
    if (ratingVarianceData?.length > 0) {
      handleUpdateLocationName(ratingVarianceData);
    }
  }, [ratingVarianceData, ratingVarianceLoading]);

  useEffect(() => {
    if (
      (pdfChecked === true && openReportEmailModal === true) ||
      (pdfDownloadChecked === true && openDownloadPdfModal === true)
    ) {
      if (ratingVarianceData?.length > 0) {
        setDataCount(ratingVarianceData?.length);
      }
    } else {
      setDataCount(5);
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];
  const handleRatingVarianceDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "ratingVariance") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };

  const loadMoreData = () => {
    if (ratingVarianceData?.length > 0) {
      setDataCount(ratingVarianceData?.length);
    }
  };
  const loadLessData = () => {
    setDataCount(5);
  };

  const handleClickGraph = () => {
    setGraphView("graph");
    setTableView(null);
  };

  const handleClickTable = () => {
    setTableView("table");
    setGraphView(null);
  };

  function truncateString(str, length) {
    if (str.length <= length) {
      return str;
    }
    return str.slice(0, length) + "....";
  }

  const handleUpdateLocationName = (data) => {
    let array = [];
    data.map((e) => {
      let obj = {
        rating: e?.overAllRating.toFixed(2),
        name: truncateString(
          e?.hasOwnProperty("locationId")
            ? handleDisplayLocationName(e?.locationId)
            : handleDisplayGroupNameSimple(e?.groupId),
          20
        ),
        fullName: e?.hasOwnProperty("locationId")
          ? handleDisplayLocationName(e?.locationId)
          : handleDisplayGroupNameSimple(e?.groupId),
      };
      array.push(obj);
    });
    setGraphData(array);
  };

  const setHeight = (length) => {
    if (length < 5) {
      return {
        container: "210px",
        chart: 200,
      };
    } else if (length >= 5 && length < 10) {
      return {
        container: "410px",
        chart: 400,
      };
    } else {
      return {
        container: "410px",
        chart: length * 40,
      };
    }
  };

  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={7.7} sm={7.7} md={7.7} lg={7.7}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.subHeadRatingReviewDetail}>
                  {t("rating_variance")}
                </Typography>
                <BootstrapTooltip title={t("Rating_Variance_Tooltip")}>
                  <Typography
                    sx={{
                      marginLeft: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <InfoOutlinedIcon />
                  </Typography>
                </BootstrapTooltip>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              sx={{
                border: "1px solid #C4C4C4",
                height: "52px",
                borderRadius: "4px",
                padding: "8px",
                marginTop: "7.5px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  onClick={handleClickGraph}
                  className={
                    graphView
                      ? classes.graphButtonDivSelected
                      : classes.graphButtonDiv
                  }
                >
                  <Typography className={classes.graphButtonTypo}>
                    {t("graph_text")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  onClick={handleClickTable}
                  className={
                    tableView
                      ? classes.graphButtonDivSelected
                      : classes.graphButtonDiv
                  }
                >
                  <Typography className={classes.graphButtonTypo}>
                    {t("table_text")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {viewPermission && (
              <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
                <Autocomplete
                  id="tags-outlined"
                  options={downloadOptions}
                  value={downloadValue}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  onChange={handleRatingVarianceDownloadChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("actionss")} />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {ratingVarianceLoading ? (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
              alignItems: "center",
              borderLeft: "1px solid #9d9d9d",
              borderBottom: "1px solid #9d9d9d",
              paddingX: "8px",
            }}
          >
            <WaveSpinner />
          </Box>
        ) : graphData?.length > 0 ? (
          <Grid container>
            {tableView && (
              <>
                {ratingVarianceLoading ? (
                  <GridSkeletonLoader />
                ) : (
                  <>
                    {ratingVarianceData?.length > 0 && (
                      <Grid container className={classes.tableContainer}>
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          className={classes.mainGridCont}
                        >
                          <Typography className={classes.tableHeading}>
                            {t("Name")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridCont}
                        >
                          <Typography className={classes.tableHeading}>
                            {t("average_rating")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridCont}
                        >
                          <Typography className={classes.tableHeading}>
                            {t("previous_average_rating")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridCont}
                        >
                          <Typography className={classes.tableHeading}>
                            {t("%variance")}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={2.25}
                          sm={2.25}
                          md={2.25}
                          lg={2.25}
                          className={classes.mainGridCont}
                        >
                          <Typography className={classes.tableHeading}>
                            {t("overall_rating")}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {ratingVarianceData?.length > 0 ? (
                      <Grid container>
                        <Grid container className={classes.tableContainer}>
                          {ratingVarianceData
                            ?.slice(0, dataCount)
                            .map((item) => {
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    sx={{
                                      padding: "16px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <Typography>
                                      {item.hasOwnProperty("locationId") &&
                                        handleDisplayName(
                                          item?.locationId,
                                          item?.lastMonthRating,
                                          item?.thisMonthRating
                                        )}

                                      {item.hasOwnProperty("groupId") &&
                                        handleDisplayGroupName(
                                          item?.groupId,
                                          item?.lastMonthRating,
                                          item?.thisMonthRating
                                        )}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={2.25}
                                    sm={2.25}
                                    md={2.25}
                                    lg={2.25}
                                    className={classes.mainGridContCursor}
                                    onClick={() => {
                                      if (
                                        item.thisMonthRating !== undefined &&
                                        item.thisMonthRating !== null &&
                                        item.thisMonthRating > 0
                                      ) {
                                        setVarianceType("");
                                        setSelectedLocationId(item?.locationId);
                                        setSelectedGroupId(item?.groupId);

                                        handleOpenReviewModel(
                                          "new",
                                          undefined,
                                          item.hasOwnProperty("locationId")
                                            ? item?.locationId
                                            : item?.groupId,
                                          item.hasOwnProperty("locationId")
                                            ? "locationId"
                                            : "groupId"
                                        );
                                      }
                                    }}
                                  >
                                    <BootstrapTooltip
                                      title={
                                        <h1 style={{ fontSize: "12px" }}>
                                          {`${t("From")} ${dayjs(
                                            startDate
                                          ).format("MMM DD, YYYY")} ${t(
                                            "To"
                                          )} ${dayjs(endDate).format(
                                            "MMM DD, YYYY"
                                          )}`}
                                        </h1>
                                      }
                                    >
                                      <span
                                        className={classes.ratingHeadings}
                                        style={{
                                          color:
                                            item?.thisMonthRating >= 4.2
                                              ? "#13CF8F"
                                              : item?.thisMonthRating >= 4.0 &&
                                                item?.thisMonthRating < 4.2
                                              ? "#F8A92B"
                                              : "#FF1616",
                                        }}
                                      >
                                        {item?.thisMonthRating ? (
                                          kFormatter(
                                            item?.thisMonthRating.toFixed(2)
                                          )
                                        ) : (
                                          <span
                                            style={{
                                              color: "#FF1616",
                                            }}
                                          >
                                            {"0.00"}
                                          </span>
                                        )}
                                      </span>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2.25}
                                    sm={2.25}
                                    md={2.25}
                                    lg={2.25}
                                    className={classes.mainGridContCursor}
                                    onClick={() => {
                                      if (
                                        item.lastMonthRating !== undefined &&
                                        item.lastMonthRating !== null &&
                                        item.lastMonthRating > 0
                                      ) {
                                        setVarianceType("previous");

                                        setSelectedLocationId(item?.locationId);
                                        setSelectedGroupId(item?.groupId);

                                        handleOpenReviewModel(
                                          "new",
                                          undefined,
                                          item.hasOwnProperty("locationId")
                                            ? item?.locationId
                                            : item?.groupId,
                                          item.hasOwnProperty("locationId")
                                            ? "locationId"
                                            : "groupId"
                                        );
                                      }
                                    }}
                                  >
                                    <BootstrapTooltip
                                      title={
                                        <h1 style={{ fontSize: "12px" }}>
                                          {`${prevTooltipContent}`}
                                        </h1>
                                      }
                                    >
                                      <span
                                        className={classes.ratingHeadings}
                                        style={{
                                          color:
                                            item?.lastMonthRating >= 4.2
                                              ? "#13CF8F"
                                              : item?.lastMonthRating >= 4.0 &&
                                                item?.lastMonthRating < 4.2
                                              ? "#F8A92B"
                                              : "#FF1616",
                                        }}
                                      >
                                        {item?.lastMonthRating ? (
                                          item?.lastMonthRating.toFixed(2)
                                        ) : (
                                          <span
                                            style={{
                                              color: "#FF1616",
                                            }}
                                          >
                                            {"0.00"}
                                          </span>
                                        )}
                                      </span>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2.25}
                                    sm={2.25}
                                    md={2.25}
                                    lg={2.25}
                                    className={classes.mainGridCont}
                                  >
                                    <BootstrapTooltip
                                      title={
                                        <h1 style={{ fontSize: "12px" }}>
                                          {`${tooltipContent}`}
                                        </h1>
                                      }
                                    >
                                      <span
                                        className={classes.ratingHeadings}
                                        style={{
                                          color:
                                            handleDisplayVariance(
                                              item?.lastMonthRating,
                                              item?.thisMonthRating
                                            ) > 0
                                              ? "#13CF8F" // Green for positive variance
                                              : handleDisplayVariance(
                                                  item?.lastMonthRating,
                                                  item?.thisMonthRating
                                                ) < 0
                                              ? "#FF1616" // Red for negative variance
                                              : "#F8A92B", // Yellow for zero variance
                                        }}
                                      >
                                        {handleDisplayVariance(
                                          item?.lastMonthRating,
                                          item?.thisMonthRating
                                        ) !== "--"
                                          ? handleDisplayVariance(
                                              item?.lastMonthRating,
                                              item?.thisMonthRating
                                            ) + "%"
                                          : "0.00%"}
                                      </span>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2.25}
                                    sm={2.25}
                                    md={2.25}
                                    lg={2.25}
                                    className={classes.mainGridContCursor}
                                    onClick={() => {
                                      if (
                                        item.overAllRating !== undefined &&
                                        item.overAllRating !== null &&
                                        item.overAllRating > 0
                                      ) {
                                        setSelectedLocationId(item?.locationId);
                                        setSelectedGroupId(item?.groupId);
                                        if (item.hasOwnProperty("groupId")) {
                                          setVarianceType("group");
                                        }

                                        handleOpenReviewModel(
                                          "old",
                                          undefined,
                                          item.hasOwnProperty("locationId")
                                            ? item?.locationId
                                            : item?.groupId,
                                          item.hasOwnProperty("locationId")
                                            ? "locationId"
                                            : "groupId"
                                        );
                                      }
                                    }}
                                  >
                                    <span
                                      className={classes.ratingHeadings}
                                      style={{
                                        color:
                                          item?.overAllRating >= 4.2
                                            ? "#13CF8F"
                                            : item?.overAllRating >= 4.0 &&
                                              item?.overAllRating < 4.2
                                            ? "#F8A92B"
                                            : "#FF1616",
                                      }}
                                    >
                                      {item?.overAllRating ? (
                                        item?.overAllRating.toFixed(2)
                                      ) : (
                                        <span
                                          style={{
                                            color: "#FF1616",
                                          }}
                                        >
                                          {"0.00"}
                                        </span>
                                      )}
                                    </span>
                                  </Grid>
                                </>
                              );
                            })}
                        </Grid>
                        <Grid container className={classes.tableContainer}>
                          {filteredLocations.map((item) => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  sx={{
                                    padding: "16px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Typography
                                    className={classes.ratingHeadingsLoc}
                                  >
                                    {handleDisplayName(item?.id)}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={2.25}
                                  sm={2.25}
                                  md={2.25}
                                  lg={2.25}
                                  className={classes.mainGridCont}
                                >
                                  <Typography
                                    className={classes.ratingHeadings}
                                    sx={{
                                      color: "#FF1616",
                                    }}
                                  >
                                    {"0.00"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2.25}
                                  sm={2.25}
                                  md={2.25}
                                  lg={2.25}
                                  className={classes.mainGridCont}
                                >
                                  <Typography
                                    className={classes.ratingHeadings}
                                    sx={{
                                      color: "#FF1616",
                                    }}
                                  >
                                    {"0.00"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2.25}
                                  sm={2.25}
                                  md={2.25}
                                  lg={2.25}
                                  className={classes.mainGridCont}
                                >
                                  <Typography
                                    className={classes.ratingHeadings}
                                    sx={{
                                      color: "#FF1616",
                                    }}
                                  >
                                    {"0.00"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2.25}
                                  sm={2.25}
                                  md={2.25}
                                  lg={2.25}
                                  className={classes.mainGridCont}
                                >
                                  <Typography
                                    className={classes.ratingHeadings}
                                    sx={{
                                      color: "#FF1616",
                                    }}
                                  >
                                    {"0.00"}
                                  </Typography>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <NoDataRatingVariance
                          handleDisplayName={handleDisplayName}
                          locations={locations}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {ratingVarianceData.length > 5 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ textAlign: "start" }}
                  >
                    {dataCount === 5 ? (
                      <Button onClick={loadMoreData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    ) : (
                      <Button onClick={loadLessData}>
                        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                          {t("View_Less")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                )}
              </>
            )}
            {graphView && (
              <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  ref={gridItemRef}
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: setHeight(graphData.length).container,
                  }}
                >
                  {chartWidth && (
                    <BarChart
                      width={chartWidth}
                      height={setHeight(graphData.length).chart}
                      data={graphData}
                      layout="vertical"
                      style={{ padding: "20px 0px 0px 0px" }}
                    >
                      <XAxis
                        dataKey="rating"
                        type="number"
                        domain={[0, 5]}
                        className="fixed-x-axis"
                        style={{
                          display: "none",
                        }}
                      />
                      <YAxis
                        dataKey="name"
                        type="category"
                        interval={0}
                        width={200}
                        textAnchor="end"
                      />
                      <Tooltip
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              tooltipData !== null && (
                                <div className={classes.customTooltipReview}>
                                  <Typography
                                    className={classes.toolTipStyleReview}
                                  >
                                    {tooltipData !== null &&
                                      tooltipData?.fullName}
                                  </Typography>
                                </div>
                              )
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar
                        name={"Locations"}
                        isAnimationActive={false}
                        dataKey="rating"
                        key={Math.random()}
                        barSize={20}
                        onMouseEnter={(event) => {
                          if (
                            event.payload &&
                            event.payload.fullName &&
                            event.payload.rating
                          ) {
                            setTooltipData({
                              fullName: event.payload.fullName,
                              rating: event.payload.rating,
                            });
                          } else {
                            setTooltipData(null);
                          }
                        }}
                        onMouseLeave={() => {
                          setTooltipData(null);
                        }}
                      >
                        {graphData.map((entry, index) => (
                          <>
                            <Cell
                              fill={
                                entry.rating <= 1
                                  ? "#ec2715"
                                  : entry.rating <= 2
                                  ? "#f2742e"
                                  : entry.rating <= 3
                                  ? "#ffc130"
                                  : entry.rating <= 4
                                  ? "#8fb02b"
                                  : entry.rating <= 5
                                  ? "#239f25"
                                  : ""
                              }
                            />
                            <LabelList dataKey="rating" position="right" />
                          </>
                        ))}
                      </Bar>
                    </BarChart>
                  )}
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <BarChart
                      width={chartWidth}
                      height={60}
                      data={[]}
                      layout="vertical"
                      style={{
                        marginTop: "-45px",
                        backgroundColor: "white",
                        /*  padding:
                          graphData.length < 5
                            ? "0px 40px 0px 48px"
                            : "0px 10px 0px 18px", */
                      }}
                    >
                      <XAxis
                        dataKey="rating"
                        type="number"
                        domain={[0, 5]}
                        className="fixed-x-axis"
                      />
                      <YAxis
                        dataKey="name"
                        type="category"
                        axisLine={false}
                        interval={0}
                        width={200}
                        textAnchor="end"
                      />
                      {/* Commented code for rating starts view */}
                      {/* <Legend
                        payload={[
                          {
                            value: `${t("1_Star")}`,
                            type: "circle",
                            id: "oneStar",
                            color: "#ec2715",
                          },
                          {
                            value: `${t("2_Star")}`,
                            type: "circle",
                            id: "twoStar",
                            color: "#f2742e",
                          },
                          {
                            value: `${t("3_Star")}`,
                            type: "circle",
                            id: "threeStar",
                            color: "#ffc130",
                          },
                          {
                            value: `${t("4_Star")}`,
                            type: "circle",
                            id: "fourStar",
                            color: "#8fb02b",
                          },
                          {
                            value: `${t("5_Star")}`,
                            type: "circle",
                            id: "fiveStar",
                            color: "#239f25",
                          },
                        ]}
                      /> */}
                    </BarChart>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <NoDataFound />
        )}
      </Grid>
    </>
  );
};

export default ReviewsDetail;
