import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox, Typography, Box, Divider } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../UserListing/Styles/style";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import { WaveSpinner } from "../../../../components/Loaders/wavaloader";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const UserActions = ({
  googleInsightData,
  data,
  ratingLoading,
  metricsLoading,
  checkNumber,
  type,
  colors,
  kFormatter,
  totalValueBuisness,
  dataSet2,
  requestDirections,
  visit,
  call,
  allLables,
  tooltipContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [buisnessPercentage, setBuisnessPercentage] = useState(0);
  const [websitePercentage, setWebsitePercentage] = useState(0);
  const [callClickPercentage, setCallClickPercentage] = useState(0);
  const [businessData, setBusinessData] = useState(null);
  const [websiteData, setWebsiteData] = useState(null);
  const [callData, setCattData] = useState(null);
  // const [tooltipContent, setTooltipContent] = useState("");

  /*  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    const month1 = dataSet2?.labels[0];
    const month2 = dataSet2?.labels[dataSet2?.labels.length - 1];

    const isSameMonth = month1 === month2;

    const content = isSameMonth
      ? `vs ${month1}, ${previousYear}`
      : `vs ${month1}, ${previousYear} - ${month2}, ${previousYear}`;

    setTooltipContent(content);
  }, [dataSet2]); */

  useEffect(() => {
    if (googleInsightData !== null && googleInsightData?.length > 0) {
      const businesssData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_DIRECTION_REQUESTS"
      );
      setBusinessData(businesssData);
      if (businesssData !== undefined) {
        const buisnessDirectionPercentage = (
          (businesssData?.metricValue / totalValueBuisness) *
          100
        ).toFixed();
        setBuisnessPercentage(buisnessDirectionPercentage);
      }

      const webData = googleInsightData?.find(
        (item) => item?.metricType === "WEBSITE_CLICKS"
      );
      setWebsiteData(webData);
      if (webData !== undefined) {
        const websiteClicks = (
          (webData?.metricValue / totalValueBuisness) *
          100
        ).toFixed();
        setWebsitePercentage(websiteClicks);
      }
      const clickData = googleInsightData?.find(
        (item) => item?.metricType === "CALL_CLICKS"
      );
      setCattData(clickData);
      if (clickData !== undefined) {
        const websiteClicks = (
          (clickData?.metricValue / totalValueBuisness) *
          100
        ).toFixed();
        setCallClickPercentage(websiteClicks);
      }
    } else {
      setBuisnessPercentage(0);
      setWebsitePercentage(0);
      setCallClickPercentage(0);
      setBusinessData(null);
      setWebsiteData(null);
      setCattData(null);
    }
  }, [totalValueBuisness]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <Grid container className={classes.resultsMainGrid}>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12}>
            {" "}
            <Typography className={classes.totalValueText}>
              {kFormatter(totalValueBuisness)}
            </Typography>
            <Typography className={classes.totalValuesubText}>
              <VisibilityIcon
                fontSize="small"
                className={classes.visibilityIcon}
              />{" "}
              {t("Profile_subtext_action")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/*   <CustomDirectionCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}{" "}
            {t("Request_directions")}{" "}
            <Typography className={classes.headingConversion}>
              {isNaN(buisnessPercentage) ? "0" : buisnessPercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltipText4")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*  {graphData?.ACTIONS_DRIVING_DIRECTIONS} */}
                {businessData !== null
                  ? kFormatter(businessData?.metricValue)
                  : "0"}
              </Typography>
              {businessData?.BUSINESS_DIRECTION_REQUESTS_PERCENTAGE !== "NaN" &&
                businessData?.BUSINESS_DIRECTION_REQUESTS_PERCENTAGE !==
                  "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {requestDirections === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          requestDirections === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {businessData !== null
                          ? checkNumber(
                              businessData?.BUSINESS_DIRECTION_REQUESTS_PERCENTAGE !==
                                "NaN"
                                ? businessData?.BUSINESS_DIRECTION_REQUESTS_PERCENTAGE
                                : "0",
                              "requestDirections"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/* <Checkbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Visit_your_website")}{" "}
            <Typography className={classes.headingWeb}>
              {isNaN(websitePercentage) ? "0" : websitePercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("toolTipText3")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*   {graphData?.ACTIONS_WEBSITE} */}
                {websiteData !== null
                  ? kFormatter(websiteData?.metricValue)
                  : "0"}
              </Typography>
              {websiteData?.WEBSITE_CLICKS_PERCENTAGE !== "NaN" &&
                websiteData?.WEBSITE_CLICKS_PERCENTAGE !== "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {visit === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          visit === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {websiteData !== null
                          ? checkNumber(
                              websiteData?.WEBSITE_CLICKS_PERCENTAGE !== "NaN"
                                ? websiteData?.WEBSITE_CLICKS_PERCENTAGE
                                : "0",
                              "visit"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>

        {/*  <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
         
            {t("Call_You")}{" "}
            <Typography className={classes.headingMobileMap}>
              {isNaN(callClickPercentage) ? "0" : callClickPercentage + "%"}
            </Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltiptext5")}</h1>}
            >
              <IconButton>
                <InfoIcon
                  sx={{
                    fontSize: "large",
                    paddingBottom: "3px",

                    color: "#8D9298",
                  }}
                />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
               
                {callData !== null ? kFormatter(callData?.metricValue) : "0"}
              </Typography>
              {callData?.CALL_CLICKS_PERCENTAGE !== "NaN" &&
                callData?.CALL_CLICKS_PERCENTAGE !== "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1 style={{ fontSize: "12px" }}>{tooltipContent}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {call === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          call === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {callData !==null
                          ? checkNumber(
                              callData?.CALL_CLICKS_PERCENTAGE !== "NaN"
                                ? callData?.CALL_CLICKS_PERCENTAGE
                                : "0",
                              "call"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid> */}
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12} className={classes.graphGrid}>
        {metricsLoading ? (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
              alignItems: "center",
              borderLeft: "1px solid #9d9d9d",
              borderBottom: "1px solid #9d9d9d",
              paddingX: "8px",
            }}
          >
            <WaveSpinner />

            {/*   <DominoSpinner /> */}
          </Box>
        ) : (
          <Line data={dataSet2} options={options} height={100} />
        )}
      </Grid>
    </>
  );
};

export default UserActions;
