import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Checkbox, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapsible from "../Components/Collapsible";

import { useStyles } from "../../../styles/style";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useParams } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CreateRoleFeature = ({
  selectAllChecked,
  setSelectAllChecked,

  checkboxes,
  setCheckboxes,
  companyFeatures,
  setPermissionsArr,
  permissionsArr,
  singleRole,
  defaultRole,
}) => {
  const params = useParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const desiredOrder = ["read", "create", "edit", "delete"];

  useEffect(() => {
    if (params?.id) {
      handleDisplaySingleRoleData();
    } else {
      handleDisplayData();
    }
  }, [companyFeatures, singleRole]);

  const handleSelectAllChange = (e, selectedFeature) => {
    let tempArr = [...permissionsArr];
    const index = tempArr.findIndex(
      (item) => item?.name === selectedFeature?.name
    );
    console.log(selectedFeature, "selectedFeature");

    if (e) {
      if (index >= 0) {
        selectedFeature?.AppFeaturePermissions?.length > 0 &&
          selectedFeature?.AppFeaturePermissions?.forEach((permission) => {
            permission?.CompanyFeatures?.length > 0 &&
              permission?.CompanyFeatures?.forEach((itemOne) => {
                let tempObj = {
                  permissionName: permission?.name,
                  companyFeatureId: itemOne?.id,
                  appFeaturePermissionId: itemOne?.appFeaturePermissionId,
                  appId: itemOne?.appId,
                };
                tempArr[index].permissions?.push(tempObj);
              });
          });
        const modifiedArray = removeDuplicatePermissions(tempArr);
        setPermissionsArr(modifiedArray);
      } else {
        let arr = [];
        selectedFeature?.AppFeaturePermissions?.length > 0 &&
          selectedFeature?.AppFeaturePermissions?.forEach((permission) => {
            permission?.CompanyFeatures?.length > 0 &&
              permission?.CompanyFeatures?.forEach((itemOne) => {
                let tempObj = {
                  permissionName: permission?.name,
                  companyFeatureId: itemOne?.id,
                  appFeaturePermissionId: itemOne?.appFeaturePermissionId,
                  appId: itemOne?.appId,
                };
                arr?.push(tempObj);
              });
          });
        let obj = {
          name: selectedFeature?.name,
          permissions: arr,
        };
        tempArr.push(obj);
        setPermissionsArr(tempArr);
      }
    } else {
      tempArr[index].permissions = [];
      setPermissionsArr(tempArr);
    }
    // setSelectAllChecked(e.target.checked);
  };

  const handleChangeSelectAllFeatures = (e) => {
    setSelectAllFeatures(e.target.checked);
    let tempArr = [];
    let tempArrTwo = [...permissionsArr];

    if (e.target.checked) {
      handleExpandAllPanels();
      companyFeatures?.forEach((featureOne) => {
        featureOne?.AppFeaturePermissions?.length > 0 &&
          featureOne?.AppFeaturePermissions?.forEach((permission) => {
            let permissionArr = [];
            permission?.CompanyFeatures?.length > 0 &&
              permission?.CompanyFeatures?.forEach((itemOne) => {
                let tempObj = {
                  permissionName: permission?.name,
                  companyFeatureId: itemOne?.id,
                  appFeaturePermissionId: itemOne?.appFeaturePermissionId,
                  appId: itemOne?.appId,
                };
                permissionArr?.push(tempObj);
              });
            let tempObj = {
              name: featureOne?.name,
              companyId: companyData?.id,
              permissions: permissionArr,
            };
            tempArr?.push(tempObj);
          });
      });
      const result = tempArr.reduce((acc, obj) => {
        const existingObj = acc.find((item) => item.name === obj.name);

        if (existingObj) {
          existingObj.permissions = existingObj.permissions.concat(
            obj.permissions
          );
        } else {
          acc.push(obj);
        }

        return acc;
      }, []);

      const modifiedArray = removeDuplicatePermissions(result);
      setPermissionsArr(modifiedArray);
    } else {
      companyFeatures?.forEach((featureOne) => {
        tempArrTwo?.forEach((featureTwo, index) => {
          if (featureOne?.name === featureTwo?.name) {
            tempArrTwo[index].permissions = [];
          }
        });
      });
      setPermissionsArr(tempArrTwo);
    }
  };

  const handleDisplaySingleRoleData = () => {
    if (companyFeatures?.length > 0) {
      let tempArr = [];
      let tempObj = {
        name: "",
        companyId: "",
        permissions: [
          {
            permissionName: "",
            companyFeatureId: "",
            appFeaturePermissionId: "",
            appId: "",
          },
        ],
      };
      companyFeatures?.map((feature) => {
        feature?.AppFeaturePermissions?.length > 0 &&
          feature?.AppFeaturePermissions?.forEach((permission) => {
            permission?.CompanyFeatures?.length > 0 &&
              permission?.CompanyFeatures?.forEach((item) => {
                singleRole?.permissions?.forEach((itemTwo) => {
                  if (
                    item?.appId === itemTwo?.appId &&
                    item?.id === itemTwo?.companyFeatureId
                  ) {
                    let tempObj = {
                      name: feature.name,
                      companyId: companyData.id,
                      permissions: [
                        {
                          permissionName: permission?.name,
                          companyFeatureId: item?.id,
                          appFeaturePermissionId: item?.appFeaturePermissionId,
                          appId: item?.appId,
                        },
                      ],
                    };
                    tempArr?.push(tempObj);
                  }
                });
              });
          });
      });

      const result = tempArr.reduce((acc, obj) => {
        const existingObj = acc.find((item) => item.name === obj.name);

        if (existingObj) {
          existingObj.permissions = existingObj.permissions.concat(
            obj.permissions
          );
        } else {
          acc.push(obj);
        }

        return acc;
      }, []);

      const filtered = result?.filter((item) => item?.permissions?.length > 0);

      if (filtered?.length > 0) {
        filtered?.map((item) => {
          expandedPanels.push(item?.name);
        });
        setExpandedPanels([...expandedPanels]);
      }
      const modifiedArray = removeDuplicatePermissions(result);
      setPermissionsArr(modifiedArray);
    }
  };

  const handleDisplayData = () => {
    if (companyFeatures?.length > 0) {
      let tempArr = [];
      let tempObj = {
        name: "",
        companyId: "",
        permissions: [
          {
            permissionName: "",
            companyFeatureId: "",
            appFeaturePermissionId: "",
            appId: "",
          },
        ],
      };
      companyFeatures?.map((feature) => {
        feature?.AppFeaturePermissions?.length > 0 &&
          feature?.AppFeaturePermissions?.forEach((permission) => {
            permission?.CompanyFeatures?.length > 0 &&
              permission?.CompanyFeatures?.forEach((item) => {
                let tempObj = {
                  name: feature.name,
                  companyId: companyData.id,
                  permissions: [
                    {
                      permissionName: permission?.name,
                      companyFeatureId: item?.id,
                      appFeaturePermissionId: item?.appFeaturePermissionId,
                      appId: item?.appId,
                    },
                  ],
                };
                tempArr?.push(tempObj);
              });
          });
      });

      const result = tempArr.reduce((acc, obj) => {
        const existingObj = acc.find((item) => item.name === obj.name);

        if (existingObj) {
          existingObj.permissions = existingObj.permissions.concat(
            obj.permissions
          );
        } else {
          acc.push(obj);
        }

        return acc;
      }, []);
      setPermissionsArr(result);
      if (result?.length > 0) {
        result?.map((item) => {
          expandedPanels.push(item?.name);
        });
        setExpandedPanels([...expandedPanels]);
      }
      handleExpandAllPanels();
    }
  };

  const handleCheckboxChange = (
    featureName,
    name,
    checked,
    companyFeatureId,
    appId,
    appFeaturePermissionId
  ) => {
    let tempArr = [...permissionsArr];

    const index = tempArr.findIndex((item) => item?.name === featureName);
    if (checked) {
      if (index >= 0) {
        const viewPermissionFound = permissionsArr[index].permissions?.find(
          (item) => item?.permissionName === "read"
        );

        if (viewPermissionFound !== undefined) {
          let tempObj = {
            permissionName: name,
            companyFeatureId: companyFeatureId,
            appFeaturePermissionId: appFeaturePermissionId,
            appId: appId,
          };
          tempArr[index].permissions?.push(tempObj);
        } else {
          const findObj = companyFeatures?.find(
            (item) => item?.name === featureName
          );
          const findPermission = findObj?.AppFeaturePermissions?.find(
            (item) => item?.name === "read"
          );
          let tempObjOne = {
            permissionName: findPermission?.name,
            companyFeatureId: findPermission?.CompanyFeatures[0]?.id,
            appFeaturePermissionId:
              findPermission?.CompanyFeatures[0]?.appFeaturePermissionId,
            appId: findPermission?.CompanyFeatures[0]?.appId,
          };
          tempArr[index].permissions?.push(tempObjOne);

          let tempObj = {
            permissionName: name,
            companyFeatureId: companyFeatureId,
            appFeaturePermissionId: appFeaturePermissionId,
            appId: appId,
          };
          tempArr[index].permissions?.push(tempObj);
        }
      } else {
        const findObj = companyFeatures?.find(
          (item) => item?.name === featureName
        );
        const findPermission = findObj?.AppFeaturePermissions?.find(
          (item) => item?.name === "read"
        );
        let temmpObj = {
          companyId: companyData?.id,
          name: featureName,
          permissions: [
            {
              permissionName: name,
              companyFeatureId: companyFeatureId,
              appFeaturePermissionId: appFeaturePermissionId,
              appId: appId,
            },
            {
              permissionName: findPermission?.name,
              companyFeatureId: findPermission?.CompanyFeatures[0]?.id,
              appFeaturePermissionId:
                findPermission?.CompanyFeatures[0]?.appFeaturePermissionId,
              appId: findPermission?.CompanyFeatures[0]?.appId,
            },
          ],
        };
        tempArr?.push(temmpObj);
      }
      const modifiedArray = removeDuplicatePermissions(tempArr);
      setPermissionsArr(modifiedArray);
    } else {
      if (name === "read") {
        tempArr[index].permissions = [];
        setPermissionsArr(tempArr);
      } else {
        const modifiedArray = tempArr.map((obj) => ({
          ...obj,
          permissions: obj.permissions.filter(
            (permission) => permission.companyFeatureId !== companyFeatureId
          ),
        }));
        setPermissionsArr(modifiedArray);
      }
    }
  };
  function removeDuplicatePermissions(array) {
    return array.map((obj) => {
      const seen = new Set();
      obj.permissions = obj.permissions.filter((permission) => {
        if (seen.has(permission.companyFeatureId)) {
          return false; // Duplicate, exclude it
        }
        seen.add(permission.companyFeatureId);
        return true; // Not a duplicate, include it
      });
      return obj;
    });
  }
  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleExpandAllPanels = () => {
    const arr = [];
    companyFeatures?.map((item) => arr.push(item?.name));
    setExpandedPanels(arr);

    setExpandAllPanels(false);
  };
  const handleCollapseAllPanelsPanels = () => {
    setExpandedPanels([]);
    setExpandAllPanels(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: "1.125rem" }}>
              {t("features_label")}
            </Typography>

            <Box>
              <Checkbox
                name="selectAll"
                color="primary"
                checked={selectAllFeatures}
                disabled={defaultRole}
                onChange={(e) => handleChangeSelectAllFeatures(e)}
              />
              {t("select_all_features")}
              {expandAllPanels ? (
                <IconButton onClick={handleExpandAllPanels}>
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleCollapseAllPanelsPanels}>
                  <ExpandLessIcon />
                </IconButton>
              )}
            </Box>

            {/*  {expandAllPanels ? (
              <IconButton onClick={handleExpandAllPanels}>
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleCollapseAllPanelsPanels}>
                <ExpandLessIcon />
              </IconButton>
            )} */}
          </Box>
        </Grid>
      </Grid>
      {companyFeatures?.length > 0 &&
        companyFeatures?.map((feature, ind) => {
          const arr = permissionsArr?.find(
            (obj) => obj?.name === feature?.name
          );

          return (
            <Box sx={{ marginTop: "12px" }}>
              <Collapsible
                title={feature?.name}
                handleChange={handleExpandPanel}
                expanded={expandedPanels.includes(feature?.name)}
                id={feature?.name}
                ComponentToRender={
                  <Grid container spacing={2}>
                    {feature?.AppFeaturePermissions?.length > 0 &&
                      feature?.AppFeaturePermissions.sort((a, b) => {
                        const orderA = desiredOrder.indexOf(a.name);
                        const orderB = desiredOrder.indexOf(b.name);
                        return orderA - orderB;
                      })?.map((permission, index) => {
                        const companyFeatureId = permissionsArr.find(
                          (obj) =>
                            obj.permissions?.length > 0 &&
                            obj.permissions.some(
                              (permissionItem) =>
                                permissionItem.companyFeatureId ==
                                permission?.CompanyFeatures[0]?.id
                            )
                        );

                        return (
                          <Grid item xs={12} md={2.4} key={index}>
                            <div
                              disabled={defaultRole}
                              className={classes.socialBoxUsers}
                              onClick={() => {
                                if (!defaultRole) {
                                  handleCheckboxChange(
                                    feature?.name,
                                    permission?.name,
                                    !(
                                      companyFeatureId !== undefined &&
                                      companyFeatureId?.permissions?.length > 0
                                    ),
                                    permission?.CompanyFeatures?.length > 0 &&
                                      permission?.CompanyFeatures[0]?.id,
                                    permission?.appId,
                                    permission?.CompanyFeatures?.length > 0 &&
                                      permission?.CompanyFeatures[0]
                                        ?.appFeaturePermissionId
                                  );
                                }
                              }}
                            >
                              <Checkbox
                                sx={{ marginTop: "4px" }}
                                name="view"
                                color="primary"
                                checked={
                                  companyFeatureId !== undefined &&
                                  companyFeatureId?.permissions?.length > 0
                                    ? true
                                    : false
                                }
                                disabled={defaultRole}
                              />
                              {permission?.name === "create"
                                ? t("Add_label")
                                : permission?.name === "read"
                                ? t("View_Label")
                                : permission?.name === "edit"
                                ? t("Edit")
                                : permission?.name === "delete"
                                ? t("Delete_label")
                                : ""}
                            </div>
                          </Grid>
                        );
                      })}
                    <Grid item xs={12} md={2.4}>
                      <div
                        disabled={defaultRole}
                        className={classes.socialBoxUsers}
                        onClick={(e) =>
                          !defaultRole &&
                          handleSelectAllChange(
                            !(
                              arr?.permissions?.length ===
                              feature?.AppFeaturePermissions?.length
                            ),
                            feature
                          )
                        }
                      >
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          name="selectAll"
                          color="primary"
                          disabled={defaultRole}
                          checked={
                            arr?.permissions?.length ===
                            feature?.AppFeaturePermissions?.length
                          }
                        />
                        {t("Select_All")}
                      </div>
                    </Grid>
                  </Grid>
                }
              />
            </Box>
          );
        })}
    </>
  );
};

export default CreateRoleFeature;
