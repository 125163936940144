import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
// const accessToken =;
// const config = {
//   headers: { Authorization: `Bearer ${accessToken}` },
// };
export const api = axios.create({
  baseURL,
  responseType: "json",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrfoken",
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    // "Access-Control-Allow-Origin": "http://localhost:3000",
    // 'Access-Control-Allow-Credentials': 'true',
  },
});
api.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("accessToken");

  config.headers.Authorization = "Bearer " + token;
  return config;
});

export const handleSessionOut = async (
  status,
  toastErr,
  handleSignOut,
  logout
) => {
  if (status === 401 || status === 409 || (status === 400 && logout === true)) {
    toast.error(toastErr);
    handleSignOut();
  }
};

const INITIALIZE = "INITIALIZE";
const SET_CONTEXT = "SET_CONTEXT";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const EDIT_PROFILE = "EDIT_PROFILE";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const SET_LOADING = "SET_LOADING";
const SET_SUCCESS = "SET_SUCCESS";

const initialState = {
  isAuthenticated: false,
  userRole: "",
  isInitialized: false,
  user: null,
  isLoading: false,
  isSuccess: false,
};
//
const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action?.payload?.isAuthenticated,
        isInitialized: true,
        userRole: action.payload?.user?.role,
        user: action?.payload?.user,
        isLoading: false,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        userRole: action.payload.user.role,
        user: action.payload.user,
        permissions: action.payload.permissions,
        permissionsAcess: action.payload.permissionsAcess,
      };
    case SIGN_OUT:
      return initialState;

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case SET_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    case EDIT_PROFILE:
      return {
        ...state,
        isAuthenticated: true,

        isLoading: false,
        user: action.payload.user,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SET_CONTEXT:
      return action.payload;
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(
    JWTReducer,
    window.localStorage.getItem("stateData")
      ? JSON.parse(window.localStorage.getItem("stateData"))
      : initialState
  );
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
          };
          const response = await api.get("/user/me", config);
          const user = await response.data.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              userRole: user.role,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    setContextdata();
  }, []);
  useEffect(() => {
    if (JSON.stringify(state) === JSON.stringify(initialState)) {
    } else {
      localStorage.setItem("stateData", JSON.stringify(state));
    }
  }, [state]);

  const signIn = async (email, password) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await api.post("/user/login", { email, password });
      const { token, user, permissions } = await response.data;

      const seenFeatureNames = new Set();
      const updatedCompanyFeatureArray = permissions.rolePermissions?.map(
        (item) => {
          const featureName = item.featureName;
          if (seenFeatureNames.has(featureName)) {
            return null;
          }
          seenFeatureNames.add(featureName);
          switch (featureName) {
            case "Listing technology":
              return {
                ...item,
                name: "Listing_Technology",
              };
            case "Emotion AI":
              return {
                ...item,
                name: "Emotion_AI",
              };
            case "Review management":
              return {
                ...item,
                name: "Review_Management",
              };
            case "Tasks":
              return {
                ...item,
                name: "Tasks",
              };
            case "Reports":
              return {
                ...item,
                name: "Reports",
              };
            case "Post management":
              return {
                ...item,
                name: "Post_Management",
              };
            case "Account Management":
              return {
                ...item,
                name: "account_settings",
              };
            default:
              return item;
          }
        }
      );

      const filteredCompanyFeatureArray =
        updatedCompanyFeatureArray?.filter(Boolean); // Remove null values

      localStorage.setItem(
        "rolePermissions",
        JSON.stringify(filteredCompanyFeatureArray)
      );

      localStorage.setItem("user", JSON.stringify(user));

      setSession(token);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
          userRole: user.role,
          permissions: filteredCompanyFeatureArray,
          permissionsAcess: permissions.rolePermissions,
        },
      });
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });

      return user;
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
      return error?.response;
    }
  };

  const signOut = async () => {
    localStorage.clear();

    setSession(null);
    dispatch({ type: SIGN_OUT });
  };
  const signUp = async (
    email,
    password,
    firstName,
    lastName,
    company,
    role,
    isPrimary
  ) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await api.post("/user/signup", {
        name: firstName + " " + lastName,
        email,
        password,
        firstName,
        lastName,
        company,
        role,
        isPrimary,
      });
      if (response.status === 200) {
        const { token } = await response.data;
        window.localStorage.setItem("accessToken", token);
      }

      return response;
    } catch (error) {
      const response = error?.response;

      return response;
    }
  };
  const switchAccount = async (user, role, permissions, rolePermissions) => {
    await dispatch({
      type: SIGN_IN,
      payload: {
        user,
        userRole: role,
        permissions: permissions,
        permissionsAcess: rolePermissions,
      },
    });
  };
  const changePassword = async (oldPassword, newPassword) => {
    var accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    var body = { oldPassword, newPassword };

    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await api.post("/auth/change-password", body, config);
      if (response.status === 200) {
        const { user, token } = await response.data;
        window.localStorage.setItem("accessToken", token);
        dispatch({
          type: CHANGE_PASSWORD,
          payload: {
            user: user,
          },
        });
        dispatch({
          type: SET_LOADING,
          payload: {
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: SET_LOADING,
          payload: {
            isLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
      dispatch({
        type: CHANGE_PASSWORD,
        payload: {
          user: error.response.data,
        },
      });
    }
  };
  const resetPassword = async (email) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await api.post("/auth/forgot-password", { email });
      if (response.status === 200) {
        const { token } = await response.data;
        window.localStorage.setItem("accessToken", token);
        dispatch({
          type: SET_LOADING,
          payload: {
            isLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
    }
  };
  const setPassword = async (password) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      var token = localStorage.getItem("accessToken");
      const response = await api.post("/auth/reset-password", {
        token,
        newPassword: password,
      });
      if (response.status === 200) {
        // const { status, data } = await response.data;

        dispatch({
          type: SET_LOADING,
          payload: {
            isLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
    }
  };
  const editProfile = async (email, firstName, lastName) => {
    var accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    dispatch({
      type: SET_LOADING,
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await api.patch(
        `/user/${state?.user?.id}`,
        {
          email,
          firstName,
          lastName,
        },
        config
      );
      if (response.status === 200) {
        let user = await response.data.data;
        // toast.success("success")
        dispatch({
          type: EDIT_PROFILE,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: SET_LOADING,
          payload: {
            isLoading: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: {
          isLoading: false,
        },
      });
    }
  };
  const setContextdata = async (email, firstName, lastName) => {
    var stateData = localStorage.getItem("stateData")
      ? JSON.parse(localStorage.getItem("stateData"))
      : initialState;
    dispatch({
      type: SET_CONTEXT,
      payload: stateData,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        setPassword,
        changePassword,
        editProfile,
        switchAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
