import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import BouncingDotsLoader from "../../../../../../components/Loaders/smallBounceLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";

import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import EditModal from "../../Modal";
import { useStyles } from "../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function Companycontactdetails({
  locationDetailsData,
  location,
  getLocationDetails,
  locationCategory,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          //onClose={handleCloseTemplateMenu}
        >
          <EditModal
            modalIndex={3}
            getLocationDetails={getLocationDetails}
            location={location}
            locationDetailsData={locationDetailsData}
            onCancel={handleCloseTemplateMenu}
            locationCategory={locationCategory}
            getLocationDetailsListing={getLocationDetailsListing}
            lodgingCategories={lodgingCategories}
            categoryLoading={categoryLoading}
            centerData={centerData}
            displayMapError={displayMapError}
          />
        </Dialog>
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {isLoading ? (
          <BouncingDotsLoader />
        ) : (
          <>
            {locationDetailsData !== null ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={8} sm={8} md={10} lg={11}>
                      <Typography className={classes.titleName}>
                        {t("Contact_Details")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={1}>
                      {editPermission && viewPermission ? (
                        <Button fullWidth onClick={handleOpenTemplateMenu}>
                          <Typography sx={{ fontWeight: 600 }}>
                            {t("Edit")}
                          </Typography>
                        </Button>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Button
                              disabled
                              fullWidth
                              sx={{ cursor: "pointer" }}
                              onClick={handleOpenTemplateMenu}
                            >
                              <Typography sx={{ fontWeight: 600 }}>
                                {t("Edit")}
                              </Typography>
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Telephone_Number")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headNameDesc}>
                        {locationDetailsData?.result?.google?.phoneNumbers
                          ?.primaryPhone
                          ? locationDetailsData?.result?.google?.phoneNumbers
                              ?.primaryPhone
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Email")}
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7} lg={7}>
                      <Typography className={classes.headNameDesc}>
                        {locationDetailsData?.result?.email
                          ? locationDetailsData?.result?.email
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container className={classes.listingGrid}>
                    <Grid item xs={5} sm={5} md={5} lg={5}>
                      <Typography className={classes.headName}>
                        {t("Website")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      sx={{ width: "40px" }}
                    >
                      <Typography className={classes.headNameDesc}>
                        {locationDetailsData?.result?.google?.websiteUri
                          ? locationDetailsData?.result?.google?.websiteUri
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NotFound text={t("No_Data_Found")} />
            )}
          </>
        )}
      </Card>
    </>
  );
}
export default Companycontactdetails;
