import React from "react";
import { languagesData } from "../../../../assets/defaultLanguage";
import {
  Typography,
  Grid,
  TextField,
  Modal,
  createFilterOptions,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Autocomplete, Box } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import { Divider, Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DatePickerComponent from "../../../../components/CustomComponents/DateRangePicker/RangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "95vh",
  width: "800px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainerHeader: {
    width: "100%",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    alignItems: "end",
    textAlign: "end",
    paddingTop: "8px",
  },
  whiteBackground: {
    background: "white",
    width: "98%",
  },
  whiteBackgroundTwo: {
    background: "white",
    width: "95%",
  },
}));

const EmotionAiFilterModal = ({
  handleChangeTopic,
  handleChangeKeywords,
  date,
  handleChangeDateFilters,
  ratingValue,
  handleCloseModalFilter,
  handleChangeRating,
  openModalFilter,
  resetHandler,
  allTopics,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeSentiment,
  sentimentValue,
  handleChangedateValue,
  dateValue,
  setStartDate,
  startDate,
  setBeforeDate,
  setAfterDate,
  endDate,
  topic,
  setEndDate,
  beforeDate,
  afterDate,
  setKeywords,
  keyword,
  allKeywords,
  getAllKeywords,
  getAllTopics,
  setDateRequired,
  dateRequired,
  allLocations,
  handleChangeLocation,
  handleChangeRange,
  range,
  dateValues,
  locations,
  handleChangeLanguage,
  language,
  allGroups,
  selectedGroups,
  handleChangeGroups,
  setSelectedGroups,
  setLocations,
  selectAllLocations,
  setSelectAllLocations,
  setSelectAll,
  selectAll,
  setCallApi,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  });

  let timer = 0;

  const ratingValues = [
    {
      label: `${t("1_star")}`,
      value: 1,
    },
    {
      label: `${t("2_star")}`,
      value: 2,
    },
    {
      label: `${t("3_star")}`,
      value: 3,
    },
    {
      label: `${t("4_star")}`,
      value: 4,
    },
    {
      label: `${t("5_star")}`,
      value: 5,
    },
  ];
  const sentimentsValues = [
    {
      label: `${t("positive")}`,
      value: "Positive",
    },
    {
      label: `${t("negative")}`,
      value: "Negative",
    },
    {
      label: `${t("neutral")}`,
      value: "Neutral",
    },
  ];

  return (
    <Modal
      open={openModalFilter}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid item>
          <Box sx={styleEditUser}>
            <Grid container lg={12} className={classes.mainContainer}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainContainerHeader}
              ></Grid>
            </Grid>

            <Box sx={{ display: "flex" }}>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Typography className={classes.mainContainerHeading}>
                  {t("filters_head")}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={{ justifyContent: "flex-end", textAlign: "end" }}
              >
                <IconButton
                  autoFocus
                  onClick={handleCloseModalFilter}
                  className="delete_button"
                  sx={{ paddingTop: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Box>

            <Divider my={6} />
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("Location")}
                </Typography>

                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  sx={{ width: "100%", padding: "8px" }}
                  options={allLocations?.length > 0 ? allLocations : []}
                  getOptionDisabled={(option) => {
                    if (option?.id === 0) {
                      return false;
                    }
                    if (option?.statusOfSentimentalAnalysis !== "done") {
                      return true;
                    }
                  }}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        ((option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null) ||
                        (option?.city !== "" && option?.city !== null)
                          ? "(" +
                            (option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? option?.addressLine1 + "," + " "
                              : "") +
                            (option?.city ? option?.city : "") +
                            ")"
                          : "")
                      : ""
                  }
                  className="Autocomplete-field"
                  value={locations ? locations : []}
                  onChange={(event, value) => {
                    setCallApi(false);
                    handleChangeLocation(value);
                    const filterLocations = allLocations?.filter(
                      (item) => item?.statusOfSentimentalAnalysis === "done"
                    );
                    if (value.find((option) => option.id === 0)) {
                      if (filterLocations?.length === locations?.length) {
                        setLocations([]);
                        setSelectAllLocations(false);
                      } else {
                        setSelectAllLocations(true);
                        setLocations(filterLocations);
                      }
                    } else {
                      setLocations(value);
                      if (value?.length === filterLocations?.length) {
                        setSelectAllLocations(true);
                      } else {
                        setSelectAllLocations(false);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [
                      { id: 0, internalName: t("Select_All") },
                      ...filtered,
                    ];
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        disabled={
                          option?.id !== 0 &&
                          option?.statusOfSentimentalAnalysis !== "done"
                        }
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          (option?.id === 0 && selectAllLocations) ||
                          (selected &&
                            option?.statusOfSentimentalAnalysis === "done")
                        }
                      />

                      <Grid
                        container
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <span style={{ textAlign: "start" }}>
                            {`${option?.internalName} ${
                              option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? "(" + option?.addressLine1 + "," + " "
                                : ""
                            } ${option?.city ? option?.city + ")" : ""}`}
                          </span>
                        </Grid>

                        {option?.statusOfSentimentalAnalysis && (
                          <Grid item={3} sm={3} md={3} lg={3}>
                            <span
                              style={{
                                textAlign: "center",

                                color:
                                  option?.statusOfSentimentalAnalysis === "done"
                                    ? "#13CF8F"
                                    : option?.reviewFoundStatus === false
                                    ? "#FC3652"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "requested"
                                    ? "#F86F35"
                                    : option?.statusOfSentimentalAnalysis ===
                                      "inProgress"
                                    ? "#FC3652"
                                    : option?.reviewFoundStatus === true &&
                                      option?.statusOfSentimentalAnalysis ===
                                        "pending"
                                    ? "#F8A92B"
                                    : "",
                              }}
                            >
                              {option?.statusOfSentimentalAnalysis === "done"
                                ? "• " + `${t("Done")}`
                                : ""}
                              {option?.reviewFoundStatus === false
                                ? "• " + `${t("No_reviews")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "requested"
                                ? "• " + `${t("requested_state")}`
                                : ""}
                              {option?.statusOfSentimentalAnalysis ==
                              "inProgress"
                                ? "• " + `${t("inProgress")}`
                                : ""}
                              {option?.reviewFoundStatus === true &&
                              option?.statusOfSentimentalAnalysis === "pending"
                                ? "• " + `${t("Pending")}`
                                : ""}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("groups_label")}
                </Typography>

                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  sx={{ width: "100%", padding: "8px" }}
                  options={
                    allGroups !== null && allGroups?.rows?.length > 0
                      ? allGroups?.rows
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  className="Autocomplete-field"
                  value={selectedGroups ? selectedGroups : []}
                  onChange={(e, value) => {
                    setCallApi(false);
                    if (value.find((option) => option.id === 0)) {
                      if (selectedGroups?.length === allGroups?.rows?.length) {
                        setSelectedGroups([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        handleChangeGroups(allGroups?.rows);
                      }
                    } else {
                      handleChangeGroups(value);
                      if (value?.length === allGroups?.rows?.length) {
                        setSelectAll(true);
                      } else {
                        setSelectAll(false);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (allGroups?.rows?.length > 0) {
                      return [{ id: 0, name: "Select All" }, ...filtered];
                    } else {
                      return [];
                    }
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selectAll || selected}
                      />

                      <Typography>{option?.name}</Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_group")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("Datee")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dateValues}
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: "100%", padding: "8px" }}
                  //  value={locationId}
                  value={range}
                  onChange={(e, value) => {
                    setCallApi(false);
                    handleChangeRange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select Range")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} sx={{ marginLeft: "0px" }}>
                  {range !== null && range?.value === "gt" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.fieldLabel}>
                        {t("Fromm")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          /*  sx={{
                            // width: "320px",
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "320px",
                            background: "blue",
                          }} */
                          disableFuture
                          className={classes.whiteBackground}
                          format="DD/MM/YYYY"
                          minDate={dayjs("2005-01-01")}
                          maxDate={dayjs().endOf("day")}
                          //  disabled={range !== null && range?.value === "gt"}
                          placeholder={t("From")}
                          value={
                            startDate !== "" && startDate !== null
                              ? dayjs(startDate)
                              : null
                          }
                          onChange={(e) => {
                            setCallApi(false);
                            if (range?.value === "between") {
                              handleChangeStartDate(e);
                              setDateRange({
                                startDate: new Date(e),
                                endDate: new Date(endDate),
                              });
                            } else {
                              handleChangeStartDate(e);
                              const today = new Date();
                              handleChangeEndDate(today);
                              setDateRange({
                                startDate: new Date(e),
                                endDate: new Date(today),
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              sx={{
                                padding: "8px",
                                background: "#FFFF",
                                wdith: "100%",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {range !== null && range?.value === "lt" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.fieldLabel}>
                        {t("Too")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          /*   sx={{
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "320px",
                            background: "blue",
                          }} */
                          /*  disabled={
                            startDate===null ||
                            (range !== null && range?.value === "lt")
                          } */
                          disableFuture
                          className={classes.whiteBackgroundTwo}
                          format="DD/MM/YYYY"
                          minDate={dayjs("2005-01-01")}
                          maxDate={dayjs().endOf("day")}
                          value={
                            endDate !== "" && endDate !== null
                              ? dayjs(endDate)
                              : null
                          }
                          onChange={(e) => {
                            setCallApi(false);
                            if (range?.value === "between") {
                              const today = new Date();
                              let selectedDate = dayjs(e).format("YYYY-MMM-DD");
                              let todayDate =
                                moment(today).format("YYYY-MMM-DD");

                              handleChangeEndDate(e.endOf("day"));

                              setDateRange({
                                startDate: new Date(startDate),
                                endDate: new Date(e.endOf("day")),
                              });
                            } else {
                              let selectedDate = dayjs(e).format("YYYY-MMM-DD");
                              let todayDate =
                                moment(today).format("YYYY-MMM-DD");

                              handleChangeEndDate(e.endOf("day"));

                              const newEndDate = dayjs(e);

                              const today = new Date();
                              const previousYear = new Date(
                                newEndDate.year() - 1,
                                newEndDate.month(),
                                newEndDate.date()
                              );
                              setDateRange({
                                startDate: new Date(previousYear),
                                endDate: new Date(e.endOf("day")),
                              });

                              handleChangeStartDate(previousYear);
                            }

                            //   setEndDate(e);
                          }}
                          placeholder={t("To")}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              //  error={dateRequired}
                              sx={{
                                padding: "8px",
                                background: "#FFFF",
                                //   wdith: "100%",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
                </Grid>

                {range !== null && range?.value === "between" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ padding: "0px" }}
                  >
                    <DatePickerComponent
                      handleChangeStartDate={handleChangeStartDate}
                      handleChangeEndDate={handleChangeEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      fromFilters={true}
                      rangeValue={"between"}
                      removePadding={false}
                    />
                  </Grid>
                )}
                {/*    <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dateValues}
                  getOptionLabel={(option) =>
                    option?.label ? option.label : ""
                  }
                  sx={{ width: "100%" }}
                  defaultValue={dateValue}
                  // onBlur={handleBlur}
                  onChange={(e, value) => {
                    handleChangedateValue(value);
            
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Datee")}
                      {...params}
                      required
                      name="language"
                      sx={{ padding: "8px" }}
                    />
                  )}
                ></Autocomplete>

                {dateValue === "" ? (
                  <></>
                ) : dateValue === "between" ? (
                  <Grid container spacing={2} lg={12} sx={{ display: "flex" }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography className={classes.fieldLabel}>
                        {t("To")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          sx={{
                            // width: "320px",
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "320px",
                            background: "blue",
                          }}
                          disableFuture={true}
                          value={startDate}
                          onChange={(e) => {
                            handleChangeStartDate(e);
                            setAfterDate(null);
                            setBeforeDate(null);
                            setDateRequired(true);
                         
                          }}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              sx={{ padding: "8px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography className={classes.fieldLabel}>
                        {t("From")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          sx={{
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "320px",
                            background: "blue",
                          }}
                          disableFuture={true}
                          value={endDate}
                          onChange={(e) => {
                            if (e) {
                              setDateRequired(false);
                              handleChangeEndDate(e);
                            } else {
                              setDateRequired(true);
                            }

                            setAfterDate(null);
                            setBeforeDate(null);
                        
                            //   setEndDate(e);
                          }}
                          renderInput={(params) => (
                            <TextField
                              autoComplete="off"
                              fullWidth
                              {...params}
                              error={dateRequired}
                              sx={{ padding: "8px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                ) : dateValue === "lt" ? (
                  <Grid container sx={{ display: "flex" }}>
                    <Typography className={classes.fieldLabel}>
                      {t("Before")}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        sx={{
                          // width: "320px",
                          border: "1px solid #E0E0E0",
                          width: "100%",
                          background: "blue",
                        }}
                        disableFuture={true}
                        value={beforeDate}
                        onChange={(e) => {
                          if (e) {
                            handleChangeDateFilters(e);
                          }

                          setBeforeDate(e);
                          setAfterDate(null);
                          setStartDate(null);
                   
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            fullWidth
                            {...params}
                            error={false}
                            sx={{ padding: "8px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                ) : dateValue === "gt" ? (
                  <Grid
                    container
                    lg={12}
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.fieldLabel}>
                        {t("After")}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          sx={{
                            // width: "320px",
                            border: "1px solid #E0E0E0",
                            width: "100%",
                            background: "blue",
                          }}
                          disableFuture={true}
                          value={afterDate}
                          onChange={(e) => {
                            if (e) {
                              handleChangeDateFilters(e);
                            }

                            setAfterDate(e);
                            setBeforeDate(null);
                            setEndDate(null);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={false}
                              sx={{ width: "100%", padding: "8px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )} */}
              </Grid>

              {/*  <Grid xs={12} sm={12} md={6} lg={4}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.fieldLabel}>
                    {t("Topicss")}
                  </Typography>
                </Grid>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allTopics?.results?.length > 0 ? allTopics?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.tagName ? option.tagName : ""
                  }
                  className="Autocomplete-field"
                  value={topic}
                  onChange={(e, value) => {
                    handleChangeTopic(value);
            
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Topicss")}
                      {...params}
                      required
                      name="language"
                      sx={{ padding: "8px" }}
                      onChange={(e, newValue) => {
                        clearTimeout(timer);
                        // field.onChange(newValue);
                        timer = setTimeout(
                          () => getAllTopics(e.target.value),
                          1000
                        );
                      }}
                    />
                  )}
                ></Autocomplete>
              </Grid>
 */}
              {/*    <Grid xs={12} sm={12} md={6} lg={4}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.fieldLabel}>
                    {t("Keywords")}
                  </Typography>
                </Grid>
          

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allKeywords?.results?.length > 0 ? allKeywords?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.tagName ? option.tagName : ""
                  }
                  className="Autocomplete-field"
                  value={keyword}
                  onChange={(e, value) => {
                    handleChangeKeywords(value);
                    setKeywords(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Keywords")}
                      {...params}
                      required
                      name="language"
                      sx={{ padding: "8px" }}
                      onChange={(e, newValue) => {
                        clearTimeout(timer);
                        // field.onChange(newValue);
                        timer = setTimeout(
                          () => getAllKeywords(e.target.value),
                          1000
                        );
                      }}
                    />
                  )}
                ></Autocomplete>
              </Grid>
 */}

              <Grid xs={12} sm={12} md={6} lg={6}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.fieldLabel}>
                    {t("Language_review")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "8px" }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={languagesData?.length > 0 ? languagesData : []}
                    value={language}
                    onChange={(e, value) => {
                      setCallApi(false);
                      handleChangeLanguage(value);
                      // setTouchedValue(true);
                    }}
                    getOptionLabel={(option) =>
                      option?.language ? option?.language : ""
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select_languages")}
                        {...params}
                        // error={item?.languageError}
                      />
                    )}
                  />
                  {/*   <Autocomplete
                    multiple
                    //  error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    options={ratingValues}
                    getOptionLabel={(option) =>
                      option?.label ? option.label : ""
                    }
                    defaultValue={ratingValue}
                    // onBlur={handleBlur}
                    onChange={(e, value) => {
                      handleChangeRating(value);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.label}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        placeholder={t("Ratings")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete> */}
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.fieldLabel}>
                    {t("Sentiment")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "8px" }}
                >
                  <Autocomplete
                    //  multiple
                    //  error={locationRequired}
                    disablePortal
                    id="combo-box-demo"
                    options={sentimentsValues}
                    getOptionLabel={(option) =>
                      option?.label ? option.label : ""
                    }
                    value={sentimentValue}
                    sx={{ minWidth: 300 }}
                    // value={locationId ? locationId : []}
                    onChange={(e, value) => {
                      setCallApi(false);
                      handleChangeSentiment(value);
                    }}
                    /*   renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.label}`}
                      </li>
                    )} */
                    renderInput={(params) => (
                      <TextField
                        name="language"
                        {...params}
                        placeholder={t("Sentiments")}
                        //  defaultValue={{ selectedLocation }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.buttonContainer}
              >
                <CommonButton
                  displayBlack="true"
                  icon={<RefreshIcon />}
                  label={t("Reset")}
                  onSubmit={() => {
                    resetHandler();
                    //  handleCloseModalFilter();
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleCloseModalFilter();
                    setCallApi(true);
                  }}
                  disabled={dateRequired}
                  sx={{
                    height: "52px",
                    // width: "88px",
                    // padding: "14px 20px",
                    fontSize: "14px",
                    marginLeft: "4px",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    /*   background:
                              displayWhite === "true"
                                ? "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x"
                                : "" || displayBlack === "true"
                                ? "#1B2430"
                                : "", */

                    border: "none",
                  }}
                >
                  {t("Apply_filters")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EmotionAiFilterModal;
