import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  TextareaAutosize,
  Tooltip,
  tooltipClasses,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PostAIModal from "../../../../../../../components/Models/AIModal/ListingAIModal";
import KeywordAIModal from "../../../../../../../components/Models/AIModal/KeywordAIModal";
import { Dialog, DialogActions, Modal } from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { styled } from "@mui/system";
import { ReactComponent as AiLogoGrey } from "../../../../../../../assets/images/AIlogogrey.svg";
import { Alert } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CompanyDescription = ({
  locationDetailsData,
  onCancel,
  location,
  getLocationDetails,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  lodgingCategories,
  setIndexValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [contentRequired, setContentRequired] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [openKeywordModel, setOpenKeywordModel] = useState(false);
  const [tags, setTags] = useState([]);
  const [aiTags, setAiTags] = useState([]);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [tagsAIRequired, setTagsAIRequired] = useState(false);
  const [tagsLimitError, setTagsLimitError] = useState(false);
  const [description, setDescription] = useState("");
  const [postData, setPostData] = useState([]);
  const [shortDescription, setShortDescription] = useState("");
  const [locationKeywords, setLocationKeywords] = useState("");
  const [newTags, setNewTags] = useState([]);
  const [newDescription, setNewDescription] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [openFrom, setOpenFrom] = useState(null);
  const [shortDescError, setShortDescError] = useState("");
  const [longDescError, setLongDescError] = useState("");
  const [height, setHeight] = useState("auto");
  const [duplicateBrand, setDuplicateBrand] = useState(false);

  let tempPostData = [...postData];
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  // const validateInput = (input) => {
  //   const regex =
  //     /^[0-9\p{L}\(\)\[\] \?:;\/!\\,\.\-%\\&=\r\n\t_\*§²`´·"'¡¿@°€£$]*$/u;
  //   return regex.test(input);
  // };

  const handleInputShortDescChange = (e) => {
    setTouchedValue(true);
    const inputValue = e.target.value;
    setShortDescription(inputValue);

    // if (!validateInput(inputValue)) {
    //   setShortDescError("Invalid input");
    // } else {
    //   setShortDescError("");
    // }
  };

  const handleInputLongDescChange = (e) => {
    setTouchedValue(true);
    const inputValue = e.target.value;
    setDescription(inputValue);

    // if (!validateInput(inputValue)) {
    //   setLongDescError("Invalid input");
    // } else {
    //   setLongDescError("");
    // }
  };

  const hotelCategoryArr = [
    "categories/gcid:hotel",
    "categories/gcid:extended_stay_hotel",
    "categories/gcid:resort_hotel",
    "categories/gcid:capsule_hotel",
    "categories/gcid:love_hotel",
    "categories/gcid:hostel",
    "categories/gcid:motel",
    "categories/gcid:resort_hotel",
    "categories/gcid:lodge",
    "categories/gcid:camping_cabin",
    "categories/gcid:inn",
    "categories/gcid:guest_house",
    "categories/gcid:farm",
    "categories/gcid:religious_lodging",
    "categories/gcid:apartment_building",
    "categories/gcid:apartment_complex",
    "categories/gcid:apartment_rental_agency",
    "categories/gcid:chalet",
    "categories/gcid:cottage",
    "categories/gcid:villa",
    "categories/gcid:campground",
  ];

  useEffect(() => {
    setTags(
      locationDetailsData?.result?.keywords !== null &&
        locationDetailsData?.result?.keywords !== undefined
        ? locationDetailsData?.result?.keywords
        : []
    );

    setDescription(
      locationDetailsData?.result?.google?.profile?.description !== null
        ? locationDetailsData?.result?.google?.profile?.description
        : ""
    );

    setShortDescription(
      locationDetailsData?.result?.shortDescription !== null
        ? locationDetailsData?.result?.shortDescription
        : ""
    );
  }, []);

  useEffect(
    (e) => {
      if (tags?.length > 20) {
        setTagsLimitError(true);
      } else {
        setTagsLimitError(false);
      }
    },
    [tags]
  );

  const handleKeyDown = (e) => {
    setTouchedValue(true);
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...tags, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
    }
    const lowerCaseBrandTags = tags.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateBrand(true);
      setTimeout(() => {
        setDuplicateBrand(false);
      }, 2000);
    } else {
      for (let i = 0; i < tags.length; i++) {
        if (filtered.includes(tags[i])) {
          setDuplicateBrand(true);
          setTimeout(() => {
            setDuplicateBrand(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
    const textField = e.target;
    // Update height based on content
    setHeight(textField.scrollHeight + "px");
  };

  const handleAddKeyword = (e) => {
    // let value = e.target.value;
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(tags);
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(tags);
      const mergeResult = [...tags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(tags);
      const mergeResult = [...tags, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setTags(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = tags.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateBrand(true);
    } else {
      setDuplicateBrand(false);
    }

    /*   const newValue = e.target.value.replaceAll(",", "");
    const newValueTwo = e.target.value.replaceAll(".", "");
   
    setTags([...tags, newValueTwo]);
    e.target.value = ""; */
  };
  const handleOpenTemplateMenu = (key) => {
    setOpenDefaultModel(true);
    setOpenFrom(key);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    // setOpenFrom(null);
  };
  const handleOpenKeywordMenu = () => {
    setOpenKeywordModel(true);
  };
  const handleCloseKeywordMenu = () => {
    setOpenKeywordModel(false);
  };
  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index));
  };

  const handleChangeAIDescription = (e) => {
    setDescription(e);
  };
  const handleChangeAIKeywords = (e) => {
    setTags(e);
  };
  const changeHandler = (index, itemName, value) => {
    if (index !== undefined) {
      tempPostData[index][`${itemName}`] = value;

      setPostData(tempPostData);
    } else {
      tempPostData?.map((item) => {
        item[`${itemName}`] = value;
      });
      setPostData(tempPostData);
    }
  };
  const generateContentKeywords = async () => {
    try {
      setKeywordLoading(true);
      const response = await api.get(
        `locationListing/create-keywords-openai/${location?.id}`
      );
      if (response.status === 200) {
        setLocationKeywords(response?.data?.data?.keywords);
        setKeywordLoading(false);
        handleOpenKeywordMenu();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setKeywordLoading(false);
    }
  };

  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = AiContentDescription.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = AiContentDescription.concat(str2);
    }

    generateContent(finalStr);
  };

  const handleSubmit = () => {
    handleCloseTemplateMenu();
    handleCloseKeywordMenu();
    setTouchedValue(true);
    if (openFrom !== null && openFrom === "long") {
      handleChangeAIDescription(generatedContent);
    } else {
      setShortDescription(generatedContent);
    }

    //handleChangeAIKeywords(locationKeywords);

    // setDescription(generatedContent?.length);
  };
  const handleSubmitKeywords = () => {
    handleCloseKeywordMenu();

    handleChangeAIKeywords(locationKeywords);

    // setDescription(generatedContent?.length);
  };

  const handleUpdateDescription = async () => {
    if (
      tagsLimitError ||
      tagsRequired ||
      description?.length > 750 ||
      shortDescription?.length > 200
    ) {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);
        const res = await api.patch(
          `/locationListing/update-profileDescription/${location?.id}`,
          {
            profile: {
              description: description,
            },
            keywords: tags,
            shortDescription: shortDescription,
          }
        );
        if (indexValue !== null) {
          let selectedValue = indexValue;

          setSelectedTab(selectedValue);
        }
        if (cancelState === true) {
          onCancel();
        }
        if (res.status === 200) {
          setIsLoading(false);
          setTouchedValue(false);
          toast.success("Updated Successfully");

          // onCancel();
          // getLocationDetails(location);
          getLocationDetailsListing(location);
          // setLocationDetailsData(res?.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  return (
    <>
      {" "}
      {openDefaultModel && (
        <Dialog maxWidth maxHeight open={openDefaultModel}>
          <Box sx={{ minHeight: "450px" }}>
            <PostAIModal
              title={t("AI_Writer")}
              onCancel={handleCloseTemplateMenu}
              aiTags={aiTags}
              setAiTags={setAiTags}
              displayError={displayError}
              AiContentDescription={AiContentDescription}
              setAiContentDescription={setAiContentDescription}
              locationDetailsData={locationDetailsData}
              generatedContent={generatedContent}
              setGeneratedContent={setGeneratedContent}
              // setDescription={setDescription}
              setTagsRequired={setTagsRequired}
              tagsAIRequired={tagsAIRequired}
              setContentRequired={setContentRequired}
              contentRequired={contentRequired}
              openFrom={openFrom}
            />
          </Box>
          <Divider />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  /*   <CommonButton
                displayWhite="true"
                onSubmit={generateAIContent}
                label={<RefreshIcon />}
                disabled={loading}
                // label={t("Cancel")}
              /> */
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Write For Me")}
                disabled={loading}
                onSubmit={generateAIContent}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Mee")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Submit")}
                disabled={loading}
                onSubmit={handleSubmit}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {openKeywordModel && (
        <Dialog maxWidth maxHeight open={openKeywordModel}>
          <Box sx={{ minHeight: "450px" }}>
            <KeywordAIModal
              title={t("AI_Writer")}
              onCancel={handleCloseKeywordMenu}
              tags={tags}
              setTags={setTags}
              displayError={displayError}
              AiContentDescription={AiContentDescription}
              setAiContentDescription={setAiContentDescription}
              locationDetailsData={locationDetailsData}
              generateContentKeywords={generateContentKeywords}
              locationKeywords={locationKeywords}
              setLocationKeywords={setLocationKeywords}
              setGeneratedContent={setGeneratedContent}
              // setDescription={setDescription}
              setTagsRequired={setTagsRequired}
              tagsAIRequired={tagsAIRequired}
              setContentRequired={setContentRequired}
              contentRequired={contentRequired}
            />
          </Box>
          <Divider />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LoadingButton
                  loading={keywordLoading}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  disabled={keywordLoading}
                  onClick={() => generateContentKeywords()}
                  className={classes.postLoadingButton}
                >
                  <RefreshIcon />
                </LoadingButton>

                {/* {generatedContent?.length===0 && (

                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Mee")}
                  </LoadingButton>
                )} */}
                {locationKeywords?.length > 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Submit")}
                disabled={loading}
                onSubmit={handleSubmit}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmitKeywords()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.postSubmitButton}
                  >
                    {t("Done")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid item xs={10} sm={11} md={11.5} lg={11.5}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.modalHeader}>
                {t("Company_Desc")}
              </Typography>
              <BootstrapTooltip title={t("Company_Desc_Subhead")}>
                <Typography
                  sx={{
                    marginLeft: "5px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                >
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
            <IconButton
              autoFocus
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container className={classes.actionContent}>
          {displayConfirmationBox && (
            <Dialog
              // fullScreen={fullScreen}
              open={displayConfirmationBox}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <ConfirmModal
                title={t("Delete_Bulk_Posts")}
                description={t("Delete_post_subhead")}
                onConfirm={handleUpdateDescription}
                onClose={handleClose}
                onCancel={onCancel}
                indexValue={indexValue}
                setSelectedTab={setSelectedTab}
                cancelState={cancelState}
                touchedValue={touchedValue}
                setTouchedValue={setTouchedValue}
                getLocationDetails={getLocationDetails}
                location={location}
                descriptions={description !== "" && description}
                //  loading={setIsLoading}
              />
            </Dialog>
          )}
          {isLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "18px", paddingTop: "50px", width: "100%" }}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <>
              <Grid container sx={{ padding: "16px" }}>
                <Grid container>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    lg={12}
                    sx={{ marginTop: "12px" }}
                  >
                    <Typography className={classes.fieldTextDescription}>
                      {t("add_Keyword")}
                    </Typography>

                    <Grid
                      className={
                        tagsRequired === false && tagsLimitError === false
                          ? "tags-input-container-keywords"
                          : "tags-input-container-error"
                      }
                      sx={{
                        maxHeight: "190px",
                        overflowY: "scroll",
                        display: "flex",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={11} sm={11} md={11} lg={11}>
                          {tags?.length > 0 &&
                            tags.map((keywords, index) => (
                              <div className={classes.tagItemnew} key={index}>
                                <Box className={classes.keywordBoxx}>
                                  <Typography className={classes.keywordTypo}>
                                    {keywords}
                                  </Typography>{" "}
                                  <CloseIcon
                                    className={classes.keywordIcon}
                                    onClick={() => removeTag(index)}
                                  />
                                </Box>
                              </div>
                            ))}

                          <TextField
                            variant="standard"
                            rows={4}
                            maxRows={20}
                            multiline
                            placeholder={t("Enter_Keywords")}
                            className={classes.tagsInput}
                            onChange={(e) => {
                              if (
                                e.target.value === "," ||
                                e.target.value === "." ||
                                e.target.value.trim() === ""
                              ) {
                                e.target.value = "";
                              } else if (
                                (e.target.value !== "" &&
                                  e.target.value !== "," &&
                                  e.target.value.includes(".")) ||
                                (e.target.value !== "." &&
                                  e.target.value !== "" &&
                                  e.target.value.includes(","))
                              ) {
                                handleAddKeyword(e);
                                setTouchedValue(true);
                              }
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            onKeyDown={handleKeyDown}
                            disabled={tags?.length > 20}
                            style={{
                              width: "100%",
                              marginLeft: "5px",
                              borderBottom: "none",
                              marginTop: "5px",
                              height: height,
                              padding: "0px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          sx={{ alignSelf: "end", textAlign: "end" }}
                        >
                          <BootstrapTooltip title={t("A.I_keywords")}>
                            <IconButton
                              onClick={() => {
                                generateContentKeywords();
                              }}
                            >
                              {keywordLoading ? (
                                <>
                                  <CircularProgress
                                    color="secondary"
                                    style={{ width: "28px", height: "28px" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <AiLogoGrey
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                </>
                              )}
                            </IconButton>
                          </BootstrapTooltip>
                        </Grid>
                      </Grid>

                      {/* <IconButton onClick={handleOpenKeywordMenu}> */}
                    </Grid>

                    {duplicateBrand ? (
                      <Typography className={classes.errorText}>
                        {t("keywords_duplicated_error")}
                      </Typography>
                    ) : tagsRequired ? (
                      <Typography className={classes.errorText}>
                        {t("Enter_Keywords_Msg")}
                      </Typography>
                    ) : tagsLimitError ? (
                      <Typography className={classes.errorText}>
                        {t("Keywords_limit_text")}
                      </Typography>
                    ) : (
                      <Typography className={classes.helpingTextkeyword}>
                        {t("Enter_Keyword_text")}
                      </Typography>
                    )}
                  </Grid>
                  <Divider />
                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography className={classes.fieldTextDescription}>
                        {t("Short_Description")}
                      </Typography>{" "}
                      <BootstrapTooltip
                        title={
                          <h1 style={{ fontSize: "12px" }}>
                            {t("Short_Description_tooltip")}
                          </h1>
                        }
                      >
                        <ErrorOutlineIcon
                          sx={{
                            color: "#1B2430",
                            width: "18px",
                            marginLeft: "5px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Box>

                    <Typography className={classes.limitFieldTextDescription}>
                      {shortDescription === "" || shortDescription?.length === 0
                        ? 0
                        : shortDescription?.length}
                      /200{" "}
                    </Typography>
                  </Box>

                  {shortDescription?.length >= 200 && (
                    <Typography className={classes.textLimitTypo}>
                      {t("Text_Limit_Short_Description")}
                    </Typography>
                  )}
                  <TextField
                    id="standard-basic"
                    rows={5}
                    maxRows={20}
                    multiline
                    type="text"
                    name="shortDescription"
                    fullWidth
                    value={shortDescription}
                    onChange={handleInputShortDescChange}
                    // helperText={shortDescError}
                    // error={Boolean(shortDescError)}
                    InputProps={{
                      endAdornment: (
                        <Box sx={{ marginRight: "22px" }}>
                          <InputAdornment position="bottom">
                            <Box className={classes.postAIBox}>
                              <BootstrapTooltip title={t("A.I_desc_short")}>
                                <IconButton
                                  onClick={() =>
                                    handleOpenTemplateMenu("short")
                                  }
                                >
                                  <AiLogoGrey />
                                </IconButton>
                              </BootstrapTooltip>
                            </Box>
                          </InputAdornment>
                        </Box>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Box sx={{ display: "flex" }}>
                      <Typography className={classes.fieldTextDescription}>
                        {t("Long_Description")}
                      </Typography>{" "}
                      <BootstrapTooltip
                        title={
                          <h1 style={{ fontSize: "12px" }}>
                            {t("Long_Description_tooltip")}
                          </h1>
                        }
                      >
                        <ErrorOutlineIcon
                          sx={{
                            color: "#1B2430",
                            width: "18px",
                            marginLeft: "5px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Box>
                    <Typography className={classes.limitFieldTextDescription}>
                      {description === "" || description?.length === 0
                        ? 0
                        : description?.length}
                      /750{" "}
                    </Typography>
                  </Box>

                  {description?.length >= 750 && (
                    <Typography className={classes.textLimitTypo}>
                      {t("Text_Limit_Description")}
                    </Typography>
                  )}
                  <TextField
                    id="standard-basic"
                    rows={10}
                    maxRows={20}
                    multiline
                    type="text"
                    name="locationdescription"
                    fullWidth
                    value={description}
                    onChange={handleInputLongDescChange}
                    // helperText={longDescError}
                    // error={Boolean(longDescError)}
                    InputProps={{
                      endAdornment: (
                        <Box sx={{ marginRight: "22px" }}>
                          <InputAdornment position="bottom">
                            <Box className={classes.postAIBox}>
                              <BootstrapTooltip title={t("A.I_desc")}>
                                <IconButton
                                  onClick={() => handleOpenTemplateMenu("long")}
                                >
                                  <AiLogoGrey />
                                </IconButton>
                              </BootstrapTooltip>
                            </Box>
                          </InputAdornment>
                        </Box>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={
              isLoading ||
              tagsLimitError ||
              tagsRequired ||
              description?.length > 750 ||
              shortDescription?.length > 200
              // shortDescError ||
              // longDescError
            }
            onSubmit={handleUpdateDescription}
            label={t("Save")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDescription;
