import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { useTranslation } from "react-i18next";
import BouncingDotsLoader from "../../../../../../components/Loaders/bounsingLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import EditModal from "../../Modal";
import { useStyles } from "../../../Styles/style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocationMap from "./map";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const containerStyle = {
  width: "100%",
  height: "400px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Locationmap({
  location,
  getLocationDetails,
  locationDetailsData,
  allAttributes,
  locationCategory,
  setLocationDetailsData,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  // const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

  const center = {
    lat: locationDetailsData?.result?.google?.latlng?.latitude,
    lng: locationDetailsData?.result?.google?.latlng?.longitude,
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <EditModal
            modalIndex={13}
            locationCategory={locationCategory}
            onCancel={handleCloseTemplateMenu}
            getLocationDetails={getLocationDetails}
            location={location}
            locationDetailsData={locationDetailsData}
            allAttributes={allAttributes}
            setLocationDetailsData={setLocationDetailsData}
            getLocationDetailsListing={getLocationDetailsListing}
            lodgingCategories={lodgingCategories}
            categoryLoading={categoryLoading}
            centerData={centerData}
            displayMapError={displayMapError}
          />
        </Dialog>
      )}
      <Card variant="outlined" className={classes.listingCard}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container className={classes.listingGrid}>
              <Grid item xs={8} sm={8} md={9} lg={10}>
                <Typography className={classes.titleName}>
                  {t("Map")}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={2}>
                {editPermission && viewPermission ? (
                  <Button fullWidth onClick={handleOpenTemplateMenu}>
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Edit")}
                    </Typography>
                  </Button>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <Button
                        disabled
                        fullWidth
                        onClick={handleOpenTemplateMenu}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("Edit")}
                        </Typography>
                      </Button>
                    </span>
                  </BootstrapTooltip>
                )}
              </Grid>
            </Grid>

            <>
              <LocationMap
                centerData={centerData}
                containerStyle={containerStyle}
                displayMapError={displayMapError}
                locationDetailsData={locationDetailsData}
                requestSend={true}
              />

              {/*  {locationDetailsData !== null ? (
                <Grid container>
                  {centerData?.lat !== "" && centerData?.lng !== "" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LoadScript googleMapsApiKey="AIzaSyDFJLhlAmlDBZS06eElolQSAL0BZA_X-qY">
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={centerData}
                          zoom={10}
                        >
                          <Marker position={centerData}></Marker>
                          <></>
                        </GoogleMap>
                      </LoadScript>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <NotFound text={t("No_Data_Found")} />
              )} */}
            </>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
export default Locationmap;
