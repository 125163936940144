import React from "react";
import { Grid, Card, Typography, Tooltip, tooltipClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import ErrorIcon from "../../../assets/images/ErrorIcon.svg";
import CommonButton from "../Buttons/CommonButton";
import Googleicon from "../../../assets/Icons/Group.svg";
import { useNavigate } from "react-router-dom";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const ReconnectCard = ({
  location,
  displayReconnectButton,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/user/token-expire/location/${location?.id}`);
  };
  const handleNavigationToLocations = () => {
    navigate(`/user/locations`);
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card
          sx={{
            bgcolor: "#FFD0D0",
            borderRadius: "8px",
            height: "100%",
            position: "relative",
          }}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              paddingY: "8px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              sx={{
                textAlign: "center",
                marginTop: "4px",
                paddingY: "4px",
              }}
            >
              <img src={ErrorIcon} alt="err" />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "700", color: "#FF1616" }}
              >
                {t("Google_Connection_Expired")}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400", color: "#330404" }}
              >
                {displayReconnectButton
                  ? t("reconnect_message1") +
                    location?.internalName +
                    t("reconnect_message2")
                  : t("reconnect_message")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                paddingRight: "5px",
              }}
            >
              {displayReconnectButton ? (
                editPermission && viewPermission ? (
                  <CommonButton
                    icon={
                      <img
                        src={Googleicon}
                        style={{ width: 20, height: 20, marginRight: "3px" }}
                        alt={"/google Icon"}
                      />
                    }
                    variant="contained"
                    onSubmit={handleNavigation}
                    label={t("Reconnect")}
                  />
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <CommonButton
                        icon={
                          <img
                            src={Googleicon}
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: "3px",
                            }}
                            alt={"/google Icon"}
                          />
                        }
                        variant="contained"
                        onSubmit={handleNavigation}
                        label={t("Reconnect")}
                        disabled={true}
                      />
                    </span>
                  </BootstrapTooltip>
                )
              ) : viewPermission ? (
                <CommonButton
                  variant="contained"
                  onSubmit={handleNavigationToLocations}
                  label={t("Check_locations")}
                  // disabled={editPermission}
                />
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <CommonButton
                      variant="contained"
                      onSubmit={handleNavigationToLocations}
                      label={t("Check_locations")}
                      disabled={true}
                    />
                  </span>
                </BootstrapTooltip>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default ReconnectCard;
