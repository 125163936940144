import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  TextField,
  Alert as MuiAlert,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { TextareaAutosize } from "@material-ui/core";
import { fontWeight, spacing } from "@mui/system";
import styled from "styled-components/macro";
import { deepLanguages } from "../../../assets/defaultLanguage/deepL";
import { useStyles } from "./Styles/style";

const Alert = styled(MuiAlert)(spacing);

const KeywordAIModal = ({
  title,
  onCancel,
  tags,
  setTags,
  AiContentDescription,
  setAiContentDescription,
  generatedContent,
  setGeneratedContent,
  locationKeywords,
  setLocationKeywords,
  setTagsAIRequired,
  tagsAIRequired,
  contentRequired,
  setContentRequired,
  displayError,
  locationDetailsData,
  generateContentKeywords,
}) => {
  const classes = useStyles();
  const textArea = useRef();
  const [language, setLanguage] = useState([]);
  useEffect(() => {
    const defaultLanguage = deepLanguages.find(
      (item) =>
        item?.code ===
        locationDetailsData?.result?.google?.storefrontAddress?.languageCode
    );
    setLanguage(defaultLanguage);
  }, [locationDetailsData]);
  useEffect(() => {
    setAiContentDescription(
      "Generate keywords for the following local business based on the information below:" +
        "\n \nBusiness Category: " +
        "{" +
        `${
          locationDetailsData?.result?.google?.categories?.primaryCategory
            ?.displayName
            ? locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.displayName
            : "--"
        }` +
        "}" +
        "\n \nCity: " +
        "{" +
        `${
          locationDetailsData?.result?.google?.storefrontAddress?.locality
            ? locationDetailsData?.result?.google?.storefrontAddress?.locality
            : "--"
        }` +
        "}" +
        "\n  \nLocal language: " +
        "{" +
        `${language !== "" ? language?.name : "--"}` +
        "}"
    );
    setGeneratedContent("");
    setTags([]);
  }, [language]);

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    //  setTagsRequired(true);
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
    // setTagsRequired(false);
  };
  const { t } = useTranslation();
  // const removeTag = (index) => {
  //   setTags(tags.filter((el, i) => i !== index));
  // };
  const removeTag = (index) => {
    setLocationKeywords(locationKeywords.filter((el, i) => i !== index));
  };

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          {/* <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>
              {t("What_Would_Like")}
            </Typography>

            <TextField
               rows={7}
                maxRows={7}
              fullWidth
                 multiline
                style={{

                    minHeight: "124px",

                  }}
              onChange={(e) => {
                setAiContentDescription(e.target.value);
                if (e.target.value?.length > 0) {
                  setContentRequired(false);
                } else {
                  setContentRequired(true);
                }
              }}
              error={contentRequired}
              helperText={contentRequired && "Content is required"}
              placeholder={t("What_Would_Like_Placeholder")}
              value={AiContentDescription}
              name="Description"
            />
            {displayError && (
              <Alert mt={2} mb={3} severity="error">

               {t("Ai_Error_Text")}
              </Alert>
            )}
          </Grid> */}
          {/* {generatedContent?.length > 0 && (
            <Grid className={classes.mainKeyWordContainer}>
              <Typography className={classes.labelText}>
                {t("Generated_Content")}
              </Typography>

              <TextField
                rows={8}
                maxRows={10}
                multiline
                style={{

                    minHeight: "224px",

                  }}
                fullWidth
                placeholder={t("What_Would_Like_Placeholder")}
                value={generatedContent}
                name="Description"
                onChange={(e) => setGeneratedContent(e.target.value)}
              />
            </Grid>
                  )} */}

          <Box sx={{ paddingTop: "8px" }}>
            <Typography className={classes.labelText}>
              {t("AI_Generated_Keywords")}
            </Typography>
            <Box>
              <Grid
                className={
                  tagsAIRequired === false
                    ? "tags-input-container"
                    : "tags-input-container-error"
                }
                sx={{ maxHeight: "210px", overflowY: "scroll" }}
              >
                {locationKeywords.map((tags, index) => (
                  <div className={classes.tagItemnew} key={index}>
                    <Box className={classes.keywordBoxx}>
                      <Typography className={classes.keywordTypo}>
                        {tags}
                      </Typography>

                      <CloseIcon
                        className={classes.keywordIcon}
                        onClick={() => removeTag(index)}
                      />
                      {/*   <span className="close" onClick={() => removeTag(index)}>
                        {" "}
                        X
                      </span> */}
                    </Box>
                  </div>
                ))}
                <input
                  type="text"
                  className="tags-input"
                  // placeholder={t("E.g.Vegan,_Restaurant")}
                  //   onKeyPress={this.keyPress}
                  // onKeyDown={handleKeyDown}

                  //  defaultValue={productData.tags}
                />
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* </DialogActions> */}
      </Grid>
    </>
  );
};

export default KeywordAIModal;
