import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { Box, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink } from "react-router-dom";
import SidebarNavSection from "./SidebarNavSection";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import logo from "../../../assets/Logo/logowhitetext.svg";
// import logo from "../../assets/Logo/logowhite.png";
// import Logo from "../../assets/Logo/Logo.png"
import "../../../vendor/perfect-scrollbar.css";
import useAuth from "../../../hooks/useAuth";

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";
import { LogoDevRounded } from "@mui/icons-material";
//  background-color: ${(props) => props.theme.sidebar.background};

const baseScrollbar = css`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
  background-color: #000000;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
  background-color: #000000;
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  // background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-right: 120px;

  cursor: pointer;
  flex-grow: 0;
  background-color: #000000;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;
// const BrandIcon = styled(Logo)`
//   // margin-right: ${(props) => props.theme.spacing(2)};
//   // color: ${(props) => props.theme.sidebar.header.brand.color};
//   // fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 100.44px;
//   height: 20.43px;
// `;

const SidebarNav = ({ items }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;
  const { permissions, user, permissionsAcess } = useAuth();

  return (
    <ScrollbarComponent>
      <List
        disablePadding
        sx={{ minHeight: "100%", backgroundColor: "#000000" }}
      >
        <Brand
          component={NavLink}
          to="/"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {/* <Box
            letterSpacing={3}
            ml={1}
            sx={{ color: "white", width: "80.66px" }}
          >
            Obenan
          </Box> */}

          <img
            src={logo}
            alt="logo"
            style={{ width: "130.44px", height: "90.43px" }}
          />

          {/* <BrandIcon /> */}
        </Brand>
        {user?.role === "super-admin" ? (
          <Items>
            {items &&
              items.map((item) => (
                <SidebarNavSection
                  component="div"
                  key={item.id}
                  pages={item.pages}
                  title={item.title}
                />
              ))}
          </Items>
        ) : (
          permissions !== undefined &&
          permissions?.length > 0 && (
            <Items>
              {items &&
                items.map((item) => {
                  const matchingFeature = permissions.find(
                    (feature) => feature.name === item.pages[0].title
                  );
                  const locationPermissionFound = permissions.find(
                    (feature) => feature?.route === "/location"
                  );

                  let pages = [...item.pages];
                  let addFound = false;
                  let newPages = "";
                  if (
                    item?.pages[0]?.title === "account_settings" &&
                    user?.role !== "company-admin"
                  ) {
                    let filteredPermissions = permissionsAcess?.filter(
                      (item) => item?.route === "/location"
                    );
                    addFound = filteredPermissions?.some(
                      (item) =>
                        item?.permissionName === "create" ||
                        item?.permissionName === "Account Management"
                    );
                    if (!addFound) {
                      const titleToRemove = "Add_Location";
                      newPages = item?.pages.map((page) => ({
                        ...page,
                        children: item?.pages[0].children.filter(
                          (child) => child.title !== titleToRemove
                        ),
                      }));
                    }
                  }
                  if (
                    item?.pages[0]?.title === "account_settings" &&
                    locationPermissionFound
                  ) {
                    return (
                      <SidebarNavSection
                        component="div"
                        key={item.title}
                        pages={
                          item?.pages[0]?.title === "account_settings" &&
                          user?.role !== "company-admin"
                            ? addFound
                              ? item.pages
                              : newPages
                            : item.pages
                        }
                        title={item.title}
                      />
                    );
                  } else if (matchingFeature !== undefined) {
                    return (
                      <SidebarNavSection
                        component="div"
                        key={item.title}
                        pages={
                          item?.pages[0]?.title === "account_settings" &&
                          user?.role !== "company-admin"
                            ? addFound
                              ? item.pages
                              : newPages
                            : item.pages
                        }
                        title={item.title}
                      />
                    );
                  }
                })}
            </Items>
          )
        )}
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
