import { Layout, Sliders } from "react-feather";
import { ReactComponent as Dashboard } from "../../../assets/Icons/Dashboard.svg";
import { ReactComponent as KeywordSearch } from "../../../assets/Icons/KeywordSearch.svg";
import { ReactComponent as BackLinks } from "../../../assets/Icons/BackLinks.svg";
import { ReactComponent as AutoResponseRule } from "../../../assets/Icons/AutoResponseRule.svg";
import { ReactComponent as Tasks } from "../../../assets/Icons/Tasks.svg";
import { ReactComponent as AutoTaskRule } from "../../../assets/Icons/AutoTask.svg";
import { ReactComponent as Reviewtemplate } from "../../../assets/Icons/Reviewtemplate.svg";
import { ReactComponent as FaqsIcon } from "../../../assets/Icons/faqsIcon.svg";
import { ReactComponent as Template } from "../../../assets/Icons/Template.svg";
import { ReactComponent as Review } from "../../../assets/Icons/Review.svg";
import { ReactComponent as MyLocations } from "../../../assets/Icons/MyLocations.svg";
import { ReactComponent as AddLocation } from "../../../assets/Icons/AddLocation.svg";
import { ReactComponent as ProductActivation } from "../../../assets/Icons/Product.svg";
import { ReactComponent as Post } from "../../../assets/Icons/Post.svg";
import { ReactComponent as ReviewSources } from "../../../assets/Icons/ReviewSources.svg";
import { ReactComponent as AutoGooglePost } from "../../../assets/Icons/AutoGooglePost.svg";
import { ReactComponent as Listings } from "../../../assets/Icons/Listings.svg";
import { ReactComponent as SentimentAnalysis } from "../../../assets/Icons/sentimentanalysis.svg";
import { ReactComponent as MonthlyReport } from "../../../assets/Icons/reportIcon.svg";
import { ReactComponent as UberAll } from "../../../assets/Icons/uberAll.svg";
import { ReactComponent as AccountManagement } from "../../../assets/Icons/AccountManagement.svg";
import { ReactComponent as GoogleStats } from "../../../assets/Icons/GoogleStats.svg";

// const pagesSection = [
//   {
//     href: "/listings",
//     icon: Layout,
//     title: "Listings",
//   },
//   {
//     href: "/reviews",
//     icon: Users,
//     title: "Reviews",
//     children: [
//       {
//         href: "/reviews/manager",
//         title: "Review Manager",
//       },
//       {
//         href: "/reviews/request",
//         title: "Review Request",
//       },
//     ],
//   },
//   {
//     href: "/seo/keyword-search",
//     icon: Layout,
//     title: "Beyond SEO",
//   },
// ];

//
// const userTool = [
//   {
//     icon: Sliders,
//     title: "Partner Tools",
//     children: [
//       // {
//       //   href: "/tools/devices",
//       //   title: "Devices",
//       // },
//       // {
//       //   href: "/user/accounts",
//       //   title: "Accounts",
//       // },
//       // {
//       //   href: "/tools/partner",
//       //   title: "Partner",
//       // },
//       // {
//       //   href: "/tools/wifi-marketing-demo",
//       //   title: "WIFI Marketing Demo",
//       // },
//       // {
//       //   href: "/tools/demo-accounts",
//       //   title: "Demo Accounts",
//       // },
//       // {
//       //   href: "/tools/partner-insights",
//       //   title: "Partner Insights",
//       // },
//     ],
//   },
// ];

//

const adminNavItems = [
  {
    // title: "Overview",
    pages: [
      {
        title: "Overview",
        children: [
          {
            href: "/",
            icon: Dashboard,
            title: "Dashboard",
          },
        ],
      },
    ],
  },

  // {
  //   title: "Beyond The Search",
  //   pages: [
  //     {
  //       href: "/admin/seo/keyword-search",
  //       icon: Layout,
  //       title: "Keyword search",
  //     },
  //   ],
  // },
  // {
  //   title: "",
  //   pages: [
  //     {
  //       // icon: Sliders,
  //       title: "Beyond_The_Search",
  //       children: [
  //         {
  //           href: "/user/seo/keyword-search",
  //           icon: KeywordSearch,
  //           title: "Keyword_search",
  //         },
  //         {
  //           href: "/user/backlink",
  //           icon: BackLinks,
  //           title: "Backlinks",
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   title: "",
  //   pages: adminTool,
  // },

  {
    // title: "Manage locations",
    pages: [
      {
        title: "Account",
        children: [
          {
            href: "/admin/accounts",
            icon: Layout,
            title: "Account",
          },
          {
            href: "/admin/accounts-management",
            icon: AccountManagement,
            title: "account_settings",
          },
        ],
      },
    ],
  },

  {
    // title: "Manage locations",
    pages: [
      {
        title: "Users",
        children: [
          {
            href: "/admin/accounts/link-newuser",
            icon: Layout,
            title: "Users",
          },
        ],
      },
    ],
  },
  {
    title: "",
    pages: [
      {
        icon: "Slider",
        title: "Review_Management",
        children: [
          {
            href: "/admin/reviews",
            icon: Review,
            title: "Reviews",
          },
          {
            href: "/admin/reviewSource",
            icon: ReviewSources,
            title: "Review_Sources",
          },

          // {
          //   href: "/user/google/reviews",
          //   icon: Review,
          //   title: "Reviews",
          // },
          {
            href: "/admin/template",
            icon: Template,
            title: "Review_Templates",
          },
          {
            href: "/admin/rules",
            icon: AutoResponseRule,
            title: "Auto_Response_Rule",
          },
          // {
          //   href: "/user/google/reviews",
          //   icon: AutoGooglePost,
          //   title: "Auto_Google_Reviews",
          // },
        ],
      },
    ],
  },
  {
    // title: "Manage locations",
    pages: [
      {
        title: "Tasks",
        children: [
          {
            icon: Tasks,
            title: "Tasks",
            href: "/admin/tasks",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        title: "Post_Management",
        children: [
          {
            href: "/admin/allposts",
            icon: AutoGooglePost,
            title: "Auto_Google_Posts",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        title: "Listings",
        children: [
          {
            href: "/admin/listings",
            icon: UberAll,
            title: "Listings",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        title: "Requests_Text",
        children: [
          {
            href: "/admin/requests/accounts",
            icon: Layout,
            title: "Account",
          },
          {
            href: "/admin/requests/emotionAI",
            icon: SentimentAnalysis,
            title: "Emotion_AI",
          },
          {
            href: "/admin/requests/locations",
            icon: MyLocations,
            title: "TaskLocation",
          },

          {
            href: "/admin/requests/listings",
            icon: UberAll,
            title: "Listings",
          },
          {
            href: "/admin/requests/products",
            icon: ProductActivation,
            title: "ProductActivation",
          },
        ],
      },
    ],
  },

  {
    // title: "Manage locations",
    pages: [
      {
        // icon: Sliders,
        title: "Manage_locations",
        children: [
          {
            href: "/admin/locations",
            icon: MyLocations,
            title: "My_Locations",
          },

          {
            href: "/admin/add-location",
            icon: AddLocation,
            title: "Add_Locations",
          },
        ],
      },
    ],
  },
];

const companyNavItems = [
  {
    // title: "Overview",

    pages: [
      {
        title: "Listing_Technology",
        children: [
          // {
          //   href: "/",
          //   // icon:  Dashboard,

          //   icon: Dashboard,
          //   title: "Dashboard",
          // },
          {
            href: "/",
            icon: Listings,
            title: "Listings",
          },
          {
            href: "/user/google-stats",
            icon: GoogleStats,
            title: "Google_Stats",
          },
        ],
      },
    ],
  },

  {
    // title: "Beyond The Search",
    pages: [
      {
        // icon: Sliders,
        title: "Emotion_AI",
        children: [
          {
            href: "/user/sentimentAnalysis",
            icon: SentimentAnalysis,
            title: "Emotion_AI",
          },
        ],
      },
    ],
  },
  /*  {
    // title: "Beyond The Search",
    pages: [
      {
        // icon: Sliders,
        title: "Beyond_The_Search",
        children: [
          {
            href: "/user/seo/keyword-search",
            icon: KeywordSearch,
            title: "Keyword_search",
          },
          {
            href: "/user/backlink",
            icon: BackLinks,
            title: "Backlinks",
          },
        ],
      },
    ],
  }, */
  {
    // title: "",
    pages: [
      {
        // icon: "Slider",
        title: "Review_Management",
        children: [
          {
            href: "/user/reviews",
            icon: Review,
            title: "Reviews",
          },
          {
            href: "/user/reviewSource",
            icon: ReviewSources,
            title: "Review_Sources",
          },

          // {
          //   href: "/user/google/reviews",
          //   icon: Review,
          //   title: "Reviews",
          // },
          {
            href: "/user/template",
            icon: Template,
            title: "Review_Templates",
          },
          {
            href: "/user/rules",
            icon: AutoResponseRule,
            title: "Auto_Response_Rule",
          },
          {
            href: "/user/review_request",
            icon: Reviewtemplate,
            title: "Review_Request",
          },
          {
            href: "/user/faqs",
            icon: FaqsIcon,
            title: "Question_Answers",
          },
          // {
          //   href: "/user/google/reviews",
          //   icon: AutoGooglePost,
          //   title: "Auto_Google_Reviews",
          // },
        ],
      },
    ],
  },
  {
    // title: "Tasks",
    pages: [
      {
        // icon: Sliders,
        title: "Tasks",
        children: [
          {
            icon: Tasks,
            title: "Tasks",
            href: "/user/tasks",
          },
          {
            icon: AutoTaskRule,
            title: "Auto_Task_Rule",
            href: "/user/task-rules",
          },
        ],
      },
    ],
  },
  {
    pages: [
      {
        // icon: Sliders,
        title: "Reports",
        children: [
          {
            href: "/user/monthly-report",
            icon: MonthlyReport,
            title: "Monthly_Report",
          },
        ],
      },
    ],
  },
  {
    // title: "Tasks",
    pages: [
      {
        // icon: Sliders,
        title: "Post_Management",
        children: [
          {
            href: "/user/allposts",
            icon: AutoGooglePost,
            title: "Auto_Google_Posts",
          },
        ],
      },
    ],
  },

  // {
  //   title: "",
  //   pages: [
  //     {
  //       // icon: Sliders,
  //       title: "Posts",
  //       children: [
  //         {
  //           icon: Sliders,
  //           title: "Posts",
  //           href: "/user/posts",
  //         },
  //       ],
  //     },
  //   ],
  // },

  //     const userPosts = [
  //   {
  //     // icon: Sliders,
  //     title: "Posts",
  //     children: [
  //       {
  //         icon: Sliders,
  //         title: "Posts",
  //         href: "/user/posts",
  //       },
  //     ],
  //   },
  // ];
  // },
  //   {
  //     title: "",
  //     pages: [
  //       {
  //         // icon: Sliders,
  //         title: "Posts",
  //         children: [
  //           {
  //             icon: Sliders,
  //             title: "Posts",
  //             href: "/user/posts",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // >>>>>>> 485a32ab6e9a1d246822711e5531763fe0219612

  // {
  //   // title: "Tasks",
  //   pages: [
  //     {
  //       // icon: Sliders,
  //       title: "Posts",
  //       children: [
  //         {
  //           icon: Sliders,
  //           title: "Posts",
  //           href: "/user/posts",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    // title: "Manage locations",
    pages: [
      {
        // icon: Sliders,
        title: "account_settings",
        children: [
          {
            href: "/user/accounts-overview",
            icon: MyLocations,
            title: "Account_Overview",
          },
          {
            href: "/user/locations",
            icon: MyLocations,
            title: "My_Locations",
          },
          {
            href: "/user/add-location",
            icon: AddLocation,
            title: "Add_Location",
          },
        ],
      },
    ],
  },
];

export default { companyNavItems, adminNavItems };
