import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { useTranslation } from "react-i18next";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import Googleicon from "../../../assets/Icons/Group.svg";

const Delete = ({ title, description, onConfirm, onCancel, loading }) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {title}
          </DialogContentText>
          <DialogContentText
            id="responsive-dialog-title"
            //  className="delete_dialog_content2"
            style={{
              marginBottom: "16px",
              fontWeight: 400,
              lineHeight: "20px",
              fontSize: "14px",
              margin: "16px",
            }}
          >
            {description}
          </DialogContentText>
          <DialogActions>
            <CommonButton
              displayWhite="true"
              onSubmit={onCancel}
              // disabled={isSubmitting}
              label={t("Cancel")}
            />
            <CommonButton
              onSubmit={onConfirm}
              icon={
                <img
                  src={Googleicon}
                  // className={classes.commonButton}
                  alt={"/google Icon"}
                  style={{ marginRight: "5px" }}
                />
              }
              label={t("Connect_With_Google")}
            />
          </DialogActions>
        </Box>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default Delete;
