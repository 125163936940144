import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "chartjs-adapter-moment";
import { BarChart, Bar, XAxis, YAxis, Legend, LabelList } from "recharts";
import dayjs from "dayjs";
import { CircularProgress, Box } from "@mui/material";
import NoDataFound from "../Components/ReviewsDetail/NoDataFound/NoDataFoundOnlineReputation";
import { WaveSpinner } from "../../../../components/Loaders/wavaloader";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useStyles } from "../Style/style";
import GridSkeletonLoader from "./ReviewsDetail/GridSkeletonLoader";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const TimeSeriesChart = ({
  graphData,
  labelsArray,
  reviewTopics,
  selectedTopic,
  handleChangeTopic,
  getReviewTopics,
  setReviewTopics,
  topicsLoading,
  startDate,
  endDate,
  reputationLoading,
  downloadFormat,
  viewPermission,
  setDownloadFormat,
  featuresObj,
  setFeaturesObj,
  topicsData,
  topicsDataLoading,
  tooltipContent,
  prevTooltipContent,
  handleOpenReviewModel,
  setRatingBreakdownValue,
  setSelectedTopicName,
}) => {
  const chartRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const firstGridRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);

  const [downloadValue, setDownloadValue] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));

  let timer = 0;

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (topicsData?.length > 0) {
      if (firstGridRef.current) {
        if (chartHeight === 0) {
          setChartHeight(firstGridRef.current.clientHeight);
          setChartWidth(firstGridRef.current.clientWidth);
        } else if (firstGridRef.current.clientHeight < chartHeight) {
          setChartHeight(firstGridRef.current.clientHeight);
          setChartWidth(firstGridRef.current.clientWidth);
        }
      }
    }
  }, [topicsData, topicsDataLoading]);
  console.log(chartHeight, "chartHeigjt");

  useEffect(() => {
    if (!graphData || !chartRef.current) return;

    // Check if a chart instance already exists
    if (chartRef.current.chart) {
      // If a chart exists, destroy it before creating a new one
      chartRef.current.chart.destroy();
    }

    // Calculate the duration between startDate and endDate in months
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const durationInMonths = end.diff(start, "days");

    // Determine the unit and display format for the X-axis based on conditions
    let xAxisUnit, xAxisDisplayFormat;

    if (
      durationInMonths <= 60 ||
      (startDate !== null &&
        endDate !== null &&
        endDate !== "" &&
        startDate !== "" &&
        dayjs(startDate).format("MM YY") === dayjs(endDate).format("MM YY"))
    ) {
      xAxisUnit = "day";
      xAxisDisplayFormat = "DD MMM YY";
    } else {
      xAxisUnit = "month";
      xAxisDisplayFormat = "MMM YY";
    }

    const chartInstance = new Chart(chartRef.current, {
      type: "line",
      data: {
        labels: labelsArray,
        datasets: graphData,
      },
      options: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: xAxisUnit,
              displayFormats: {
                day: xAxisDisplayFormat,
                month: "MMM YY",
              },
              min: moment().startOf("year").startOf("month"),
            },
          },
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            labels: {
              pointStyle: "circle",
              usePointStyle: true,
              align: "start",
              boxWidth: 7,
              boxHeight: 7,
              padding: 15,
            },
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              title: (tooltipItems) => {
                return moment(tooltipItems[0].label).format("MMM DD, YYYY");
              },
              label: (context) => {
                const label = context.dataset.label || "";
                const value = context.parsed.y;
                return `${label}: ${value}`;
              },
            },
          },
        },
      },
    });
    chartRef.current.chart = chartInstance;
  }, [graphData]);

  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 1, value: "email", title: t("email_report") },
  ];
  const handleReputationDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);
    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "onlineReputation") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };
  console.log(topicsData, "topicsData");
  const handleCalculateDifference = (currentValue, previousValue) => {
    let difference = currentValue - previousValue;
    let percentageDifference = (difference / previousValue) * 100;
    console.log(percentageDifference, "percentageDifference");

    return (
      <div>
        <span className={classes.ratingHeadingsLoc}>
          {difference > 0 ? (
            <span
              style={{
                background: "#E3F9F2",
                color: "#13CF8F",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowUpwardIcon className={classes.greenIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "100%"}
            </span>
          ) : difference < 0 ? (
            <span
              style={{
                background: "#FFE3E3",
                color: "#FF1616",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowDownwardIcon className={classes.redIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "0%"}
            </span>
          ) : difference === 0 ? (
            <span
              style={{
                background: "#FFF7E7",
                color: "#F8A92B",
                padding: "4px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                marginLeft: "3px",
                marginBottom: "3px",
              }}
            >
              <ArrowForwardIcon className={classes.orangeIconClassGraph} />
              {percentageDifference !== Infinity && !isNaN(percentageDifference)
                ? percentageDifference.toFixed() + "%"
                : "0%"}
            </span>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };
  return (
    <>
      <Grid container>
        <Grid container>
          <Grid
            item
            xs={topicsLoading ? 6.5 : 7}
            sm={topicsLoading ? 6.5 : 7}
            md={topicsLoading ? 6.5 : 7}
            lg={topicsLoading ? 6.5 : 7}
          >
            <Box className={classes.headingBox}>
              <Typography className={classes.subHeadRatingReviewDetail}>
                {t("online_reputation")}
              </Typography>
              <BootstrapTooltip title={t("Online_Reputation_Tooltip")}>
                <Typography className={classes.toolTipIconStyle}>
                  <InfoOutlinedIcon />
                </Typography>
              </BootstrapTooltip>
            </Box>
          </Grid>
          <Grid item xs={2.7} sm={2.7} md={2.7} lg={2.7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={reviewTopics?.length > 0 ? reviewTopics : []}
              getOptionLabel={(option) =>
                companyData?.emotionAIStaticTopics === true
                  ? option
                  : option?.topicText?.charAt(0)?.toUpperCase() +
                    option?.topicText?.slice(1)
              }
              sx={{ width: "100%", padding: "8px" }}
              value={selectedTopic}
              onChange={(e, value) => {
                handleChangeTopic(value);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("Topicss")}
                  {...params}
                  required
                  name="language"
                  sx={{ padding: "8px" }}
                  onChange={(e, newValue) => {
                    if (companyData?.emotionAIStaticTopics === false) {
                      if (e.target.value.length === 0) {
                        setReviewTopics([]);
                      }
                      clearTimeout(timer);
                      timer = setTimeout(
                        () => getReviewTopics(e.target.value),
                        1000
                      );
                    }
                  }}
                />
              )}
            ></Autocomplete>
          </Grid>
          {viewPermission && (
            <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3} mt={4}>
              <Autocomplete
                id="tags-outlined"
                options={downloadOptions}
                value={downloadValue}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={handleReputationDownloadChange}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t("actionss")} />
                )}
              />
            </Grid>
          )}
          {topicsLoading && (
            <Grid
              item
              md={0.5}
              sm={0.5}
              xs={0.5}
              lg={0.5}
              className={classes.topicsLoadingGrid}
            >
              <CircularProgress className={classes.topicsLoadingStyle} />
            </Grid>
          )}
        </Grid>
        {reputationLoading ? (
          <Box className={classes.reputationLoadingStyle}>
            <WaveSpinner />
          </Box>
        ) : (
          <>
            {graphData?.length > 0 ? (
              <Grid container className={classes.graphContainer}>
                <canvas ref={chartRef}></canvas>
              </Grid>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TimeSeriesChart;
