import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  TextField,
  Autocomplete,
  Checkbox,
  MenuItem,
  Select,
  createFilterOptions,
  TableBody,
  Paper,
  TablePagination,
  TableContainer,
  Table,
  IconButton,
} from "@mui/material";
import Loader from "../../../../../components/Loaders/Loader";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { languagesData } from "../../../../../assets/defaultLanguage";
import { toast } from "react-toastify";
import { api } from "../../../../../contexts/JWTContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  // maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  // maxHeight: "87vh",
  width: "850px",
  boxShadow: 24,
  p: 4,
  // overflowY: "scroll",
};

const EnhancedTableHead = (props) => {
  const { t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "city", alignment: "left", label: t("Address") },
    { id: "email", alignment: "left", label: t("email") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell?.id}
            align={headCell?.alignment}
            padding={headCell?.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

const AddBusinessModel = ({
  handleCloseAddEmailModel,
  open,
  handleOpenAddBulkEmailModel,
  setAllLocations,
  setBusinessEmailLocations,
  setDisplayEmailWarning,
  setSelectAllLocations,
  setSelectedLocation,
  setLocationRequired,
  selectedLocation,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const filter = createFilterOptions();

  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [allModelLocations, setAllModelLocations] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const userData = JSON.parse(localStorage.getItem("user"));
  let timer = 0;

  useEffect(() => {
    if (open === true) {
      getAllLocations();
    }
  }, [open, rowsPerPage, page]);

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/location/search?limit=${rowsPerPage}&page=${
          page + 1
        }&businessEmail=null`,
        {
          params: {
            isLocationPage: true,
          },
        }
      );
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          let arr = [...res?.data?.data.results];

          arr?.map((item) => {
            let emailList = [userData?.email];
            if (item?.socialLink?.length > 0) {
              let googleLink = item?.socialLink?.find(
                (item) => item?.type === "google"
              );
              if (googleLink !== undefined) {
                emailList.push(googleLink?.email);
              }
            }
            item["businessEmailsList"] = emailList;
          });

          setAllModelLocations(arr);
          setRowCount(res?.data?.data?.count);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (rowData) => {
    const indexValue = allModelLocations?.findIndex(
      (item) => item?.id === rowData?.id
    );
    let tempArr = allModelLocations;
    tempArr[indexValue]["editOption"] = true;
    setAllModelLocations([...tempArr]);
  };

  const handleCancelAddEmail = (rowData) => {
    const indexValue = allModelLocations?.findIndex(
      (item) => item?.id === rowData?.id
    );
    let tempArr = allModelLocations;
    tempArr[indexValue]["editOption"] = false;
    tempArr[indexValue]["businessEmail"] = null;
    tempArr[indexValue]["emailError"] = false;
    setAllModelLocations([...tempArr]);
  };

  const handleInputChange = (newInputValue, rowData) => {
    const indexValue = allModelLocations?.findIndex(
      (item) => item?.id === rowData?.id
    );
    let tempArr = allModelLocations;
    if (newInputValue && isValidEmail(newInputValue)) {
      let tempArr = allModelLocations;
      if (tempArr[indexValue].hasOwnProperty("businessEmailsList")) {
        const existingEmails = tempArr[indexValue]["businessEmailsList"].map(
          (email) => email
        );

        if (!existingEmails.includes(newInputValue.toLowerCase())) {
          let updatedEmails = [
            ...tempArr[indexValue]["businessEmailsList"],
            newInputValue,
          ];
          tempArr[indexValue]["businessEmailsList"] = updatedEmails;
        }
      } else {
        tempArr[indexValue]["businessEmailsList"] = [newInputValue];
      }

      tempArr[indexValue]["emailError"] = false;
      setAllModelLocations([...tempArr]);
    } else {
      tempArr[indexValue]["emailError"] = true;
    }
    setAllModelLocations([...tempArr]);

    setInputValue(newInputValue);
  };

  const handleChange = (newValue, rowData) => {
    const indexValue = allModelLocations?.findIndex(
      (item) => item?.id === rowData?.id
    );
    let tempArr = allModelLocations;
    if (newValue !== null) {
      tempArr[indexValue]["businessEmail"] = newValue;
    } else {
      tempArr[indexValue]["businessEmail"] = "";
      tempArr[indexValue]["emailError"] = false;
    }
    setAllModelLocations([...tempArr]);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getAllLocationsForMainPage = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
          // searchTerm: filterWithName,
          // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
        },
      });
      if (res.status === 200) {
        setAllLocations(res.data.data);

        if (res.data.data?.results?.length > 0) {
          let arr = [...selectedLocation];
          res.data.data?.results?.map((item) => {
            arr.map((itemTwo) => {
              if (item?.id === itemTwo?.id) {
                itemTwo["businessEmail"] = item?.businessEmail;
              }
            });
          });
          setSelectedLocation(arr);

          const filteredData = res.data.data?.results?.filter(
            (item) => item?.businessEmail === null || item?.businessEmail === ""
          );
          setBusinessEmailLocations(filteredData);

          setSelectAllLocations(false);
          if (filteredData?.length > 0) {
            setDisplayEmailWarning(true);
          } else {
            setDisplayEmailWarning(false);
          }
        }
        handleCloseAddEmailModel();
      }
    } catch (error) {}
  };

  const submitBulkUpdate = async () => {
    let locationsDataToUpdate = [...allModelLocations];
    let filteredData = locationsDataToUpdate?.filter(
      (item) => item?.businessEmail !== "" && item?.businessEmail !== null
    );
    let data = [];
    if (filteredData?.length > 0) {
      filteredData?.map((item) => {
        let obj = {
          businessEmail: item?.businessEmail,
          locationId: item?.id,
        };
        data.push(obj);
      });
    }
    if (data?.length > 0) {
      setBulkLoading(true);
      try {
        const res = await api.patch(`/location/updateBusinessEmails`, {
          data: data,
        });
        if (res.status === 200) {
          getAllLocationsForMainPage();

          setBulkLoading(false);

          toast.success(res?.data?.message);
        }
      } catch (error) {
        setBulkLoading(false);
        handleCloseAddEmailModel();

        toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item md={9.5}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "28px",
                  //  marginBottom: "8px",
                  // marginTop: "8px",
                }}
              >
                {t("Business_emailModel")}
              </Typography>
            </Grid>
            <Grid item md={2} sx={{ textAlign: "end", paddingBottom: "8px" }}>
              <CommonButton
                customHeight={true}
                label={t("Bulk_Add")}
                onSubmit={handleOpenAddBulkEmailModel}
              />
            </Grid>
            <Grid item md={0.5} sx={{ textAlign: "end" }}>
              <IconButton
                autoFocus
                onClick={handleCloseAddEmailModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          {isLoading ? (
            <Grid
              container
              sx={{
                height: "350px",
                paddingTop: "15px",
                paddingBottom: "15px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Loader />
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                paddingTop: "10px",
                paddingBottom: "15px",
                height: "300px",
                overflow: "auto",
              }}
            >
              <TableContainer component={Paper}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    t={t}
                    rowCount={rowCount}
                    getAllLocation={getAllLocations}
                    selectAll={selectAll}
                  />
                  <TableBody>
                    {allModelLocations?.length > 0 ? (
                      allModelLocations?.map((row, index) => {
                        return (
                          <CustomizedTableRow
                            // hover
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                          >
                            <CustomizedTableCell sx={{ width: "50px" }}>
                              {row.id}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="left"
                              sx={{ width: "220px" }}
                            >
                              {/* {row?.name ? row?.name + "(" + row?.city + ")" : ""} */}
                              <Typography>
                                {row?.internalName
                                  ? row?.internalName +
                                    (row?.city !== null &&
                                    row?.city !== undefined
                                      ? "(" + row?.city + ")"
                                      : "")
                                  : ""}
                              </Typography>
                              {row?.state === "in-active" && (
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "16px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                    color: "#FF1616",
                                  }}
                                >
                                  {t("Permanently_Closed")}
                                </Typography>
                              )}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="left"
                              sx={{ width: "220px" }}
                            >
                              {row?.addressLine1 !== "" &&
                              row?.addressLine1 !== null &&
                              row?.addressLine1 !== undefined
                                ? row?.addressLine1
                                : ""}
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left">
                              {row?.hasOwnProperty("editOption") &&
                              row?.editOption === true ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Autocomplete
                                    //  disableCloseOnSelect
                                    freeSolo
                                    //  value={row?.businessEmail}
                                    onChange={(e, value) =>
                                      handleChange(value, row)
                                    }
                                    //  inputValue={inputValue}

                                    sx={{ width: "270px" }}
                                    options={
                                      row?.businessEmailsList?.length > 0
                                        ? row?.businessEmailsList
                                        : []
                                    }
                                    getOptionLabel={(option) => option}
                                    value={row.businessEmail}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        type="email"
                                        size="small"
                                        fullWidth
                                        placeholder="e.g. john@obenan.com"
                                        variant="outlined"
                                        error={row?.emailError}
                                        onChange={(e, newValue) => {
                                          clearTimeout(timer);
                                          timer = setTimeout(
                                            () =>
                                              handleInputChange(
                                                e.target.value,
                                                row
                                              ),
                                            500
                                          );
                                        }}
                                      />
                                    )}
                                  />

                                  <IconButton
                                    autoFocus
                                    onClick={() => handleCancelAddEmail(row)}
                                    className="delete_button"
                                    sx={{ paddingTop: "0px" }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              ) : (
                                <AddCircleIcon
                                  onClick={() => handleClickEdit(row)}
                                  sx={{ color: "#06BDFF", cursor: "pointer" }}
                                />
                              )}
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        );
                      })
                    ) : (
                      <Typography variant="h3">{t("data_found")}</Typography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container sx={{ justifyContent: "end" }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          )}

          <Divider />
          <Grid container spacing={1} sx={{ paddingTop: "10px" }}>
            <Grid item spacing={2} xs={12} md={12}>
              <Box sx={{ float: "right" }}>
                <CommonButton
                  label={t("Cancel_Button")}
                  displayWhite="true"
                  onSubmit={handleCloseAddEmailModel}
                />
                &nbsp;&nbsp;
                <CommonButton
                  isLoading={bulkLoading}
                  label={t("submit_edit")}
                  disabled={
                    allModelLocations?.find(
                      (item) =>
                        item?.hasOwnProperty("emailError") &&
                        item?.emailError === true
                    ) !== undefined
                      ? true
                      : allModelLocations?.find(
                          (item) =>
                            item?.businessEmail !== "" &&
                            item?.businessEmail !== null
                        ) !== undefined
                      ? false
                      : true
                  }
                  onSubmit={submitBulkUpdate}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default AddBusinessModel;
