import React from "react";
import Divider from "@mui/material/Divider";
import { ReactComponent as More } from "../../../../../assets/images/more.svg";
import { ReactComponent as Google } from "../../../../../assets/images/Button.svg";
import { ReactComponent as Tiktok } from "../../../../../assets/images/tiktok.svg";
import { ReactComponent as Twitter } from "../../../../../assets/images/Twitterbutton.svg";
import { ReactComponent as Vector } from "../../../../../assets/images/Facebook.svg";
import Link from "@mui/material/Link";
import { Grid, Typography, Button, Box } from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "../../../../../styles/style";
import BouncingDotsLoader from "../../../../../components/Loaders/smallBounceLoader";

function Ratings({
  location,
  handleNavigation,
  googleInsightData,
  singleLocationLoading,
  allLocations,
  profileLink,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const isMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid item xs={6} sm={6} md={6} lg={3}>
        <Grid container sx={{ paddingLeft: "10px" }}>
          {/*   <Grid item xs={3} sm={3} md={3} lg={3} sx={{ textAlign: "center" }}>
            <img src={logo} width={42} height={42} />
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ textAlign: "start" }}
          >
            {/*   {singleLocationLoading ? (
              <BouncingDotsLoader />
            ) : (
              <> */}
            <Typography className={classes.headLocationNameRating}>
              {location?.internalName
                ? location?.internalName
                : "No Location Found"}
            </Typography>

            <Typography className={classes.subHeadLocationName}>
              {location?.addressLine1}
            </Typography>
            {profileLink !== null && (
              <Link
                href={`${profileLink}`}
                target="_blank"
                //onClick={() => onCancel()}
              >
                {" "}
                {t("Google_My_CTA")}
              </Link>
            )}
            {/*    </>
            )} */}
          </Grid>
        </Grid>
      </Grid>

      {/* {ratingLoading ? (

          <BouncingDotsLoader />
        ) : (
          <>
            <Grid item xs={3} sm={3} md={4} lg={4}>
              <Typography className={classes.headRatingName}>
                {googleInsightData !== null
                  ? googleInsightData?.googleReviewAverageRating
                  : "0"}
              </Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9} sx={{ textAlign: "start" }}>
              <Rating
                name="half-rating"
                defaultValue={
                  googleInsightData !== null
                    ? googleInsightData?.googleReviewAverageRating
                    : 0
                }
                precision={0.5}
                disabled
              />
              <Typography className={classes.subHeadRatingName}>
                {`based on ${
                  googleInsightData?.googleReviewTotalCount
                    ? googleInsightData?.googleReviewTotalCount
                    : "0"
                } Google Reviews`}
              </Typography>
            </Grid>
          </>
        )} */}

      {/* {!isMobile && (
        <Divider
          orientation="vertical"
          sx={{ height: "60px", marginTop: "18px" }}
          flexItem
        />
      )} */}
    </>
  );
}

export default Ratings;
