import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Checkbox,
  Typography,
  Box,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Paper,
  TableRow,
  Button,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../UserListing/Styles/style";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import { WaveSpinner } from "../../../../components/Loaders/wavaloader";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const SearchesBreakdown = ({
  googleInsightData,
  data,
  ratingLoading,
  metricsLoading,
  checkNumber,
  type,
  colors,
  kFormatter,
  totalValueBuisness,
  dataSet4,
  searchesBreakdownData,
  totalKeywordsCount,
  branded,
  discovery,
}) => {
  const initialItemsToShow = 5;
  const classes = useStyles();
  const { t } = useTranslation();
  const [brandedKeywords, setBrandedKeywords] = useState(0);
  const [discoveryKeywords, setDiscoveyKeywords] = useState(0);
  const [callClickPercentage, setCallClickPercentage] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  useEffect(() => {
    setItemsToShow(5);
  }, [searchesBreakdownData]);

  const handleViewMore = () => {
    setItemsToShow((prevItemsToShow) => {
      const brandedLength = searchesBreakdownData?.brandedKeywords.length || 0;
      const discoveryLength =
        searchesBreakdownData?.discoveryKeywords.length || 0;

      if (brandedLength > 5 && discoveryLength <= 5) {
        return prevItemsToShow === brandedLength
          ? initialItemsToShow
          : brandedLength;
      } else if (discoveryLength > 5 && brandedLength <= 5) {
        return prevItemsToShow === discoveryLength
          ? initialItemsToShow
          : discoveryLength;
      } else if (brandedLength > 5 && discoveryLength > 5) {
        if (prevItemsToShow !== initialItemsToShow) {
          return initialItemsToShow;
        } else if (brandedLength > discoveryLength) {
          return brandedLength;
        } else {
          return discoveryLength;
        }
        /*  return prevItemsToShow === Math.min(brandedLength, discoveryLength)
          ? initialItemsToShow
          : Math.min(brandedLength, discoveryLength); */
      } else {
        return prevItemsToShow === brandedLength
          ? initialItemsToShow
          : brandedLength;
      }
    });
  };

  const visibleItemsBranded = searchesBreakdownData?.brandedKeywords?.slice(
    0,
    itemsToShow
  );
  const visibleItemsDiscovery = searchesBreakdownData?.discoveryKeywords?.slice(
    0,
    itemsToShow
  );
  useEffect(() => {
    if (searchesBreakdownData !== null) {
      const buisnessDirectionPercentage = (
        (searchesBreakdownData?.sumOfBranded / totalKeywordsCount) *
        100
      ).toFixed();
      setBrandedKeywords(buisnessDirectionPercentage);
    }
    const discoveryKeywords = (
      (searchesBreakdownData?.sumOfDiscovery / totalKeywordsCount) *
      100
    ).toFixed();
    setDiscoveyKeywords(discoveryKeywords);
  }, [totalValueBuisness]);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const headCells = [
    { id: "no", alignment: "left", label: t("serial_number") },
    { id: "br_keywords", alignment: "left", label: t("branded_keywords") },
    { id: "", alignment: "", label: "" },
  ];
  const headCells2 = [
    { id: "no", alignment: "left", label: t("serial_number") },
    { id: "br_keywords", alignment: "left", label: t("discovery_keywords") },
    { id: "", alignment: "", label: "" },
  ];

  return (
    <>
      <Grid container className={classes.resultsMainGrid}>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12}>
            {" "}
            <Typography className={classes.totalValueText}>
              {kFormatter(totalKeywordsCount)}
            </Typography>
            <Typography className={classes.totalValuesubText}>
              <VisibilityIcon
                fontSize="small"
                className={classes.visibilityIcon}
              />{" "}
              {t("Profile_subtext_action")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/* <span style={{ marginLeft: "-10px" }}>
              <Checkbox
                defaultChecked
                sx={{
                  "&.Mui-checked": {
                    color: "#13CF8F",
                  },
                  "&:hover": {
                    color: "#13CF8F",
                  },
                }}
              />
            </span> */}
            {t("branded_keywords")}{" "}
            <Typography className={classes.headingConversion}>
              {isNaN(brandedKeywords) ? "0%" : brandedKeywords + "%"}
            </Typography>
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {t("tooltipText_branded_keywords")}
                </h1>
              }
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*  {graphData?.ACTIONS_DRIVING_DIRECTIONS} */}
                {/* {googleInsightData !== null
                  ? kFormatter(googleInsightData?.BUSINESS_DIRECTION_REQUESTS)
                  : "0"} */}
                {searchesBreakdownData !== null
                  ? kFormatter(searchesBreakdownData?.sumOfBranded)
                  : "0"}
              </Typography>
              {type === "month" &&
                searchesBreakdownData?.percentageChangeBranded !== undefined &&
                searchesBreakdownData?.percentageChangeBranded !== "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1
                        style={{ fontSize: "12px" }}
                      >{`vs ${dataSet4?.labels[0]}`}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {branded === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          branded === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {searchesBreakdownData !== null
                          ? checkNumber(
                              searchesBreakdownData?.percentageChangeBranded !==
                                "NaN"
                                ? searchesBreakdownData?.percentageChangeBranded
                                : "0",
                              "branded"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/* <span style={{ marginLeft: "-10px" }}>
              <Checkbox
                defaultChecked
                sx={{
                  "&.Mui-checked": {
                    color: "#F8A92B",
                  },
                  "&:hover": {
                    color: "#F8A92B",
                  },
                }}
              />
            </span> */}
            {t("discovery_keywords")}{" "}
            <Typography className={classes.headingWeb}>
              {isNaN(discoveryKeywords) ? "0%" : discoveryKeywords + "%"}
            </Typography>
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {t("tooltipText_discovery_keywords")}
                </h1>
              }
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip>
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*   {graphData?.ACTIONS_WEBSITE} */}
                {/* {googleInsightData !== null
                  ? kFormatter(googleInsightData?.WEBSITE_CLICKS)
                  : "0"} */}
                {searchesBreakdownData !== null
                  ? kFormatter(searchesBreakdownData?.sumOfDiscovery)
                  : "0"}
              </Typography>
              {type === "month" &&
                searchesBreakdownData?.percentageChangeDiscovery !==
                  undefined &&
                searchesBreakdownData?.percentageChangeDiscovery !== "0.00" && (
                  <BootstrapTooltip
                    title={
                      <h1
                        style={{ fontSize: "12px" }}
                      >{`vs ${dataSet4?.labels[0]}`}</h1>
                    }
                  >
                    <Box className={classes.percentageBox}>
                      {discovery === "positive" ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ color: "#13CF8F" }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ color: "#FC3652" }}
                        />
                      )}

                      <Typography
                        className={
                          discovery === "positive"
                            ? classes.chartSubHeadingPercentPositive
                            : classes.chartSubHeadingPercentNegative
                        }
                      >
                        {searchesBreakdownData !== null
                          ? checkNumber(
                              searchesBreakdownData?.percentageChangeDiscovery !==
                                "NaN"
                                ? searchesBreakdownData?.percentageChangeDiscovery
                                : "0",
                              "discovery"
                            ) + "%"
                          : "0"}
                      </Typography>
                    </Box>
                  </BootstrapTooltip>
                )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12} className={classes.graphGrid}>
        {metricsLoading ? (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
              alignItems: "center",
              borderLeft: "1px solid #9d9d9d",
              borderBottom: "1px solid #9d9d9d",
              paddingX: "8px",
            }}
          >
            <WaveSpinner />

            {/*   <DominoSpinner /> */}
          </Box>
        ) : (
          <Line data={dataSet4} options={options} height={100} />
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} mt={8}>
          <TableContainer
            sx={{ border: "1px solid #E0E0E0" }}
            className={{ root: classes.customTableContainer }}
            component={Paper}
          >
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <CustomizedTableHead sx={{ height: "0px" }}>
                <CustomizedTableRow>
                  {headCells.map((headCell) => (
                    <CustomizedTableCell
                      sx={{ fontWeight: 700 }}
                      key={headCell.id}
                      align={headCell.alignment}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </CustomizedTableCell>
                  ))}
                </CustomizedTableRow>
              </CustomizedTableHead>
              {visibleItemsBranded?.length > 0 ? (
                <TableBody>
                  {visibleItemsBranded?.map((item, index) => {
                    return (
                      <CustomizedTableRow tabIndex={-1}>
                        <CustomizedTableCell
                          sx={{ cursor: "pointer", width: "70px" }}
                          align="left"
                        >
                          {index + 1}
                        </CustomizedTableCell>

                        <CustomizedTableCell
                          sx={{ cursor: "pointer" }}
                          align="left"
                        >
                          {item?.keyword?.charAt(0)?.toUpperCase() +
                            item?.keyword?.slice(1)}
                        </CustomizedTableCell>

                        <CustomizedTableCell
                          align="center"
                          sx={{ cursor: "pointer" }}
                        >
                          {item?.totalValue}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <CustomizedTableRow>
                  <CustomizedTableCell colSpan={3} align="center">
                    {" "}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "35vh",
                      }}
                    >
                      <Typography>{t("branded_not_found")}</Typography>
                    </Grid>
                  </CustomizedTableCell>
                </CustomizedTableRow>
              )}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} mt={8}>
          <TableContainer
            sx={{ border: "1px solid #E0E0E0" }}
            className={{ root: classes.customTableContainer }}
            component={Paper}
          >
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <CustomizedTableHead sx={{ height: "0px" }}>
                <CustomizedTableRow>
                  {headCells2.map((headCell) => (
                    <CustomizedTableCell
                      sx={{ fontWeight: 700 }}
                      key={headCell.id}
                      align={headCell.alignment}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </CustomizedTableCell>
                  ))}
                </CustomizedTableRow>
              </CustomizedTableHead>
              {visibleItemsDiscovery?.length > 0 ? (
                <TableBody>
                  {visibleItemsDiscovery?.map((item, index) => {
                    return (
                      <CustomizedTableRow tabIndex={-1}>
                        <CustomizedTableCell
                          sx={{ cursor: "pointer", width: "70px" }}
                          align="left"
                        >
                          {index + 1}
                        </CustomizedTableCell>

                        <CustomizedTableCell
                          sx={{ cursor: "pointer" }}
                          align="left"
                        >
                          {item?.keyword?.charAt(0)?.toUpperCase() +
                            item?.keyword?.slice(1)}
                        </CustomizedTableCell>

                        <CustomizedTableCell
                          align="center"
                          sx={{ cursor: "pointer" }}
                        >
                          {item?.totalValue}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <CustomizedTableRow>
                  <CustomizedTableCell colSpan={3} align="center">
                    {" "}
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "35vh",
                      }}
                    >
                      <Typography>{t("discovry_not_found")}</Typography>
                    </Grid>
                  </CustomizedTableCell>
                </CustomizedTableRow>
              )}
            </Table>
          </TableContainer>
        </Grid>
        {searchesBreakdownData?.brandedKeywords.length > 5 ||
        searchesBreakdownData?.discoveryKeywords.length > 5 ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ alignItems: "start", padding: "12px" }}
          >
            <Button onClick={handleViewMore}>
              <Typography className={classes.viewMoreButton}>
                {itemsToShow === initialItemsToShow
                  ? t("View_More")
                  : t("View_Less")}
              </Typography>
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default SearchesBreakdown;
