import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import useAuth from "../../../../hooks/useAuth";

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
  MenuItem,
  Menu,
  Button,
  Autocomplete,
  createFilterOptions,
  TextField,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { spacing } from "@mui/system";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import NotFound from "../../../../components/NotFound/NotFound";
import FeatureRequestModel from "../../../../components/Models/DeleteModal/FeatureRequestModel";

const Paper = styled(MuiPaper)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#13CF8F",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  modalCompanyTitle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  modalCircularProgress: {
    width: "25px",
    height: "25px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  buttonAccept: {
    color: "white",
    backgroundColor: "#06BDFF",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    "&:hover": { backgroundColor: "#0638C1", color: "white" },
  },
  buttonReject: {
    color: "white",
    backgroundColor: "#FC3652",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    marginLeft: "8px",
    "&:hover": { backgroundColor: "#e37685", color: "white" },
  },
  primaryChip: {
    background: "#13CF8F",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    width: "80px",
    marginLeft: "-5px",
  },
  primaryChipTypo: {
    fontSize: "10px",
    fontWeight: "400",
    marginY: "auto",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    justifyContent: "center",
  },
  proceedButton: {
    height: "32px",
    width: "81px",
    padding: "6px 8px",
    color: "#FFF",
    background: "#06BDFF",
    fontWeight: 700,
    fontSize: "12px",
    borderRadius: "8px",
    "&:hover": { backgroundColor: "#0638C1", color: "white" },
  },
}));

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    getAllRequestedCompanies,
    t,
    handleChangeCheckButton,
    selectAll,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    getAllRequestedCompanies();
  };

  const headCells = [
    /*  {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ),
    }, */
    { id: "id", alignment: "left", label: t("company_id") },
    { id: "name", alignment: "left", label: t("CompanyyName") },
    { id: "userName", alignment: "left", label: t("user_name") },
    { id: "user_email", alignment: "left", label: t("user_email") },
    { id: "UserTableRole", alignment: "left", label: t("user_role") },
    /*  { id: "request_type", alignment: "left", label: t("request_type") },
    { id: "customer", alignment: "left", label: t("requestedFeatures") }, */
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function ProductActivation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [filterWithName, setFilterWithName] = useState("");
  const [filterWithFeatureName, setFilterWithFeatureName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [bulkButton, setBulkButton] = useState(false);
  const [selectedRequestData, setselectedRequestData] = useState([]);
  const [openSingleRejectModel, setOpenSingleRejectModel] = useState(false);
  const [openSingleAcceptModel, setOpenSingleAcceptModel] = useState(false);
  const [openBulkRejectModel, setOpenBulkRejectModel] = useState(false);
  const [openBulkAcceptModel, setOpenBulkAcceptModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const [openFeaturesModel, setOpenFeaturesModel] = useState(false);
  const [search, setSearch] = useState(false);

  const featuresArray = [
    {
      name: "Account Management",
    },
    {
      name: "Emotion AI",
    },
    {
      name: "Listing technology",
    },
    {
      name: "Post management",
    },
    {
      name: "Review management",
    },
    {
      name: "Tasks",
    },
    {
      name: "User Management",
    },
  ];

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getAllRequestedCompanies();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);
  useEffect(() => {
    getAllRequestedCompanies();
  }, [rowsPerPage, page, filterWithFeatureName]);

  useEffect(() => {
    if (selectedRequestData?.length !== allCompanies?.rows?.length) {
      setSelectAll(false);
    } else {
      //  setSelectAll(true);
    }
  }, [selectedRequestData]);

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    setBulkButton(true);
    let dummyArr = [];
    if (e.target.checked) {
      for (var i = 0; i < allCompanies?.rows?.length; i++) {
        selectedRequestData?.push(allCompanies?.rows[i]?.id);
        dummyArr?.push(allCompanies?.rows[i]?.id);
      }

      const uniqueTasks = new Set(selectedRequestData);
      {
      }
      setselectedRequestData([...uniqueTasks]);
      // dummyArr([...uniqueTasks]);
    } else {
      setselectedRequestData([]);
      // dummyArr([]);
    }

    if (selectedRequestData?.length === dummyArr?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleTaskChecked = (e, index, requestDataId, requestData) => {
    setBulkButton(true);
    if (allCompanies?.rows?.length === selectedRequestData?.length) {
      setselectedRequestData([]);
      setSelectAll(false);
    } else {
      setSelectAll(true);
      setselectedRequestData(allCompanies?.rows);
    }
    if (e.target.checked) {
      selectedRequestData.push(requestData);
    } else {
      var locationIndex = selectedRequestData.indexOf(requestDataId);
      selectedRequestData.splice(locationIndex, 1);
    }

    setselectedRequestData([...selectedRequestData]);
  };

  console.log(selectedRequestData, "selectedRequestData");

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    //  setSelectedData(data);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getAllRequestedCompanies = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyFeatures/requests?limit=${rowsPerPage}&pageNumber=${
          page + 1
        }&subscribedRequested=true&unSubscribedRequested=true${
          filterWithName !== "" ? "&search=" + filterWithName : ""
        }${
          filterWithFeatureName !== null
            ? "&featureName=" + filterWithFeatureName?.name
            : ""
        }`
      );
      if (res.status === 200) {
        if (res?.data?.data?.length === 0) {
          setAllCompanies("noData");
        } else {
          setSearch(false);
          setAllCompanies(res.data.data);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllCompanies("noData");
    }
  };

  const filterByName = (e) => {
    setSearch(true);
    setFilterWithName(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseConfirmBox = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestForEmotionAI = async () => {
    setRequestLoading(true);

    try {
      const res = await api.patch(`/sentimentalAnalysis/location/script`, {
        // locationIds: locationIds,
      });
      if (res.status === 200) {
        setRequestLoading(false);
        setOpen(false);
        setCompanyId(null);
        setAllLocation([]);

        toast.success(res?.data.message);
        setFilterWithName("");
      }
    } catch (error) {
      setOpen(false);

      setAllLocation([]);
      setCompanyId(null);
      setCompanyId(null);

      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleRejectRequest = async () => {
    try {
      /*   const res = await api.delete(
        `sentimentalAnalysis/location/rejectRequestForSentimentalAnalysis/${selectedData}`
      ); */
      setRequestLoading(true);
      let locationIds = [];

      /*    if (locationId?.length > 0) {
        locationId?.map((item) => {
          locationIds.push(item?.id);
        });
      } */
      const res = await api.patch(
        `/sentimentalAnalysis/location/rejectRequestForSentimentalAnalysis`,
        {
          //   locationIds: locationIds?.length > 0 ? locationIds : [],
        }
      );

      if (res.status === 200) {
        setSelectedData(null);

        setOpenDefaultModel(false);
        setRequestLoading(false);
        setOpen(false);

        setBulkButton(false);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      setOpenDefaultModel(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleOpenSingleRejectModel = () => {
    setOpenSingleRejectModel(true);
  };
  const handleCloseSingleRejectModel = () => {
    setOpenSingleRejectModel(false);
  };
  const handleOpenSingleAcceptModel = () => {
    setOpenSingleAcceptModel(true);
  };
  const handleCloseSingleAcceptModel = () => {
    setOpenSingleAcceptModel(false);
  };

  const handleOpenBulkeRejectModel = () => {
    setOpenBulkRejectModel(true);
  };
  const handleCloseBulkeRejectModel = () => {
    setOpenBulkRejectModel(false);
  };
  const handleOpenBulkAcceptModel = () => {
    setOpenBulkAcceptModel(true);
  };
  const handleCloseBulkAcceptModel = () => {
    setOpenBulkAcceptModel(false);
  };
  const handleSelectFeature = (value) => {
    setFilterWithFeatureName(value);
  };

  const handleOpenFeaturesModel = (rowData) => {
    setSelectedData(rowData);
    setOpenFeaturesModel(true);
  };
  const handleCloseFeaturesModel = () => {
    setOpenFeaturesModel(false);
  };

  console.log(selectedRequestData, "selectedRequestData");

  const handleRejectApprovalRequest = async () => {
    setRequestLoading(true);

    let data = [];
    if (selectedRequestData?.length > 0) {
      selectedRequestData?.map((item) => {
        let obj = {
          appId: item?.appId,
          subscribed:
            item?.unSubscribedRequested === true
              ? false
              : item?.subscribedRequested === true
              ? true
              : true, // this mean approval of feature,// this mean approval of feature,
          companyId: item?.companyId,
        };
        data?.push(obj);
      });
    }

    try {
      const res = await api.patch(`/companyFeatures/request`, {
        data: data,
      });
      if (res.status === 200) {
        setselectedRequestData([]);
        setRequestLoading(false);
        handleCloseBulkeRejectModel();
        getAllRequestedCompanies();
        toast.success(t("toast_subscriptions"));
      }
    } catch (error) {
      handleCloseBulkeRejectModel();
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };
  const handleAcceptApprovalRequestBulk = async () => {
    setRequestLoading(true);

    let data = [];
    if (selectedRequestData?.length > 0) {
      selectedRequestData?.map((item) => {
        let obj = {
          appId: item?.appId,
          subscribed:
            item?.unSubscribedRequested === true
              ? false
              : item?.subscribedRequested === true
              ? true
              : true, // this mean approval of feature,
          companyId: item?.companyId,
        };
        data?.push(obj);
      });
    }

    try {
      const res = await api.patch(`/companyFeatures/request`, {
        data: data,
      });
      if (res.status === 200) {
        setselectedRequestData([]);
        setRequestLoading(false);
        handleCloseBulkAcceptModel();
        getAllRequestedCompanies();
        toast.success(t("toast_subscriptions"));
      }
    } catch (error) {
      handleCloseBulkAcceptModel();
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };
  return (
    <>
      {openFeaturesModel && (
        <FeatureRequestModel
          handleClose={handleCloseFeaturesModel}
          open={openFeaturesModel}
          title={t("Subscription_Requests")}
          selectedCompany={selectedData}
          getAllRequestedCompanies={getAllRequestedCompanies}
        />
      )}
      {openSingleRejectModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openSingleRejectModel}
          onClose={handleCloseSingleRejectModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Product_Activation_Request")}
            loading={requestLoading}
            description={t("Product_Activation_RequestDes_Reject")}
            onConfirm={handleRejectRequest}
            onCancel={handleCloseSingleRejectModel}
          />
        </Dialog>
      )}
      {openSingleAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openSingleAcceptModel}
          onClose={handleCloseSingleAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Product_Activation_Request")}
            loading={requestLoading}
            description={t("Product_Activation_RequestDes_Accept")}
            onConfirm={handleRejectRequest}
            onCancel={handleCloseSingleAcceptModel}
          />
        </Dialog>
      )}
      {openBulkRejectModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openBulkRejectModel}
          onClose={handleCloseBulkeRejectModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Product_Activation_Request")}
            loading={requestLoading}
            description={t("Product_ActivationBulk_RequestDes_Reject")}
            onConfirm={handleRejectApprovalRequest}
            onCancel={handleCloseBulkeRejectModel}
          />
        </Dialog>
      )}
      {openBulkAcceptModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openBulkAcceptModel}
          onClose={handleCloseBulkAcceptModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Product_Activation_Request")}
            loading={requestLoading}
            description={t("Product_ActivationBulk_RequestDes_Accept")}
            onConfirm={handleAcceptApprovalRequestBulk}
            onCancel={handleCloseBulkAcceptModel}
          />
        </Dialog>
      )}

      <Helmet title="Locations" />
      <TitleHeader
        title={t("ProductActivation")}
        //  subHeading={t("LocationList")}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getAllRequestedCompanies}
                placeholder={t("filter_text_products")}
                filterWithName={filterWithName}
              />
            </Grid>
            {/*    <Grid item xs={2.5} sm={2.5} md={2.5}>
          
              <Autocomplete
                // multiple
                disablePortal
                id="combo-box-demo"
                options={featuresArray?.length > 0 ? featuresArray : []}
                getOptionLabel={(option) => option?.name}
                className="Autocomplete-field"
                value={filterWithFeatureName}
                onChange={(event, value) => {
                  handleSelectFeature(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />

                    <Typography sx={{ textTransform: "capitalize" }}>
                      {" "}
                      {option?.name}{" "}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("filter_Feature_name")}
                    {...params}
                    required
                    name="language"
                  />
                )}
              ></Autocomplete>
            
            </Grid> */}
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
              <CommonButton
                alignment={true}
                onSubmit={getAllRequestedCompanies}
                label={t("Search")}
                leftMargin={true}
              />
            </Grid>
          </Grid>
          {bulkButton === true && (
            <>
              {selectedRequestData?.length > 0 && (
                <Grid container sx={{ marginTop: "16px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      className={classes.buttonAccept}
                      onClick={handleOpenBulkAcceptModel}
                    >
                      {t("accept_button")}
                    </Button>
                    <Button
                      className={classes.buttonReject}
                      onClick={handleOpenBulkeRejectModel}
                    >
                      {t("Reject")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {allCompanies !== null && allCompanies?.count > 0 ? (
            <Grid container marginTop="16px">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      t={t}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={allCompanies?.count}
                      getAllRequestedCompanies={getAllRequestedCompanies}
                      handleChangeCheckButton={handleChangeCheckButton}
                      selectAll={selectAll}
                      selectedRequestData={selectedRequestData}
                      allCompanies={allCompanies}
                    />
                    <TableBody>
                      {allCompanies?.rows?.length > 0 &&
                        allCompanies?.rows?.map((row, index) => {
                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                            >
                              {/*   <CustomizedTableCell hover>
                                <Checkbox
                                  checked={
                                    selectedRequestData?.length > 0 &&
                                    selectedRequestData?.find(
                                      (item) =>
                                        item?.companyId === row?.companyId &&
                                        item?.appId === row?.appId
                                    ) !== undefined
                                  }
                                  onChange={(e) => {
                                    handleTaskChecked(e, index, row.id, row);
                                  }}
                                  //   inputProps={{ "aria-label": "controlled" }}
                                  label=""
                                />
                              </CustomizedTableCell> */}
                              <CustomizedTableCell>
                                {row.companyId}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "250px" }}
                              >
                                {row?.Company !== null
                                  ? row?.Company?.name
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row?.User !== null
                                  ? row?.User?.firstName
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    row?.User?.firstName?.slice(1) +
                                    " " +
                                    row?.User?.lastName
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row?.User !== null ? row?.User?.email : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row?.User !== null &&
                                row?.User?.role !== null ? (
                                  row?.User?.role?.isPrimary === true ? (
                                    <Item className={classes.primaryChip}>
                                      <Typography
                                        className={classes.primaryChipTypo}
                                      >
                                        {t("Primary_text")}
                                      </Typography>
                                    </Item>
                                  ) : (
                                    (
                                      row?.User?.role?.name
                                        ?.charAt(0)
                                        .toUpperCase() +
                                      row?.User?.role?.name?.slice(1)
                                    ).replaceAll("-", " ")
                                  )
                                ) : (
                                  "--"
                                )}
                              </CustomizedTableCell>

                              <CustomizedTableCell
                                padding="none"
                                align="center"
                              >
                                <Button
                                  className={classes.proceedButton}
                                  onClick={() => handleOpenFeaturesModel(row)}
                                >
                                  {t("Proceed")}
                                </Button>
                                {/*   <Box mr={2}>
                                  <IconButton
                                    onClick={(e) =>
                                      handleClick(e, row?.id, row)
                                    }
                                    variant="outlined"
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenSingleAcceptModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                      >
                                        {t("approve")}
                                      </Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={(e) => {
                                        handleOpenSingleRejectModel();
                                        closeMenu();
                                      }}
                                    >
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                      >
                                        {t("Reject")}
                                      </Typography>
                                    </MenuItem>
                                  </Menu>
                                </Box> */}
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })}
                    </TableBody>
                    {open && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleCloseConfirmBox}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Delete
                          title={t("Emotion_AI_Request")}
                          description={t("Emotion_Ai_des")}
                          onConfirm={handleRequestForEmotionAI}
                          loading={requestLoading}
                          onCancel={handleCloseConfirmBox}
                        />
                      </Dialog>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allCompanies?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          ) : (
            allCompanies === "noData" && (
              <NotFound text={t("no_request_found")} />
            )
          )}
        </>
      )}
    </>
  );
}

export default ProductActivation;
